import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BasicModalComponent } from '../../components/modals/basic-modal/basic-modal.component';
import { EmailExportConfirmModalComponent } from '../../components/modals/email-export-confirm-modal/email-export-confirm-modal.component';
import { GungError, GungErrorHandlerModalComponent } from '../../components/modals/gung-error-handler-modal/gung-error-handler-modal.component';
import { InputSaveModalComponent } from '../../components/modals/input-save-modal/input-save-modal.component';
import { JsonViewModal, JsonViewModalComponent } from '../../components/modals/json-view-modal/json-view-modal.component';
import { ListManagementModalComponent } from '../../components/modals/list-management-modal/list-management-modal.component';
import { ModalConfirmYesNoComponent } from '../../components/modals/modal-confirm-yes-no/modal-confirm-yes-no.component';
import { NoteModalComponent } from '../../components/modals/note-modal/note-modal.component';
import { GungDatepickerModalComponent } from '../../components/modals/gung-datepicker-modal/gung-datepicker-modal.component';
import { InputModalComponent } from '../../components/modals/input-modal/input-modal.component';
import { UploadModalComponent } from '../../components/upload-modal/upload-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CommonModalService {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  constructor(protected ngbModal: NgbModal) { }

  openConfirmYesNoModal(
    title?: string,
    description?: string,
    modalOption?: NgbModalOptions,
    btnTrue = 'YES',
    btnFalse = 'NO',
    htmlDescription?: boolean,
    reverseButtonOrder?: boolean
  ): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      ...modalOption
    };
    const ref = this.ngbModal.open(ModalConfirmYesNoComponent, option);
    const componentInstance = ref.componentInstance as ModalConfirmYesNoComponent;
    componentInstance.title = title;
    componentInstance.description = description;
    componentInstance.btnTrue = btnTrue;
    componentInstance.btnFalse = btnFalse;
    componentInstance.htmlDescription = htmlDescription;
    componentInstance.reverseButtonOrder = reverseButtonOrder;

    return ref.result;
  }

  openBasicModal(
    title?: string,
    htmlContent?: string,
    modalOption?: NgbModalOptions,
    hideFooter?: boolean
  ): Promise<any> {
    const option: NgbModalOptions = {
      keyboard: true,
      centered: true,
      ...modalOption
    };
    const ref = this.ngbModal.open(BasicModalComponent, option);
    const componentInstance = ref.componentInstance as BasicModalComponent;
    componentInstance.title = title;
    componentInstance.htmlContent = htmlContent;
    componentInstance.hideFooter = hideFooter;

    return ref.result;
  }

  openInputSaveModal(
    label: string,
    title: string,
    value: string,
    isTextArea = false,
    modalOption?: NgbModalOptions
  ): Promise<any> {
    const id = InputSaveModalComponent.name;

    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(InputSaveModalComponent, option);
    ref.componentInstance.label = label;
    ref.componentInstance.title = title;
    ref.componentInstance.value = value;
    ref.componentInstance.isTextArea = isTextArea;
    ref.componentInstance.delegate = this;

    this.modalRef.set(id, ref);

    return ref.result;
  }

  openEmailConfirmExportModal(email) {
    const id = EmailExportConfirmModalComponent.name;

    const ref = this.ngbModal.open(EmailExportConfirmModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.email = email;

    this.modalRef.set(id, ref);

    return ref.result;
  }

  openEditNoteModal(note: string, modalOption?: NgbModalOptions, maxLength?: number): Promise<any> {
    const id = NoteModalComponent.name;

    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(NoteModalComponent, option);
    ref.componentInstance.note = note;
    ref.componentInstance.maxLength = maxLength;
    ref.componentInstance.delegate = this;

    this.modalRef.set(id, ref);

    return ref.result;
  }

  openGungErrorHandler(error: GungError) {
    const id = GungErrorHandlerModalComponent.name;

    const ref = this.ngbModal.open(GungErrorHandlerModalComponent, {
      size: 'lg',
      keyboard: true
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.error = error;
    ref.result.then(
      result => { },
      reason => { }
    );

    this.modalRef.set(id, ref);
  }

  openJSONModal(data: JsonViewModal, modalOption?: NgbModalOptions) {
    const id = JsonViewModalComponent.name;
    const option: NgbModalOptions = {
      size: 'lg',
      keyboard: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(JsonViewModalComponent, option);
    ref.componentInstance.delegate = this;
    ref.componentInstance.data = data;
    ref.result.then(
      result => { },
      reason => { }
    );

    this.modalRef.set(id, ref);
  }

  openListManagementModal(modalTitle: string, inputLabel: string, currentList: any[] = [], modalOption?: NgbModalOptions) {
    const id = ListManagementModalComponent.name;
    const option: NgbModalOptions = {
      size: 'lg',
      keyboard: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(ListManagementModalComponent, option);
    ref.componentInstance.delegate = this;
    ref.componentInstance.currentList = currentList;
    ref.componentInstance.modalTitle = modalTitle;
    ref.componentInstance.inputLabel = inputLabel;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openDatepickerModal(
    label: string,
    title: string,
    selectedDate?: Date,
    modalOption?: NgbModalOptions
  ): Promise<any> {
    const id = GungDatepickerModalComponent.name;

    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(GungDatepickerModalComponent, option);
    ref.componentInstance.label = label;
    ref.componentInstance.title = title;
    ref.componentInstance.selectedDate = selectedDate;
    ref.componentInstance.delegate = this;

    this.modalRef.set(id, ref);

    return ref.result;
  }

  openInputModalComponent(modalTitle: string, inputLabel: string, type: 'text' | 'textarea' = 'text', labelType: 'text' | 'html' = 'text', btnOk: string = 'OK', btnCancel: string = 'CANCEL', modalOption?: NgbModalOptions) {
    const id = InputModalComponent.name;
    const option: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(InputModalComponent, option);
    ref.componentInstance.delegate = this;
    ref.componentInstance.title = modalTitle;
    ref.componentInstance.label = inputLabel;
    ref.componentInstance.btnOk = btnOk;
    ref.componentInstance.btnCancel = btnCancel;
    ref.componentInstance.type = type;
    ref.componentInstance.labelType = labelType;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openUploadModalComponent(
    uploadMethod: (file: File[]) => Promise<any>,
    enableDragDrop?: boolean,
    multiple?: boolean,
    accept?: string,
    modalOptions?: NgbModalOptions
  ) {
    const id = UploadModalComponent.name;
    const option: NgbModalOptions = {
      backdrop: true,
      size: 'lg',
      keyboard: true,
      centered: true,
      scrollable: true,
      ...modalOptions
    };
    const ref = this.ngbModal.open(UploadModalComponent, option);
    ref.componentInstance.delegate = this;
    ref.componentInstance.uploadMethod = uploadMethod;
    ref.componentInstance.multiple = multiple;
    ref.componentInstance.accept = accept;
    ref.componentInstance.enableDragDrop = enableDragDrop;

    this.modalRef.set(id, ref);
    return ref.result;
  }

}
