import { Component as t, createElement as e } from "../../../../external/preact/dist/preact.js";
import r from "./GetDeviceFingerprint.js";
import s from "./utils.js";
import { DF_VERSION as o } from "../../constants.js";
class i extends t {
  setStatusComplete(t) {
    this.setState({
      status: "complete"
    }, () => {
      this.props.onComplete(t);
    });
  }
  render({
    loadingContext: t
  }, {
    dfpURL: o
  }) {
    return "retrievingFingerPrint" === this.state.status ? e("div", {
      className: "adyen-checkout-risk__device-fingerprint--wrapper",
      style: {
        position: "absolute",
        width: 0,
        height: 0
      }
    }, e(r, {
      loadingContext: t,
      dfpURL: o,
      onCompleteFingerprint: t => {
        this.setStatusComplete(t);
      },
      onErrorFingerprint: t => {
        this.props.onError(s(t.errorCode)), this.setStatusComplete(t.result);
      }
    })) : null;
  }
  constructor(t) {
    super(t), t.clientKey && (this.state = {
      status: "retrievingFingerPrint",
      dfpURL: `${this.props.loadingContext}assets/html/${t.clientKey}/dfp.${o}.html`
    });
  }
}
var n, p, a;
a = {
  onComplete: () => {},
  onError: () => {}
}, (p = "defaultProps") in (n = i) ? Object.defineProperty(n, p, {
  value: a,
  enumerable: !0,
  configurable: !0,
  writable: !0
}) : n[p] = a;
export { i as default };
