<div *ngFor="let product of mappedData">
  <div class="card product-list-card">
    <div class="card-body mt-3 flex-column flex-sm-row">
      <div class="product-image d-none d-md-block">
        <div class="fav-product">
          <lib-favourite-product-icon [id]="product.id"></lib-favourite-product-icon>
        </div>
        <ng-template ngTemplateOutlet
          *ngIf="!product.extra.oldProduct; then productImg; else oldProductImg"></ng-template>
        <ng-template #productImg>
          <a routerLink="/product/{{ product.id }}">
            <img class="img-fluid" *ngIf="!!product.extra.images && !!product.extra.images[0]" src="https://cdn1.gung.io/fit-in/250x250/filters:etag({{ product.extra.images[0].s3ETag }})/{{
                product.extra.images[0].s3Uri
              }}" alt="" />
          </a>
        </ng-template>
        <ng-template #oldProductImg>
          <div class="old-product-image">
            <a routerLink="/product/{{ product.id }}">
              <img src="assets/old-product.png" class="img-fluid" />
              <div class="text text-center" translate>OLD_PRODUCT_IMAGE</div>
            </a>
          </div>
        </ng-template>
      </div>

      <div class="product-info-wrapper p-3">
        <div class="product-name mb-1 h5 font-weight-bold">
          <a class="product-link" routerLink="/product/{{ product.id }}">
            @if (product.extra.i18n[currentLang]?.artbeskrspec) {
            {{ product.extra.i18n[currentLang].artbeskrspec }}
            } @else if (product.extra.ar?.artbeskrspec) {
            {{ product.extra.ar.artbeskrspec }}
            } @else {
            {{ product.extra.ar.artbeskr }}
            }
          </a>
        </div>
        @if (isSales) {
        <div class="product-text">({{ product.extra.ar.artbeskr }})</div>
        }
        @if (product.extra?.i18n[currentLang]?.itemspecweb) {
        <div class="product-text">
          {{ product.extra?.i18n[currentLang]?.itemspecweb }}
        </div>
        }
        @if (getSecondaryDescription(product) !== '') {
        <div class="product-text">
          <span style="font-weight: bold" translate>DESCRIPTION</span><span style="font-weight: bold">:</span>
          {{ getSecondaryDescription(product) }}
        </div>
        }
        <div class="h5 font-weight-bold price d-block">
          <span translate>PRICE</span>:
          @if (displayStaffedPrices && product.price) {
          <hl-display-price [price]="product.price" priceLevelsDisplayType="FULL" displayOnlyPrice="true" type="STAFFED"
            [assortmentType]="getAssortmentType(product.extra)"></hl-display-price>
          } @else {
          <span *ngIf="product.price">{{
            product.price.customerGrossPrice.value
            | gungCurrency: product.price.customerGrossPrice.currencyCode : 'symbol-narrow' : '1.2-2' : 'sv_SE'
            }}

            <!-- <span *ngIf="product.price.backendPriceLevel.extra.isComplementaryPriceList">(S)</span>   -->
          </span>
          @if (displayDiscountFromPriceList && product.price?.backendPrice?.levels?.[0]?.extra?.prl?.proc1 > 0) {
          <span class="text-disc">(-{{ product.price.backendPrice.levels[0].extra.prl.proc1 }}%)</span>
          }
          <span *ngIf="getAssortmentType(product.extra) != ''">{{ getAssortmentType(product.extra) }}</span>
          }
        </div>

        <div>
          <span class="d-block">
            <span translate>ID</span>: {{ product.id }}
            @if (!!currentCustomer && currentCustomer.extra.kuarMapped?.[product.id]?.artnrkund) {
            &nbsp;({{ currentCustomer.extra.kuarMapped[product.id]?.artnrkund }})
            }
          </span>
          <span class="d-block"
            *ngIf="product.extra.hlPimItem.ItemColourName && hlDisplayMetadataHelperService.getProductColours(product) != ''"><span
              translate>COLOR</span>: {{
            hlDisplayMetadataHelperService.getProductColours(product) }}</span>
          <span *ngIf="productSize && productSize[product.id]" class="d-block"><span translate>SIZE</span>: {{
            productSize[product.id] }}
          </span>
          <span class="d-block"><span translate>PACK_SIZE</span>: {{ product.extra.stepAmount }}</span>

          <span *ngIf="product.extra.ar.antalperpall" class="d-block"><span translate>QTY_PER_PALLET</span>: {{
            product.extra.ar.antalperpall }}</span>
          <span *ngIf="
              isSales &&
              product.price &&
              getAssortmentType(product.extra) === '(W)' &&
              product.extra.ar.q_basecost_start &&
              product.extra.ar.q_basecost_unit
            " class="d-block">
            <span translate>BASECOST_S_U</span>:
            {{
            product.extra.ar.q_basecost_start
            | gungCurrency
            : product.price.customerNetPrice.currencyCode
            : ('symbol-narrow')
            : '1.2-2'
            }}
            /
            {{
            product.extra.ar.q_basecost_unit
            | gungCurrency
            : product.price.customerNetPrice.currencyCode
            : ('symbol-narrow')
            : '1.2-2'
            }}
          </span>
        </div>
        <div>
          <span translate>AVAILABILITY</span>:
          <app-hl-display-availability *ngIf="product?.availability" [availability]="product.availability">
          </app-hl-display-availability>
        </div>
      </div>
      <div class="product-btns mb-3 mt-auto" [ngClass]="{ 'product-btns-kesko': false }">
        <app-hl-display-buy-btn [product]="product" *ngIf="!product.extra.oldProduct" [id]="product.id">
        </app-hl-display-buy-btn>
        <a class="product-link" routerLink="/product/{{ product.id }}">
          <button type="button" class="btn btn-block btn-outline-primary mt-2 w-100" style="white-space: nowrap"
            translate>
            MORE_INFO
          </button>
        </a>
      </div>
    </div>
  </div>
</div>