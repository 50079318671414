import { checkPaymentMethodsResponse as e, processPaymentMethods as t, processStoredPaymentMethods as n } from "./utils.js";
function o(e, t, n) {
  return t in e ? Object.defineProperty(e, t, {
    value: n,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = n, e;
}
class s {
  mapCreatedComponentType(e) {
    return "card" === e ? "scheme" : e;
  }
  has(e) {
    return Boolean(this.paymentMethods.find(t => t.type === this.mapCreatedComponentType(e)));
  }
  find(e) {
    return this.paymentMethods.find(t => t.type === this.mapCreatedComponentType(e));
  }
  constructor(s, r = {}) {
    o(this, "paymentMethods", []), o(this, "storedPaymentMethods", []), e(s), this.paymentMethods = s ? t(s.paymentMethods, r) : [], this.storedPaymentMethods = s ? n(s.storedPaymentMethods, r) : [];
  }
}
export { s as default };
