import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { MetadataService, Product } from 'gung-standard';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductStoreCategoryFilterService extends SimpleConfigBaseFilter<Product> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  SEGMENT: string = 'ProductStoreCategory';
  SEGMENT_META: string = 'ProductStoreCategory_StoreCategoryTitle';

  getName(): string {
    return 'STORE_CATEGORY';
  }

  getOptionIds(item: Product): string[] {
    if (!!item.extra.hlPimProduct && !!item.extra.hlPimProduct[this.SEGMENT]) {
      const productExternalCategoryValue = this.getI18nProductExternalCategory(item);

      return productExternalCategoryValue;
    }

    return [];
  }

  getOptionName(key: string): string {
    return key;
  }

  private getI18nProductExternalCategory(item: Product): string[] {
    // in case not find a product external category for the selected language then use the default

    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    if (!!item.extra.i18n) {
      if (!!item.extra.i18n[selectedLanguage] && !!item.extra.i18n[selectedLanguage][this.SEGMENT_META]) {
        return item.extra.i18n[selectedLanguage][this.SEGMENT_META];
      }

      const defaultLanguage = 'en';

      if (!!item.extra.i18n[defaultLanguage] && !!item.extra.i18n[defaultLanguage][this.SEGMENT_META]) {
        return item.extra.i18n[defaultLanguage][this.SEGMENT_META];
      }
    }

    return [];
  }
}
