import { createElement as t } from "../../../../external/preact/dist/preact.js";
import { useState as e, useRef as a, useEffect as o } from "../../../../external/preact/hooks/dist/hooks.js";
import { KlarnaWidget as n } from "../KlarnaWidget/KlarnaWidget.js";
function i({
  setComponentRef: i,
  ...s
}) {
  const [r, d] = e(null),
    [p, l] = e(),
    [m, c] = e("ready"),
    u = a({
      setAction: t => {
        l(t), d(new Date().getTime());
      },
      setStatus: t => c(t),
      reinitializeWidget: () => {
        d(new Date().getTime());
      }
    });
  return o(() => {
    i(u.current);
  }, [i]), p?.sdkData ? t(n, {
    widgetInitializationTime: r,
    sdkData: p.sdkData,
    paymentMethodType: p.paymentMethodType,
    paymentData: p.paymentData,
    payButton: s.payButton,
    onComplete: s.onComplete,
    onError: s.onError,
    onLoaded: () => {
      s.onActionHandled?.({
        componentType: s.type,
        actionDescription: "sdk-loaded",
        originalAction: p
      }), s.onLoaded();
    }
  }) : s.showPayButton ? s.payButton({
    ...s,
    status: m,
    disabled: "loading" === m,
    classNameModifiers: ["standalone"],
    label: `${this.props.i18n.get("continueTo")} ${s.displayName}`
  }) : null;
}
export { i as KlarnaContainer };
