import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'inspector';
import { Observable, first, map, of, tap, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GungBackendFeatureService {
  protected features: GungFeatureMap = undefined;
  protected baseUrl = 'public/json/features';

  constructor(
    protected http: HttpClient
  ) { }

  public isActivated(featureId: string): Observable<boolean> {
    if (!this.features) {
      return this.getAvailableFeatures().pipe(
        first(),
        map(features => {
          return !!features[featureId];
        })
      );
    }

    return of(!!this.features[featureId]);
  }

  public getAvailableFeatures(noCache?: boolean): Observable<GungFeatureMap> {
    const headers = {
      maxAge: noCache ? '-1' : '300'
    };
    // When we don't have the features, or we want to force a reload, fetch the features from the backend.
    return this.http.get<GungFeatureMap>(this.baseUrl, { headers }).pipe(
      // Ensure that we set the current features on the service.
      tap(features => this.features = features),
      catchError((error, caught) => {
        // Handle issue where the backend is old enough that the endpoint does not exist.
        this.features = {};
        return of({});
      })
    );

  }
}

export type GungFeatureMap = { [s: string]: GungFeature };
export interface GungFeature {
  visibleForRoles: string[];
  featureId: string;
  version: string;
  extra: { [s: string]: any };
}
