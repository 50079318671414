function e(e, r, s) {
  return r in e ? Object.defineProperty(e, r, {
    value: s,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = s, e;
}
class r extends Error {
  toString() {
    return `Reason: ${this.reason} / Source: ${this.source} / Scheme: ${this.scheme} / Message: ${this.message}`;
  }
  constructor(r, s, o) {
    super(), e(this, "reason", void 0), e(this, "message", void 0), e(this, "source", void 0), e(this, "scheme", void 0), e(this, "errorFromCardSchemeSdk", void 0);
    const t = "error" in r ? r?.error?.message : r?.message,
      i = "error" in r ? r?.error?.reason : r?.reason;
    this.message = t, this.reason = i, this.source = s, this.scheme = o, this.errorFromCardSchemeSdk = r;
  }
}
export { r as default };
