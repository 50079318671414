import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationConfigService, PriceConfigService } from 'gung-standard';
import { JeevesOrderActionConfigService, JeevesOrderCardListComponent } from 'gung-standard-jeeves';
import { first, map } from 'rxjs';
import { HlDisplayDateUtilService } from '../../../services/hl-display-date-util.service';
import { environment } from './../../../../environments/environment';
import {
  HlDisplayPortalFeaturesService,
  OrderSearchSettingsGroup
} from '../../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-hl-display-jeeves-order-card-list',
  templateUrl: './hl-display-jeeves-order-card-list.component.html',
  styleUrls: ['./hl-display-jeeves-order-card-list.component.css']
})
export class HlDisplayJeevesOrderCardListComponent extends JeevesOrderCardListComponent implements OnInit {
  currentLang = '';
  dateFormat = 'yyyy-MM-dd'; //Fallback date

  isShowOrderTrackingLink = false;

  constructor(
    public configService: JeevesOrderActionConfigService,
    locationConfigService: LocationConfigService,
    protected translate: TranslateService,
    protected dateUtilService: HlDisplayDateUtilService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    public priceConfigService: PriceConfigService
  ) {
    super(configService, locationConfigService, priceConfigService);
  }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.dateUtilService
      .getLangSettingsObservable()
      .pipe(first())
      .subscribe(langSettings => {
        this.dateFormat = langSettings.dateFormat || this.dateFormat;
      });

    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('orderSearchSettingsGroup')
      .pipe(
        first(),
        map(orderSettings => orderSettings as OrderSearchSettingsGroup)
      )
      .subscribe(orderSearchSettings => {
        this.isShowOrderTrackingLink = orderSearchSettings.showOrderTrackingLink || this.isShowOrderTrackingLink;
      });
  }
}
