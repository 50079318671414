import { CTP_IFRAME_NAME as e, createCheckoutPayloadBasedOnScheme as i, createShopperCardsList as t } from "./utils.js";
import s from "./sdks/SrciError.js";
import { SchemeNames as o } from "./sdks/utils.js";
import r from "../../../../utils/uuid.js";
import n from "../../../../core/Errors/AdyenCheckoutError.js";
import { isRejected as a, isFulfilled as d } from "../../../../utils/promise-util.js";
import h from "../errors/TimeoutError.js";
import { executeWithTimeout as c } from "./execute-with-timeout.js";
function l(e, i, t) {
  return i in e ? Object.defineProperty(e, i, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[i] = t, e;
}
var m = function (e) {
  return e.Idle = "Idle", e.Loading = "Loading", e.ShopperIdentified = "ShopperIdentified", e.OneTimePassword = "OneTimePassword", e.Ready = "Ready", e.Login = "Login", e.NotAvailable = "NotAvailable", e;
}({});
class S {
  get shopperAccountFound() {
    return ["Ready", "ShopperIdentified"].includes(this.state);
  }
  get schemes() {
    return this.sdkLoader.schemes;
  }
  updateStoreCookiesConsent(e) {
    this.storeCookies = e;
  }
  async initialize() {
    this.setState("Loading");
    try {
      this.sdks = await this.sdkLoader.load(this.environment), await this.initiateSdks();
      const {
        recognized: e = !1,
        idTokens: i = null
      } = await this.verifyIfShopperIsRecognized();
      if (e) return await this.getShopperProfile(i), void this.setState("Ready");
      if (!this.shopperIdentity) return void this.setState("NotAvailable");
      const {
        isEnrolled: t
      } = await this.verifyIfShopperIsEnrolled(this.shopperIdentity);
      if (t) return void this.setState("ShopperIdentified");
      this.setState("NotAvailable");
    } catch (e) {
      e instanceof s && "REQUEST_TIMEOUT" === e?.reason || e instanceof h ? this.handleTimeout(e) : e instanceof s ? console.warn(`Error at ClickToPayService # init: ${e.toString()}`) : console.warn(e), this.setState("NotAvailable");
    }
  }
  subscribeOnStateChange(e) {
    this.stateSubscriber = e;
  }
  async startIdentityValidation() {
    if (!this.validationSchemeSdk) throw Error("startIdentityValidation: No ValidationSDK set for the validation process");
    try {
      const {
        maskedValidationChannel: e
      } = await this.validationSchemeSdk.initiateIdentityValidation();
      this.identityValidationData = {
        maskedShopperContact: e.replace(/\*/g, "•"),
        selectedNetwork: o[this.validationSchemeSdk.schemeName]
      }, this.setState("OneTimePassword");
    } catch (e) {
      throw this.validationSchemeSdk = null, e;
    }
  }
  async finishIdentityValidation(e) {
    if (!this.validationSchemeSdk) throw Error("finishIdentityValidation: No ValidationSDK set for the validation process");
    const i = await this.validationSchemeSdk.completeIdentityValidation(e);
    await this.getShopperProfile([i.idToken]), this.setState("Ready"), this.validationSchemeSdk = null;
  }
  async checkout(t) {
    if (!t) throw Error("ClickToPayService # checkout: Missing card data");
    const s = this.sdks.find(e => e.schemeName === t.scheme),
      o = await s.checkout({
        srcDigitalCardId: t.srcDigitalCardId,
        srcCorrelationId: t.srcCorrelationId,
        ...(t.isDcfPopupEmbedded && {
          windowRef: window.frames[e]
        }),
        ...(this.storeCookies && {
          complianceSettings: {
            complianceResources: [{
              complianceType: "REMEMBER_ME",
              uri: ""
            }]
          }
        })
      });
    if ("COMPLETE" !== o.dcfActionCode) throw new n("ERROR", `Checkout through Scheme DCF did not complete. DCF Action code received: ${o.dcfActionCode}`);
    return i(t, o, this.environment);
  }
  async logout() {
    if (!this.sdks) throw new n("ERROR", "ClickToPayService is not initialized");
    try {
      const e = this.sdks.map(e => e.unbindAppInstance());
      await Promise.all(e);
    } catch (e) {
      e instanceof s ? console.warn(`Error at ClickToPayService # logout: ${e.toString()}`) : console.warn(e);
    }
    this.shopperCards = null, this.identityValidationData = null, this.validationSchemeSdk = null, this.setState("Login");
  }
  verifyIfShopperIsEnrolled(e) {
    const {
      shopperEmail: i
    } = e;
    return new Promise((e, t) => {
      const s = this.sdks.map(s => c(() => s.identityLookup({
        identityValue: i,
        type: "email"
      }), 5e3, new h({
        source: "identityLookup",
        scheme: s.schemeName,
        isTimeoutTriggeredBySchemeSdk: !1
      })).then(i => {
        i.consumerPresent && !this.validationSchemeSdk && (this.setSdkForPerformingShopperIdentityValidation(s), e({
          isEnrolled: !0
        }));
      }).catch(e => {
        t(e);
      }));
      Promise.allSettled(s).then(() => {
        e({
          isEnrolled: !1
        });
      });
    });
  }
  setState(e) {
    this.state = e, this.stateSubscriber?.(this.state);
  }
  setSdkForPerformingShopperIdentityValidation(e) {
    this.validationSchemeSdk = e;
  }
  handleTimeout(e) {
    const i = e instanceof s ? new h({
      source: e.source,
      scheme: e.scheme,
      isTimeoutTriggeredBySchemeSdk: !0
    }) : e;
    "visa" === i.scheme && (i.setCorrelationId(window.VISA_SDK?.correlationId), window.VISA_SDK?.correlationId ? window.VISA_SDK?.buildClientProfile?.() : window.VISA_SDK?.buildClientProfile?.(this.schemesConfig.visa.srciDpaId)), this.onTimeout?.(i);
  }
  async getShopperProfile(e) {
    return new Promise((i, s) => {
      const o = this.sdks.map(i => i.getSrcProfile(e));
      Promise.allSettled(o).then(e => {
        e.every(a) && s(e[0].reason);
        const o = e.map((e, i) => d(e) && {
          ...e.value,
          scheme: this.sdks[i].schemeName
        }).filter(e => !!e);
        this.shopperCards = t(o), i();
      });
    });
  }
  verifyIfShopperIsRecognized() {
    return new Promise((e, i) => {
      const t = this.sdks.map(t => c(() => t.isRecognized(), 5e3, new h({
        source: "isRecognized",
        scheme: t.schemeName,
        isTimeoutTriggeredBySchemeSdk: !1
      })).then(i => {
        i.recognized && e(i);
      }).catch(e => {
        i(e);
      }));
      Promise.allSettled(t).then(() => {
        e({
          recognized: !1
        });
      });
    });
  }
  initiateSdks() {
    const e = this.sdks.map(e => {
      const i = this.schemesConfig[e.schemeName];
      return c(() => e.init(i, this.srciTransactionId), 5e3, new h({
        source: "init",
        scheme: e.schemeName,
        isTimeoutTriggeredBySchemeSdk: !1
      }));
    });
    return Promise.all(e);
  }
  constructor(e, i, t, s, o) {
    l(this, "sdkLoader", void 0), l(this, "schemesConfig", void 0), l(this, "shopperIdentity", void 0), l(this, "environment", void 0), l(this, "onTimeout", void 0), l(this, "srciTransactionId", r()), l(this, "sdks", void 0), l(this, "validationSchemeSdk", null), l(this, "stateSubscriber", void 0), l(this, "state", "Idle"), l(this, "shopperCards", null), l(this, "identityValidationData", null), l(this, "storeCookies", !1), this.sdkLoader = i, this.schemesConfig = e, this.shopperIdentity = s, this.environment = t, this.onTimeout = o;
  }
}
export { m as CtpState, S as default };
