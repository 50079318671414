import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, first, map, Observable, of } from 'rxjs';
import { debounceTime, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { GungBackendFeatureService, GungFeature as CommonGungFeature } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class BackendFeatureService extends GungBackendFeatureService {
  currentUser: string = null;

  constructor(
    protected http: HttpClient,
    protected authService: AuthService
  ) {
    super(http);
  }

  public isActivated(featureId: string): Observable<boolean> {
    if (!this.features) {
      return this.getAvailableFeatures().pipe(
        first(),
        map(features => {
          this.features = features;
          return !!this.features[featureId];
        })
      );
    }

    return of(!!this.features[featureId]);
  }

  public getAvailableFeatures(nocache?: boolean): Observable<GungFeatureMap> {
    // When we don't have the features, or we want to force a reload, fetch the features from the backend.
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => {
        if (nocache || !this.features || this.currentUser !== user?.username) {
          const url = 'public/json/features';
          const headers = {
            maxAge: '-1'
          };
          return this.http.get<GungFeatureMap>(url, { headers }).pipe(
            // Ensure that we set the current features on the service.
            tap(features => {
              this.features = features;
              this.currentUser = user?.username;
            }),
            catchError((error, caught) => {
              // Handle issue where the backend is old enough that the endpoint does not exist.
              this.features = {};
              return of({});
            })
          );
        }

        // We already have fetched the features, so return the cached values.
        return of(this.features);
      })
    );
  }
}

export type GungFeatureMap = { [s: string]: GungFeature };
export interface GungFeature extends CommonGungFeature { }