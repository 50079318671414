import { createElement as t } from "../../../external/preact/dist/preact.js";
import e from "../BaseElement/BaseElement.js";
import s from "../PayButton/PayButton.js";
import { assertIsDropin as i, getRegulatoryDefaults as n, sanitizeResponse as o, verifyPaymentDidNotFail as r, cleanupFinalResult as a } from "./utils.js";
import h from "../../../core/Errors/AdyenCheckoutError.js";
import { hasOwnProperty as l } from "../../../utils/hasOwnProperty.js";
import { ANALYTICS_SUBMIT_STR as d } from "../../../core/Analytics/constants.js";
import p from "../../../core/Errors/CancelError.js";
function c(t, e, s) {
  return e in t ? Object.defineProperty(t, e, {
    value: s,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[e] = s, t;
}
class m extends e {
  buildElementProps(t) {
    const e = {
        showPayButton: !0,
        ...this.core.getCorePropsForComponent(),
        ...(!!t?.isStoredPaymentMethod ? {} : this.core.paymentMethodsResponse.find(t?.type || this.constructor.type)),
        ...t
      },
      s = i(this);
    this.props = this.formatProps({
      ...this.constructor.defaultProps,
      ...n(this.core.options.countryCode, s),
      ...e
    });
  }
  storeElementRefOnCore(t) {
    t?.isDropin || this.core.storeElementReference(this);
  }
  isAvailable() {
    return Promise.resolve();
  }
  setState(t) {
    this.state = {
      ...this.state,
      ...t
    }, this.onChange();
  }
  showValidation() {
    return this.componentRef && this.componentRef.showValidation && this.componentRef.showValidation(), this;
  }
  setElementStatus(t, e) {
    return this.elementRef?.setStatus(t, e), this;
  }
  setStatus(t, e) {
    return this.componentRef?.setStatus && this.componentRef.setStatus(t, e), this;
  }
  onChange() {
    this.props.onChange?.({
      data: this.data,
      isValid: this.isValid,
      errors: this.state.errors,
      valid: this.state.valid
    }, this.elementRef);
  }
  setUpAnalytics(t) {
    const e = this.props.session?.id;
    return this.props.modules.analytics.setUp({
      ...t,
      ...(e && {
        sessionId: e
      })
    });
  }
  submitAnalytics(t, e) {
    try {
      this.props.modules.analytics.sendAnalytics(this.getComponent(t), t, e);
    } catch (t) {
      console.warn("Failed to submit the analytics event. Error:", t);
    }
  }
  getComponent({
    component: t
  }) {
    return t || (this.constructor.analyticsType ? this.constructor.analyticsType : "scheme" === this.constructor.type || "bcmc" === this.constructor.type ? this.constructor.type : this.props.type);
  }
  submit() {
    this.isValid ? this.makePaymentsCall().then(o).then(r).then(this.handleResponse).catch(t => {
      t instanceof p ? this.setElementStatus("ready") : this.handleFailedResult(t);
    }) : this.showValidation();
  }
  makePaymentsCall() {
    if (this.setElementStatus("loading"), this.props.onSubmit) return this.submitUsingAdvancedFlow();
    if (this.core.session) {
      return (this.props.beforeSubmit ? new Promise((t, e) => this.props.beforeSubmit(this.data, this.elementRef, {
        resolve: t,
        reject: () => e(new p("beforeSubmitRejected"))
      })) : Promise.resolve(this.data)).then(this.submitUsingSessionsFlow);
    }
    this.handleError(new h("IMPLEMENTATION_ERROR", 'It can not perform /payments call. Callback "onSubmit" is missing or Checkout session is not available'));
  }
  async submitUsingAdvancedFlow() {
    return new Promise((t, e) => {
      this.submitAnalytics({
        type: d
      }), this.props.onSubmit({
        data: this.data,
        isValid: this.isValid
      }, this.elementRef, {
        resolve: t,
        reject: e
      });
    });
  }
  async submitUsingSessionsFlow(t) {
    this.submitAnalytics({
      type: d
    });
    try {
      return await this.core.session.submitPayment(t);
    } catch (t) {
      return t instanceof h ? this.handleError(t) : this.handleError(new h("ERROR", "Error when making /payments call", {
        cause: t
      })), Promise.reject(t);
    }
  }
  onComplete(t) {
    this.props.onComplete && this.props.onComplete(t, this.elementRef);
  }
  handleAdditionalDetails(t) {
    this.makeAdditionalDetailsCall(t).then(o).then(r).then(this.handleResponse).catch(this.handleFailedResult);
  }
  makeAdditionalDetailsCall(t) {
    return this.props.onAdditionalDetails ? new Promise((e, s) => {
      this.props.onAdditionalDetails(t, this.elementRef, {
        resolve: e,
        reject: s
      });
    }) : this.core.session ? this.submitAdditionalDetailsUsingSessionsFlow(t.data) : void this.handleError(new h("IMPLEMENTATION_ERROR", 'It can not perform /payments/details call. Callback "onAdditionalDetails" is missing or Checkout session is not available'));
  }
  async submitAdditionalDetailsUsingSessionsFlow(t) {
    try {
      return await this.core.session.submitDetails(t);
    } catch (t) {
      return t instanceof h ? this.handleError(t) : this.handleError(new h("ERROR", "Error when making /details call", {
        cause: t
      })), Promise.reject(t);
    }
  }
  handleAction(t, e = {}) {
    if (!t || !t.type) {
      if (l(t, "action") && l(t, "resultCode")) throw new Error('handleAction::Invalid Action - the passed action object itself has an "action" property and a "resultCode": have you passed in the whole response object by mistake?');
      throw new Error('handleAction::Invalid Action - the passed action object does not have a "type" property');
    }
    const s = this.core.createFromAction(t, {
      ...this.elementRef.props,
      ...e,
      onAdditionalDetails: this.handleAdditionalDetails
    });
    return s ? (this.unmount(), s.mount(this._node)) : null;
  }
  onActionHandled(t) {
    this.props?.onActionHandled?.({
      originalAction: this.props.originalAction,
      ...t
    });
  }
  handleResponse(t) {
    const e = o(t);
    e.action ? this.elementRef.handleAction(e.action) : e.order?.remainingAmount?.value > 0 ? this.handleOrder(e) : this.handleSuccessResult(e);
  }
  handleKeyPress(t) {
    "Enter" !== t.key && "Enter" !== t.code || (t.preventDefault(), this.onEnterKeyPressed(document?.activeElement, this));
  }
  onEnterKeyPressed(t, e) {
    this.props.onEnterKeyPressed ? this.props.onEnterKeyPressed(t, e) : (t.blur(), this.submit());
  }
  updateParent(t = {}) {
    return this.elementRef.core.update(t);
  }
  get isValid() {
    return !1;
  }
  get icon() {
    const t = this.props.paymentMethodType || this.type;
    return this.props.icon ?? this.resources.getImage()(t);
  }
  get displayName() {
    const t = this.core.paymentMethodsResponse?.paymentMethods?.find(t => t.type === this.type);
    return this.props.name || t?.name || this.type;
  }
  get accessibleName() {
    return this.displayName;
  }
  get additionalInfo() {
    return null;
  }
  get type() {
    return this.props.type || this.constructor.type;
  }
  async handleAdvanceFlowPaymentMethodsUpdate(t, e) {
    return new Promise((e, s) => {
      if (!this.props.onPaymentMethodsRequest) return e();
      this.props.onPaymentMethodsRequest({
        ...(t && {
          order: {
            orderData: t.orderData,
            pspReference: t.pspReference
          }
        }),
        locale: this.core.options.locale
      }, {
        resolve: e,
        reject: s
      });
    }).catch(t => {
      this.handleError(new h("IMPLEMENTATION_ERROR", "Something failed during payment methods update or onPaymentMethodsRequest was not implemented", {
        cause: t
      }));
    }).then(s => this.core.update({
      ...(s && {
        paymentMethodsResponse: s
      }),
      order: t,
      amount: t ? t.remainingAmount : e
    }));
  }
  constructor(e, n) {
    super(e, n), c(this, "componentRef", void 0), c(this, "resources", void 0), c(this, "elementRef", void 0), c(this, "handleError", t => {
      this.setElementStatus("ready"), this.props.onError && this.props.onError(t, this.elementRef);
    }), c(this, "handleOrder", t => {
      const {
        order: e
      } = t;
      (this.core.session ? this.core.update({
        order: e
      }) : this.handleAdvanceFlowPaymentMethodsUpdate(e)).then(() => {
        this.props.onOrderUpdated?.({
          order: e
        });
      });
    }), c(this, "handleFailedResult", t => {
      i(this.elementRef) && this.elementRef.displayFinalAnimation("error"), a(t), this.props.onPaymentFailed?.(t, this.elementRef);
    }), c(this, "handleSuccessResult", t => {
      i(this.elementRef) && this.elementRef.displayFinalAnimation("success"), a(t), this.props.onPaymentCompleted?.(t, this.elementRef);
    }), c(this, "setComponentRef", t => {
      this.componentRef = t;
    }), c(this, "payButton", e => t(s, {
      ...e,
      amount: this.props.amount,
      secondaryAmount: this.props.secondaryAmount,
      onClick: this.submit
    })), this.core.register(this.constructor), this.submit = this.submit.bind(this), this.setState = this.setState.bind(this), this.onComplete = this.onComplete.bind(this), this.handleAction = this.handleAction.bind(this), this.handleOrder = this.handleOrder.bind(this), this.handleAdditionalDetails = this.handleAdditionalDetails.bind(this), this.handleResponse = this.handleResponse.bind(this), this.setElementStatus = this.setElementStatus.bind(this), this.submitAnalytics = this.submitAnalytics.bind(this), this.makePaymentsCall = this.makePaymentsCall.bind(this), this.makeAdditionalDetailsCall = this.makeAdditionalDetailsCall.bind(this), this.submitUsingSessionsFlow = this.submitUsingSessionsFlow.bind(this), this.elementRef = n && n.elementRef || this, this.resources = this.props.modules ? this.props.modules.resources : void 0, this.storeElementRefOnCore(this.props), this.onEnterKeyPressed = this.onEnterKeyPressed.bind(this), this.onActionHandled = this.onActionHandled.bind(this);
  }
}
c(m, "type", void 0), c(m, "txVariants", []);
export { m as UIElement, m as default };
