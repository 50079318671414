import { createElement as t } from "../../../external/preact/dist/preact.js";
import { UIElement as e } from "../../internal/UIElement/UIElement.js";
import o from "../../internal/QRLoader/QRLoader.js";
import { CoreProvider as r } from "../../../core/Context/CoreProvider.js";
import n from "../../internal/RedirectButton/RedirectButton.js";
import s from "../../../core/Errors/SRPanelProvider.js";
class i extends e {
  formatData() {
    return {
      paymentMethod: {
        type: this.type,
        ...this.state.data
      }
    };
  }
  get isValid() {
    return !0;
  }
  renderQRCode() {
    return t(r, {
      i18n: this.props.i18n,
      loadingContext: this.props.loadingContext,
      resources: this.resources
    }, t(s, {
      srPanel: this.props.modules.srPanel
    }, t(o, {
      ref: t => {
        this.componentRef = t;
      },
      ...this.props,
      type: this.constructor.type,
      brandLogo: this.props.brandLogo || this.icon,
      delay: this.props.delay,
      onComplete: this.onComplete,
      countdownTime: this.props.countdownTime,
      instructions: this.props.instructions,
      onActionHandled: this.onActionHandled,
      brandName: this.displayName,
      onSubmitAnalytics: this.submitAnalytics
    })));
  }
  render() {
    return this.props.paymentData ? this.renderQRCode() : this.props.showPayButton ? t(r, {
      i18n: this.props.i18n,
      loadingContext: this.props.loadingContext,
      resources: this.resources
    }, t(n, {
      showPayButton: this.props.showPayButton,
      name: this.displayName,
      onSubmit: this.submit,
      payButton: this.payButton,
      ref: t => {
        this.componentRef = t;
      }
    })) : null;
  }
}
var a, p, l;
l = {
  qrCodeImage: "",
  amount: null,
  paymentData: null,
  onError: () => {},
  onComplete: () => {}
}, (p = "defaultProps") in (a = i) ? Object.defineProperty(a, p, {
  value: l,
  enumerable: !0,
  configurable: !0,
  writable: !0
}) : a[p] = l;
export { i as default };
