import { createElement as t, Fragment as e } from "../../../external/preact/dist/preact.js";
import { useState as a, useEffect as o, useCallback as r } from "../../../external/preact/hooks/dist/hooks.js";
import n from "../../internal/ClickToPay/context/useClickToPayContext.js";
import { CtpState as i } from "../../internal/ClickToPay/services/ClickToPayService.js";
import l from "../../internal/ClickToPay/ClickToPayComponent.js";
import s from "../../internal/ContentSeparator/ContentSeparator.js";
import c from "../../internal/Button/Button.js";
import { useCoreContext as p } from "../../../core/Context/CoreProvider.js";
const m = ({
  children: m
}) => {
  const {
      i18n: d
    } = p(),
    [C, u] = a(null),
    {
      ctpState: y,
      isCtpPrimaryPaymentMethod: f,
      setIsCtpPrimaryPaymentMethod: k,
      status: P
    } = n(),
    h = null === C && null === f;
  o(() => {
    if (h) {
      if (y === i.ShopperIdentified || y === i.Ready) return u(!1), void k(!0);
      y === i.NotAvailable && (u(!0), k(!1));
    }
  }, [y, h]);
  const j = r(() => {
      u(!0), k(!1);
    }, []),
    v = r(t => {
      "Enter" === t.key && j();
    }, [j]);
  return y === i.NotAvailable ? m() : y === i.Loading || y === i.ShopperIdentified ? t(l, null) : t(e, null, t(l, {
    onDisplayCardComponent: j
  }), t(s, {
    classNames: ["adyen-checkout-ctp__separator"],
    label: d.get("ctp.separatorText")
  }), C ? m(!f) : t(c, {
    variant: "secondary",
    disabled: "loading" === P,
    label: d.get("ctp.manualCardEntry"),
    onClick: j,
    onKeyDown: v
  }));
};
export { m as default };
