@if (authenticated) {
  <div class="gung-app-navbar navbar" [style.height.px]="navbarHeight">
    <div class="navbar-top">
      <div class="left-top-navbar align-items-center">
        @if (showflowMenu && !forceToNotShowFlowsOnTop) {
          <div class="navbar-nav">
            <div class="flow-menu desktop-flow-menu">
              @for (menu of flowsMenu; track menu) {
                <div class="s-menu flex-row d-flex align-items-center">
                  <div class="menu-name" translate>
                    <a [routerLink]="menu.link ? [menu.link] : null" [class.active]="selectedFlow.name === menu.heading" (click)="menu.action ? menu.action() : null">{{
                      menu.heading | translate
                    }}</a>
                  </div>
                </div>
              }
            </div>
          </div>
        }
        @if (gungNavbar.showNavbarSearch) {
        <div class="global-search-desktop w-75 mr-4" style="max-width: 450px;">
          <lib-global-search></lib-global-search>
        </div>
        }

      </div>

      <div class="logo">
        <div class="nav-brand-wrapper d-flex">
          <a class="navbar-brand" routerLink="/home">
            <img src="{{ logoUrl }}" width="auto" height="36px" alt="Logotype" />
          </a>
        </div>
      </div>

      <div class="navbar-right d-flex align-items-center">
        @if (!isAnonymous && authenticated) {
          <div class="icons d-flex align-items-center">
            @if (gungNavbar.showNavbarSearch) {
            <div class="mobile-search icon-action">
              <span>
                <i class="fa-solid fa-magnifying-glass" (click)="isGlobalSearchOpen = !isGlobalSearchOpen"></i>
                <span class="icon-label">{{ 'SEARCH' | translate }}</span>
              </span>
              @if (isGlobalSearchOpen) {
                <div class="mobile-search-container">
                  <lib-global-search [roundedCorners]="true"></lib-global-search>
                </div>
              }
            </div>
            }
            <div class="icon-action" *ngIf="isSales && !mobileDevice">
              <a [routerLink]="['/customers']">
                <i class="fa-solid fa-users" title="{{ 'CUSTOMERS' | translate }}"></i>
                <span class="icon-label">{{ 'MY_CUSTOMERS' | translate }}</span>
              </a>
            </div>
            <div class="icon-action" *ngIf="!mobileDevice">
              <a [routerLink]="['/favourites']">
                <i class="fa-solid fa-heart" title="{{ 'FAVOURITES' | translate }}"></i>
                <span class="icon-label">{{ 'FAVOURITES' | translate }}</span>
              </a>
            </div>
            <div class="icon-action icon-checkout" #iconCheckoutElement>
              <a [routerLink]="['/checkout']">
                <i class="fa-solid fa-cart-shopping"></i>
                <span class="icon-label">{{ 'CART' | translate }}</span>
                @if (!!cartContents) {
                  <span class="badge badge-primary rounded-circle cart-icon-badge text-white">{{ cartContents.rows.length }}</span>
                }
              </a>
              <div class="navbar-cart">
                <ng-template #navbarCart></ng-template>
              </div>
            </div>
          </div>
        }@else{
          <div class="icons d-flex align-items-center">
            <div class="icon-action">
              <a [routerLink]="['/login']">
                <i class="fa-solid fa-user" title="{{ 'LOGIN' | translate }}"></i>
                <span class="icon-label">{{ 'LOGIN' | translate }}</span>
              </a>
            </div>
          </div>
        }
      </div>
    </div>
    @if (showflowMenu && !forceToNotShowFlowsOnTop) {
      <div class="mobile-flow-menu">
        <div class="navbar-nav ">
          <div class="flow-menu">
            @for (menu of flowsMenu; track menu) {
              <div class="s-menu flex-row d-flex align-items-center">
                <div class="menu-name" translate>
                  <a [routerLink]="menu.link ? [menu.link] : null" [class.active]="selectedFlow.name === menu.heading" (click)="menu.action ? menu.action() : null">{{
                    menu.heading | translate
                  }}</a>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

    }
    <div class="navbar-nav">
      <div class="shop-menu d-flex align-items-center">
        @for (menu of shopMenu; track menu) {
          <div class="s-menu flex-row d-flex align-items-center" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: false }">
            <div class="menu-name" translate>
              <a [routerLink]="menu.link ? [menu.link] : null" (click)="menu.action ? menu.action() : null" routerLinkActive="active">{{ menu.heading | translate }}</a>
            </div>
            @if (menu.links && menu.links.length > 0) {
              <i class="fa-solid fa-chevron-down ml-2 pr-1"></i>
              @if (!isClosingShowMenu) {
                <div class="sub-menu" [class.d-none]="isClosingShowMenu">
                  <lib-gung-expand-menu
                    (closeExpandMenu)="closeExpandMenu($event)"
                    [menu]="menu.links"
                    [levels]="gungNavbar.assortmentMenuLevel"
                    top="32"
                    left="0"
                  />
                </div>
              }
            }
          </div>
        }
      </div>
      <div class="nav-info">
        @if (!isAnonymous && authenticated) {
          @if (customerInfo) {
            <div class="customer ml-2">
              <a [routerLink]="['/customers', customerInfo.id]">
                {{ customerInfo.description }}
              </a>
            </div>
          }
          <div class="icon-action btn-open-menu" (click)="toggleMainMenu()"><i class="fa-solid fa-bars"></i></div>
        }
      </div>
    </div>
  </div>
  <div class="main-menu" [class.menu-open]="activeMainMenu" [style.top.px]="navbarHeight">
    <lib-gung-main-menu (menuClickEvent)="menuClickEvent($event)"></lib-gung-main-menu>
  </div>
  <div class="content-margin" [style.height.px]="navbarHeight"></div>
}

