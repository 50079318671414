import { Component as e, createElement as i } from "../../../external/preact/dist/preact.js";
import t from "../../../external/classnames/index.js";
function a(e, i, t) {
  return i in e ? Object.defineProperty(e, i, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[i] = t, e;
}
class n extends e {
  iframeOnLoad() {
    this.props.callback && "function" == typeof this.props.callback && this.props.callback(this.iframeEl.contentWindow);
  }
  componentDidMount() {
    this.iframeEl.addEventListener ? this.iframeEl.addEventListener("load", this.iframeOnLoad.bind(this), !1) : this.iframeEl.attachEvent ? this.iframeEl.attachEvent("onload", this.iframeOnLoad.bind(this)) : this.iframeEl.onload = this.iframeOnLoad.bind(this);
  }
  componentWillUnmount() {
    this.iframeEl.removeEventListener ? this.iframeEl.removeEventListener("load", this.iframeOnLoad.bind(this), !1) : this.iframeEl.detachEvent ? this.iframeEl.detachEvent("onload", this.iframeOnLoad.bind(this)) : this.iframeEl.onload = null;
  }
  render({
    name: e,
    src: a,
    width: n,
    height: r,
    minWidth: s,
    minHeight: l,
    allow: o,
    title: m,
    classNameModifiers: h
  }) {
    const d = h.filter(e => !!e);
    return i("iframe", {
      ref: e => {
        this.iframeEl = e;
      },
      allow: o,
      className: t("adyen-checkout__iframe", `adyen-checkout__iframe--${e}`, d.length && h.map(i => `adyen-checkout__iframe--${e}-${i}`)),
      name: e,
      src: a,
      width: n,
      height: r,
      frameBorder: "0",
      title: m,
      referrerpolicy: "origin",
      "min-width": s,
      "min-height": l
    });
  }
  constructor(...e) {
    super(...e), a(this, "iframeEl", void 0);
  }
}
a(n, "defaultProps", {
  width: "0",
  height: "0",
  minWidth: "0",
  minHeight: "0",
  src: null,
  allow: null,
  title: "components iframe",
  classNameModifiers: []
});
export { n as default };
