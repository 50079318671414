import { get3DS2FlowProps as e } from "../../../components/ThreeDS2/components/utils.js";
import o from "../../../utils/uuid.js";
const t = (e, t, n, r) => {
    const i = t.getComponent(n);
    if (!i) throw Error(`Action Element of type ${n} not found in the registry`);
    return new i(e, {
      ...r,
      id: `${n}-${o()}`
    });
  },
  n = e => (o, n, r, i) => {
    const s = {
      ...i,
      ...r,
      onComplete: i.onAdditionalDetails,
      onError: i.onError,
      statusType: e,
      originalAction: r
    };
    return t(o, n, r.paymentMethodType, s);
  },
  r = {
    redirect: (e, o, n, r) => {
      const i = {
        ...r,
        ...n,
        statusType: "redirect",
        originalAction: n
      };
      return t(e, o, "redirect", i);
    },
    threeDS2: (o, n, r, i) => {
      const s = "fingerprint" === r.subtype ? "threeDS2DeviceFingerprint" : "threeDS2Challenge",
        a = "fingerprint" === r.subtype || i.isMDFlow ? r.paymentData : r.authorisationToken,
        l = {
          core: o,
          token: r.token,
          paymentData: a,
          onActionHandled: i.onActionHandled,
          onComplete: i.isMDFlow ? i.onComplete : i.onAdditionalDetails,
          onError: i.onError,
          isDropin: !!i.isDropin,
          loadingContext: i.loadingContext,
          clientKey: i.clientKey,
          paymentMethodType: i.paymentMethodType,
          challengeWindowSize: i.challengeWindowSize,
          isMDFlow: i.isMDFlow,
          modules: {
            analytics: i.modules?.analytics,
            resources: i.modules?.resources
          },
          ...e(r.subtype, i)
        };
      return t(o, n, s, l);
    },
    voucher: n("custom"),
    qrCode: n("custom"),
    await: n("custom"),
    bankTransfer: n("custom"),
    sdk: n("custom")
  };
export { r as default };
