import { createElement as e } from "../../external/preact/dist/preact.js";
import t from "../../components/internal/BaseElement/BaseElement.js";
import { SRMessages as s } from "./SRMessages.js";
function n(e, t, s) {
  return t in e ? Object.defineProperty(e, t, {
    value: s,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = s, e;
}
class o extends t {
  get enabled() {
    return this._enabled;
  }
  get moveFocus() {
    return this._moveFocus;
  }
  setAriaProps(e) {
    const t = document.querySelector('[class^="adyen-checkout-sr-panel"]');
    for (const [s, n] of Object.entries(e)) t.setAttribute(s, n);
    this.props = {
      ...this.props,
      ariaAttributes: {
        ...this.props.ariaAttributes,
        ...e
      }
    };
  }
  render() {
    return this.props.enabled ? e("div", {
      className: this.showPanel ? "adyen-checkout-sr-panel" : "adyen-checkout-sr-panel--sr-only",
      role: "log",
      ...this.props.ariaAttributes
    }, e(s, {
      setComponentRef: this.setComponentRef
    })) : null;
  }
  constructor(e, t) {
    if (super(e, t), n(this, "srPanelContainer", null), n(this, "id", void 0), n(this, "showPanel", void 0), n(this, "_enabled", void 0), n(this, "_moveFocus", void 0), n(this, "componentRef", void 0), n(this, "setComponentRef", e => {
      this.componentRef = e;
    }), n(this, "setMessages", e => {
      if (!this.props.enabled) return;
      let t = null;
      e && (t = Array.isArray(e) ? e : [e]), this.componentRef.setMessages(t);
    }), this.id = this.props.id, this.showPanel = !1, this._enabled = !1, this._moveFocus = this.props.moveFocus ?? !0, this.props.enabled) {
      this._enabled = !0;
      const e = document.querySelector(this.props.node);
      if (!e) throw new Error("Component could not mount. Root node was not found.");
      {
        const t = document.getElementById(this.id);
        t && e.removeChild(t), this.srPanelContainer = document.createElement("div"), this.srPanelContainer.className = "sr-panel-holder", this.srPanelContainer.id = this.id, e.appendChild(this.srPanelContainer), this.mount(this.srPanelContainer);
      }
    }
  }
}
n(o, "type", "srPanel"), n(o, "defaultProps", {
  enabled: !0,
  node: "body",
  showPanel: !1,
  id: "ariaLiveSRPanel",
  ariaAttributes: {
    "aria-relevant": "all",
    "aria-live": "polite",
    "aria-atomic": "true"
  }
});
export { o as SRPanel };
