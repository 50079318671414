import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, forkJoin, of, takeUntil, zip } from 'rxjs';
import { Order, MetadataService, SelectedCustomerService } from 'gung-standard';
import { JeevesOrderListConfigService } from 'gung-standard-jeeves';
import { TranslateService } from '@ngx-translate/core';
import { HlDisplayOrderService } from './hl-display-order.service';
import { ConfigBaseFilter, ListLayout, ListLayoutSingleComponent, SimpleConfigBaseFilter } from 'gung-list';
import { CustomerService } from 'gung-standard';
import { switchMap, map, mergeMap, tap, first, merge } from 'rxjs';
import { HlDisplayJeevesOrderCardListComponent } from '../components/hl-display-open-orders-quotes/hl-display-jeeves-order-card-list/hl-display-jeeves-order-card-list.component';
import { environment } from '../../environments/environment';
import { HlDisplayOrderPODFilter } from './hl-display-jeeves-order-list-config.service';
import {
  DeliverySettingsGroup,
  HlDisplayPortalFeaturesService,
  IFeatureGroup,
  OpenOrdersSettingsGroup,
  OrderSearchSettingsGroup
} from './hl-display-portal-features.service';
import { DateUtilService } from 'gung-common';

export class OrderNameFilter extends SimpleConfigBaseFilter<Order> {
  constructor() {
    super();
  }

  getName(): string {
    return 'NAME';
  }
  getOptionIds(item: Order): string[] {
    const value =
      item.extra.oh.ordlevadr1 ||
      (item.extra.deliveryCustomer ? item.extra.deliveryCustomer.name : item.extra.customerName);
    return [value];
  }
  getOptionName(key: string): string {
    return key;
  }
}
@Injectable({
  providedIn: 'root'
})
export class HlDisplayOpenOrdersListConfigService extends JeevesOrderListConfigService implements OnInit {
  filterOrdersOnPOD: boolean = false;
  filterOrdersOnName: boolean = false;
  topFilter: boolean = false;

  constructor(
    protected translationService: TranslateService,
    protected orderService: HlDisplayOrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    protected dateUtilService: DateUtilService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService, dateUtilService);
  }

  ngOnInit() {}

  getItems(): Observable<Order[]> {
    return this.orderService.getOpenOrders().pipe(
      mergeMap(items =>
        forkJoin([
          of(items),
          this.customerService.getCustomers().pipe(first()),
          this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('deliverySettingsGroup').pipe(first())
        ])
      ),
      map(([orders, customers, settingsGroup]) => {
        let deliverySettingsGroup = settingsGroup as DeliverySettingsGroup;
        const result = orders.map(order => {
          return {
            ...order,
            extra: {
              ...order.extra
            }
          };
        });

        result.map(resOrder => {
          const cust = customers.find(
            customer =>
              customer.id === resOrder.deliveryCustomerId ||
              (deliverySettingsGroup.fetchDeliveryCustomerFromOrdLevPlats &&
                customer.id === resOrder.deliveryCustomerId + '@' + resOrder.extra.oh.ordlevplats1)
          );
          resOrder.extra.customerName = cust ? cust.name : '';
          resOrder.extra.deliveryCustomer = cust;
          return resOrder;
        });

        const sorted = result.sort((a, b) => {
          if (a.extra.oh.orddatum > b.extra.oh.orddatum) {
            return 1;
          }
          if (a.extra.oh.orddatum < b.extra.oh.orddatum) {
            return -1;
          }
          return 0;
        });

        return sorted.reverse();
      }),
      map(orders => {
        if (this.filterOrdersOnPOD) {
          for (const order of orders) {
            const lpMap: { name: string; addr1: string; addr2: string; postnr: string; land: string }[] = [];
            const lps: any[] = order.extra?.deliveryCustomer?.extra?.lp || [];

            for (const lp of lps) {
              lpMap.push({
                name: lp._fr?.ftgnamn || '',
                addr1: lp._fr?.ftgpostadr1 || '',
                addr2: lp._fr?.ftgpostadr2 || '',
                postnr: lp._fr?.ftgpostnr || '',
                land: lp._fr?.landskod || ''
              });
            }

            lpMap.push({
              name: order.extra?.deliveryCustomer?.extra?.fr?.ftgnamn || '',
              addr1: order.extra?.deliveryCustomer?.extra?.fr?.ftgnamn || '',
              addr2: order.extra?.deliveryCustomer?.extra?.fr?.ftgpostadr2 || '',
              postnr: order.extra?.deliveryCustomer?.extra?.fr?.ftgpostnr || '',
              land: order.extra?.deliveryCustomer?.extra?.fr?.landskod || ''
            });

            order.extra._gungLpMap = lpMap;
          }
        }

        return orders;
      })
    );
  }

  getSearchTerms(item: Order): string[] {
    const res = [...super.getSearchTerms(item), item.extra?.oh?.godsmarke2];

    if (item.extra.customerName) {
      res.push(item.extra.customerName);
    }
    res.push(
      item.extra.oh.ordlevadr1 ||
        (item.extra.deliveryCustomer ? item.extra.deliveryCustomer.name : item.extra.customerName)
    );
    res.push(item.extra?.deliveryCustomer?.name);
    return res;
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        ...super.getLayouts()[0],
        getListItemComponent: () => HlDisplayJeevesOrderCardListComponent
      }
    ];
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    const filters = super.getFilters();

    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('openOrdersSettingsGroup')
      .pipe(first())
      .subscribe(openOrdersSettingsGroup => {
        let orderSearchGroup = openOrdersSettingsGroup as OrderSearchSettingsGroup;
        this.filterOrdersOnPOD = orderSearchGroup.filterOrdersOnPOD;
        this.filterOrdersOnName = orderSearchGroup.filterOrdersOnName;
        if (orderSearchGroup.filterOrdersOnPOD) {
          filters.push(new HlDisplayOrderPODFilter(this.translationService));
        }
        if (orderSearchGroup.filterOrdersOnName) {
          filters.push(new OrderNameFilter());
        }
      });
    return filters;
  }
}
