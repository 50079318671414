import { createElement as t, Fragment as e } from "../../../../../external/preact/dist/preact.js";
import { useState as r, useEffect as o, useCallback as s } from "../../../../../external/preact/hooks/dist/hooks.js";
import n from "../../context/useClickToPayContext.js";
import a from "./CtPSingleCard/CtPSingleCard.js";
import i from "../../../PayButton/PayButton.js";
import { amountLabel as c } from "../../../PayButton/utils.js";
import l from "./CtPCardsList/CtPCardsList.js";
import m from "../../services/sdks/SrciError.js";
import u from "../CtPSection/CtPSection.js";
import { CTP_IFRAME_NAME as d } from "../../services/utils.js";
import p from "../../../IFrame/Iframe.js";
import f from "../../../../../core/Context/useImage.js";
import { useCoreContext as g } from "../../../../../core/Context/CoreProvider.js";
import C from "../../../../../utils/isMobile.js";
import h from "../../../../../core/Errors/AdyenCheckoutError.js";
import { PREFIX as y } from "../../../Icon/constants.js";
function k(t, e) {
  if (!t) return null;
  const r = e.get(`ctp.errors.${t}`);
  return r.includes("ctp.errors") ? e.get("ctp.errors.UNKNOWN_ERROR") : r;
}
function P(t, e, r) {
  return r ? C() ? null : t.get("payButton.with", {
    values: {
      value: c(t, e),
      maskedData: `•••• ${r?.panLastFour}`
    }
  }) : t.get("payButton");
}
const j = ({
  onDisplayCardComponent: c
}) => {
  const {
      i18n: C
    } = g(),
    j = f(),
    {
      amount: E,
      cards: S,
      checkout: x,
      isCtpPrimaryPaymentMethod: b,
      status: v,
      onSubmit: R,
      onSetStatus: $,
      onError: w
    } = n(),
    [B, M] = r(S.find(t => !t.isExpired) || S[0]),
    [N, D] = r(null),
    F = S.every(t => t.isExpired),
    [I, T] = r(!1);
  o(() => {
    (0 === S.length || F) && c?.();
  }, [c, F, S]);
  const _ = s(async () => {
      if (B) try {
        T(!0), D(null), $("loading");
        const t = await x(B);
        R(t);
      } catch (t) {
        t instanceof m && (D(t?.reason), console.warn(`CtP - Checkout: Reason: ${t?.reason} / Source: ${t?.source} / Scheme: ${t?.scheme}`)), T(!1), w(t instanceof h ? t : new h("ERROR", "Error during ClickToPay checkout", {
          cause: t
        }));
      }
    }, [x, B]),
    L = s(t => {
      M(t);
    }, []),
    O = I && "loading" === v && B?.isDcfPopupEmbedded,
    K = "loading" !== v || !O,
    A = s(t => {
      "Enter" === t.key && _();
    }, [_]);
  return t(e, null, t(p, {
    name: d,
    height: "380",
    width: "100%",
    classNameModifiers: [O ? "" : "hidden"]
  }), K && t(e, null, t(u.Title, null, C.get("ctp.cards.title")), t(u.Text, null, C.get("ctp.cards.subtitle")), 0 === S.length && t("div", {
    className: "adyen-checkout-ctp__empty-cards"
  }, C.get("ctp.emptyProfile.message")), 1 === S.length && t(a, {
    card: S[0],
    errorMessage: k(N, C)
  }), S.length > 1 && t(l, {
    cardSelected: B,
    cards: S,
    onChangeCard: L,
    errorMessage: k(N, C)
  }), t(i, {
    disabled: F,
    amount: E,
    label: P(C, E, B),
    status: v,
    variant: b ? "primary" : "secondary",
    icon: 0 !== S.length && j({
      imageFolder: "components/"
    })(b ? `${y}lock` : `${y}lock_black`),
    onClick: _,
    onKeyDown: A
  })));
};
export { j as default };
