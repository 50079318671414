import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { first, forkJoin, of, Subject, Subscription } from 'rxjs';
import { Availability } from '../../models/availability';
import { CustomerProductPrice } from '../../models/price';
import { Product, ProductExtended } from '../../models/product';
import { AssortmentService } from '../../services/assortment.service';
import { AuthService } from '../../services/auth/auth.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { PriceService } from '../../services/price/price.service';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { GungFlow } from '../../state/flow/types';
import { GridViewProductCardV2 } from '../product-grid-view-v2/product-grid-view-v2.component';

@Component({
  selector: 'lib-product-list-view-v2',
  templateUrl: './product-list-view-v2.component.html',
  styleUrls: ['./product-list-view-v2.component.scss']
})
export class ProductListViewV2Component extends ListItemRendererComponent<Product[]> implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  // @ViewChildren(CustomHostDirective)
  // private viewChildren: QueryList<CustomHostDirective>;

  // public listItemRenderer: Type<ListItemRendererComponent<GridViewProductCardV2>>;

  protected keyedMapData: { [id: string]: GridViewProductCardV2 } = {};
  protected subscriptions: Subscription[] = [];
  protected flowSubscriptions: Subscription;

  public includeAvailability = false;
  public isSales = false;
  public isAnonymous = true;
  public mappedData: GridViewProductCardV2[] = [];
  protected currentAssortment;

  protected mappedDataChanged: Subject<void> = new Subject<void>();
  protected unsubscribe: Subject<void> = new Subject();

  protected currentFlow: GungFlow;
  protected oldFlow: GungFlow;
  renderedIds: { [s: string]: string } = {};
  errorMessage = 'ERROR: ';
  findError = false;
  // private componentFactory: ComponentFactory<ListItemRendererComponent<GridViewProductCardV2>>;

  private viewInitiated = false;
  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    protected assortmentService: AssortmentService,
    protected selectedCustomerService: SelectedCustomerService
  ) {
    super();
  }

  public ngOnInit() {
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.isSales =
          roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0;
        this.isAnonymous = roles.filter(role => role.toUpperCase() === 'ANONYMOUS').length > 0;
      });

    this.subscribeToProducts();
    this.flowSubscriptions = this.gungFlowService.getSelectedFlow().subscribe(flow => (this.currentFlow = flow));
    this.mappedDataChanged.subscribe(res => {
      // this.renderItemComponents();
    });
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  public ngOnChanges() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscribeToProducts();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.flowSubscriptions.unsubscribe();

    this.mappedDataChanged.complete();

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected subscribeToProducts() {
    if (!this.oldFlow || !this.currentFlow || this.oldFlow.id !== this.currentFlow.id) {
      // remove cached data;
      this.keyedMapData = {};
      this.oldFlow = this.currentFlow;
    }
    const newIds = this.data.map(p => p.id).filter(id => !Object.keys(this.keyedMapData).includes(id));
    if (newIds.length === 0) {
      this.readMappedDataFromCache();
      return;
    }

    const subscription = forkJoin([
      of(this.data),
      this.priceService.getCurrentCustomerPrices(newIds).pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ])
      .pipe(first())
      .subscribe(data => {
        const productData = data[0];
        const prices = data[1];
        let avs = [];
        this.includeAvailability = data[2].useAvailabilities || data[2].requireAvailability;
        if (this.includeAvailability) {
          avs = newIds.map(id => this.getProductAvailability(this.data.find(product => product.id === id)));
        }

        this.keyedMapData = {
          ...this.keyedMapData,
          ...newIds.reduce((acc, curr) => {
            const product = productData.filter(p => p && p.id === curr)[0];

            if (!product) {
              this.findError = true;
              this.errorMessage += 'No product found. ';
              throw new Error('No product found');
            }

            const price = prices.filter(p => p && p.productId === curr)[0];
            if (!price) {
              this.findError = true;
              this.errorMessage += 'No price found.';
              throw new Error('No price found');
            }

            const av = avs.filter(p => p && p.productId === curr)[0];
            if (!av && this.includeAvailability) {
              this.findError = true;
              this.errorMessage += 'No availability found. ';
              throw new Error('No availability found');
            }

            const item: GridViewProductCardV2 = this.mapItem(curr, product, price, av);
            return {
              ...acc,
              [curr]: item
            };
          }, {})
        };
        this.readMappedDataFromCache();
      });
    this.subscriptions.push(subscription);
  }

  protected getProductAvailability(product: Product): Availability {
    if (product.extra.availabilities) {
      const availabilities = Object.keys(product.extra.availabilities).map(key => product.extra.availabilities[key]);
      if (availabilities.length > 0) {
        return availabilities[0];
      }
    }

    return null;
  }

  protected readMappedDataFromCache() {
    const ids = this.data.map(d => d.id);
    this.mappedData = ids.map(id => this.keyedMapData[id]);

    this.mappedDataChanged.next();
  }

  protected mapItem(
    id: string,
    product: ProductExtended,
    price: CustomerProductPrice,
    availability: Availability
  ): GridViewProductCardV2 {
    const isSales = this.isSales;
    const isAnonymous = this.isAnonymous;
    const includeAvailability = this.includeAvailability;

    const dynamicCollumnsValues = [];
    if (this.dynamicColumns) {
      for (const [index, column] of this.dynamicColumns.entries()) {
        if (column.isDisplayList) {
          dynamicCollumnsValues.push(product?.dynamicCollumnsValues?.[index]);
        }
      }
    }

    product = {
      ...product,
      dynamicCollumnsValues
    }

    return {
      product,
      price,
      availability,
      isSales,
      isAnonymous,
      includeAvailability
    };
  }

  public trackByFn(index: number, item: any) {
    if (!!item.product) {
      return item.product.id;
    } else {
      return index;
    }
  }
}
