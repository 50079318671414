{
  "USERNAME": "TEST",
  "PASSWORD": "TEST",
  "LOGIN": "TEST",
  "SIGN_IN": "TEST",
  "LOGOUT": "TEST",
  "LOGIN_FAILED": "TEST",
  "FORGOT_PASSWORD": "TEST",
  "WARNING_NO_USE_EXPLORER": "TEST",
  "LOGGED_OUT": "TEST",
  "LOGGED_OUT_INFO": "TEST",
  "LOG_IN_AGAIN": "TEST",
  "HOME": "TEST",
  "PRODUCTS": "TEST",
  "PREVIOUS_ORDERS": "TEST",
  "MOST_PURCHASED": "TEST",
  "SELECT_CATEGORY": "TEST",
  "GRAVITY_SCOOP_BINS": "TEST",
  "SHELF_MANAGEMENT_AUTOMATIC": "TEST",
  "SHELF_MANAGEMENT_MANUAL": "TEST",
  "STORE_COMMUNICATION_BRANDING": "TEST",
  "LIGHTING_POWER_INFRASTRUCTURE": "TEST",
  "PRICE_COMMUNICATION": "TEST",
  "PRESENTOIRS_PANIERS": "TEST",
  "FRESH_PRODUCE_SOLUTIONS": "TEST",
  "PROTECTION_SAFETY_EQUIPMENT": "TEST",
  "DISPLAY_ACCESSORIES": "TEST",
  "VIEW_ALL_PRODUCTS": "TEST",
  "OUR_HISTORY": "TEST",
  "CORPORATE_RESPONSIBILITY": "TEST",
  "TERMS_AND_CONDITIONS": "TEST",
  "CONTACT_US": "TEST",
  "ORDER_INFORMATION": "TEST",
  "MY_PAGE": "TEST",
  "MY_PAGES": "TEST",
  "TRACK_ORDER": "TEST",
  "CART": "TEST",
  "DELIVERY_ADDRESS": "TEST",
  "CUSTOMER_NAME": "TEST",
  "ADDRESS": "TEST",
  "ADDRESS1": "TEST",
  "FR_FTGPOSTADR1": "TEST",
  "ADDRESS2": "TEST",
  "FR_FTGPOSTADR2": "TEST",
  "EMAIL": "TEST",
  "EMAIL_ADDRESS": "TEST",
  "CONTACT": "TEST",
  "PHONE": "TEST",
  "PHONE_NUMBER": "TEST",
  "ZIP_CODE": "TEST",
  "FTGPOSTNR": "TEST",
  "FR_FTGPOSTNR": "TEST",
  "ZIP": "TEST",
  "COUNTRY_CODE": "TEST",
  "FR_LANDSKOD": "TEST",
  "OGL_LND": "TEST",
  "BILLING_ADDRESS": "TEST",
  "INVOICE_ADDRESS": "TEST",
  "BUSINESS_NAME": "TEST",
  "SELECT_ORDER": "TEST",
  "ORDER_NUMBER": "TEST",
  "PRICE": "TEST",
  "DATE": "TEST",
  "ORDER_AGAIN": "TEST",
  "DELIVERY_TRACKING": "TEST",
  "SEARCH": "TEST",
  "ADD_TO_BASKET": "TEST",
  "TOP_PURCHASED_TITLE": "TEST",
  "CATEGORY": "TEST",
  "FILTERS": "TEST",
  "SELECTED_FILTERS": "TEST",
  "SEE_MORE": "TEST",
  "COLOR": "TEST",
  "COLOUR": "TEST",
  "DIMENSIONS": "TEST",
  "CAPACITY": "TEST",
  "LENGTH": "TEST",
  "DEPTH": "TEST",
  "HEIGHT": "TEST",
  "ETIQUETTE_TYPE": "TEST",
  "SHELF_MANUFACTURER": "TEST",
  "MERCHANDISE_TYPE": "TEST",
  "CUSTOMER_PURCHASED_PRODUCT": "TEST",
  "CUSTOMER_PURCHASED_PRODUCT_FILTER_SELECTED": "TEST",
  "DISPLAY_BATCH_PREFIX": "TEST",
  "AVAILABILITY": "TEST",
  "IN_STOCK": "TEST",
  "NOT_IN_STOCK": "TEST",
  "NOT_ON_STOCK": "TEST",
  "AVAILABLE_2_3_WEEKS": "TEST",
  "AVAILABLE_4_5_WEEKS": "TEST",
  "ADD_TO_CART": "TEST",
  "MORE_INFO": "TEST",
  "MORE_INFORMATION": "TEST",
  "LOAD_MORE": "TEST",
  "DESCRIPTION": "TEST",
  "SPECIFICATION": "TEST",
  "PIM_I18N.SIZE": "TEST",
  "NOTHING_FOUND_CONTACT_GUNG": "TEST",
  "CLEAR_CART": "TEST",
  "SHIPPING_INFORMATION": "TEST",
  "NAME": "TEST",
  "REQUESTED_QUANTITY": "TEST",
  "QUANTITY_AVAILABLE": "TEST",
  "FIRST_AVAILABLE_DATE": "TEST",
  "UNIT_PRICE": "TEST",
  "TOTAL_PRICE": "TEST",
  "CART_ITEM_QTY_ABOVE_AVAILABLE_QTY": "TEST",
  "CAMPAIGN_CODE": "TEST",
  "CONTINUE_SHOPPING": "TEST",
  "DELIVERY_DATE": "TEST",
  "CONTINUE_TO_SHIPPING": "TEST",
  "ADVANCE_DELIVERY_INFORMATION": "TEST",
  "FREIGHT_INFORMATION": "TEST",
  "MINIMUM_ORDER_INFORMATION": "TEST",
  "CITY": "TEST",
  "FR_FTGPOSTADR3": "TEST",
  "FTGPOSTADR3": "TEST",
  "OH_ORDLEVADR4": "TEST",
  "CUSTOMER_PORTAL_OH_ORDLEVADR4": "TEST",
  "CUSTOMER_PORTAL_CITY": "TEST",
  "YOUR_REFERENCE_NO": "TEST",
  "MESSAGE": "TEST",
  "CONFIRM": "TEST",
  "CONFIRM_ORDER": "TEST",
  "CART_IS_EMPTY": "TEST",
  "PORTE_ETIQUETTE": "Etiketthållare",
  "IMPULSE_PROMOTION": "Impulse & Promotion",
  "CONTACT_NAME": "Navn",
  "EMAIL_QUOTE_ORDER_CONFIRMATION": "E-mail tilbud/ordrebekræftelse",
  "EMAIL_QUOTE_ORDER_CONFIRMATION_TEMPORARY": "E-mail tilbud/ordrebekræftelse - midlertidig *",
  "EMAIL_QUOTE_TEMPORARY_REQUIRED": "E-mail tilbud/ordrebekræftelse - midlertidigt felt påkrævet",
  "CREDIT_BLOCKED_MODAL_HTML": "Kunden <b>{{customerName}}</b> har deres kredit spærret, så det vil ikke være muligt at afgive nogen ordre.",
  "CREDIT_SOFT_BLOCKED_MODAL_HTML": "Kunden <b>{{customerName}}</b> vil kun være i stand til at indsende tilbud, fordi den aktuelle indkøbskurvværdi er {{total}} {{currency}} og deres resterende kredit er {{credit}} {{currency}}.",
  "CREDIT_SOFT_BLOCKED": "Kun tilgængelige tilbud",
  "EMAIL_QUOTE_TEMPORARY_INVALID": "Ugyldig email angivet!",
  "ORDER_TITLE": "Order",
  "EMAIL_ORDER_CONFIRMATION_TEMPORARY": "Vikarer for ordrebekræftelse via e-mail",
  "MESSAGE_EMAIL_ORDER_CONFIRMATION_TEMP": "Oplysninger, der tilføjes i dette felt, vil være den e-mail, som ordrebekræftelsen sendes til. Efterlades denne tom, vil ordrebekræftelsen blive sendt til standard-e-mailen.",
  "CREDIT_BLOCKED_MODAL_HTML_1210_FI": "Ordren kan desværre ikke gemmes, kontakt venligst kundeservice: info.fi@hl-display.com / +358 9 562 9180",
  "CREDIT_BLOCKED_MODAL_HTML_1210_SE": "Ordren kan desværre ikke gemmes, kontakt venligst kundeservice: info.se@hl-display.com / +46 8 683 7363",
  "CREDIT_BLOCKED_MODAL_HTML_1210_NO": "Ordren kan desværre ikke gemmes, kontakt venligst kundeservice: info.no@hl-display.com / +47 66 983 600",
  "CREDIT_BLOCKED_MODAL_HTML_1810_DE": "Ordren kan desværre ikke gemmes, kontakt venligst kundeservice: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_2400_CH": "Ordren kan desværre ikke gemmes, kontakt venligst kundeservice: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_1600_FR_OR_NL": "Desværre var vi ikke i stand til at validere din ordre. Kan du kontakte vores kundeservice på 02 47 48 85 00? Tak på forhånd.",
  "CREDIT_BLOCKED_MODAL_HTML_1600_BE": "Desværre var vi ikke i stand til at validere din ordre. Kan du kontakte vores kundeservice på 02 47 48 85 00? Tak på forhånd.",
  "INTERNAL_CREDIT_BLOCKED_MODAL_HTML": "Bestillingen kan desværre ikke gemmes, kontakt venligst kundeservice",
  "CREDIT_BLOCKED_MODAL_HTML_CUSTOMER_SERVICE": "Ordren kan desværre ikke gemmes, kontakt venligst kundeservice: {customerServiceEmail} / {customerServicePhoneNumber}",
  "BASECOST_S_U": "Basecost S / U",
  "PRODUCT_LIST": "Product list",
  "SEE_YOUR_AGREED_ASSORTMENT_AND_PRICES": "See your agreed assortment and prices",
  "ORDER_HISTORY": "Order history",
  "FIND_YOUR_PREVIOUS_ORDERS_TWO_YEAR_BACK": "Find your previous orders two year back",
  "FAVORITE_LIST": "Favorite list",
  "GO_TO_YOUR_FAVOURITE_PRODUCTS": "Go to your favourite products",
  "FIND_OUR_CONTECT_INFORMATION_BY_CLICKING_HERE": "Find our contect information by clicking here",
  "HELP_US_IMPROVE": "Help us improve",
  "IF_YOU_WANT_TO_SEND_US_FEEDBACK_OR_HAVE_IDEAS_FOR_IMPROVEMENTS": "If you want to send us feedback or have ideas for improvements on the portal, please use this form.",
  "NEW_FREIGHT_INFORMATION": "<span class='highlight'>Fragtomkostninger:</span> {{value}} {{currency}} hvis ordreværdien er under {{min}} {{currency}}",
  "NEW_MINIMUM_ORDER_INFORMATION": "<span class='highlight'>Minimum ordreværdi:</span> {{value}} {{currency}}",
  "NEW_ADMIN_FEE_INFORMATION": "<span class='highlight'>Administrative omkostninger:</span> {{value}} {{currency}} for enhver ordre mindre end {{min}} {{currency}}",
  "NEW_CUSTOM_FREIGHT_INFORMATION": "<span class='highlight'>Fragtomkostninger:</span> {{value}} {{currency}} hvis ordreværdien er under {{min}} {{currency}}"
}
