const e = e => {
    switch (e.resultCode.toLowerCase()) {
      case "refused":
      case "error":
      case "cancelled":
        return {
          type: "error",
          props: {
            ...e,
            message: "error.subtitle.refused"
          }
        };
      case "unknown":
        return {
          type: "error",
          props: {
            ...e,
            message: "error.message.unknown"
          }
        };
      case "pending":
      case "received":
        return {
          type: e.resultCode.toLowerCase(),
          props: e
        };
      default:
        return {
          type: "success",
          props: e
        };
    }
  },
  r = r => {
    if (!r.type && r.resultCode) return e(r);
    if (!r.type) return {
      type: "error",
      props: r
    };
    switch (r.type.toLowerCase()) {
      case "pending":
        return {
          type: "pending",
          props: r
        };
      case "complete":
        return e(r);
      default:
        return {
          type: "error",
          props: r
        };
    }
  };
export { r as default, r as processResponse };
