import { createElement as t } from "../../external/preact/dist/preact.js";
import { UIElement as r } from "../internal/UIElement/UIElement.js";
import { CoreProvider as e } from "../../core/Context/CoreProvider.js";
import o from "./components/RedirectShopper/RedirectShopper.js";
import s from "../internal/RedirectButton/RedirectButton.js";
import { TxVariants as n } from "../tx-variants.js";
import i from "../../utils/browserInfo.js";
import { ANALYTICS_EVENT as p, ANALYTICS_ERROR_TYPE as a, ANALYTICS_ERROR_CODE as c } from "../../core/Analytics/constants.js";
function m(t, r, e) {
  return r in t ? Object.defineProperty(t, r, {
    value: e,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[r] = e, t;
}
class d extends r {
  formatData() {
    return {
      paymentMethod: {
        type: this.type
      },
      browserInfo: this.browserInfo
    };
  }
  get isValid() {
    return !0;
  }
  get browserInfo() {
    return i();
  }
  render() {
    return this.props.url && this.props.method ? t(o, {
      url: this.props.url,
      ...this.props,
      onActionHandled: this.onActionHandled,
      onRedirectError: this.handleRedirectError
    }) : this.props.showPayButton ? t(e, {
      i18n: this.props.i18n,
      loadingContext: this.props.loadingContext,
      resources: this.resources
    }, t(s, {
      ...this.props,
      showPayButton: this.props.showPayButton,
      name: this.displayName,
      onSubmit: this.submit,
      payButton: this.payButton,
      ref: t => {
        this.componentRef = t;
      }
    })) : null;
  }
  constructor(...t) {
    super(...t), m(this, "handleRedirectError", () => {
      super.submitAnalytics({
        component: this.props.paymentMethodType,
        type: p.error,
        errorType: a.redirect,
        code: c.redirect
      });
    });
  }
}
m(d, "type", n.redirect), m(d, "defaultProps", {
  type: d.type
});
export { d as default };
