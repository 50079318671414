import { createElement as t } from "../../external/preact/dist/preact.js";
import { UIElement as e } from "../internal/UIElement/UIElement.js";
import r from "./components/DeviceFingerprint/PrepareFingerprint3DS2.js";
import i from "./callSubmit3DS2Fingerprint.js";
import { existy as n } from "../../utils/commonUtils.js";
import { TxVariants as o } from "../tx-variants.js";
import s, { API_ERROR as p } from "../../core/Errors/AdyenCheckoutError.js";
import { Analytics3DS2Errors as a, ANALYTICS_ERROR_TYPE as m, ANALYTICS_RENDERED_STR as l, Analytics3DS2Events as c } from "../../core/Analytics/constants.js";
import { THREEDS2_ERROR as u, THREEDS2_FINGERPRINT_ERROR as y, THREEDS2_FULL as d, THREEDS2_FINGERPRINT as h } from "./constants.js";
function A(t, e, r) {
  return e in t ? Object.defineProperty(t, e, {
    value: r,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[e] = r, t;
}
class D extends e {
  onComplete(t) {
    super.onComplete(t), this.unmount();
  }
  render() {
    return n(this.props.paymentData) ? t(r, {
      ...this.props,
      onComplete: this.props.isMDFlow ? this.onComplete : this.callSubmit3DS2Fingerprint,
      onSubmitAnalytics: this.submitAnalytics,
      isMDFlow: this.props.paymentData.length < 15,
      onActionHandled: this.onActionHandled
    }) : (this.props.onError(new s(p, "No paymentData received. 3DS2 Fingerprint cannot proceed")), this.submitAnalytics({
      type: u,
      code: a.ACTION_IS_MISSING_PAYMENT_DATA,
      errorType: m.apiError,
      message: `${y}: Missing 'paymentData' property from threeDS2 action`
    }), null);
  }
  constructor(...t) {
    super(...t), A(this, "callSubmit3DS2Fingerprint", i.bind(this)), A(this, "submitAnalytics", t => {
      t.type !== l && super.submitAnalytics(t);
    }), A(this, "onActionHandled", t => {
      this.submitAnalytics({
        type: d,
        message: t.actionDescription,
        subtype: c.FINGERPRINT_IFRAME_LOADED
      }), super.onActionHandled(t);
    });
  }
}
A(D, "type", o.threeDS2Fingerprint), A(D, "defaultProps", {
  dataKey: "fingerprintResult",
  type: h
});
export { D as default };
