<div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" translate>ID</th>
          <th scope="col" translate>CUSTOMERDISCOUNTGROUP</th>
          <th scope="col" translate>ITEMDISCOUNTGROUP</th>
          <th scope="col" translate>DISCOUNT_PERCENT</th>
          <th scope="col" translate>LOWERLIMIT</th>
          <th scope="col">
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-primary" translate (click)="openAddNew()">CREATE_NEW</button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let matrixDiscount of data">
          <th scope="row">{{ matrixDiscount.id }}</th>
          <td>{{metadataService.getMetadataValue(matrixDiscountService.customerDiscountMetadata.table, matrixDiscountService.customerDiscountMetadata.name, matrixDiscount.customerDiscountGroup) || matrixDiscount.customerDiscountGroup }}</td>
          <td>{{ metadataService.getMetadataValue(matrixDiscountService.itemDiscountMetadata.table, matrixDiscountService.itemDiscountMetadata.name, matrixDiscount.productDiscountGroup ) || matrixDiscount.productDiscountGroup }}</td>
          <td>{{ matrixDiscount.discountPercent }}</td>
          <td>{{ matrixDiscount.lowerLimit }}</td>
          <td>
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-secondary" (click)="onEdit(matrixDiscount)" translate>EDIT</button>
              <button type="button" class="btn btn-danger" (click)="onDelete(matrixDiscount)" translate>DELETE</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
