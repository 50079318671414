import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdyenOrderType, AdyenService } from '../../services/adyen/adyen.service';
import {
  AdditionalDetailsActions,
  AdditionalDetailsData,
  AdyenCheckout,
  AdyenCheckoutError,
  PaymentCompletedData,
  PaymentFailedData,
  UIElement
} from '@adyen/adyen-web';
import { CartService } from '../../services/cart/cart.service';
import { first, forkJoin } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'lib-adyen-redirect-result',
  templateUrl: './adyen-redirect-result.component.html',
  styleUrl: './adyen-redirect-result.component.scss'
})
export class AdyenRedirectResultComponent implements OnInit {
  constructor(
    protected route: ActivatedRoute,
    protected adyenService: AdyenService,
    protected router: Router,
    protected cartService: CartService,
    protected authService: AuthService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {}

  loading = true;
  merchantReference: string;
  pspReference: string;
  paymentMethodType: string;
  successfulPayment = false;

  ngOnInit(): void {
    console.log(this.route.snapshot);

    const redirectResultString = this.route.snapshot.queryParamMap.get('redirectResult');
    if (redirectResultString) {
      let checkout = AdyenCheckout({
        environment: this.environment.adyenEnvironment,
        clientKey: this.environment.adyenClientKey,
        countryCode: 'US',

        ...{
          onAdditionalDetails: async (
            state: AdditionalDetailsData,
            component: UIElement,
            actions: AdditionalDetailsActions
          ) => {
            this.adyenService.getPaymentDetails(state).subscribe(result => {
              if (!result.resultCode) {
                actions.reject();
                return;
              }

              if (result.resultCode === 'Authorised' || result.resultCode === 'Received') {
                this.merchantReference = result.merchantReference;
                this.pspReference = result.pspReference;
                this.paymentMethodType = result.paymentMethod.type;
              }

              const { resultCode, action, order, donationToken } = result;

              actions.resolve({
                resultCode,
                action,
                order,
                donationToken
              });
            });
          }
        },

        onPaymentCompleted: (data: PaymentCompletedData, component?: UIElement) => {
          try {
            this.adyenService
              .onPaymentCompleted({
                data,
                pspReference: this.pspReference,
                merchantReference: this.merchantReference,
                selectedPaymentMethod: this.paymentMethodType
              })
              .subscribe({
                next: response => {
                  if (response.orderType == AdyenOrderType.ADYEN_ORDER) {
                    this.cartService.clearCart();
                    this.router.navigateByUrl('payment-successful-adyen/' + this.merchantReference);
                  } else if (response.orderType == AdyenOrderType.ADYEN_INVOICE) {
                    this.authService
                      .getCurrentUser()
                      .pipe(first())
                      .subscribe(currentUser => {
                        this.successfulPayment = true;
                        setTimeout(() => {
                          if (
                            currentUser.roles.indexOf('USER') > -1 &&
                            currentUser.roles.indexOf('ADMIN') === -1 &&
                            currentUser.roles.indexOf('SALES') === -1
                          ) {
                            //   this.router.navigate(['/customers', this.checkout.billingCustomerId], {
                            //     queryParams: { tab: 'lib-gung-tab-4' }
                            //  });
                          } else {
                            this.router.navigate(['/invoices']);
                          }
                        }, 1500);
                      });
                  }
                  this.loading = false;
                },
                error: error => {
                  console.error(error);
                  this.router.navigateByUrl('order-failure-adyen');
                }
              });
          } catch (e) {
            console.error(e);
            this.router.navigate(['/order-failure-adyen']);
          }
        },
        onPaymentFailed: (data?: PaymentFailedData, component?: UIElement) => {
          console.log(data);
          this.router.navigateByUrl('payment-failure-adyen');
        },
        onError: (error: AdyenCheckoutError) => {
          console.error(error);
        }
      }).then(checkout => {
        checkout.submitDetails({ details: { redirectResult: redirectResultString } });
      });
    }
  }
}
