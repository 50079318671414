import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, Observable } from 'rxjs';
import { HlDisplayPortalFeaturesService } from './hl-display-portal-features.service';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayFreightService {

  constructor(
    protected http: HttpClient,
    protected portalFeatureSettings: HlDisplayPortalFeaturesService
  ) { }

  getCustomFreightLevels(): Observable<any> {
    const baseUrl = 'json/hl-display/get-freight-levels';

    const portalName = this.portalFeatureSettings.getPortalName();

    const url = `${baseUrl}/${portalName}`;

    return this.http.get(url);
  }
}
