import { createElement as e } from "../../external/preact/dist/preact.js";
import t from "../../components/internal/BaseElement/BaseElement.js";
import n from "./components/DeviceFingerprint/DeviceFingerprint.js";
import o from "../../utils/base64.js";
import { RISK_DATA_VERSION as s, DEVICE_FINGERPRINT as r } from "./constants.js";
function i(e, t, n) {
  return t in e ? Object.defineProperty(e, t, {
    value: n,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = n, e;
}
class a extends t {
  formatProps(e) {
    return {
      ...e,
      risk: {
        ...a.defaultProps.risk,
        ...e.risk
      }
    };
  }
  get isValid() {
    return this.state.isValid;
  }
  get data() {
    if (this.isValid) {
      const e = {
        version: s,
        ...this.state.data
      };
      return o.encode(JSON.stringify(e));
    }
    return !1;
  }
  get enabled() {
    return this.props.risk.enabled;
  }
  componentWillUnmount() {
    this.cleanUp();
  }
  render() {
    return e(n, {
      ...this.props,
      loadingContext: this.props.loadingContext,
      onComplete: this.onComplete,
      onError: this.onError
    });
  }
  constructor(e, t) {
    super(e, t), i(this, "nodeRiskContainer", null), i(this, "onComplete", e => {
      const t = {
        ...this.state.data,
        [e.type]: e.value,
        persistentCookie: e.persistentCookie,
        components: e.components
      };
      this.setState({
        data: t,
        isValid: !0
      }), this.props.risk.onComplete(this.data), this.cleanUp();
    }), i(this, "onError", e => {
      this.props.risk.onError(e), this.cleanUp();
    }), i(this, "cleanUp", () => {
      this.nodeRiskContainer && this.nodeRiskContainer.parentNode && this.nodeRiskContainer.parentNode.removeChild(this.nodeRiskContainer);
    });
    const n = {
      [r]: null
    };
    this.setState({
      data: n
    }), !0 === this.props.risk.enabled && (document.querySelector(this.props.risk.node) ? (this.nodeRiskContainer = document.createElement("div"), document.querySelector(this.props.risk.node).appendChild(this.nodeRiskContainer), this.mount(this.nodeRiskContainer)) : this.onError({
      message: "RiskModule node was not found"
    }));
  }
}
i(a, "type", "risk"), i(a, "defaultProps", {
  risk: {
    enabled: !0,
    onComplete: () => {},
    onError: () => {},
    node: "body"
  }
});
export { a as default };
