import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnInit } from '@angular/core';
import { forkJoin, of, Observable } from 'rxjs';
import { first, map, switchMap, tap, mergeMap } from 'rxjs';
import {
  ListLayout,
  ListLayoutSingleComponent,
  ListLayoutMultipleComponent,
  SimpleConfigBaseFilter,
  ConfigBaseFilter
} from 'gung-list';
import {
  Product,
  ProductListConfigService,
  ProductService,
  AuthService,
  SelectedCustomerService,
  ProductSortService,
  PriceService,
  MetadataService,
  AssortmentService,
  GungFlowService,
  Customer,
  CloudPimFiltersService,
  CurrentAssortmentService
} from 'gung-standard';
import { ProductItemGroupFilterService } from 'gung-standard-jeeves';
import { ProductCategoryFilterService } from './filters/product-category-filter.service';
import { ProductMerchandiseTypeFilterService } from './filters/product-merchandise-type-filter.service';
import { ProductDepthFilterService } from './filters/product-depth-filter.service';
import { ProductEtiquetteFilterService } from './filters/product-etiquette-filter.service';
import { ProductGondolaBrandFilterService } from './filters/product-gondola-brand-filter.service';
import { ProductLengthFilterService } from './filters/product-length-filter.service';
import { ProductCapacityFilterService } from './filters/product-capacity-filter.service';
import { ProductColorFilterService } from './filters/product-color-filter.service';
import { ProductShelfManufacturerFilterService } from './filters/product-shelf-manufacturer-filter.service';


import { environment } from '../../environments/environment';
import { ProductMousquetairesFilterService } from './filters/product-mousquetaires-filter.service';
import { ProductAssortmentTypeFilterService } from './filters/product-assortment-type-filter.service';
import { ProductPimFilterService } from './filters/product-pim-filter.service';
import { HlDisplayHelperService } from './hl-display-helper.service';
import { ProductExternalCategoryFilterService } from './filters/product-external-category-filter.service';
import { ProductExternalSubCategoryFilterService } from './filters/product-external-sub-category-filter.service';
import { ProductMainFunctionFilterService } from './filters/product-main-function-filter.service';
import { ProductStoreCategoryFilterService } from './filters/product-store-category-filter.service';
import { HlProductService } from './hl-product.service';
import { HlDisplayOrderService } from './hl-display-order.service';
import { ProductHeightFilterService } from './filters/product-height-filter.service';
import { ProductProductShelfTypeFilterService } from './filters/product-product-shelf-type-filter.service';
import { ProductWidthFilterService } from './filters/product-width-filter.service';
import { HlBackendInterceptorService } from './hl-backend-interceptor/hl-backend-interceptor.service';
import {
  FilterSettingsGroup,
  HlDisplayPortalFeaturesService,
  LanguageSettingsGroup,
  PortalFeatures
} from './hl-display-portal-features.service';
import { HlProductCardListGridViewComponent } from '../components/hl-product-card-list-grid-view/hl-product-card-list-grid-view.component';
import { HlProductCardListComponent } from '../components/hl-product-card-list/hl-product-card-list.component';
import { HlProductCompressedListViewComponent } from '../components/hl-product-compressed-list-view/hl-product-compressed-list-view.component';
import { PriceSettingsGroup } from './hl-display-portal-features.service';

export class ProductMetadataPimFilterService extends SimpleConfigBaseFilter<Product> {
  constructor(
    protected metadataService: MetadataService,
    protected metadataTable: string,
    protected language: string,
    protected filterLabel: string,
    protected segment: string,
    protected field: string,
    protected isList = false,
    protected isSimpleObjectArray?: boolean
  ) {
    super();
  }

  getName(): string {
    return this.filterLabel;
  }


  /*
  new ProductMetadataPimFilterService(
            this.metaDataService,
            'StoreCategoryNonFood',
            this.translateService.currentLang,
            'STORE_AREA',
            'hlPimItem',
            'ItemStoreCategoryNonFood',
            true
          )
  */
  getOptionIds(item: Product): string[] {
    let options = [];
    if (this.isList) {
      // Here we need to take into consideration if the field values are mapped to language codes or not
      // The difference is if the field is translateable in Struct or not. - Adam

      if (item.extra[this.segment][this.field]) {
        //With the Struct PIM implementation a lot of their previous raw string-array values (from inRiver) are now
        //objects with an Id and (possibly) a Value. We need to handle this differently. 
        // This gives us the possibility to only target these new Id-Value objects. Other filters will still work the same as before.
        // - Alexander 
        if (this.isSimpleObjectArray) {
          options = item.extra[this.segment][this.field].map(arrayEntry => arrayEntry.Id) || [];

        }
        else if (typeof item.extra[this.segment][this.field][0] === 'string') {
          options = item.extra[this.segment][this.field] || [];
        } else if (typeof item.extra[this.segment][this.field][0] === 'object') {
          options = item.extra[this.segment][this.field].map(arrayEntry => arrayEntry[this.language]) || [];
        }
      }
    } else {
      options = [item.extra[this.segment][this.field] || ''];
    }
    return options;
  }

  getOptionName(key: string): string {
    const i18nValues = this.metadataService.getMetadataValue(this.metadataTable, 'Values', key);

    let language = this.language;
    if (language === 'en') {
      language = 'en-GB';
    } else if (language === 'se') {
      language = 'sv';
    }

    if (i18nValues?.[language]) {
      return i18nValues?.[language];
    }

    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class HlDisplayProductListConfigService extends ProductListConfigService {
  private warehouseProductsCache: { [productId: string]: Product };

  currentLang = this.translateService.currentLang;
  defaultLang = null;

  roles = [];

  constructor(
    public productService: HlProductService,
    protected productCategoryFilter: ProductCategoryFilterService,
    protected productMerchandiseTypeFilter: ProductMerchandiseTypeFilterService,
    protected productLengthFilter: ProductLengthFilterService,
    protected productDepthFilter: ProductDepthFilterService,
    protected productGondolaBrandFilter: ProductGondolaBrandFilterService,
    protected productEtiquetteFilter: ProductEtiquetteFilterService,
    protected productCapacityFilter: ProductCapacityFilterService,
    protected productColorFilter: ProductColorFilterService,
    protected productShelfManufacturerFilter: ProductShelfManufacturerFilterService,
    protected productItemGroupFilter: ProductItemGroupFilterService,
    protected productMousquetairesFilter: ProductMousquetairesFilterService,
    protected productAssortmentTypeFilter: ProductAssortmentTypeFilterService,
    protected metaDataService: MetadataService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected translateService: TranslateService,
    productSortService: ProductSortService,
    protected priceService: PriceService,
    protected assortmentService: AssortmentService,
    protected gungFlowService: GungFlowService,
    private helperService: HlDisplayHelperService,
    protected productCategoryFilterService: ProductCategoryFilterService,
    protected productExternalCategoryFilterService: ProductExternalCategoryFilterService,
    protected productExternalSubCategoryFilterService: ProductExternalSubCategoryFilterService,
    protected productMainFunctionFilterService: ProductMainFunctionFilterService,
    protected productStoreCategoryFilterService: ProductStoreCategoryFilterService,
    protected hlDisplayOrderService: HlDisplayOrderService,
    protected productProductShelfTypeFilterService: ProductProductShelfTypeFilterService,
    protected productWidthFilterService: ProductWidthFilterService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(
      productService,
      authService,
      productSortService,
      gungFlowService,
      cloudPimFiltersService,
      currentAssortmentService
    );

    this.authService.getRoles().subscribe(roles => {
      this.roles = roles;
    });
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    let filters = [];
    this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('filterSettingsGroup').subscribe(filterSettingsGroup => {
      let filterGroup = filterSettingsGroup as FilterSettingsGroup;

      if (filterGroup.hasProductAssortmentTypeFilterEnabled) {
        filters.push(this.productAssortmentTypeFilter);
      }
      if (filterGroup.hasProductHeightFilterEnabled) {
        filters.push(new ProductHeightFilterService());
      }
      if (filterGroup.hasProductLengthFilterEnabled) {
        filters.push(this.productLengthFilter);
      }
      if (filterGroup.hasProductDepthFilterEnabled) {
        filters.push(this.productDepthFilter);
      }
      if (filterGroup.hasProductBeenPurchasedByCustomerFilterEnabled) {
        filters.push(new CustomerPurchasedProductFilter());
      }

      if (filterGroup.hasProductRollOutFilterEnabled) {
        filters.push(new RollOutProductFilter());
      }

      if (filterGroup.hasProductShelfTypeFilterEnabled) {
        filters.push(this.productProductShelfTypeFilterService);
      }
      if (filterGroup.hasProductEtiquetteFilterEnabled) {
        filters.push(this.productEtiquetteFilter);
      }

      if (filterGroup.hasProductCategoryFilterEnabled) {
        filters.push(this.productCategoryFilter);
      }
      if (filterGroup.hasProductCapacityFilterEnabled) {
        filters.push(this.productCapacityFilter);
      }
      if (filterGroup.hasProductMousquetairesFilterEnabled) {
        filters.push(this.productMousquetairesFilter);
      }
      if (filterGroup.hasProductDiscountedItemsFilterEnabled) {
        filters.push(new ProductDiscountedItemsFilter());
      }
      if (filterGroup.hasProductExternalSubCategoryFilterEnabled) {
        filters.push(this.productExternalSubCategoryFilterService);
      }
      if (filterGroup.hasProductItemGroupFilterEnabled) {
        filters.push(this.productItemGroupFilter);
      }
      if (filterGroup.hasProductMainFunctionFilterEnabled) {
        filters.push(this.productMainFunctionFilterService);
      }
      if (filterGroup.hasProductStoreCategoryFilterEnabled) {
        filters.push(this.productStoreCategoryFilterService);
      }

      // Metadata Filters
      if (filterGroup.hasProductColorFilterEnabled) {
        filters.push(
          new ProductMetadataPimFilterService(
            this.metaDataService,
            'ColourName',
            this.translateService.currentLang,
            'COLOUR',
            'hlPimItem',
            'ItemColourName',
            true,
            true
          )
        );
      }
      if (filterGroup.hasProductMaterialFilterEnabled) {
        filters.push(
          new ProductMetadataPimFilterService(
            this.metaDataService,
            'Material',
            this.translateService.currentLang,
            'MATERIAL',
            'hlPimItem',
            'ProductStdMaterial',
            true,
            true
          )
        );
      }
      if (filterGroup.hasProductShelfManufacturerFilterEnabled) {
        filters.push(
          new ProductMetadataPimFilterService(
            this.metaDataService,
            'ShelfManufacturer',
            this.translateService.currentLang,
            'SHELF_MANUFACTURER',
            'hlPimProduct',
            'ProductShelfManufacturer',
            true
          )
        );
      }

      if (filterGroup.hasProductStoreNonFoodFilterEnabled) {
        filters.push(
          new ProductMetadataPimFilterService(
            this.metaDataService,
            'StoreCategoryNonFood',
            this.translateService.currentLang,
            'STORE_AREA',
            'hlPimItem',
            'ItemStoreCategoryNonFood',
            true,
            true
          )
        );
      }

      if (filterGroup.hasProductMerchandiseTypeFilterEnabled) {
        filters.push(
          new ProductMetadataPimFilterService(
            this.metaDataService,
            'MerchandiseType',
            this.translateService.currentLang,
            'MERCHANDISE_TYPE',
            'hlPimItem',
            'ItemMerchandiseType',
            true,
            true
          )
        );
      }

      if (filterGroup.hasProductMerchandiseBrandFilterEnabled) {
        filters.push(
          new ProductMetadataPimFilterService(
            this.metaDataService,
            'MerchandiseBrand',
            this.translateService.currentLang,
            'MERCHANDISE_BRAND',
            'hlPimItem',
            'ItemMerchandiseBrand',
            true,
            true
          )
        );
      }

      // Inriver Filters
      if (filterGroup.hasProductWidthFilterEnabled) {
        filters.push(new ProductPimFilterService('WIDTH_MM', 'hlPimItem', 'ItemWidthmm', ' mm'));
      }
      if (filterGroup.hasProductSizeFilterEnabled) {
        //essentially a copy of capacity filter - but with another name?
        //This is used for e.g fr-portals, whilst capacity is used on e.g se
        filters.push(new ProductPimFilterService('SIZE', 'hlPimItem', 'ItemSize'));
      }
      if (filterGroup.hasProductItemDiameterMmEnabled) {
        filters.push(new ProductPimFilterService('DIAMETER_MM', 'hlPimItem', 'ItemDiametermm', ' mm'));
      }

      /*
      DUPLICATE FILTER
      if (filterGroup.hasProductStoreDepartmentFilterEnabled) {
        filters.push(new ProductPimFilterService('STORE_DEPARTMENT', 'hlPimProduct', 'ProductStoreCategory', '', true));
      }*/
      if (filterGroup.hasProductTrademarkFilterEnabled) {
        filters.push(new ProductPimFilterService('TRADEMARK', 'hlPimProduct', 'ProductRange', '', true));
      }
      if (filterGroup.hasProductSystemEnabled) {
        filters.push(new ProductPimFilterService('PRODUCT_SYSTEM', 'hlPimProduct', 'ProductSystem'));
      }
      if (filterGroup.hasProductFrontFilterEnabled) {
        filters.push(new ProductPimFilterService('FRONT', 'hlPimItem', 'ItemFront'));
      }
      if (filterGroup.hasProductLabelTypeFilterEnabled) {
        filters.push(new ProductPimFilterService('LABEL_TYPE', 'hlPimItem', 'LabelType'));
      }
      if (filterGroup.hasProductSpringFilterEnabled) {
        filters.push(new ProductPimFilterService('SPRING', 'hlPimItem', 'ItemSpring'));
      }
    });

    return filters;
  }

  getWarehouseProducts(): Observable<Product[]> {
    return this.productService.getProductsByAssortment('warehouse');
  }

  getItems() {
    return forkJoin({
      filterSettingsGroup: this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('filterSettingsGroup'),
      languageSettingsGroup: this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('languageSettingsGroup'),
      priceSettingsGroup: this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('priceSettingsGroup')
    }).pipe(
      first(),
      switchMap(({ filterSettingsGroup, languageSettingsGroup, priceSettingsGroup }) => {
        let filterSettings = filterSettingsGroup as FilterSettingsGroup;
        let languageSettings = languageSettingsGroup as LanguageSettingsGroup;
        let priceSettings = priceSettingsGroup as PriceSettingsGroup;
        this.defaultLang = languageSettings.defaultLanguage;
        if (this.roles.includes('SALES')) {
          //Was environment.sales
          return this.selectedCustomerService.getSelectedCustomer().pipe(
            switchMap(customer =>
              forkJoin({
                selectedCustomer: of(customer),
                products: this.productService
                  .getProductsByAssortment(
                    this.helperService.checkRequestPricelistAssortmentWithWebCondition(customer.extra.kus.prislista)
                  )
                  .pipe(first()),
                priceListAssortment: this.assortmentService.getAssortment(
                  this.helperService.checkRequestPricelistAssortmentWithWebCondition(customer.extra.kus.prislista)
                ),
                warehouseProducts:
                  !!priceSettings.includeWarehouseProductsInProductList && !this.warehouseProductsCache
                    ? this.getWarehouseProducts().pipe(first())
                    : of([]),
                orderRows:
                  customer && filterSettings.hasProductBeenPurchasedByCustomerFilterEnabled
                    ? this.hlDisplayOrderService.getOrderRows(customer.id).pipe(first())
                    : of([])
              })
            ),
            tap(({ products, orderRows }) => {
              // const purchasedIds = customerPurchasedProducts.map(p => p.artnr);
              if (orderRows.length === 0) {
                return products;
              }
              const purchasedIds = orderRows.map(p => p.productId);
              return products.map(product => {
                if (purchasedIds.includes(product.id)) {
                  product.extra._customerPurchasedProduct = true;
                }
                return product;
              });
            }),
            map(({ selectedCustomer, products, priceListAssortment, warehouseProducts }) => {
              const customerProducts = priceListAssortment.children
                .filter(child => child.extra.pricelist === '' + selectedCustomer.extra.kus.prislista)
                .map(child => child.id);
              const standardProducts = priceListAssortment.children
                .filter(child => child.extra.pricelist !== '' + selectedCustomer.extra.kus.prislista)
                .map(child => child.id);

              const mergedProducts = [...products];

              const mergedProductsIndex = {};
              mergedProducts.forEach(p => {
                mergedProductsIndex[p.id] = true;
              });

              const standardProductsIndex = {};
              standardProducts.forEach(p => {
                standardProductsIndex[p] = true;
              });

              const customerProductsIndex = {};
              customerProducts.forEach(p => {
                customerProductsIndex[p] = true;
              });

              warehouseProducts.forEach(p => {
                if (!mergedProductsIndex[p.id]) {
                  mergedProducts.push(p);
                  mergedProductsIndex[p.id] = true;
                }
              });

              mergedProducts.forEach(product => {
                if (customerProductsIndex[product.id]) {
                  product.extra.assortmentType = 'C';
                } else if (standardProductsIndex[product.id]) {
                  product.extra.assortmentType = 'S';
                } else {
                  product.extra.assortmentType = 'W';
                }
              });

              const sorted = mergedProducts.sort((p1, p2) => {
                const artbeskr1 = p1.extra.ar.artbeskr || 'ZZZZZZZZZZZZZZZZZ';
                const artbeskr2 = p2.extra.ar.artbeskr || 'ZZZZZZZZZZZZZZZZZ';
                return artbeskr1.localeCompare(artbeskr2);
              });
              console.log('leaving getItems', sorted.length);
              //return sorted;
              return sorted;
            })
          );
        }
        //Was previously: (environment.mainCountry && environment.mainCustomer) || !!environment.showPreviousOrderFilter
        //This should not be based on the feature, but rather when the filter dependent on the filter is checked
        else if (filterSettings.hasProductBeenPurchasedByCustomerFilterEnabled) {
          //Go through: should be replaced by features
          let selectedCustomer: Customer;
          return this.selectedCustomerService.getSelectedCustomer().pipe(
            tap(customer => (selectedCustomer = customer)),
            switchMap(customer =>
              this.productService.getProductsByAssortment(
                this.helperService.checkRequestPricelistAssortmentWithWebCondition(customer.extra.kus.prislista)
              )
            ),
            mergeMap(products =>
              forkJoin({
                products: of(products),
                // customerPurchasedProducts: this.productService.getCustomerPurchasedProducts().pipe(first())
                orderRows: selectedCustomer
                  ? this.hlDisplayOrderService.getOrderRows(selectedCustomer.id).pipe(first())
                  : of([])
              })
            ),
            map(({ products /* , customerPurchasedProducts */, orderRows }) => {
              // const purchasedIds = customerPurchasedProducts.map(p => p.artnr);
              const purchasedIds = orderRows.map(p => p.productId);
              return products.map(product => {
                if (purchasedIds.includes(product.id)) {
                  product.extra._customerPurchasedProduct = true;
                }
                return product;
              });
            }),
            map(products => {
              const blockedItems = this.getBlockedItems();
              const filteredProducts = products.filter(item => blockedItems.indexOf(item.id) === -1);

              if (this.roles.includes('MANAGER')) {
                //Go through: was previously this.isManagersCoop || this.isManagersNisa
                products.map(p => {
                  p.extra._idToCustomer = selectedCustomer.extra?.kuarMapped?.[p.id]?.artnrkund || '';
                });
              }

              if (this.roles.includes('MANAGER') && selectedCustomer?.extra.kuarMapped) {
                //Go through: isCloettaPortal should be replaced by a feature
                //Was this.isCloettaPortal && && selectedCustomer?.extra.kuarMapped
                const kuarMapped = selectedCustomer?.extra.kuarMapped;
                filteredProducts.forEach(p => {
                  if (kuarMapped?.[p.id]) {
                    p.extra._artnrkund = kuarMapped[p.id].artnrkund;
                  }
                });
              }

              return filteredProducts.sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                return 0;
              });
            })
          );
        } else {
          return this.authService.getCurrentUser().pipe(
            switchMap(user =>
              forkJoin({
                products: this.productService.getProductsByAssortment(user.assortment).pipe(first()),
                selectedCustomer: this.selectedCustomerService.getSelectedCustomer().pipe(first())
              })
            ),
            switchMap(({ products, selectedCustomer }) => {
              if (this.roles.length === 1 && this.roles.includes('USER')) {
                //Go through: should be replaced by a feature
                //Was this.isCurrysPortal
                const customerId = selectedCustomer?.id.includes('@')
                  ? selectedCustomer.id.slice(0, selectedCustomer.id.indexOf('@'))
                  : selectedCustomer?.id;
                products.forEach(p => {
                  p.extra._kuar = selectedCustomer?.extra.kuarMapped?.[p.id]?.artnrkund || '';
                });
              }
              return forkJoin({
                products: of(products),
                // customerPurchasedProducts: this.productService.getCustomerPurchasedProducts().pipe(first())
                orderRows: selectedCustomer
                  ? this.hlDisplayOrderService.getOrderRows(selectedCustomer.id).pipe(first())
                  : of([])
              });
            }),
            map(({ products /* , customerPurchasedProducts */, orderRows }) => {
              // const purchasedIds = customerPurchasedProducts.map(p => p.artnr);
              const purchasedIds = orderRows.map(p => p.productId);
              return products.map(product => {
                if (purchasedIds.includes(product.id)) {
                  product.extra._customerPurchasedProduct = true;
                }
                return product;
              });
            }),
            map(products => {
              const blockedItems = this.getBlockedItems();
              const filteredProducts = products.filter(item => blockedItems.indexOf(item.id) === -1);

              return filteredProducts.sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                return 0;
              });
            })
          );
        }
      })
    );
  }

  getBatchSizes(): number[] {
    return [20, 80, 120, 160];
  }

  getLayouts(): ListLayout<Product>[] {
    if (this.roles.length === 1 && this.roles.includes('USER')) {
      //Go trough: was previously isCustomerPorta && !isNOCustomerPortal
      return [
        {
          getIconClass: () => 'fas fa-th',
          getListItemComponent: () => HlProductCardListGridViewComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'Grid View'
        },
        {
          getIconClass: () => 'fa fa-list',
          getListItemComponent: () => HlProductCardListComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'Product grid'
        },
        {
          getIconClass: () => 'fa-solid fa-table-list',
          getButtonClass: () => 'd-none d-sm-none d-md-inline-block',
          getListItemComponent: () => HlProductCompressedListViewComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'Compressed list view'
        }
      ];
    }
    return [
      {
        getIconClass: () => 'fa fa-list',
        getListItemComponent: () => HlProductCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Product grid'
      },
      {
        getIconClass: () => 'fas fa-th',
        getListItemComponent: () => HlProductCardListGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Grid View'
      },
      {
        getIconClass: () => 'fa-solid fa-table-list',
        getButtonClass: () => 'd-none d-sm-none d-md-inline-block',
        getListItemComponent: () => HlProductCompressedListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Compressed list view'
      }
    ];
  }

  getSearchTerms(item: Product): string[] {
    let result = [];
    if (item.id) {
      result.push(item.id);
    }
    if (item.name) {
      result.push(item.name);
    }

    if (item.description) {
      result.push(item.description);
    }

    if (item?.extra?.i18n?.[this.currentLang]?.artbeskr2) {
      result.push(item.extra.i18n[this.currentLang].artbeskr2);
    }

    result = [...result, ...this.getTranslatedTerms(item)];

    const name = this.getName(item);
    if (name) {
      result.push(name);
    }

    if (item.extra.ar.varugruppkod) {
      const value = this.metaDataService.getMetadataValue('vg', 'varugruppbeskr', item.extra.ar.varugruppkod);
      if (value) {
        result.push(value);
      }
    }

    if (item.extra.hlPimItem.ItemMerchandiseType && item.extra.hlPimItem.ItemMerchandiseType.length > 0) {
      if (typeof item.extra.hlPimItem.ItemMerchandiseType[0] === 'object') {
        const filteredMerchandiseTypes = item.extra.hlPimItem.ItemMerchandiseType.filter(type => !!type)
        result = [...result, ...filteredMerchandiseTypes.map(type => type.Id)];
      } else {
        result = [...result, ...item.extra.hlPimItem.ItemMerchandiseType.filter(type => !!type)];
      }
    }

    if (item.extra._artnrkund) {
      result.push(item.extra._artnrkund);
    }

    if (item.extra._kuar) {
      result.push(item.extra._kuar);
    }

    let itemDesc = item.extra.ar.artbeskr;
    if (item.extra?.i18n?.[this.currentLang]) {
      itemDesc = item.extra.i18n[this.currentLang].artbeskr || itemDesc;
    }

    result.push(itemDesc);

    if (item.extra.arb) {
      result.push(item.extra.arb.artBeskrSpec);
      result.push(item.extra.arb.itemSpecWeb);
    }
    if (item.extra.ar) {
      result.push(item.extra.ar.artbeskrspec);
      result.push(item.extra.ar.artbeskr);
      result.push(item.extra.ar.artnr);
    }
    if (item.extra.kuar) {
      result.push(item.extra.ar.artnrkund);
    }

    return result;
  }

  private getTranslatedTerms(item: Product): string[] {
    if (!item.extra.i18n) {
      return [];
    }

    const selectedLanguageCode = this.translateService.currentLang;
    const result = [];

    const i18n = item.extra.i18n;

    [this.defaultLang, selectedLanguageCode].forEach(languageCode => {
      const languageProperties = i18n[languageCode];
      if (!languageProperties) {
        return;
      }

      if (languageProperties.artbeskrspec) {
        result.push(languageProperties.artbeskrspec);
      }
      if (languageProperties.itemspecweb) {
        result.push(languageProperties.itemspecweb);
      }
      if (languageProperties.artbeskr) {
        result.push(languageProperties.artbeskr);
      }
    });

    return result;
  }

  protected getBlockedItems(): string[] {
    // Customer portal
    if (!this.roles.includes('SALES')) {
      /**
       * These product ids are 'blocked', meaning that they should not be viewed in the product list.
       * This is kept as a comment for legacy purposes, to revise to if this should be reimplemented in the future.
       */
      /* if (environment.mainCountry === 'no') {
        return ['527859', '991100', '785710', '534222', '798076', '900000'];
      }
      if (
        environment.mainCountry === 'fr' &&
        (environment.mainCustomer === 'Pharmacies' || environment.mainCustomer === 'General') //Go through: should be replaced by a feature?
      ) {
        return ['527859', '900000'];
      } */
      return ['900000'];
    }
    return [];
  }

  getSearchGroupCss(): string {
    return '';
  }

  getFilterGroupCss(): string {
    return '';
  }

  getName(product: Product): string {
    if (!this.roles.includes('SALES')) {
      //Go through: was previously !environment.sales
      return product.name;
    }

    const id = product.id;

    // const itemGroup = this.metaDataService.getMetadataValue('vg', 'varugruppbeskr', product.extra.ar.varugruppkod);
    const itemProductSystem = product.extra.hlPimProduct?.ProductSystem;
    const itemDesc =
      this.i18nSeExists(product) && product.extra.i18n.se.artbeskr
        ? product.extra.i18n.se.artbeskr
        : product.extra.ar.artbeskr;
    return `${id}${itemProductSystem ? ' - ' + itemProductSystem : ''} - ${itemDesc}`;
  }

  i18nSeExists(data: Product): boolean {
    return data.extra.i18n && data.extra.i18n.se;
  }

  hideZeroOptions() {
    return true;
  }
}
function peek(arg0: (products: any) => void): import('rxjs').OperatorFunction<Product[], unknown> {
  throw new Error('Function not implemented.');
}

export class CustomerPurchasedProductFilter extends SimpleConfigBaseFilter<Product> {
  getName(): string {
    return 'CUSTOMER_PURCHASED_PRODUCT';
  }
  getOptionIds(item: Product): string[] {
    if (!!item.extra?._customerPurchasedProduct) {
      return ['CUSTOMER_PURCHASED_PRODUCT_FILTER_SELECTED'];
    }
    return [];
  }
  getOptionName(key: string): string {
    return `${key}`;
  }
}

export class RollOutProductFilter extends SimpleConfigBaseFilter<Product> {
  getName(): string {
    return 'ROLL_OUT';
  }
  getOptionIds(item: Product): string[] {
    if (!!item.extra?.hlPimItem?.ItemCSRollOut) {
      return ['ROLL_OUT'];
    }
    return [];
  }
  getOptionName(key: string): string {
    return `${key}`;
  }
}

export class ProductDiscountedItemsFilter extends SimpleConfigBaseFilter<Product> {
  getName(): string {
    return 'DISCOUNTED_ITEMS';
  }
  getOptionIds(item: Product): string[] {
    if (item?.extra?.price?.backendPrice?.levels?.[0]?.extra?.prl?.proc1 > 0) {
      // This conditions is not correct
      return ['DISCOUNTED_FILTER_SELECTED'];
    }
    return [];
  }
  getOptionName(key: string): string {
    return `${key}`;
  }
}
