import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import t from "../PaymentMethodIcon.js";
import { getFullBrandName as n } from "../../../../Card/components/CardInput/utils.js";
import { useCoreContext as a } from "../../../../../core/Context/CoreProvider.js";
const r = ({
  brands: r,
  excludedUIBrands: s = [],
  isPaymentMethodSelected: o,
  keepBrandsVisible: m = !1,
  showOtherInsteafOfNumber: d = !1
}) => {
  const {
    i18n: c
  } = a();
  if (o && !m) return null;
  const l = r.filter(e => !s?.includes(e.name)),
    {
      visibleBrands: i,
      leftBrandsAmount: p
    } = (e => {
      const t = e.length <= 4 ? e : e.slice(0, 3);
      return {
        visibleBrands: t,
        leftBrandsAmount: e.length - t.length
      };
    })(l);
  return e("span", {
    className: "adyen-checkout__payment-method__brands"
  }, i.map(a => e(t, {
    key: a.name,
    altDescription: n(a.name),
    type: a.name,
    src: a.icon
  })), d ? e("span", {
    className: "adyen-checkout__payment-method__brand-number"
  }, "+ ", c.get("paymentMethodBrand.other")) : 0 !== p && e("span", {
    className: "adyen-checkout__payment-method__brand-number"
  }, "+", p));
};
export { r as default };
