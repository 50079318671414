<div *ngIf="data && loaded; else loading" class="order-portal-list">
  <div class="row" *ngFor="let entry of data; index as i; trackBy: trackByFn">
    <div class="col-12 card-body">
      <div class="row">


        <div class="col-12 card-title">
          <div class="d-flex align-items-baseline justify-content-between">
            <h5 class="d-inline mt-0">

              <!-- TODO Below tooltip should not be hardcoded to EDI_MESSAGE, but fetched based on the  original input type -->
              <!-- At the time of writing that would always be an edi message though, so fine for now. -->
              <a [routerLink]="orderPortalChannelService.getOriginalInputRoute(entry, entry.originalInputId)"
                 [ngbTooltip]="'NAVIGATE_TO_ORIGINAL_EDI_MESSAGE' | translate"
                 *ngIf="!!entry.originalInputId">
                <i class="fas fa-eye pr-1" style="font-size: larger"></i>
                {{ entry.id }}
              </a>
              <span *ngIf="!entry.originalInputId">
                {{ entry.id }}
              </span>

              <span class="bold">
                - <lib-order-portal-status-display [entry]="entry"></lib-order-portal-status-display>
              </span>

              <br *ngIf="!!entry.createdOrderIds && entry.createdOrderIds.length > 0" />
              <span class="created-ids" *ngIf="!!entry.createdOrderIds && entry.createdOrderIds.length > 0">
              <span >{{getCreatedEntityTranslationTag(entry) | translate}}</span>:
              <a [routerLink]="getRouterLink(entry, id)" [queryParams]="getRouterLinkParams(entry, id)"
                 class="ml-1" *ngFor="let id of entry.createdOrderIds">{{id}}</a>
            </span>
            </h5>
            <div class="float-right">
              <lib-action-btn [(item)]="data[i]" [config]="actionConfigService"></lib-action-btn>
            </div>
          </div>
        </div>


        <div class="col-12 col-md-4">
          <div class="form-group">
            <label class="font-weight-bold">
              <span translate>ORDER_CHANNEL</span>
            </label>
            <p>
              <span translate>{{entry.orderChannel}} </span>
              <span *ngIf="entry.orderSubChannel">(<span translate>{{entry.orderSubChannel}}</span>)</span>
            </p>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <div class="form-group">
            <label class="font-weight-bold">
              <span translate>CREATED_BY</span>
            </label>
            <p>{{entry.createdBy}}</p>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <div class="form-group">
            <label class="font-weight-bold">
              <span translate>DATE</span>
            </label>
            <p>{{entry.timestamp | date: 'yyyy-MM-dd HH:mm:ss'}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
  LOADING...
</ng-template>
