import { createElement as t } from "../../../../external/preact/dist/preact.js";
import { useRef as a, useState as e, useCallback as o, useEffect as n } from "../../../../external/preact/hooks/dist/hooks.js";
import r from "../../../../utils/Script.js";
import { KLARNA_WIDGET_URL as i } from "../../constants.js";
function s({
  sdkData: s,
  paymentMethodType: m,
  widgetInitializationTime: c,
  payButton: d,
  ...l
}) {
  const p = a(null),
    [y, u] = e("ready"),
    _ = o(() => {
      u("error"), l.onComplete({
        data: {
          paymentData: l.paymentData,
          details: {}
        }
      });
    }, [l.paymentData, l.onComplete]),
    h = o(() => {
      window.Klarna.Payments.init({
        client_token: s.client_token
      }), window.Klarna.Payments.load({
        container: p.current,
        payment_method_category: s.payment_method_category
      }, function (t) {
        !t.show_form || t.error ? _() : l.onLoaded();
      });
    }, [s.client_token, s.payment_method_category]),
    w = o(() => {
      u("loading");
      try {
        window.Klarna.Payments.authorize({
          payment_method_category: s.payment_method_category
        }, function (t) {
          !0 === t.approved && !0 === t.show_form ? (u("success"), l.onComplete({
            data: {
              paymentData: l.paymentData,
              details: {
                authorization_token: t.authorization_token
              }
            }
          })) : t.approved || !0 !== t.show_form ? _() : (u("ready"), l.onError(t));
        });
      } catch (t) {
        _();
      }
    }, [s.payment_method_category, l.onComplete, l.onError]);
  return n(() => {
    const t = window.Klarna?.Payments?.init;
    t && h();
  }, [c]), n(() => {
    window.klarnaAsyncCallback = function () {
      h();
    };
    const t = new r(i);
    return t.load(), () => {
      t.remove();
    };
  }, [h]), "error" !== y && "success" !== y ? t("div", {
    className: "adyen-checkout__klarna-widget"
  }, t("div", {
    ref: p
  }), d({
    status: y,
    disabled: "loading" === y,
    onClick: w
  })) : null;
}
export { s as KlarnaWidget };
