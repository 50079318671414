import { httpPost as e } from "../../core/Services/http.js";
import { pick as t } from "../../utils/commonUtils.js";
import { THREEDS2_ERROR as o, THREEDS2_FINGERPRINT_SUBMIT as i } from "./constants.js";
import { Analytics3DS2Errors as n, ANALYTICS_ERROR_TYPE as r } from "../../core/Analytics/constants.js";
function s({
  data: s
}) {
  e({
    path: `v1/submitThreeDS2Fingerprint?token=${this.props.clientKey}`,
    loadingContext: this.props.loadingContext,
    errorLevel: "fatal"
  }, {
    ...s
  }).then(e => {
    let s;
    if ("completed" === e.type) {
      const {
        details: t
      } = e;
      return e.details ? this.onComplete({
        data: {
          details: t
        }
      }) : (console.debug('Handled Error::callSubmit3DS2Fingerprint::FAILED:: no details object in a response indicating either a "frictionless" flow, or a "refused" response. resData=', e), s = {
        type: o,
        code: n.NO_DETAILS_FOR_FRICTIONLESS_OR_REFUSED,
        errorType: r.apiError,
        message: `${i}: no details object in a response indicating either a "frictionless" flow, or a "refused" response`
      }, void this.submitAnalytics(s));
    }
    if (!e.action) return console.debug('Handled Error::callSubmit3DS2Fingerprint::FAILED:: no action object in a response indicating a "challenge". resData=', e), s = {
      type: o,
      code: n.NO_ACTION_FOR_CHALLENGE,
      errorType: r.apiError,
      message: `${i}: no action object in a response indicating a "challenge" flow`
    }, void this.submitAnalytics(s);
    const a = this.props.elementRef ?? this;
    return a ? "threeDS2" === e.action?.type ? a.handleAction(e.action, t("challengeWindowSize").from(this.props)) : "redirect" === e.action?.type ? a.handleAction(e.action) : void 0 : (console.debug("Handled Error::callSubmit3DS2Fingerprint::FAILED:: no actionHandler"), s = {
      type: o,
      code: n.NO_COMPONENT_FOR_ACTION,
      errorType: r.sdkError,
      message: `${i}: no component defined to handle the action response`
    }, void this.submitAnalytics(s));
  }).catch(e => {
    this.handleError(e);
  });
}
export { s as default };
