import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class ProductPimFilterService extends SimpleConfigBaseFilter<Product> {
  constructor(
    protected filterLabel: string,
    protected segment: string,
    protected field: string,
    protected addToName = '',
    protected isList = false
  ) {
    super();
  }

  getName(): string {
    return this.filterLabel;
  }
  getOptionIds(item: Product): string[] {
    let options = [];
    if (this.isList) {
      // Take into consideration if the fields from Struct are transelateable. Currently we do not support this for non-meta fields.
      // In this case, we will just grab the english value. - Adam

      if (item.extra[this.segment][this.field]) {
        item.extra[this.segment][this.field].map(valueObject => {
          if (typeof valueObject.Value === 'string') {
            options.push(valueObject.Value);
          } else if (typeof valueObject === 'object') {
            options.push(valueObject['en']);
          }
        });
      }
    } else {
      options = [item.extra[this.segment][this.field] || ''];
    }
    return options;
  }
  getOptionName(key: string): string {
    return key + this.addToName;
  }
}
