import { ENCRYPTED_CARD_NUMBER as _, ENCRYPTED_EXPIRY_DATE as R, ENCRYPTED_EXPIRY_MONTH as c, ENCRYPTED_EXPIRY_YEAR as E, ENCRYPTED_SECURITY_CODE as C, ENCRYPTED_PWD_FIELD as O, ENCRYPTED_BANK_ACCNT_NUMBER_FIELD as r, ENCRYPTED_BANK_LOCATION_FIELD as M } from "../../components/internal/SecuredFields/lib/constants.js";
const a = "-ariaError",
  n = "-ariaContext",
  t = "focusField",
  e = "notValidating:blurScenario",
  L = "field.error.required",
  T = "field.error.invalid",
  N = "invalid.format.expects",
  i = "creditCard.holderName.invalid",
  d = "creditCard.taxNumber.invalid",
  S = "boleto.socialSecurityNumber.invalid";
var o = function (_) {
    return _.CC_NUM = "cc.num", _.CC_DAT = "cc.dat", _.CC_MTH = "cc.mth", _.CC_YR = "cc.yr", _.CC_CVC = "cc.cvc", _.KCP_PWD = "kcp.pwd", _.ACH_NUM = "ach.num", _.ACH_LOC = "ach.loc", _;
  }({}),
  A = function (_) {
    return _.ERROR_MSG_INCOMPLETE_FIELD = "err.gen.9100", _.ERROR_MSG_INVALID_FIELD = "err.gen.9101", _.ERROR_MSG_LUHN_CHECK_FAILED = "cc.num.902", _.ERROR_MSG_EMPTY_PAN = "cc.num.900", _.ERROR_MSG_UNSUPPORTED_CARD_ENTERED = "cc.num.903", _.ERROR_MSG_INCORRECTLY_FILLED_PAN = "cc.num.901", _.ERROR_MSG_CARD_TOO_OLD = "cc.dat.912", _.ERROR_MSG_CARD_TOO_FAR_IN_FUTURE = "cc.dat.913", _.ERROR_MSG_CARD_EXPIRES_TOO_SOON = "cc.dat.914", _.ERROR_MSG_EMPTY_DATE = "cc.dat.910", _.ERROR_MSG_INCORRECTLY_FILLED__DATE = "cc.dat.911", _.ERROR_MSG_EMPTY_YEAR = "cc.yr.917", _.ERROR_MSG_INCORRECTLY_FILLED_YEAR = "cc.yr.918", _.ERROR_MSG_EMPTY_MONTH = "cc.mth.915", _.ERROR_MSG_EMPTY_CVC = "cc.cvc.920", _.ERROR_MSG_INCORRECTLY_FILLED_CVC = "cc.cvc.921", _.ERROR_MSG_EMPTY_KCP_PWD = "kcp.pwd.940", _.ERROR_MSG_INCORRECTLY_FILLED_KCP_PWD = "kcp.pwd.941", _.ERROR_MSG_EMPTY_ACH_ACCOUNT_NUMBER = "ach.num.945", _.ERROR_MSG_INCORRECTLY_FILLED_ACH_ACCOUNT_NUMBER = "ach.num.946", _.ERROR_MSG_EMPTY_ACH_BANK_LOCATION_ID = "ach.loc.947", _.ERROR_MSG_INCORRECTLY_FILLED_ACH_BANK_LOCATION_ID = "ach.loc.948", _;
  }({});
const D = {
  [_]: "cc.num.900",
  [R]: "cc.dat.910",
  [c]: "cc.mth.915",
  [E]: "cc.yr.917",
  [C]: "cc.cvc.920",
  [O]: "kcp.pwd.940",
  [r]: "ach.num.945",
  [M]: "ach.loc.947"
};
export { n as ARIA_CONTEXT_SUFFIX, a as ARIA_ERROR_SUFFIX, S as BOLETO_SOCIAL_SECURITY_NUMBER_INVALID, i as CREDITCARD_HOLDER_NAME_INVALID, d as CREDITCARD_TAX_NUMBER_INVALID, D as EMPTY_FIELD_ERROR_MESSAGES, e as ERROR_ACTION_BLUR_SCENARIO, t as ERROR_ACTION_FOCUS_FIELD, T as ERROR_FIELD_INVALID, L as ERROR_FIELD_REQUIRED, N as ERROR_INVALID_FORMAT_EXPECTS, o as ErrorCodePrefixes, A as SF_ErrorCodes };
