import { isReadyToPayRequest as t, initiatePaymentRequest as e } from "./requests.js";
import { resolveEnvironment as a } from "./utils.js";
import n from "../../utils/Script.js";
import i from "./config.js";
class o {
  async getGooglePaymentsClient(t) {
    if (!window.google?.payments) {
      const t = new n(i.URL);
      await t.load();
    }
    return new google.payments.api.PaymentsClient(t);
  }
  isReadyToPay(e) {
    return this.paymentsClient ? this.paymentsClient.then(a => a.isReadyToPay(t(e))) : Promise.reject(new Error("Google Pay is not available"));
  }
  prefetchPaymentData(t, a) {
    if (!this.paymentsClient) throw new Error("Google Pay is not available");
    const n = e(t, a);
    this.paymentsClient.then(t => t.prefetchPaymentData(n));
  }
  initiatePayment(t, a) {
    if (!this.paymentsClient) throw new Error("Google Pay is not available");
    const n = e(t, a);
    return this.paymentsClient.then(t => t.loadPaymentData(n));
  }
  constructor(t, e) {
    var n, i, o;
    o = void 0, (i = "paymentsClient") in (n = this) ? Object.defineProperty(n, i, {
      value: o,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : n[i] = o;
    const s = a(t);
    this.paymentsClient = this.getGooglePaymentsClient({
      environment: s,
      paymentDataCallbacks: e
    });
  }
}
export { o as default };
