{
  "PIMITEM_DEPTH_MM": "Depth mm",
  "PIMITEM_WIDTH_MM": "Width mm",
  "WIDTH_MM": "Width (mm)",
  "GO_TO_ALL_PRODUCTS": "Show all products",
  "SHOP_BY_PRODUCT_LIST": "Products",
  "CAPACITY": "Volume",
  "DEPTH": "Width (mm)",
  "LENGTH": "Length (mm)",
  "FORGOT_PASSWORD": "Forgot Password",
  "HEADER_TEXT": "Welcome to the <b>customer portal</b> of<br> HL Display - The better shopping experience",
  "SEARCH": "Search",
  "OPEN_ORDERS_QUOTE": "Open O&Q",
  "ORGANISATION_NO": "Org. no",
  "CREATE_ORDER": "Create order",
  "ORDER": "Order",
  "SUPER_GROUP": "Super group",
  "FINANCIAL_GROUP": "Financial group",
  "YOUR_REFERENCE_NO": "Reference no",
  "DISCOUNT_PERCENT": "Discount %",
  "SELECT_CUSTOMER_MODAL_TITLE": "Customer information",
  "SELECTED_CUSTOMER_OPTION": "The currently selected customer is",
  "SELECTED_CUSTOMER_CONTINUE": "Would you like to continue or change customer to",
  "CUSTOMER_CONTINUE": "Keep current",
  "CUSTOMER_CHANGE": "Change customer",
  "ORDER_ID": "Order id",
  "CREATE_QUOTE": "Create quote",
  "SALJ_SALJARENAMN": "Sales person",
  "INVOICE_ADDRESS": "Invoice address",
  "QUOTE_TO_ORDER": "Create order from quote",
  "ADD_ROW": "Add row",
  "ADD_ORDER_ROW": "Add order row",
  "SUBMIT_ROWS": "Submit order rows",
  "PREV": "Previous",
  "XDL_DELLEVTILLATENBESKR": "Part delivery allowed",
  "X2T_TRANSPORTORSNAMN": "Transporter",
  "FTGNAMN": "Customer name",
  "FTGPOSTADR2": "Address",
  "FTGPOSTNR": "Zip code",
  "FTGPOSTADR3": "City",
  "CHANGE_DATE_ON_ALL_ROWS": "Select date for all rows",
  "UNABLE_TO_DELIVER_ROWS": "Unable to deliver the following rows",
  "AVAILABLE_QUANTITY": "Available quantity",
  "REDUCE_QUANTITY": "Reduce quantity",
  "REMOVE_ROW": "Remove product from order",
  "DELIVERY_TRACKING": "Delivery tracking",
  "FINAL_FREIGHT_CONDITIONS": "",
  "EXPECTED_DELIVERY_DAYS": "Expected days to delivery",
  "AVAILABLE_SHORT": "Av.",
  "CLICK": "Click",
  "HERE": "Here",
  "HL_VALIDATION_MODAL_TEXT": "The following rows in your cart cannot be delivered. Please reduce the quantities or replace the products.",
  "PRODUCT_REPLACED_BY": "Replaced by: ",
  "PRODUCT_NO_REPLACEMENT": "No replacement available",
  "SEARCH_STORE_NAME_NUMBER": "Search your store name or number",
  "MEETING": "Meeting",
  "MEETING_TYPE": "Meeting type",
  "SALES_CYCLE": "Sales cycle",
  "MEETING_SYSTEM": "System",
  "MEETINGS": "Meeting",
  "UPDATE_MEETING": "Change",
  "SYNC_OUTLOOK": "Sync Outlook",
  "SPECIFICATION": "Specification",
  "COLOUR": "Colour",
  "MORE_INFO": "More information",
  "TRACKING_EMAIL": "Tracking email",
  "TRACKING_EMAIL_TEMP": "Tracking email temp",
  "TRACKING_LINK_TEMP_EMAIL": "Use if the customer want the tracking link sent to a temporary email",
  "INVOICE_EMAIL": "Invoice email",
  "CUSTOMER_TRACKING_EMAIL_UPDATE_SUCCESS": "Tracking email updated with success",
  "CUSTOMER_TRACKING_EMAIL_UPDATE_ERROR": "Error trying to update the tracking email",
  "GOODS_LABEL": "Goods label",
  "GRAVITY_SCOOP_BINS": "Gravity & Scoop Bins",
  "DISPLAY_ACCESSORIES": "Display Accessories",
  "SHELF_MANAGEMENT_AUTOMATIC": "Shelf Management - Automatic",
  "LIGHTING_POWER_INFRASTRUCTURE": "Lighting & power infrastructure",
  "PRICE_COMMUNICATION": "Price Communication",
  "FRESH_PRODUCE_SOLUTIONS": "Fresh Produce Solutions",
  "PROTECTION_SAFETY_EQUIPMENT": "Protection & Safety Equipment",
  "SHELF_MANAGEMENT_MANUAL": "Shelf Management - Manual",
  "VIEW_ALL_PRODUCTS": "View all products",
  "MUSKETEERS_SELECTION": "Musketeers selection",
  "PRESENTOIRS_PANIERS": "Fresh Produce Solutions",
  "STORE_COMMUNICATION_BRANDING": "Store Communication & Branding",
  "FAQ": "FAQ",
  "FAQ_ACCESS_PORTAL_QUERY": "How do I get access or activate my account?",
  "FAQ_ACCESS_PORTAL_RESPONSE": "An email has been sent to your store email address with credentials – user name and password. If you haven’t received an email or are a new store, please contact our customer support. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_ACCESS_PORTAL_RESPONSE_PHARMA": "An email has been sent to you with your username and password. If you have not received an email or notification, please contact your customer service. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_ACCOUNT_INFO_QUERY": "How do I find information about my account?",
  "FAQ_ACCOUNT_INFO_RESPONSE": "All information about your store is stored under",
  "FAQ_MES_DONNEES": "My pages.",
  "FAQ_CATALOUGE_QUERY": "Is it possible to see all your products in a catalouge?",
  "FAQ_CATALOUGE_RESPONSE": "Follow this <a target='_BLANK' href='https://www.hl-display.com/our-offer/catalogue/'>link</a> to our website to look at the digital catalouge where all products are illustrated.",
  "FAQ_FORGOT_PASSWORD_QUERY": "What do I do if I forgot my password?",
  "FAQ_FORGOT_PASSWORD_RESPONSE": "Click on 'Forgot password' or contact our customer support. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_INVOICE_QUERY": "Is it possible to print my invoice?",
  "FAQ_INVOICE_RESPONSE": "Please contact our customer support. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_ORDER_HISTORY_QUERY": "How do I find information on previous orders?",
  "FAQ_ORDER_HISTORY_RESPONSE": "All information about your current order and previous orders is stored under",
  "FAQ_ORDER_MODIFICATION_QUERY": "How to modify or cancel an order?",
  "FAQ_ORDER_MODIFICATION_RESPONSE": "Please contact our customer support. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_ORDER_PLACEMENT_QUERY": "How do I place an order?",
  "FAQ_ORDER_PLACEMENT_RESPONSE": "Go into the product section and add items in the cart, then follow the guided steps to confirm your order. If you feel uncertain about placing an order, please contact your sales representative or our customer support and they will guide you. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_ORDER_TRACKING_QUERY": "How to track my order?",
  "FAQ_ORDER_TRACKING_RESPONSE": "All information about your current order and previous orders is stored under",
  "FAQ_PAYMENT_POLICY_QUERY": "What is your trade policy?",
  "FAQ_PAYMENT_POLICY_RESPONSE": "Minimum spend to eliminate freight: 300€/HT<br>Freight cost: 10€<br>Minimum order on the site: 50€/HT<br>Admin cost: Non",
  "FAQ_PAYMENT_POLICY_RESPONSE_PHARMA": "Minimum spend to eliminate freight: 300€/HT<br>Freight cost: 25€<br>Minimum order on the site: 100€/HT<br>Admin cost: 17€ for any order less than 300€",
  "FAQ_PAYMENT_POLICY_RESPONSE_PHARMA_VALUE": "Minimum spend to eliminate freight: {{minimumFreight}}€/HT<br>Freight cost: {{freightCost}}€<br>Minimum order on the site: {{minimumOrder}}€/HT<br>Admin cost: {{adminCost}}€ for any order less than 300€",
  "FAQ_PAYMENT_POLICY_RESPONSE_COOP": "Freight cost will be applied.",
  "FAQ_PAYMENT_POLICY_RESPONSE_NORGE_GROUP_SPAR": "Admin cost: NOK 200 will be added for orders under NOK 2500",
  "FAQ_PAYMENT_POLICY_RESPONSE_REITA_GROUP": "Admin cost: NOK 300 will be added for orders under NOK 2500",
  "FAQ_PAYMENT_SAMPLE_QUERY": "How do I request a sample?",
  "FAQ_PAYMENT_SAMPLE_RESPONSE": "Please contact our customer support. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_PAYMENT_TERMS_QUERY": "What is your payment terms?",
  "FAQ_PAYMENT_TERMS_RESPONSE": "Your payment should be placed within 45 day. Complete information about the terms could be found <a href='/contact'>here</a>.",
  "FAQ_PAYMENT_ONLINE_QUERY_PHARMA": "Is my online payment secure?",
  "FAQ_PAYMENT_ONLINE_RESPONSE_PHARMA": "HL Display has implemented a payment solution that meets the highest standards of transaction security. All the data you provide during your payment is protected and encrypted by SSL (Secure Sockets Layer) via our partner's platform. We have no access to your bank details. These are transferred in a coded and secure way directly to our bank.",
  "FAQ_RETURNS_QUERY": "How do I return my order?",
  "FAQ_RETURNS_RESPONSE": "Please contact our customer support. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_TECH_FITTING_QUERY": "Which items is best suitable for my store?",
  "FAQ_TECH_FITTING_QUERY_PHARMA": "What products are best suited to my needs?",
  "FAQ_TECH_FITTING_RESPONSE": "Please contact you sales representative or our customer support and they will guide you to the best solution possible. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_TECH_FITTING_RESPONSE_PHARMA": "Please contact your sales representative, that will guide you step by step to find the best possible solution. You will find their contact details in <a href='/contact'>Contact us</a>.",
  "FAQ_TECH_MEASUREMENT_QUERY": "How do I take measurements?",
  "FAQ_TECH_MEASUREMENT_RESPONSE": "Please contact you sales representative or our customer support and they will guide you to the best solution possible. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_TIME_EST_QUERY": "What is the estimated delivery time?",
  "FAQ_TIME_EST_RESPONSE": "The estimated delivery time depends on the item you have selected but we aim to deliver between 5-10 days.",
  "FAQ_PRINT_ORDER_CONFIRMATION_QUERY": "How do I get my order confirmation?",
  "FAQ_PRINT_ORDER_CONFIRMATION_RESPONSE": "An order confirmation will be sent to you by e-mail as soon as it is validated.",
  "DELIVERY_TRACKING_LINK": "Link",
  "MOUSQUETAIRES_SELECTION": "Musketeers selection",
  "MOUSQUETAIRES_FILTER_SELECTED": "Selection",
  "CONFIRM_ORDER_PAYMENT_BY_CARD": "Payment by credit card",
  "CONFIRM_ORDER_PHARMA_PORTAL": "Order",
  "VAT_INFORMATION_PHARMA_PORTAL": "<span class='highlight'>VAT: </span> 20% will be added at the payment stage",
  "FREIGHT_INFORMATION_PHARMA_PORTAL": "<span class='highlight'>Freight cost: </span> 25 € H.T if ordervalue is under 300 € H.T.",
  "FREIGHT_INFORMATION_PHARMA_PORTAL_VALUE": "<span class='highlight'>Freight cost: </span> {{value}} € H.T if ordervalue is under {{min}} € H.T.",
  "MINIMUM_ORDER_INFORMATION_PHARMA_PORTAL": "<span class='highlight'>Minimum order value: </span>100 € H.T",
  "MINIMUM_ORDER_INFORMATION_PHARMA_PORTAL_VALUE": "<span class='highlight'>Minimum order value: </span>{{value}} € H.T",
  "MINIMUM_ORDER_INFORMATION_CURRYS_PORTAL_VALUE": "<span class='highlight'>Minimum order value: </span>£ {{value}} ",
  "ADMIN_FEE_INFORMATION_PHARMA_PORTAL": "<span class='highlight'>Administrative costs: </span> 17 € for any order less than 300 €",
  "ADMIN_FEE_INFORMATION_PHARMA_PORTAL_VALUE": "<span class='highlight'>Administrative costs: </span> {{value}} € for any order less than {{min}} €",
  "CAMPAIGN_CODE": "Welcome Code",
  "TOP_PURCHASED_TITLE_PHARMA": "Top purchased products",
  "PURCHASED": "Purchased",
  "FREIGHT_INFORMATION_COOP_CUSTOMER": "<span class='highlight'>Freight cost: </span> will be added later",
  "ADMIN_FEE_INFORMATION_NORGE_GROUP_SPAR_PORTAL": "<span class='highlight'>Admin: </span>NOK 200 will be added for orders under NOK 2500",
  "ADMIN_FEE_INFORMATION_REITA_GROUP_PORTAL": "<span class='highlight'>Admin: </span>NOK 300 will be added for orders under NOK 2500",
  "10%_DISCOUNT_WILL_BE_APPLIED": "10% will be added after order confirmation",
  "CART_ITEM_QTY_ABOVE_AVAILABLE_QTY": "<span class='quantity-above-than-available'>*</span> The desired quantity of one or more items is not available. The delivery date indicated takes into account the replenishment time of the item.",
  "IN_STOCK_BELOW_REQUESTED_QTY": "Few in stock",
  "CUSTOMER_PORTAL_OH_ORDLEVADR1": "Name",
  "CUSTOMER_PORTAL_OH_ORDLEVADR2": "C/O",
  "CUSTOMER_PORTAL_OH_ORDLEVADR3": "Address",
  "CUSTOMER_PORTAL_OH_ORDLEVADR4": "Town",
  "CUSTOMER_PORTAL_CITY": "Town",
  "CUSTOMER_PORTAL_ADDRESS2": "Address",
  "CUSTOMER_PORTAL_ADDRESS1": "C/O",
  "ASSORTMENT_TYPE": "Assortment",
  "PRODUCT_ASSORTMENT_TYPE_FILTER_CUSTOMER_PRICE_LIST": "Customer PL",
  "PRODUCT_ASSORTMENT_TYPE_FILTER_STANDARD_PRICE_LIST": "Standard PL",
  "PRODUCT_ASSORTMENT_TYPE_FILTER_WAREHOUSE": "Warehouse",
  "CARRIER_CODE": "Carrier code",
  "WARNING_NO_USE_EXPLORER": "To get the best user experience use Chrome, Firefox, Safari or Edge. Please avoid Microsoft Explorer as your browser.",
  "SET_PASSWORD": "Change password",
  "RESET_PASSWORD_REQUEST_SENT": "Password change request sent",
  "PASSWORD_CONFIRM": "Confirm password",
  "EMAIL_INVOICE": "Email invoice",
  "EMAIL_QUOTE": "Email quote",
  "EMAIL_QUOTE_TEMPORARY": "Email quote temporary *",
  "EMAIL_TEXT": "Email text",
  "MESSAGE_EMAIL_QUOTE_TEMP": "Information added in this field will be the email the quote is sent to. Leaving this empty the quote will be sent to ”Email quote”.",
  "SEND_QUOTE": "Send quote",
  "OUTSIDE_ASSORTMENT": "Outside assortment",
  "BLOCKED": "Blocked",
  "OFFERS": "Offers",
  "ACTIVITIES": "Activities",
  "ADD_MEETING": "Add meeting",
  "MEETING_DATE": "Meeting date",
  "FH_KUNDBETALARENR": "Payer Code",
  "FH_FTGRN": "Code Delivered",
  "FH_FTGNAMN": "Customer Delivered",
  "PAY_CLIENT_NAME": "Paying Client",
  "PRODUCT_SYSTEM": "Product group",
  "MERCHANDISE_BRAND": "Trademark",
  "FRONT": "Article Front",
  "LABEL_TYPE": "Label Type",
  "SPRING": "Pusher strength",
  "SHEFT_TYPE": "Shelf type",
  "SHELF_MANUFACTURER": "Shelf manufacturer",
  "SHELF_LENGHT": "Shelf length",
  "SHELF_DEPTH": "Shelf depth",
  "EXTERNAL_CATEGORY": "Category",
  "EXTERNAL_SUB_CATEGORY": "Subcategory",
  "MAIN_FUNCTION": "Main function",
  "STORE_CATEGORY": "Store department",
  "SAMPLE_ORDER": "Send sample order",
  "SAMPLE": "Sample",
  "OFFER": "Offer",
  "ADD_TO_CART": "Add to cart",
  "CUSTOMER_PURCHASED_PRODUCT": "Previously ordered products",
  "CUSTOMER_PURCHASED_PRODUCT_FILTER_SELECTED": "Previously ordered products",
  "DURATION": "Duration",
  "HOURS": "Hours",
  "PIMITEM_HEIGHT_MM": "Height mm",
  "PIMITEM_DIAMETER_MM": "Diameter mm",
  "STORE_DEPARTMENT": "Store department",
  "TRADEMARK": "Trademark",
  "PORTE_ETIQUETTE": "Label Holder",
  "KESKO_SSO_LOGIN": "Kesko SSO login",
  "CLICK_HERE_TO_LOGIN": "Click here to login",
  "OR_USE_INTERNAL_LOGIN": "Or use the internal login below.",
  "IMPULSE_PROMOTION": "Impulse and Promotion",
  "CONTACT_NAME": "Name",
  "EMAIL_QUOTE_ORDER_CONFIRMATION": "Email quote / order confirmation",
  "EMAIL_QUOTE_ORDER_CONFIRMATION_TEMPORARY": "Email quote / order confirmation - temporary *",
  "EMAIL_QUOTE_TEMPORARY_REQUIRED": "Email quote / order confirmation - temporary field required",
  "PRODUCT_SHELF_TYPE": "Shelves model",
  "SHELF_AUTOMATION": "Shelf Automation",
  "CREDIT_BLOCKED_MODAL_HTML": "The customer <b>{{customerName}}</b> has their credit blocked, so it will not be possible to place any orders.",
  "CREDIT_SOFT_BLOCKED_MODAL_HTML": "The customer <b>{{customerName}}</b> will only be able to submit offer's because the current cart value is {{total}} {{currency}} and their remaing credit is {{credit}} {{currency}}.",
  "CREDIT_SOFT_BLOCKED": "Only offers available",
  "READ_ONLY_PRICE": "Price read only",
  "EXPECTED_DISPATCH_DATE": "Expected Dispatch Date",
  "CREDIT_BLOCKED_MODAL_HTML_1210": "Unfortunately the order can't be saved, kindly contact Customer Service: info.no@hl-display.com / +47 66 983 600.",
  "CREDIT_BLOCKED_MODAL_HTML_1600": "Unfortunately, we were unable to validate your order. Could you contact our customer service on 02 47 48 85 00? Thanks in advance.",
  "EMAIL_QUOTE_TEMPORARY_INVALID": "Invalid email provided!",
  "NO_FREIGHT_NO_VAT": "Excl. VAT and freight",
  "ORDER_TITLE": "Order",
  "ORDERSTATUS": "Orderstatus",
  "ORDER_ROW": "Order row",
  "YOUR_ORDERNO": "Your order no.",
  "ORP_RESTANT": "ORP Restant",
  "CREDIT_BLOCKED_MODAL_HTML_1210_FI": "Unfortunately the order can't be saved, kindly contact Customer Service: info.fi@hl-display.com / +358 9 562 9180",
  "CREDIT_BLOCKED_MODAL_HTML_1210_SE": "Unfortunately the order can't be saved, kindly contact Customer Service: info.se@hl-display.com / +46 8 683 7363",
  "CREDIT_BLOCKED_MODAL_HTML_1210_NO": "Unfortunately the order can't be saved, kindly contact Customer Service: info.no@hl-display.com / +47 66 983 600",
  "CREDIT_BLOCKED_MODAL_HTML_1810_DE": "Unfortunately the order can't be saved, kindly contact Customer Service: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_2400_CH": "Unfortunately the order can't be saved, kindly contact Customer Service: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_1600_FR_OR_NL": "Unfortunately, we were unable to validate your order. Could you contact our customer service on 02 47 48 85 00? Thanks in advance.",
  "CREDIT_BLOCKED_MODAL_HTML_1600_BE": "Unfortunately, we were unable to validate your order. Could you contact our customer service on 02 47 48 85 00? Thanks in advance.",
  "EMAIL_ORDER_CONFIRMATION_TEMPORARY": "Order confirmation email temp",
  "MESSAGE_EMAIL_ORDER_CONFIRMATION_TEMP": "Information added in this field will be the email the order confirmation is sent to. Leaving this empty the order confirmation will be sent to the default email.",
  "INTERNAL_CREDIT_BLOCKED_MODAL_HTML": "Unfortunately the order can't be saved, kindly contact customer service",
  "CREDIT_BLOCKED_MODAL_HTML_CUSTOMER_SERVICE": "Unfortunately the order can't be saved, kindly contact Customer Service: {customerServiceEmail} / {customerServicePhoneNumber}",
  "POD_FILTER": "Point of delivery",
  "NO_POD_FOUND": "No point of delivery",
  "BASECOST_S_U": "Basecost S / U",
  "QTY_PER_PALLET": "Qty per pallet",
  "PRODUCT_LIST": "Product list",
  "SEE_YOUR_AGREED_ASSORTMENT_AND_PRICES": "See your agreed assortment and prices",
  "ORDER_HISTORY": "Order history",
  "FIND_YOUR_PREVIOUS_ORDERS_TWO_YEAR_BACK": "Find your previous orders two year back",
  "FAVORITE_LIST": "Favorite list",
  "GO_TO_YOUR_FAVOURITE_PRODUCTS": "Go to your favourite products",
  "CONTACT_US": "Contact us",
  "FIND_OUR_CONTECT_INFORMATION_BY_CLICKING_HERE": "Find our contect information by clicking here",
  "HELP_US_IMPROVE": "Help us improve",
  "IF_YOU_WANT_TO_SEND_US_FEEDBACK_OR_HAVE_IDEAS_FOR_IMPROVEMENTS": "If you want to send us feedback or have ideas for improvements on the portal, please use this form.",
  "NEW_FREIGHT_INFORMATION": "<span class='highlight'>Freight cost:</span> {{value}} {{currency}} if order value is under {{min}} {{currency}}",
  "NEW_MINIMUM_ORDER_INFORMATION": "<span class='highlight'>Minimum order value:</span> {{value}} {{currency}}",
  "NEW_ADMIN_FEE_INFORMATION": "<span class='highlight'>Administrative costs:</span> {{value}} {{currency}} for any order less than {{min}} {{currency}}",
  "NEW_CUSTOM_FREIGHT_INFORMATION": "<span class='highlight'>Freight cost:</span> {{value}} {{currency}} if order value is under {{min}} {{currency}}"
}
