import { getTranslation as t } from "./utils.js";
import { getLocalisedAmount as i } from "../utils/amount-util.js";
import o from "../core/Errors/AdyenCheckoutError.js";
function e(t, i, o) {
  return i in t ? Object.defineProperty(t, i, {
    value: o,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[i] = o, t;
}
class a {
  get(i, o) {
    const e = t(this.translations, i, o);
    return null !== e ? e : i;
  }
  amount(t, o, e) {
    return i(t, this.locale, o, e);
  }
  date(t, i = {}) {
    if (void 0 === t) return "";
    const o = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      ...i
    };
    return new Date(t).toLocaleDateString(this.locale, o);
  }
  dateTime(t) {
    return void 0 === t ? "" : this.timeAndDateFormatter.format(new Date(t));
  }
  constructor(t) {
    e(this, "locale", void 0), e(this, "languageCode", void 0), e(this, "translations", void 0), e(this, "customTranslations", void 0), e(this, "timeFormatOptions", {
      hour: "numeric",
      minute: "numeric"
    }), e(this, "timeAndDateFormatOptions", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      ...this.timeFormatOptions
    }), e(this, "timeAndDateFormatter", void 0);
    const {
      locale: i,
      translations: a,
      customTranslations: s
    } = t;
    if (!i) throw new o("IMPLEMENTATION_ERROR", 'Language: "locale" property is not defined');
    this.locale = i, this.languageCode = this.locale.split("-")[0], this.customTranslations = s || {}, this.timeAndDateFormatter = Intl.DateTimeFormat(this.locale, this.timeAndDateFormatOptions), this.translations = {
      ...a,
      ...(!!this.customTranslations[this.locale] && this.customTranslations[this.locale])
    };
  }
}
export { a as Language, a as default };
