import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GungNotificationService } from 'gung-common';
import { first, forkJoin } from 'rxjs';
import { BackendFeatureService, FeatureActivatedGuardWrapper, GungFeatureMap, GungModalService } from 'gung-standard';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-statistics-excel-export-card',
  templateUrl: './statistics-excel-export-card.component.html',
  styleUrls: ['./statistics-excel-export-card.component.scss']
})
export class StatisticsExcelExportCardComponent {
  constructor(
    protected http: HttpClient,
    protected gungNotificationService: GungNotificationService,
    protected gungModalService: GungModalService,
    protected featureService: BackendFeatureService
  ) {}

  openGenerationModal(): void {
    this.featureService
      .getAvailableFeatures(false)
      .pipe(first())
      .subscribe((features: GungFeatureMap) => {
        const featureId = 'reportCentral-statistics-excels';

        const isActivated = !!features[featureId];
        if (!isActivated) {
          this.gungNotificationService.notify(
            'Feature not available in the current version of Gung.',
            `Reach out to Gung support for more information.`,
            'error'
          );
          return;
        }

        // Check if we have legacy version. This is done in order to mitigate the risks of frontend update before a backend
        // has been updated.
        const oldVersionActive = features[featureId].version === '1.0.0';
        if (oldVersionActive) {
          this.generateExcel();
          return;
        }

        // We have the latest supported model that works with dates.
        this.gungModalService.openSelectIntervalDates('GENERATE').then(result => {
          if (result) {
            this.generateExcelFromDates(result.fromDate, result.toDate);
          }
        });
      });
  }

  generateExcelFromDates(fromDate: string, toDate: string): void {
    this.http
      .post<any>(`json/report-central/generate-statistics-excels-with-dates/${fromDate}/${toDate}`, undefined)
      .pipe(first())
      .subscribe(result => {
        this.gungNotificationService.notify(
          'Export started succesfully',
          `To view the status and download the resulting files, click here.`,
          'success',
          {
            routerLink: ['report-central'],
            queryParams: {
              search: `DEFAULT__:__${result.id}`
            }
          }
        );
      });
  }

  /**
   * @deprecated
   * This is used for legacy purposes. For new updates of the backend (version 1.0.1 and above),
   * only date-range version above will be used.
   */
  generateExcel(): void {
    this.http
      .post<any>('/json/report-central/generate-statistics-excels', undefined)
      .pipe(first())
      .subscribe(result => {
        this.gungNotificationService.notify(
          'Export started succesfully',
          `To view the status and download the resulting files, click here.`,
          'success',
          {
            routerLink: ['report-central'],
            queryParams: {
              search: `DEFAULT__:__${result.id}`
            }
          }
        );
      });
  }

  private getNotificationBody(result: any): string {
    return;
  }
}
