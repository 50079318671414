import { createElement as e } from "../../../../external/preact/dist/preact.js";
import a from "../../../../external/classnames/index.js";
import { getUniqueId as r } from "../../../../utils/idGenerator.js";
import { useCoreContext as o } from "../../../../core/Context/CoreProvider.js";
function t(t) {
  const {
      items: i,
      name: n,
      onChange: l,
      value: c,
      isInvalid: s,
      uniqueId: d,
      ariaLabel: _,
      style: u = "classic"
    } = t,
    {
      i18n: p
    } = o(),
    m = d?.replace(/[0-9]/g, "").substring(0, d.lastIndexOf("-"));
  return e("div", {
    className: a(["adyen-checkout__radio_group", `adyen-checkout__radio_group--${u}`]),
    role: "radiogroup",
    ...(_ && {
      "aria-label": _
    })
  }, i.map(o => {
    const i = r(m);
    return e("div", {
      key: o.id,
      className: "adyen-checkout__radio_group__input-wrapper"
    }, e("input", {
      id: i,
      type: "radio",
      checked: c === o.id,
      className: "adyen-checkout__radio_group__input",
      name: n,
      onChange: l,
      onClick: l,
      value: o.id
    }), e("label", {
      className: a(["adyen-checkout__label__text", "adyen-checkout__radio_group__label", t.className, {
        "adyen-checkout__radio_group__label--invalid": s
      }]),
      htmlFor: i
    }, p.get(o.name)));
  }));
}
t.defaultProps = {
  onChange: () => {},
  items: []
};
export { t as default };
