import { CREDITCARD_HOLDER_NAME_INVALID as e, CREDITCARD_TAX_NUMBER_INVALID as r, BOLETO_SOCIAL_SECURITY_NUMBER_INVALID as t, ERROR_FIELD_REQUIRED as _, ERROR_INVALID_FORMAT_EXPECTS as n } from "../Errors/constants.js";
const o = "v3/analytics",
  E = 1e4,
  i = 3e3,
  a = {
    log: "log",
    error: "error",
    info: "info"
  },
  I = {
    network: "Network",
    implementation: "ImplementationError",
    internal: "Internal",
    apiError: "ApiError",
    sdkError: "SdkError",
    thirdParty: "ThirdParty",
    generic: "Generic",
    redirect: "Redirect",
    threeDS2: "ThreeDS2"
  },
  N = {
    redirect: "600"
  },
  T = "action",
  S = "submit",
  c = "selected",
  O = "rendered",
  l = "displayed",
  d = "input",
  A = "download",
  R = "validationError",
  s = "focus",
  p = "unfocus",
  L = "configured",
  D = "qr_download_button",
  u = "instant_payment_button",
  f = "featured_issuer",
  m = "list",
  C = "list_search";
var G = function (e) {
    return e.ACTION_IS_MISSING_PAYMENT_DATA = "700", e.ACTION_IS_MISSING_TOKEN = "701", e.TOKEN_IS_MISSING_THREEDSMETHODURL = "702", e.TOKEN_IS_MISSING_OTHER_PROPS = "703", e.TOKEN_DECODE_OR_PARSING_FAILED = "704", e.THREEDS2_TIMEOUT = "705", e.TOKEN_IS_MISSING_ACSURL = "800", e.NO_TRANSSTATUS = "801", e.NO_DETAILS_FOR_FRICTIONLESS_OR_REFUSED = "802", e.NO_COMPONENT_FOR_ACTION = "803", e.NO_ACTION_FOR_CHALLENGE = "804", e.CHALLENGE_RESOLVED_WITHOUT_RESULT_PROP = "805", e;
  }({}),
  P = function (e) {
    return e.FINGERPRINT_DATA_SENT = "fingerprintDataSentWeb", e.FINGERPRINT_IFRAME_LOADED = "fingerprintIframeLoaded", e.FINGERPRINT_COMPLETED = "fingerprintCompleted", e.CHALLENGE_DATA_SENT = "challengeDataSentWeb", e.CHALLENGE_IFRAME_LOADED = "challengeIframeLoaded", e.CHALLENGE_COMPLETED = "challengeCompleted", e;
  }({});
const h = {
    [e]: "925",
    [r]: "942",
    [t]: "926",
    [`${_}.country`]: "930",
    [`${_}.street`]: "931",
    [`${_}.house_number_or_name`]: "932",
    [`${_}.postal_code`]: "933",
    [`${_}.city`]: "935",
    [`${_}.state_or_province`]: "936",
    [`${n}.postal_code`]: "934"
  },
  M = ["cart", "minicart", "pdp", "checkout"],
  F = ["applicationInfo", "checkoutAttemptId"],
  g = "fetch-checkoutAttemptId-failed",
  H = {
    all: "all",
    initial: "initial"
  };
export { F as ALLOWED_ANALYTICS_DATA, T as ANALYTICS_ACTION_STR, L as ANALYTICS_CONFIGURED_STR, l as ANALYTICS_DISPLAYED_STR, A as ANALYTICS_DOWNLOAD_STR, N as ANALYTICS_ERROR_CODE, I as ANALYTICS_ERROR_TYPE, a as ANALYTICS_EVENT, M as ANALYTICS_EXPRESS_PAGES_ARRAY, f as ANALYTICS_FEATURED_ISSUER, s as ANALYTICS_FOCUS_STR, E as ANALYTICS_INFO_TIMER_INTERVAL, d as ANALYTICS_INPUT_STR, u as ANALYTICS_INSTANT_PAYMENT_BUTTON, m as ANALYTICS_LIST, C as ANALYTICS_LIST_SEARCH, o as ANALYTICS_PATH, D as ANALYTICS_QR_CODE_DOWNLOAD, O as ANALYTICS_RENDERED_STR, i as ANALYTICS_SEARCH_DEBOUNCE_TIME, c as ANALYTICS_SELECTED_STR, S as ANALYTICS_SUBMIT_STR, p as ANALYTICS_UNFOCUS_STR, R as ANALYTICS_VALIDATION_ERROR_STR, H as ANALYTIC_LEVEL, G as Analytics3DS2Errors, P as Analytics3DS2Events, g as NO_CHECKOUT_ATTEMPT_ID, h as errorCodeMapping };
