import { EventEmitter, Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ExportSelection,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SelectionAction,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs';
import { EdiOrderCardListComponent } from '../../components/edi/edi-order-card-list/edi-order-card-list.component';
import { EdiMessageBase, EdiOrder } from '../../models/edi';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { EdiService } from '../edi/edi.service';
import { format } from 'date-fns';
import { gungComparatorHelper } from 'gung-common';
import { TranslateService } from '@ngx-translate/core';
import { EdiOrderMessageService } from '../edi-order-message.service';
import { GungModalService } from '../gung-modal/gung-modal.service';

export class SupplierEdiOrderFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'SupplierGln';
  }
  getOptionIds(item: any): string[] {
    return [item.supplierGln];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class DeliveryEdiOrderFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'DeliveryGln';
  }
  getOptionIds(item: any): string[] {
    return [item.deliveryGln];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class BuyerEdiOrderFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'BuyerGln';
  }
  getOptionIds(item: any): string[] {
    return [item.buyerGln];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class ChannelEdiOrderFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'EdiChannel';
  }
  getOptionIds(item: any): string[] {
    return [item.ediChannel];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class GungEdiMessageSendStatusEdiOrderFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'GUNG_EDI_MESSAGE_SEND_STATUS';
  }
  getOptionIds(item: any): string[] {
    return [item.gungEdiMessageSendStatus];
  }
  getOptionName(key: string): string {
    return key;
  }
}
export class GungEdiMessageValidationStatusEdiOrderFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'GUNG_EDI_MESSAGE_VALIDATION_STATUS';
  }
  getOptionIds(item: any): string[] {
    return [item.validationState];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class GungEdiMessageOrderTypeFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'ORDER_TYPE';
  }
  getOptionIds(item: any): string[] {
    return [item.orderType];
  }
  getOptionName(key: string): string {
    return key;
  }
}

/*

These cannot be properties of the ordermessage since there are potentially many to be sent.

export class OrderResponseSendStatusEdiOrderFilter extends SimpleConfigBaseFilter<EdiOrders> {
  constructor() {
    super();
  }
  getName(): string {
    return 'orderResponseSendStatus';
  }
  getOptionIds(item: any): string[] {
    return [item.orderResponseSendStatus];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class InvoiceSendStatusEdiOrderFilter extends SimpleConfigBaseFilter<EdiOrders> {
  constructor() {
    super();
  }
  getName(): string {
    return 'InvoiceSendStatus';
  }
  getOptionIds(item: any): string[] {
    return [item.invoiceSendStatus];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class DespatchAdviceSendStatusEdiOrderFilter extends SimpleConfigBaseFilter<EdiOrders> {
  constructor() {
    super();
  }
  getName(): string {
    return 'despatchAdviceSendStatus';
  }
  getOptionIds(item: any): string[] {
    return [item.despatchAdviceSendStatus];
  }
  getOptionName(key: string): string {
    return key;
  }
}
*/

export class InvoiceStatusFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'EDI_MESSAGE_SEND_STATUS';
  }
  getOptionIds(item: EdiOrder): string[] {
    return [item.gungEdiMessageSendStatus || 'UNDEFINED'];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class ValidationFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'VALIDATION_STATE';
  }
  getOptionIds(item: EdiOrder): string[] {
    return [item.validationState || 'UNDEFINED'];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class ConfigIdFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'CONFIG_ID';
  }
  getOptionIds(item: EdiOrder): string[] {
    return [item.ediConfigId || 'UNDEFINED'];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class EdiOrderDateFilter extends SimpleConfigBaseFilter<EdiOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'DELIVERY_DATE';
  }
  getOptionIds(item: EdiOrder): string[] {
    let date = item.deliveryDate ? new Date(item.deliveryDate) : new Date();
    return [format(date, 'yyyy-MM-dd')];
  }
  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EdiOrderListConfigService implements ConfigService<EdiOrder> {
  constructor(
    protected ediService: EdiService,
    protected baseViewConfigService: BaseViewConfigService,
    protected translateService: TranslateService,
    protected gungModalService: GungModalService,
    protected ediOrderMessageService: EdiOrderMessageService
  ) {}

  getItems(): Observable<EdiOrder[]> {
    return this.ediService.getOrders().pipe(
      // map(edis =>
      //   edis.sort((a, b) => {
      //     const aSort = a?.documentReferences?.supplierOrder?.date && new Date(a.documentReferences.supplierOrder.date);
      //     const bSort = b?.documentReferences?.supplierOrder?.date && new Date(b.documentReferences.supplierOrder.date);
      //     if ((!bSort && aSort) || (aSort && bSort && aSort > bSort)) {
      //       return -1;
      //     } else if ((!aSort && bSort) || (aSort && bSort && aSort < bSort)) {
      //       return 1;
      //     } else {
      //       return 0;
      //     }
      //   })
      // ),
      map(items =>
        items.sort((a, b) =>
          gungComparatorHelper(
            [a.timestamp, Array.isArray(a.erpIds) ? a.erpIds[0] : a.erpIds],
            [b.timestamp, Array.isArray(b.erpIds) ? b.erpIds[0] : b.erpIds],
            -1
          )
        )
      )
    );
  }

  getFilters(): ConfigBaseFilter<EdiOrder>[] {
    return [
      new GungEdiMessageValidationStatusEdiOrderFilter(),
      new GungEdiMessageSendStatusEdiOrderFilter(),
      new SupplierEdiOrderFilter(),
      new DeliveryEdiOrderFilter(),
      new BuyerEdiOrderFilter(),
      new ChannelEdiOrderFilter(),
      new GungEdiMessageOrderTypeFilter(),
      new ConfigIdFilter(),
      new EdiOrderDateFilter()
    ];
  }

  getSortOptions(): ListSortOption<EdiOrder>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<EdiOrder>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => this.baseViewConfigService.getEdiOrderListComponent(),
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'CustomerUsers grid'
      }
    ];
  }

  getSearchTerms(item: EdiOrder): string[] {
    const generalSearchTerms = [
      item.documentReferences?.supplierOrder?.id,
      item.documentReferences?.buyerOrder?.id,
      item.documentReferences?.invoice?.id,
      item.documentReferences?.invoiceOcr?.id,
      item.documentReferences?.despatchAdvice?.id,
      item.documentReferences?.parcel?.id
    ].filter(s => !!s);
    return [item.id, item.documentReferences.supplierOrder.id, item.documentReferences.buyerOrder.id].concat(
      generalSearchTerms
    );
  }

  getItemId(item: EdiOrder): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }

  getSelectionActions(): Observable<SelectionAction<EdiOrder>[]> {
    const actions: SelectionAction<EdiMessageBase>[] = [
      {
        label: this.translateService.instant('REPLACE_EDI_ORDERS_EAN'),
        performAction: (selection: ExportSelection<EdiMessageBase>) => {
          const itemList = Object.values(selection.selectedItems);
          const idList = itemList.map(item => item.id);
          this.gungModalService.openEanReplaceModal(idList).then(
            result => {
              const convMap = {};

              result.eanList.forEach(ean => {
                convMap[ean.oldEan] = ean.newEan;
              });

              this.ediOrderMessageService.updateEanToNewEan(idList, convMap).subscribe(_ => {});
            },
            reject => {}
          );
          return of();
        }
      },
      {
        label: this.translateService.instant('UPDATE_ITEMS_CHANGES'),
        performAction: (selection: ExportSelection<EdiMessageBase>) => {
          const itemList = Object.values(selection.selectedItems);
          const idList = itemList.map(item => item.id);
          this.gungModalService.openItemsChangesUpdateModal(idList).then(
            result => {
              const convMap = {};

              result.changItemList.forEach(item => {
                convMap[item.id] = { skipValidation: item.skipValidation, cancelled: item.cancelled };
              });

              this.ediOrderMessageService.updateItemsChanges(idList, convMap).subscribe(_ => {});
            },
            reject => {}
          );
          return of();
        }
      }
    ];

    return of(actions);
  }

  getSelectionActionsButtonTitle(): string {
    return this.translateService.instant('EDI_BULK_ACTIONS');
  }
}
