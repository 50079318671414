{
  "ADVANCE_DELIVERY_INFORMATION": "<span class='highlight'>Deellevering: </span>Deellevering niet toegestaan",
  "ADMIN_FEE_INFORMATION_PHARMA_PORTAL_VALUE": "<span class='highlight'>Administratiekosten: </span> {{value}} € als de orderwaarde lager is dan {{min}} € excl. BTW",
  "FAQ_ACCESS_PORTAL_QUERY": "Hoe krijg ik toegang of activeer ik mijn account?",
  "FAQ_ACCESS_PORTAL_RESPONSE": "Er is een e-mail verzonden naar het e-mailadres van uw winkel met inloggegevens - gebruikersnaam en wachtwoord. Als je geen e-mail hebt ontvangen of een nieuwe winkel bent, neem dan contact op met onze klantenservice.",
  "FAQ_ACCESS_PORTAL_RESPONSE_PHARMA": "Er is een e-mail verzonden naar het e-mailadres van uw winkel met inloggegevens - gebruikersnaam en wachtwoord. Als je geen e-mail hebt ontvangen of een nieuwe winkel bent, neem dan contact op met onze klantenservice.  De informatie is te vinden in 'contacteer ons'",
  "FAQ_ACCOUNT_INFO_QUERY": "Hoe vind ik informatie over mijn account?",
  "FAQ_ACCOUNT_INFO_RESPONSE": "Alle informatie kunt u terugvinden onder het menu -Mijn pagina-",
  "FAQ_MES_DONNEES": "Mijn pagina",
  "FAQ_CATALOUGE_QUERY": "Is het mogelijk om alle producten te bekijken in een catalogus?",
  "FAQ_CATALOUGE_RESPONSE": "Volg deze link <a target='_BLANK' href='https://www.hl-display.com/our-offer/catalogue/'>link</a> naar onze website om de digitale catalogus te bekijken waarin alle producten worden geïllustreerd.",
  "FAQ_FORGOT_PASSWORD_QUERY": "Wat doe ik als ik mijn paswoord vergeten ben?",
  "FAQ_FORGOT_PASSWORD_RESPONSE": "Klik op 'Paswoord vergeten' of conatcteer de klantenservice. De informatie kan terug gevonden worden in <a href='/contact'>-contacteer ons-</a>.",
  "FAQ_INVOICE_QUERY": "Is het mogelijk om mijn factuur te printen?",
  "FAQ_INVOICE_RESPONSE": "Gelieve onze customer support te contacteren. De contactgegevens kunt u terugvinden onder <a href='/contact'>'contacteer ons'</a>.",
  "FAQ_ORDER_HISTORY_QUERY": "Hoe vind ik informatie over vorige bestellingen?",
  "FAQ_ORDER_HISTORY_RESPONSE": "Alle informatie over uw huidige en vorige bestellingen kunt u terugvinden onder 'mijn pagina'",
  "FAQ_ORDER_MODIFICATION_QUERY": "Hoe kan ik een bestelling wijzigen of annuleren?",
  "FAQ_ORDER_MODIFICATION_RESPONSE": "Gelieve onze customer support te contacteren. De contactgegevens kunt u terugvinden onder <a href='/contact'>'contacteer ons'</a>.",
  "FAQ_ORDER_PLACEMENT_QUERY": "Hoe plaats ik een order?",
  "FAQ_ORDER_PLACEMENT_RESPONSE": "Ga naar het productgedeelte en voeg artikelen toe aan de winkelwagen, volg dan de begeleide stappen om uw bestelling te bevestigen. Als u twijfelt over het plaatsen van een bestelling, neem dan contact op met uw Account manager of onze klantenservice en zij zullen u verder helpen. De informatie is te vinden in <a href='/contact'>'contacteer ons'<a>",
  "FAQ_ORDER_TRACKING_QUERY": "Hoe traceer ik mijn bestelling?",
  "FAQ_ORDER_TRACKING_RESPONSE": "Alle informatie over uw huidige en vorige bestellingen kunt u terugvinden onder -mijn pagina-",
  "FAQ_PAYMENT_POLICY_QUERY": "Wat is ons handelsbeleid?",
  "FAQ_PAYMENT_POLICY_RESPONSE": "Onze minimum bestelwaarde bedraagt 300€ excl.BTW<br>Vrachtkosten 10€<br>Minimumbestelwaarden op de site: 50€ excl.BTW<br>Adiministratiekosten: 0€",
  "FAQ_PAYMENT_POLICY_RESPONSE_PHARMA": "Onze minimum bestelwaarde bedraagt: 300€/HT<br>Vrachtkosten: 25€<br>Minimumbestelwaarden op de site: 100€/HT<br>Adiministratiekosten: 17€ for any order less than 300€",
  "FAQ_PAYMENT_POLICY_RESPONSE_PHARMA_VALUE": "Onze minimum bestelwaarde bedraagt: {{minimumFreight}}€/HT<br>Vrachtkosten: {{freightCost}}€<br>Minimumbestelwaarden op de site: {{minimumOrder}}€/HT<br>Adiministratiekosten: {{adminCost}}€ for any order less than 300€",
  "FAQ_PAYMENT_POLICY_RESPONSE_COOP": "Freight cost will be applied.",
  "FAQ_PAYMENT_POLICY_RESPONSE_NORGE_GROUP_SPAR": "Admin cost: NOK 200 will be added for orders under NOK 2500",
  "FAQ_PAYMENT_POLICY_RESPONSE_REITA_GROUP": "Admin cost: NOK 300 will be added for orders under NOK 2500",
  "FAQ_PAYMENT_SAMPLE_QUERY": "Hoe vraag ik een sample aan?",
  "FAQ_PAYMENT_ONLINE_QUERY_PHARMA": "Is mijn online betaling veilig?",
  "FAQ_TECH_FITTING_QUERY_PHARMA": "Welke producten zijn het meest geschikt voor mijn behoeften?",
  "FAQ_TECH_FITTING_RESPONSE_PHARMA": "Gelieve uw Accountmanager of onze customer support te contacteren en zij zullen u naar de beste oplossing begeleiden. De contactgegevens kunt u terugvinden onder <a href='/contact'>'contacteer ons'</a>.",
  "FAQ_PAYMENT_SAMPLE_RESPONSE": "Gelieve onze customer support te contacteren. De contactgegevens kunt u terugvinden onder <a href='/contact'>'contacteer ons'</a>.",
  "FAQ_PAYMENT_TERMS_QUERY": "Wat is de betalingstermijn?",
  "FAQ_PAYMENT_TERMS_RESPONSE": "Uw betaling moet binnen de 45 dagen uitgevoerd zijn. Complete informatie over onze voorwaarden vindt u hier terug.",
  "FAQ_RETURNS_QUERY": "Hoe retourneer ik mijn bestelling?",
  "FAQ_RETURNS_RESPONSE": "Gelieve onze customer support te contacteren. De contactgegevens kunt u terugvinden onder <a href='/contact'>'contacteer ons'</a>.",
  "FAQ_TECH_FITTING_QUERY": "Welk item is geschikt voor mijn winkel?",
  "FAQ_TECH_FITTING_RESPONSE": "Gelieve uw Accountmanager of onze customer support te contacteren en zij zullen u naar de beste oplossing begeleiden. De contactgegevens kunt u terugvinden onder <a href='/contact'>'contacteer ons'</a>.",
  "FAQ_TECH_MEASUREMENT_QUERY": "How do I take measurements?",
  "FAQ_TECH_MEASUREMENT_RESPONSE": "Please contact you sales representative or our customer support and they will guide you to the best solution possible. The information could be found in <a href='/contact'>Contact us</a>.",
  "FAQ_TIME_EST_QUERY": "Wat is de geschatte levertermijn?",
  "FAQ_TIME_EST_RESPONSE": "Deze hangt af van het geselecteerde item, maar we streven ernaar om tussen de 5-10 dagen te leveren.",
  "FAQ_PRINT_ORDER_CONFIRMATION_QUERY": "Hoe krijg ik mijn orderbevestiging?",
  "FAQ_PRINT_ORDER_CONFIRMATION_RESPONSE": "Een orderbevestiging zal per email naar u toegestuurd worden van zodra uw bestelling bevestigd is.",
  "FAQ_PAYMENT_ONLINE_RESPONSE_PHARMA": "HL Display heeft een betalingsoplossing geïmplementeerd die voldoet aan de hoogste zekerheidsstandaarden. Alle gegevens die u tijdens uw betaling verstrekt, worden beschermd en versleuteld door SSL (Secure Sockets Layer) via het platform van onze partner. Wij hebben geen toegang tot uw bankgegevens. Deze worden gecodeerd en veilig rechstreeks naar onze bank doorgegeven.",
  "FREIGHT_INFORMATION_PHARMA_PORTAL_VALUE": "<span class='highlight'>Transportkosten: </span> {{value}} € als orderwaarde lager is dan  {{min}} € excl. BTW",
  "ADD_TO_BASKET": "Voeg toe aan winkemmandje",
  "ADD_TO_CART": "Voeg toe aan winkelwagen",
  "ADDRESS": "Adres",
  "ADDRESS1": "Adres",
  "ADDRESS2": "Adres 2",
  "AVAILABILITY": "Beschikbaarheid",
  "AVAILABLE_2_3_WEEKS": "Beschikbaar in 2-3 weken",
  "AVAILABLE_4_5_WEEKS": "Beschikbaar in 4-5 weken",
  "BACK_TO_LOGIN": "Back to login",
  "BILLING_ADDRESS": "Facturatieadres",
  "BILLING_INFORMATION": "Factuur informatie",
  "BUSINESS_NAME": "Bedrijfsnaam",
  "CAMPAIGN_CODE": "Kortingscode",
  "CAPACITY": "Formaat",
  "CART": "Winkelmandje",
  "CART_IS_EMPTY": "Uw winkelwagen is leeg",
  "CART_ITEM_QTY_ABOVE_AVAILABLE_QTY": "Het gewenst aantal van 1 of meer items is niet beschikbaar. De aangegeven leverdatum houdt rekening met de aanvultijd van het artikel",
  "CATEGORY": "Categorie",
  "CITY": "Gemeente",
  "CLEAR_CART": "Maak winkelwagen leeg",
  "COLOR": "Kleur",
  "COLOUR": "Kleur",
  "CONFIRM": "Bevestig",
  "CONFIRM_DELETE_SHOPPING_CART": "Weet u zeker dat u het volgende opgeslagen winkelwagentje wilt verwijderen",
  "CONFIRM_ORDER": "Bevestig bestelling",
  "CONTACT": "Contactpersoon",
  "CONTACT_PERSON_NAME": "Contactpersoon",
  "CONTACT_US": "Contacteer ons",
  "CONTINUE_SHOPPING": "Verder winkelen",
  "CONTINUE_TO_SHIPPING": "Volgende",
  "CORPORATE_RESPONSIBILITY": "Bedrijfsverantwoordelijkheid",
  "COUNTRY_CODE": "Landcode",
  "CUSTOMER_NAME": "Klantnaam",
  "CUSTOMER_PORTAL_CITY": "Gemeente",
  "CUSTOMER_PORTAL_OH_ORDLEVADR4": "Gemeente",
  "CUSTOMER_PURCHASED_PRODUCT": "Vorige aangekochte artikelen",
  "CUSTOMER_PURCHASED_PRODUCT_FILTER_SELECTED": "Vorige aangekochte artikelen",
  "DATE": "Datum",
  "DELIVERY_ADDRESS": "Bezorgadres",
  "DELIVERY_DATE": "Leverdatum",
  "DELIVERY_TRACKING": "Traceer levering",
  "DEPTH": "Diepte",
  "DESCRIPTION": "Beschrijving",
  "DIMENSIONS": "Afmetingen",
  "DISPLAY_ACCESSORIES": "Display accessoires",
  "DISPLAY_BATCH_PREFIX": "Resultaten",
  "EMAIL": "Email",
  "EMAIL_ADDRESS": "Email",
  "ERP_SYNC_EVENTS": "ERP sync events",
  "ETIQUETTE_TYPE": "Type Etikethouder",
  "FILTERS": "Filters",
  "FIRST_AVAILABLE_DATE": "Beschikbare datum",
  "FLOW_NOT_ALLOWED_FOR_USER": "U heeft geen toegang tot deze stroom. De vorige stroom blijft geselecteerd.",
  "FORGOT_PASSWORD": "Wachtwoord vergeten",
  "FR_FTGPOSTADR1": "Adres",
  "FR_FTGPOSTADR2": "Adres 2",
  "FR_FTGPOSTADR3": "Gemeente",
  "FR_FTGPOSTNR": "Postcode",
  "FR_LANDSKOD": "Landcode",
  "FREIGHT COST: €XX IF ORDER VALUE IS UNDER €XXX": "Transportkosten:  10 € als orderwaarde lager is dan 300€",
  "FREIGHT_INFORMATION": "Verzendinformatie",
  "FRESH_PRODUCE_SOLUTIONS": "Oplossing voor versschap",
  "FTGPOSTADR3": "Gemeente",
  "FTGPOSTNR": "Postcode",
  "GRAVITY_SCOOP_BINS": "Schep en gravity bins",
  "HEIGHT": "Hoogte",
  "HOME": "Home",
  "IN_STOCK": "In voorraad",
  "INVOICE_ADDRESS": "Facturatieadres",
  "LENGTH": "Lengte",
  "LIGHTING_POWER_INFRASTRUCTURE": "Schapverlichting",
  "LOAD_MORE": "Laad meer",
  "LOG_IN_AGAIN": "Opnieuw inloggen",
  "LOGGED_OUT": "Uitgelogd",
  "LOGGED_OUT_INFO": "U bent automatisch uitgelogd wegens inactiviteit",
  "LOGIN": "Inloggen",
  "LOGIN_BACKGROUND": "Inloggen achtergrond",
  "LOGIN_FAILED": "Inloggen mislukt",
  "LOGOUT": "Uitloggen",
  "MERCHANDISE_TYPE": "Type merchandising",
  "MESSAGE": "Bericht",
  "MINIMUM ORDER VALUE:": "Minimum bestelwaarde: €50 Inclusief BTW",
  "MINIMUM_ORDER_INFORMATION_PHARMA_PORTAL_VALUE": "<span class='highlight'>Minimum bestelwaarde: </span>{{value}} € excl. BTW",
  "MINIMUM_ORDER_INFORMATION_CURRYS_PORTAL_VALUE": "<span class='highlight'>Minimum bestelwaarde: </span>{{value}} £ H.T",
  "MORE_INFO": "Meer informatie",
  "MORE_INFORMATION": "Meer informatie",
  "MOST_PURCHASED": "Bestseller",
  "MY_PAGE": "Mijn Pagina",
  "MY_PAGES": "Mijn Pagina",
  "NAME": "Productnaam",
  "NOT_IN_STOCK": "Niet in voorraad",
  "NOT_ON_STOCK": "Niet in voorraad",
  "NOTHING_FOUND_CONTACT_GUNG": "Uw zoekactie heeft geen resultaat opgeleverd",
  "OGL_LND": "Landcode",
  "OH_KUNDBESTNR": "Referentienaam",
  "OH_ORDLEVADR1": "Ontvanger",
  "OH_ORDLEVADR3": "Adres",
  "OH_ORDLEVADR4": "Gemeente",
  "OH_ORDLEVADRLANDSKOD": "Landcode",
  "ORDER_AGAIN": "Herbestelling",
  "ORDER_INFORMATION": "Order informatie",
  "ORDER_NUMBER": "Bestelnummer",
  "OUR_HISTORY": "Onze geschiedenis",
  "PACK_SIZE": "Pakket afmeting",
  "PACKAGE_SIZE": "Pakket afmeting",
  "PARTIAL DELIVERY: PARTIAL DELIVERIES ARE NOT ALLOWED": "Deellevering: Deellevering niet toegestaan",
  "PASSWORD": "Wachtwoord",
  "PHONE": "Telefoonnummer",
  "PHONE_NUMBER": "Telefoonnummer",
  "PIM_I18N.SIZE": "Afmeting",
  "PRESENTOIRS_PANIERS": "Winkelmandjes",
  "PREVIOUS_ORDERS": "Vorige bestellingen",
  "PRICE": "Prijs",
  "PRICE_COMMUNICATION": "Prijscommunicatie",
  "PRODUCTS": "Producten",
  "PROTECTION_SAFETY_EQUIPMENT": "Bescherming en veiligheidsuitrusting",
  "QUANTITY_AVAILABLE": "Beschikbaar aantal",
  "REQUEST_ALL_FIELDS_TO_CREATE_USER": "Alle velden moeten zijn ingevuld om de gebruiker aan te maken",
  "REQUESTED_QUANTITY": "Gewenst aantal",
  "RESTRICTED_FLOW": "Beperkte stroom",
  "RETURN_WARRANTY": "Return/Warrantys",
  "SALES_CODE": "Verkoopcode",
  "SEARCH": "Zoeken",
  "SEE_MORE": "Meer",
  "SELECT_CATEGORY": "Selecteer categorie",
  "SELECT_CUSTOMER_GO_TO_DETAILS": "Klant selecteren en naar de details navigeren",
  "SELECT_ORDER": "Selecteer bestelling",
  "SELECTED_FILTERS": "Geselecteerde filters",
  "SEND_EMAIL_TO_USER": "Stuur e-mail naar de gebruiker",
  "SHELF_MANAGEMENT_AUTOMATIC": "Automatisch schapbeheer",
  "SHELF_MANAGEMENT_MANUAL": "Manueel schapbeheer",
  "SHELF_MANUFACTURER": "Schapproducent",
  "SHIPPING_INFORMATION": "Verzendinformatie",
  "SHOP_BY_PRODUCT_LIST": "Producten",
  "SIGN_IN": "Inloggen",
  "SPECIFICATION": "Specificatie",
  "STORE_COMMUNICATION_BRANDING": "Instore communicatie en branding",
  "TERMS_AND_CONDITIONS": "Algemene voorwaarden",
  "TOP_PURCHASED_TITLE": "Bestellers",
  "TOTAL_PRICE": "Totaal",
  "TRACK_ORDER": "Traceer bestelling",
  "UNIT_PRICE": "Prijs per stuk",
  "USER_DETAILS": "Brukerdetaljer",
  "USER_ID": "Gebruikersnaam",
  "USERNAME": "Gebruikersnaam",
  "VALIDATE_BILLING_INFORMATION": "Factuur informatie",
  "VIEW_ALL_PRODUCTS": "Bekijk alle producten",
  "WARNING_NO_USE_EXPLORER": "Gebruik Chrome, Firefox, Safari of Edge voor de beste gebruikerservaring. Vermijd Internet Explorer als browser.",
  "YOUR_REFERENCE_NO": "Referentienaam ",
  "ZIP": "Postcode",
  "ZIP_CODE": "Postcode",
  "IMPULSE_PROMOTION": "Impulse & Promotion",
  "CONTACT_NAME": "Naam",
  "HL_CONTACT_NAME": "HL Display België N.V",
  "C_PHONE": "Telefoon",
  "POST_ADRESS": "Postadres",
  "NETHERLANDS": "Nederland",
  "BELGIUM": "België",
  "SHELF_AUTOMATION": "Shelf automatisering",
  "CREDIT_BLOCKED_MODAL_HTML": "De klant <b>{{customerName}}</b> heeft zijn tegoed geblokkeerd, dus het is niet mogelijk om bestellingen te plaatsen.",
  "CREDIT_SOFT_BLOCKED_MODAL_HTML": "De klant <b>{{customerName}}</b> kan alleen aanbiedingen indienen omdat de huidige winkelwagenwaarde {{total}} {{currency}} is en het resterende tegoed is {{credit}} {{currency}}.",
  "CREDIT_SOFT_BLOCKED": "Alleen aanbiedingen beschikbaar",
  "EMAIL_QUOTE_TEMPORARY_INVALID": "Ongeldig e-mailadres opgegeven!",
  "NO_FREIGHT_NO_VAT": "Excl. BTW en vracht",
  "ORDER_TITLE": "Order",
  "CREDIT_BLOCKED_MODAL_HTML_1210_FI": "Helaas kan de bestelling niet worden opgeslagen. Neem contact op met de klantenservice: info.fi@hl-display.com / +358 9 562 9180",
  "CREDIT_BLOCKED_MODAL_HTML_1210_SE": "Helaas kan de bestelling niet worden opgeslagen. Neem contact op met de klantenservice: info.se@hl-display.com / +46 8 683 7363",
  "CREDIT_BLOCKED_MODAL_HTML_1210_NO": "Helaas kan de bestelling niet worden opgeslagen. Neem contact op met de klantenservice: info.no@hl-display.com / +47 66 983 600",
  "CREDIT_BLOCKED_MODAL_HTML_1810_DE": "Helaas kan de bestelling niet worden opgeslagen. Neem contact op met de klantenservice: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_2400_CH": "Helaas kan de bestelling niet worden opgeslagen. Neem contact op met de klantenservice: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_1600_FR_OR_NL": "Helaas kan de bestelling niet worden opgeslagen, neem contact op met de klantenservice: ordersbenelux@hl-display.com / +31 762300320",
  "CREDIT_BLOCKED_MODAL_HTML_1600_BE": "Helaas kan de bestelling niet worden opgeslagen, neem contact op met de klantenservice: ordersbenelux@hl-display.com / +31 762300350",
  "CREDIT_BLOCKED_MODAL_HTML_CUSTOMER_SERVICE": "Helaas kan de bestelling niet worden opgeslagen. Neem contact op met de klantenservice: {customerServiceEmail} / {customerServicePhoneNumber}",
  "EMAIL_ORDER_CONFIRMATION_TEMPORARY": "Temperatuur e-mail orderbevestiging",
  "MESSAGE_EMAIL_ORDER_CONFIRMATION_TEMP": "De informatie die in dit veld wordt toegevoegd, is het e-mailadres waarnaar de orderbevestiging wordt verzonden. Als u dit leeg laat, wordt de orderbevestiging naar het standaard e-mailadres verzonden.",
  "INTERNAL_CREDIT_BLOCKED_MODAL_HTML": "Helaas kan de bestelling niet worden opgeslagen. Neem contact op met de klantenservice",
  "GENERAL_SHELF_MATERIALS": "Algemene schapmaterialen",
  "PRIJSRAILS": "Prijsrails",
  "POD_FILTER": "Punt van levering",
  "NO_POD_FOUND": "Geen afleverpunt",
  "BASECOST_S_U": "Basecost S / U",
  "PRODUCT_LIST": "Product list",
  "SEE_YOUR_AGREED_ASSORTMENT_AND_PRICES": "See your agreed assortment and prices",
  "ORDER_HISTORY": "Order history",
  "FIND_YOUR_PREVIOUS_ORDERS_TWO_YEAR_BACK": "Find your previous orders two year back",
  "FAVORITE_LIST": "Favorite list",
  "GO_TO_YOUR_FAVOURITE_PRODUCTS": "Go to your favourite products",
  "FIND_OUR_CONTECT_INFORMATION_BY_CLICKING_HERE": "Find our contect information by clicking here",
  "HELP_US_IMPROVE": "Help us improve",
  "IF_YOU_WANT_TO_SEND_US_FEEDBACK_OR_HAVE_IDEAS_FOR_IMPROVEMENTS": "If you want to send us feedback or have ideas for improvements on the portal, please use this form.",
  "NEW_FREIGHT_INFORMATION": "<span class='highlight'>Vrachtkosten:</span> {{value}} {{currency}} als de orderwaarde lager is dan {{min}} {{currency}}",
  "NEW_MINIMUM_ORDER_INFORMATION": "<span class='highlight'>Minimale orderwaarde:</span> {{value}} {{currency}}",
  "NEW_ADMIN_FEE_INFORMATION": "<span class='highlight'>Administratieve kosten:</span> {{value}} {{currency}} voor elke order lager dan {{min}} {{currency}}",
  "NEW_CUSTOM_FREIGHT_INFORMATION": "<span class='highlight'>Vrachtkosten:</span> {{value}} {{currency}} als de orderwaarde lager is dan {{min}} {{currency}}"
}
