import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GungTableActiveFilter } from '../../../models/common-table';

@Component({
  selector: 'gung-table-clear-filter-button',
  templateUrl: './gung-table-clear-filter-button.component.html',
  styleUrl: './gung-table-clear-filter-button.component.scss'
})
export class GungTableClearFilterButtonComponent {

  showClear = false;
  activeValues = 0;

  private _activeFilters: GungTableActiveFilter[];
  
  @Input() index: Number;

  @Input()
  set activeFilters(value: GungTableActiveFilter[]) {
    this._activeFilters = value;
    this.onActiveFiltersChanged();
  }

  get activeFilters(): GungTableActiveFilter[] {
    return this._activeFilters;
  }

  @Output() clearFilter = new EventEmitter<Number>();
 

  onActiveFiltersChanged(): void {
    const thisFilter = this.activeFilters.find(f=> f.headerIndex === this.index);
    if(thisFilter && thisFilter.activeValues.length > 0){
      this.showClear = true;
      this.activeValues = thisFilter.activeValues.length;
    }else{
      this.showClear = false;
    }
  }

  clickClearFilter(){
    this.clearFilter.emit(this.index);

  }
}
