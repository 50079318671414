export function setColors({ primary, secondary, hover, contrasting, navbarcolor, navbarfontcolor, navbarfonthovercolor }: { [s: string]: string; }) {
    let cssToAdd = '';
    if (primary) {
        document.documentElement.style.setProperty('--bs-primary', primary);
        cssToAdd += global(primary, 'primary');
        cssToAdd += buttons(primary, 'primary');
        cssToAdd += checkoutStepper(primary);
        cssToAdd += badge(primary);
    }

    if (secondary) {
        document.documentElement.style.setProperty('--bs-secondary', secondary);
        document.documentElement.style.setProperty('--bs-link-color', secondary);
        document.documentElement.style.setProperty('--bs-link-hover-color', colorLuminance(secondary, -0.5));
        cssToAdd += global(secondary, 'secondary');
        cssToAdd += buttons(secondary, 'secondary');
    }

    if (navbarcolor) {
        document.documentElement.style.setProperty('--bs-navbar-color', navbarcolor, 'important');
        cssToAdd += `
            .gung-app-navbar {
                --bs-navbar-color: ${navbarcolor} !important;
                background-color: var(--bs-navbar-color) !important;
            }
        `;
    }

    if (navbarfontcolor) {
        document.documentElement.style.setProperty('--bs-nav-link-color', navbarfontcolor, 'important');
        cssToAdd += `
            .navbar-nav,
            .navbar-top {
                --bs-nav-link-color: ${navbarfontcolor} !important;
            }
            
            .navbar-nav .menu-name a,
            .navbar-nav .nav-info a,
            .navbar-top .navbar-right .icons .icon-action a,
            .navbar-top .navbar-right .icons .icon-action span,
            .navbar-nav .btn-open-menu,
            .navbar .global-search-desktop .btn {
                color: var(--bs-nav-link-color) !important;
            }

            

        `;
    }

    if (navbarfonthovercolor) {
        document.documentElement.style.setProperty('--bs-nav-link-hover-color', navbarfonthovercolor, 'important');
        cssToAdd += `
            .navbar-nav,
            .navbar-top {
                --bs-nav-link-hover-color: ${navbarfonthovercolor} !important;
            }
            
            .navbar-nav .menu-name a:hover,
            .navbar-nav .nav-info a:hover,
            .navbar-top .navbar-right .icons .icon-action a:hover,
            .navbar-nav .btn-open-menu:hover,
            .navbar .global-search-desktop .btn:hover {
                color: var(--bs-nav-link-hover-color) !important;
            }

        `;
    }

    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.appendChild(document.createTextNode(cssToAdd));
    document.head.appendChild(styleElement);
}

function buttons(color, cssClass) {
    const css = `
        .btn-${cssClass} {
            --bs-btn-color: ${pickTextColor(color)};
            --bs-btn-bg: ${color};
            --bs-btn-border-color: ${color} ;
            --bs-btn-hover-bg: ${colorLuminance(color, 0.15)} ;
            --bs-btn-hover-color: ${pickTextColor(color)};
            --bs-btn-hover-border-color: ${colorLuminance(color, 0.15)};
            --bs-btn-active-bg: ${colorLuminance(color, 0.3)};
            --bs-btn-disabled-bg: ${colorLuminance(color, 0.65)};
            --bs-btn-disabled-border-color: ${colorLuminance(color, 0.7)} ;
            --bs-btn-active-border-color: ${colorLuminance(color, 0.15)};
            --bs-btn-disabled-color:  ${pickTextColor(color)};
            background:${color};
        }

        .btn-outline-${cssClass}{
            --bs-btn-color: ${color};
            --bs-btn-border-color: ${color};
            --bs-btn-hover-color: ${pickTextColor(color)};
            --bs-btn-hover-bg: ${color};
            --bs-btn-hover-border-color: ${color};
            --bs-btn-focus-shadow-rgb: 253, 0, 0;
            --bs-btn-active-color: ${pickTextColor(color)};
            --bs-btn-active-bg: ${color};
            --bs-btn-active-border-color: ${color}
            --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --bs-btn-disabled-color: ${color};
            --bs-btn-disabled-bg: transparent;
            --bs-btn-disabled-border-color: ${color};
        }
          
    `;


    return css;
}

function checkoutStepper(color) {
    const css = `
        .stepper-indicator, .stepper-indicator:before, .stepper-indicator:last-child:after {
            background-color: ${colorBrightness(color, 0.3)} !important;
        }
    `;
    return css;
}

function global(color, cssClass) {
    const css = `
        .bg-${cssClass}{
            background-color: ${color} !important;
            color: ${pickTextColor(color)};
        }
        .text-${cssClass}{
            color: ${color} !important;
        }
    `;
    return css;
}

function badge(color) {
    const css = `
        .badge.badge-primary{
            background-color: ${color} !important;
            color: ${pickTextColor(color)} !important;
        }
            
    `;
    return css;
}


/**
 * 
 * @param hex  - a hex color value such as “#abc” or “#123456” (the hash is optional)
 * @param lum  -  the luminosity factor, i.e. -0.1 is 10% darker, 0.2 is 20% lighter, etc.
 * @returns - color in rgb
 */

function colorLuminance(hex, lum) {

    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
    }

    return rgb;
}



function pickTextColor(backgroundColor: string): string {
    // Convert hex to RGB
    const hex = backgroundColor.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return black for light backgrounds and white for dark backgrounds
    return luminance > 0.5 ? 'black' : 'white';
}

function colorBrightness(hex, lum) {
    // Validate hex string
    hex = hex.replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];

    // Convert hex to RGB
    let rgb = parseInt(hex, 16);
    let r = (rgb >> 16) & 255, g = (rgb >> 8) & 255, b = rgb & 255;

    // Convert RGB to HSL
    r /= 255; g /= 255; b /= 255;
    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    if (max == min) {
        h = s = 0;
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    // Adjust lightness
    l = Math.min(Math.max(0, l + lum), 1);

    // Convert HSL back to RGB
    if (s == 0) {
        r = g = b = l;
    } else {
        let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        let p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    // Convert RGB back to hex
    return "#" + toHex(r) + toHex(g) + toHex(b);

    function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
    }

    function toHex(x) {
        let hex = Math.round(x * 255).toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
}
