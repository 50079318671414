{
  "0": "0",
  "1": "1",
  "#": "#",
  "ABOUT": "Om",
  "ACCEPT_CONDITIONS": "Aksepter salgsvillkår",
  "ACCEPT_CONDITIONS_FIRST": "Aksepter salgsvillkår først",
  "ACCEPT_FUTURE_DATE": "Akksepter leveringstid på de restede produktene",
  "ACCEPT_PART_DELIVERIES": "OK med delleveranse",
  "ACCEPT_PART_DELIVERIES_NO": "Ikke delleveranse",
  "ACCEPT_PART_DELIVERIES_SELECTION_REQUIRED": "Velg delleveranse eller ikke delleveranse før orderen kan sendes",
  "ACCEPT_PART_DELIVERIES_YES": "Delleveranse",
  "ACCESSORIES": "Tilbehør",
  "ACCOUNT": "Konto",
  "ACCOUNT_DETAILS": "Kontodetaljer",
  "ACCOUNT_REQUEST": "Kontospørsmål",
  "ACCOUNT_REQUEST_FAIL": "Vi kan ikke ta imot din kontosøknad. Forsøk igjen eller kontakt Unident direkte",
  "ACCOUNT_REQUEST_SUCCESS": "Vi har nå fått din kontosøknad, du får beskjed når den er behandlet",
  "ACTION": "Utfør",
  "ACTIONS": "Funksjoner",
  "ACTIVE_FILTERS": "Aktive filtre",
  "ACTIVITIES": "Aktiviteter",
  "ACTIVITY": "Aktivitet",
  "ACTIVITY_DETAILS": "Aktivitetsdetaljer",
  "ACTIVITY_SEARCH": "Søk etter type og dato",
  "ACTIVITY_TYPE": "Aktivitetstype",
  "ADD": "Legg til",
  "ADD_ACTIVITY": "Legg til aktivitet",
  "ADD_ALL_QUANTITIES_FIRST": "Legg til alle varianter før du oppdaterer rabatten. Hvis du legger til flere, må du oppdatere igjen.",
  "ADD_CONTACT": "Legg til kontakt",
  "ADD_EMAIL_ADDRESS": "Legg til e-postmottaker",
  "ADD_MODEL_DISCOUNT": "Legg til modellrabatt",
  "ADD_MODEL_PRICE": "Legg til modellpris",
  "ADD_MORE": "Legg til flere",
  "ADD_PHONE_CALL": "Legg til telefonsamtale",
  "ADD_PRODUCTS_AND_OPTIONS": "Legg til produkter og velg",
  "ADD_TO_ALL_OF_SAME_MODEL": "Legg til på alle med samme modellnummer",
  "ADD_TO_CART": "Kjøpe",
  "ADD_TO_CURRENT_ORDER": "Legg til gjeldende ordre",
  "ADD_TO_FAVORITE_LIST": "Legg til i favorittlisten",
  "ADDITIONAL_INFO": "Ytterligere informasjon",
  "ADDON": "Tillegg",
  "ADDON_PRODUCT_PRICE": "Pris på første produkt",
  "ADDRESS": "Adresse",
  "ADDRESS_INFO": "Adressinformasjon",
  "ADDRESS1": "Gateadresse",
  "ADDRESS2": "C/O Adresse",
  "ADDRESS3": "Poststed",
  "ADDRESS4": "Adresse 4",
  "ADMIN": "Admin",
  "ADVANCE_DELIVERY_INFORMATION": "<span class='highlight'>Dellevering: </span> Dellevering er ikke tillatt.",
  "ADVANCED_SETTINGS": "Avanserte innstillinger",
  "ALL": "alle",
  "ALL_PRICES": "Alle priser",
  "ALL_PRODUCTS": "Alle produkter",
  "ALSO_SUITABLE_FOR": "Passer også til følgende biler",
  "AMOUNT": "Beløp",
  "AMOUNTS": "Beløp",
  "APPLY_TO_COLOR": "Legg til på variant",
  "APPLY_TO_MODEL": "Legg til på modell",
  "April": "April",
  "AR_BALANCE": "Kundreskontra",
  "AR_ITEMTYPECD2": "Artikelfilter 1",
  "AR_ITEMTYPECD3": "Artikelfilter 2",
  "AR_ITEMTYPECD4": "Artikelfilter 3",
  "AR_VARUGRUPPKOD": "Produktgruppe",
  "ARTICLE_DESCRIPTION": "Artikkelbeskrivelse",
  "ASSORTMENT": "Sortiment",
  "ASSORTMENT_GROUP": "Sortimentgruppe",
  "ASSORTMENT_HOME": "Sortiment hjem",
  "August": "August",
  "AVAILABILITY": "Status",
  "AVAILABLE": "stk i lager",
  "AVAILABLE_LATER": "Tilgjengelig senere",
  "AVAILABLE_STOCK": "Disponibel lagersaldo",
  "B24_PRICAT_TEMPLATES": "B24_PRICAT_TEMPLATES",
  "BACK_IN_STOCK": "Tilbake på lager",
  "BACK_TO_PRODUCTS": "Fortsett å handle",
  "BACKEND_DOWN": "Feil ved kommunikasjon med serveren",
  "BAD_EMAIL": "Ugyldig epost",
  "BALANCE": "Balanse",
  "BASIC_SETTINGS": "Grunninnstillinger",
  "BEST_BEFORE": "Best før",
  "BEST_BEFORE_ASC": "Best før (stigende)",
  "BEST_BEFORE_DESC": "Best før (synkende)",
  "BILLING_ADDRESS": "Fakturaadresse",
  "BILLING_CUSTOMER": "Fakturamottaker",
  "BILLING_INFORMATION": "Fakturainformasjon",
  "BLANK": "Mangler informasjon",
  "BONUS": "Bonus",
  "BONUS_POINTS": "Bonusbeløp",
  "BRAND": "VAREMERKE",
  "BRAND_NAME": "Gung - Extend your ERP!",
  "BRAND_TITLE": "Gung - Extend your ERP!",
  "BREAKDOWN": "Sammendrag",
  "BUY": "Kjøp",
  "BY_ITEM_CATEGORY": "Pr. produkt-kategori",
  "BY_ITEM_ID": "Pr. produkt-id",
  "BY_MONTH": "Måned",
  "BY_QUARTER": "Kvartal",
  "CACHES": "Caches",
  "CALC_DELIVERY_TIME": "beregnet sendningsdato",
  "CALC_IN_STOCK": "Forventet på lager",
  "CAMPAIGN": "Kampanje",
  "CAMPAIGN_FILTER_OPTION": "Vis kampanjeprodukter",
  "CAMPAIGN_LABEL": "Kampanje",
  "CAMPAIGN_PRODUCTS": "Kampanjeprodukter",
  "CANCEL": "Avbryt",
  "CANCEL_REMAINING_QUANTITIES": "Annuller restantall, og merk ordren som fullført",
  "CANCELLED": "Annullert",
  "CART": "Handlekurv",
  "CART_CREATIONDATE": "Opprettet dato",
  "CART_HAS_BEEN_LOADED": "Handlevogn er hentet og lagt i aktuell handlevogn",
  "CART_ID": "Id",
  "CART_ID_PLACEHOLDER": "Id genereres ved spart handlevogn",
  "CART_LOADED": "Handlevogn er hentet",
  "CART_LOADING": "Legg i handlevogn",
  "CART_NAME": "Beskrivelse",
  "CART_NAME_PLACEHOLDER": "Beskrivelse av handlevogn",
  "CART_SUBTOTAL": "Sum",
  "CART_SUMMARY": "Summering av handlekurv",
  "CART_WAS_SAVED_WITH_NAME": "Handlevognen har blitt lagret med id:",
  "CARTS": "Handlevogner",
  "CARTS_FILTER": "Filter",
  "CARTS_FILTER_PLACEHOLDER": "Navn",
  "CATEGORIES": "Kategorier",
  "CATEGORY": "Kategori",
  "CATEGORY_DESC": "Kategori-beskrivelse",
  "CATEGORY_ID": "Kategori-id",
  "CATEGORY_IMAGE_UPLOAD": "Last opp kategoribilde",
  "CATEGORY_MAPPING": "Kategorikartlegging",
  "CATEGORY_VALUE": "Kategoriverdi",
  "CHANGE_PRODUCT_INFORMATION": "Annen produktinformasjon",
  "CHANGE_SAVED_FAIL": "Det oppstod en feil ved forsøk på å lagre endringene dine",
  "CHANGE_SAVED_SUCCESS": "Endring lagret med suksess",
  "CHECK_CREDENTIALS": "Kontroller din brukerinformasjon",
  "CHECKOUT": "Kasse",
  "CHECKOUT_CONDITIONS": "Ved bekreftelse av ordre aksepteres våre betingelser",
  "CHECKOUT_ORDER": "Send inn ordre",
  "CITY": "By",
  "CLEAR": "Tøm",
  "CLEAR_ALL_ERROR_LOGS": "Tøm alle feillogger",
  "CLEAR_CART": "Tøm handlevogn",
  "CLEAR_CART_QUESTION": "Er du sikker på at du vil tømme handlevognen?",
  "CLICK": "Klikk",
  "CLICK_TO_CLOSE": "Klikk for å lukke",
  "CLOSE": "Steng",
  "CODE_FOR_RESET": "Gjenopprettingskode",
  "COLOR": "Farge",
  "COLOR_DESCRIPTION": "COLOR_DESCRIPTION",
  "COLOR_SIZE": "Farge/Størrelse",
  "COLORS": "Farger",
  "COLUMN_FORMAT": "Kolonneformat",
  "COMMENT": "Kommentar",
  "COMPACT": "Kompakt",
  "COMPACT_PRODUCT_INFO": "Kompakt produktinformasjon",
  "COMPANY": "Firma",
  "COMPANY_INFORMATION": "Firmainformasjon",
  "COMPANY_NAME": "Firmanavn",
  "COMPLETE": "Fullstendig",
  "COMPLETE_FORM_BEFORE_SUBMIT": "Fyll ut skjemaet før utsjekking",
  "COMPLETED": "Fullstendig",
  "COMPLETED_DATE": "Dato",
  "CONDITIONS": "Villkår",
  "CONFIRM": "Bekreft ordre",
  "CONFIRM_DELETION": "Er du sikker på at du vil slette {{value}}",
  "CONFIRMATION_EMAIL": "Bekreftelse E-post",
  "CONTACT": "Kontakt",
  "CONTACT_DETAILS": "Kontaktinformasjon",
  "CONTACT_FOR_PRICE": "Kontakt oss for pris",
  "CONTACT_OR_RESPONSIBLE": "Kontakt eller ansvarlig",
  "CONTACT_PERSON_ASSORTMENT": "Sortiment",
  "CONTACT_PERSON_DEPARTMENT": "Avdeling",
  "CONTACT_PERSON_NAME": "Kontaktperson",
  "CONTACT_PERSON_PHONE_MOBILE": "Mobilnr.",
  "CONTACT_PERSON_PHONE1": "Telefon 1",
  "CONTACT_PERSON_PHONE2": "Telefon 2",
  "CONTACT_PERSON_PHONE3": "Telefon 3",
  "CONTACT_PERSON_PHONE4": "Telefon 4",
  "CONTACT_PERSON_PHONE5": "Telefon 5",
  "CONTACT_PERSON_POSITION": "Stilling",
  "CONTACT_PERSONS": "Kontaktpersoner",
  "CONTACT_SEARCH": "Søk etter navn",
  "CONTACT_US": "Kontakt oss",
  "CONTACTS": "Kontakter",
  "CONTAINS_PRODUCT": "Inneholder produkt",
  "CONTINUE_SHOPPING": "Tilbake til produkter",
  "CONTINUE_TO_SHIPPING": "Fortsett til leveringsinformasjon",
  "CONVERT": "Konvertere",
  "CONVERT_TO_ORDER": "Konverter til ordre",
  "COPIED_TO_CLIPBOARD": "En lenke er kopiert til utklippstavlen.",
  "COPY_TO_CART": "Kopier til handlevogn",
  "COPY_USER": "Kopier bruker",
  "COUNT": "Antall",
  "COUNTRY": "Land",
  "COVER": "Dekke",
  "CREATE": "Opprett",
  "CREATE_CUSTOMER": "Opprett kunde",
  "CREATE_LINESHEET": "Opprett Linesheet",
  "CREATE_NEW": "Opprett ny",
  "CREATE_PICKLIST": "Opprett plukkliste",
  "CREATED_BY": "Opprettet av",
  "CREATED_DATE": "Opprettet dato",
  "CREATEMODELCONTEXT_PREFIX": "CREATEMODELCONTEXT_PREFIX",
  "CREATEUSER_ASSORTMENT": "Sortiment",
  "CREATEUSER_EMAIL": "E-post",
  "CREATEUSER_MANAGEDCOMPANYIDS[0]": "Kundenummer",
  "CREATEUSER_NAME": "Navn",
  "CREATEUSER_USERNAME": "Brukernavn",
  "CREDIT_CHECK_OK": "Kredittverdighet OK",
  "CREDIT_CKECK_NOT_OK": "Kredittverdighet ikke OK",
  "CRON": "Cron",
  "CS1_COMNR": "Telefon",
  "CS4_COMNR": "Mobilnummer",
  "CS8_COMNR": "E-post",
  "CURRENCY": "Valuta",
  "CURRENT_LIST": "Liste",
  "CURRENTLY": "Akkurat nå",
  "CUSTOM_EXPORTS": "Tilpassede eksporter",
  "CUSTOMER": "Kunde",
  "CUSTOMER_ACTIVITY_CONTACT": "Kontaktperson",
  "CUSTOMER_ACTIVITY_RESPONIBLE": "Ansvarlig",
  "CUSTOMER_ACTIVITY_STARTDATE": "Startdato",
  "CUSTOMER_ACTIVITY_STARTTIME": "Starttid",
  "CUSTOMER_ACTIVITY_TEXT": "Notat",
  "CUSTOMER_CHANGE": "Bytt kunde",
  "CUSTOMER_CONTACT": "Kundekontakt",
  "CUSTOMER_CONTINUE": "Hold deg oppdatert",
  "CUSTOMER_DETAILS": "Kundeinformasjon",
  "CUSTOMER_DISCOUNT": "Kunderabatt",
  "CUSTOMER_ID": "Kundenummer",
  "CUSTOMER_INFO": "Kundeinformasjon",
  "CUSTOMER_NAME": "Kundenavn",
  "CUSTOMER_ORDER_DETAILS": "Kundeordre detaljer",
  "CUSTOMER_SEARCH": "Søk etter navn, nummer, sted, adresse",
  "CUSTOMERS": "Kunder",
  "DATA": "Data",
  "DATE": "Dato",
  "DAYS": "dager",
  "DE_TEXT": "Tysk tekst",
  "December": "December",
  "DEFAULT": "Normal",
  "DEFAULT_HOME": "Standard Hjem",
  "DEFAULT_LANGUAGE": "Sett som standardspråk",
  "DELETE": "Ta bort",
  "DELETE_CART": "Ta bort handlevogn",
  "DELIVER": "Lever",
  "DELIVER_ALL_AND_FINISH": "Lever alle linjer",
  "DELIVER_ALL_AND_FINISH_FINAL": "Lever alle linjer uten restordre",
  "DELIVER_NOW": "Lever linje",
  "DELIVERED": "Levert",
  "DELIVERED_0-12": "Levert 0-12 måneder",
  "DELIVERED_12-24": "Levert 12-24 måneder",
  "DELIVERED_QUANTITY": "Levert antall",
  "DELIVERED_VALUE": "Levert verdi",
  "DELIVERIES": "Leveringer",
  "DELIVERY": "Levering",
  "DELIVERY_ADDRESS": "Leveringsadresse",
  "DELIVERY_CUSTOMER": "Leveransekunde",
  "DELIVERY_DATE": "Est. Sendningsdato",
  "DELIVERY_ID": "Leveringsnummer",
  "DELIVERY_INFORMATION": "Leveringsinformasjon",
  "DELIVERY_METHOD": "Leveringsmåte",
  "DELIVERY_NOTE": "Følgeseddel",
  "DELIVERY_OPTIONS": "Leveringsalternativ",
  "DELIVERY_STATUS": "Leveringsstatus",
  "DELIVERY_TERM": "Leveransevillkår",
  "DELIVERY_TRACKING": "Leveringssporing",
  "DELIVERY_TRACKING_LINK": "Link",
  "DELIVERY_WEEK": "Leveranseuke",
  "DEPTH": "Dybde",
  "DESCRIPTION": "Beskrivning",
  "DESELECT_ALL": "Merk alle",
  "DESIRED_PASSWORD": "Ønsket passord",
  "DETAILED_CHECKOUT": "Detaljert utsjekk",
  "DETAILS": "Detaljer",
  "DIMENSION": "Dimensjon",
  "DIMENSIONS": "Dimensjoner",
  "DISCOUNT": "Rabatt",
  "DISCOUNT_CODE": "Rabattkode",
  "DISCOUNT_CODE_APPLY": "Legg til",
  "DISCOUNT_CODE_PLACEHOLDER": "Skriv inn rabattkode",
  "DISCOUNT_TERM": "Rabattvillkår",
  "DISPATCH_METHOD": "Leveringsmåte",
  "DISPATCH_MODE": "Leveringsmåte",
  "DISPATCH_TERMS": "Leveransevillkår",
  "DISPLAY_BATCH_PREFIX": "Vis",
  "DISTRICT": "Distrikt",
  "DK_TEXT": "Dansk tekst",
  "DOCUMENTS": "Dokument",
  "DONE": "Klar",
  "DOWNLOAD": "Nedlastning",
  "DOWNLOAD_DEFAULT_COVER": "Last ned standarddeksel",
  "DOWNLOAD_DELIVERY_NOTE": "Last ned følgeseddel",
  "DOWNLOAD_DOCUMENT": "Last ned dokument",
  "DOWNLOAD_PICKLIST": "Last ned plukkliste",
  "DOWNLOADS": "Nedlastninger",
  "DUE_DATE": "Forfallsdato",
  "E_MAIL_RECIPIENTS": "E-post mottaker",
  "EAN": "EAN-kode",
  "ECONOMY": "Økonomi",
  "EDIT": "Endre",
  "EDIT_ACTIVITY": "Endre aktivitet",
  "EDIT_CART": "Endre handlevogn",
  "EDIT_CONTACT": "Endre kontakt",
  "EDIT_DIMENSION": "Rediger mål",
  "EDIT_LOCATION_PROPERTY": "Editera lagerinformation",
  "EDIT_ORDER_ROW": "Rediger ordrelinje",
  "EDIT_USER": "Rediger bruker",
  "EDITOR_FOR_ADMIN": "Editor under vises kun for administrator",
  "EDUCATION": "Opplæring",
  "ELEMENT_EDITOR": "Elementredaktør",
  "EMAIL": "E-post",
  "EMAIL_CONFIRMATION": "E-post bekreftelse",
  "EMAIL_NOT_FOUND": "Din e-post ble ikke funnet",
  "EMAIL_PLACEHOLDER": "din.epost@firma.no",
  "EMAIL_RECIPIENT": "E-post mottaker",
  "EMAIL_RECIPIENT_PLACEHOLDER": "Mottaker@domene.no",
  "EMAIL_SEND": "Send",
  "EMAIL_USERNAME": "E-Mail (Brukernavn)",
  "EMAIL_TEXT": "E-posttekst",
  "EMPLOYEES": "Personal",
  "EMPTY_CART": "Tøm handlekurv",
  "EMPTY_SEARCH_MESSAGE": "Kontroller at du ikke har noen filtersøk aktivert. Dette kan forstyrre ditt produktsøk.",
  "EN_DESCRIPTION": "Engelsk beskrivelse",
  "ENABLED": "aktivert",
  "ENTER_ADDRESS": "Oppgi adresse",
  "ENTER_ADDRESS1": "Oppgi adresse",
  "ENTER_ADDRESS2": "Oppgi adresse 2",
  "ENTER_CITY": "Oppgi sted",
  "ENTER_COMPANY_ID": "Oppgi kundenummer",
  "ENTER_COMPANY_NAME": "Oppgi firmanavn",
  "ENTER_CONTACT": "Oppgi kontaktperson",
  "ENTER_COUNTRY": "Oppgi land",
  "ENTER_EMAIL": "Oppgi e-post",
  "ENTER_MESSAGE": "Har du spesifikke ønsker angående din ordre eller leveranse, skriv beskjed her",
  "ENTER_NAME": "Oppgi navn",
  "ENTER_NAME_OR_ID": "Søk etter navn eller ID",
  "ENTER_ORG_NO": "Oppgi organisasjonsnummer",
  "ENTER_PASSWORD": "Oppgi passord",
  "ENTER_PHONE": "Oppgi telefonnummer",
  "ENTER_PHONE_NUMBER": "Oppgi telefonnummer",
  "ENTER_REGION": "Oppgi Region",
  "ENTER_RESET_CODE": "Oppgi gjenopprettingskode",
  "ENTER_RESET_TOKEN": "Oppgi tilbakestillingskode",
  "ENTER_USERNAME": "Oppgi brukernavn",
  "ENTER_VAT": "Oppgi organisasjonsnummer",
  "ENTER_YOUR_EMAIL": "Oppgi din e-post",
  "ENTER_ZIP": "Oppgi Postnr",
  "EST_DISPATCH_DATE": "Beregnet sendningsdato",
  "ESTIMATED_DELIVERY_DATE": "Beregnet sendningsdato",
  "EXCEL_TO_ORDER": "Excel å bestille",
  "EXECUTING": "Utfører",
  "EXECUTIONS": "Henrettelser",
  "EXPECTED_DELIVERY_DAYS": "Forventede dager til levering",
  "EXPORT_LINESHEET": "Eksporter Linesheet",
  "EXPORT_LINESHEET_PDF_READ_OR_EXCEL_EDIT": "Eksporter linjeark som PDF (skrivebeskyttet, utskriftsvennlig) eller Excel (redigerbar, import mulig)",
  "EXTERNAL_TEXT": "Ekstern tekst",
  "EXTRA_FREIGHT_ADDED": "Porto kostnader tilkommer dersom bestillingen inneholder bestillingsvarer",
  "FAILED": "Mislyktes",
  "FALSE": "Nei",
  "FAST_SEARCH_HOME": "Rask søk hjem",
  "FAST_SEARCH_PRODUCT": "Rask søk produkter",
  "FAVORITE_LIST": "Favorittliste",
  "FAVORITE_LIST_NOT_FOUND": "Kunne ikke opprette favorittlisten",
  "FAVORITE_LISTS": "Favorittlister",
  "FAVOURITES": "Favoritter",
  "FAX": "Fax",
  "February": "February",
  "FEW_LEFT": "Begrenset antall på lager",
  "FH_BETKOD": "Betalingsvillkår",
  "FH_BETKOD_10": "10 dagar netto",
  "FH_BETKOD_12": "120 dagar netto",
  "FH_BETKOD_14": "14 dagar netto",
  "FH_BETKOD_15": "15 dagar netto",
  "FH_BETKOD_20": "20 dagar netto",
  "FH_BETKOD_21": "21 dagar netto",
  "FH_BETKOD_30": "30 dagar netto",
  "FH_BETKOD_45": "45 dagar netto",
  "FH_BETKOD_75": "75 dagar netto",
  "FH_BETKOD_90": "90 dagar netto",
  "FH_FAKTDAT": "Fakturadato",
  "FH_FAKTDAT_FILTER_PLACEHOLDER": "Fakturadato",
  "FH_FAKTFFDAT": "Forfallsdato",
  "FH_FAKTFFDAT_FILTER_PLACEHOLDER": "Forfallsdato",
  "FH_FAKTSTKOD": "Fakturastatus",
  "FH_FAKTSTKOD_-1": "Foreløpig frakt (under opprettelse)",
  "FH_FAKTSTKOD_-2": "Forhåndsvisning (ikke fakt.)",
  "FH_FAKTSTKOD_0": "Fakturerad",
  "FH_FAKTSTKOD_3": "Under utredning",
  "FH_FAKTSTKOD_5": "Betald",
  "FH_FAKTSTKOD_9": "Makulerad",
  "FH_KUNDREF2": "Deres referansenummer",
  "FH_ORDERNR": "Ordrenummer",
  "FH_VALKOD": "Valutakode",
  "FH_VB_FAKTSUM": "Beløp",
  "FH_VBFAKTINBBEL": "Innbetalt",
  "FH_VBFAKTMOMS": "Moms",
  "FH_VBFAKTREST": "Restbeløp å betale",
  "FH_VBFAKTTOTUMOMS": "Beløp eksl.mva",
  "FH_VREF": "Vår referanse",
  "FILL_OPTION_IMAGE": "Fyll alternativbilde",
  "FILL_OPTION_LINK": "Fyll alternativlink",
  "FILL_OPTION_TEXT": "Fyll alternativ tekst",
  "FILTER_EXPLANATION": "Filtrere på artikkelnummer eller navn",
  "FILTERS": "Filter",
  "FINANCIAL_GROUP": "Financial Group",
  "FINISH_WITHOUT_DELIVERING_REST": "Avslutt uten restordre",
  "FIRST_AVAILABLE_DATE": "Tilgjengelig dato",
  "FOR": "for",
  "FORGOT_PASSWORD": "Nytt passord",
  "FR_DISTRKOD": "Distrikt",
  "FR_EUMOMSNR": "Organisasjonsnummer",
  "FR_FTGNAMN": "Firmanavn",
  "FR_FTGNR": "Leveransadress",
  "FR_FTGPOSTADR1": "C/O Adresse",
  "FR_FTGPOSTADR2": "Gateadresse",
  "FR_FTGPOSTADR3": "Poststed",
  "FR_FTGPOSTADR4": "Kontorsadress",
  "FR_FTGPOSTADR5": "Leveranseadresse",
  "FR_FTGPOSTLEVADR3": "Sted (Leverering)",
  "FR_FTGPOSTNR": "Postnummer",
  "FR_LANDSKOD": "Land",
  "FR_ORGNR": "Org. nr.",
  "FREIGHT": "Frakt",
  "FREIGHT_CHARGE": "Fraktkostnader",
  "FREIGHT_INFORMATION": "<span class='highlight'>Fraktkostnad: </span> 10 € hvis bestillingsverdien er under 300 €.",
  "FREIGHT_WILL_BE_ADDED": "Porto kostnade tilkommer",
  "FROZEN_PRODUCTS": "Fryste produkter",
  "FT_ARTBESKR": "Navn",
  "FT_ARTNR": "Art.nr",
  "FT_FAKTLEVANTAL": "Antall",
  "FT_FAKTRADNR": "Rad",
  "FT_VALKOD": "Valuta",
  "FT_VB_FAKTRADSUMMA": "Totalt",
  "FT_VB_PRIS": "Pris, valuta",
  "FT_VBFAKTMOMS": "Moms",
  "FULL_PRODUCT_INFO": "Mer produktinformasjon",
  "FUTURE": "Fremtid",
  "FUTURE_STOCK": "Fremtidig lagersaldo",
  "GALLERY": "Galleri",
  "GARP_OGF_TX1": "Tekst 1",
  "GARP_OGF_TX2": "Tekst 2",
  "GARP_OGF_TX3": "Tekst 3",
  "GENERATE": "Generer",
  "GET_ACCOUNT": "Observer at du må være registrert tannlege/tannpleier for å kunne handle hos oss. Vi selger ikke produkter til privatpersoner uten et organisasjonsnummer.",
  "GO_TO_ALL_PRODUCTS": "Vis alle produktene",
  "GO_TO_CHECKOUT": "Gå til kassen",
  "GOODSMARK": "Godsmerket",
  "GUNG_COOKIE_LINK": "/#/p/cookies-se",
  "GUNG_COOKIE_LINK_TEXT": "Les mer",
  "GUNG_COOKIE_TEXT": "Vi bruker informasjonskapsler for å sikre at du får en god opplevelse når du besøker nettstedet. Når du bruker nettstedet, antar vi at du godtar dette.",
  "GUNG_FLOW": "Flyt",
  "GUNG_FLOWS": "Flyt",
  "GUNG_ITEM_LIST_LOAD_MORE": "Viser {{limit}} av {{total}}, vise {{batchSize}} fler",
  "GUNG_MISSING_QUANTITIES_LATER": "Orderen inneholder artikler som ikke finnes i lager. Orderen sendes når alle artikler er ankommet lager",
  "GUNG_MISSING_QUANTITIES_LIMIT": "Om du fortsetter kommer antallet justeres til hva som finnes tilgjengelig.",
  "GUNG_PRODUCT_LIST_LOAD_MORE": "Viser {{limit}}  av {{total}}, vise fler",
  "GUNG_USER": "Gung-bruker",
  "GUNGDATA_AKTIVITET": "Aktivitet",
  "GUNGDATA_ANSVARIG": "Ansvarlig",
  "GUNGDATA_BEFATTNING": "Stilling",
  "GUNGDATA_EMAIL": "E-post",
  "GUNGDATA_END": "Slutt",
  "GUNGDATA_KONTAKTPERSON": "Kontaktperson",
  "GUNGDATA_MOBILTFN": "Mobiltelefon",
  "GUNGDATA_POSITION": "Stilling",
  "GUNGDATA_PRIVATTFN": "Privat telefon",
  "GUNGDATA_START": "Start",
  "GUNGDATA_STATUS": "Status",
  "GUNGDATA_WWW": "Nettsted",
  "GUNGFLOW_DELIVERYSTARTDATE": "Første mulige sendningsdato",
  "GUNGFLOW_ID": "Flytens ID",
  "GUNGFLOW_IS_DEFAULT": "Forhåndsvalgt flyt",
  "GUNGFLOW_IS_PREORDER": "Forhåndsbestillingsflyt",
  "GUNGFLOW_IS_RETURN": "Returflyt",
  "GUNGFLOW_ISACTIVE_ROLES": "Flyten er aktiv for:",
  "GUNGFLOW_NAME": "Flytens navn",
  "HAS_CONTENT": "Har innhold i handlekurven",
  "HEADER_TEXT": "Velkommen til HL Display <b>kundeportal</b><br>Vi ønsker deg en god handleopplevelse",
  "HERE": "her",
  "HI_RES_IMAGES": "Bilder",
  "HIDE": "Skjul",
  "HIDE_CATEGORIES": "Skjul kategorier",
  "HIDE_SIZES": "Skjul størrelser",
  "HOME": "Hjem",
  "HTML_OUTPUT": "HTML output",
  "HYGENIST": "Tannpleier",
  "ID": "ID",
  "ID_ASC": "ID_ASC",
  "ID_DATE_OR_ADDRESS": "id",
  "ID_DESC": "ID_DESC",
  "IMAGE": "Bilde",
  "IMAGE_NOT_ENABLED": "Bilde - ikke aktivert",
  "IMPORT": "Importer",
  "IMPORT_LINESHEET": "Importer Linesheet",
  "IMPORT_LINESHEET_PREORDER": "Importer ordre (Preorder)",
  "IMPORT_LINESHEET_STOCKORDER": "Importer ordre (Stockorder)",
  "IN_CART": "i handlekurven",
  "IN_STOCK": "På lager",
  "INCOMING": "Snart på lager",
  "INTERNAL": "Intern",
  "INFO": "Informasjon",
  "INITIALIZING": "Intitsierer",
  "INOVICE_DETAILS": "Fakturadetaljer",
  "INOVICE_INFORMATION": "Faktura info",
  "INSERT_TEXT_HERE": "Sett inn tekst her",
  "INTERNAL_STATE": "Intern tilstand",
  "INTERNAL_TEXT": "Intern tekst",
  "INVENTORY": "Lager",
  "INVENTORY_STATUS": "Lagerstatus",
  "INVOICE": "Faktura",
  "INVOICED": "Invoiced",
  "NOT_INVOICED": "Not invoiced",
  "INVOICE_DATE": "Fakturadato",
  "INVOICE_DETAILS_DISCLAIMER": "Merk: Dette er ikke fakturaen din. Bruk den du får fra leverandøren.",
  "INVOICE_DETAILS_NO_ROWS": "Det finnes ingen leverte linjer for denne fakturaen.",
  "INVOICE_GT_1": "1–14 dager siden",
  "INVOICE_GT_14": "15–30 dager siden",
  "INVOICE_GT_30": "mer enn 30 dager siden",
  "INVOICE_HAS_NOT_PASSED": "Ikke passert ennå",
  "INVOICE_HISTORY": "Fakturahistorikk",
  "INVOICE_ID": "Fakturanummer",
  "INVOICE_INFORMATION": "Faktura info",
  "INVOICE_NUMBER": "Fakturanummer",
  "INVOICE_REFERENCE": "Fakturareferanse",
  "INVOICE_ROW_SEARCH": "Søk etter ordrenummer, artikkelnummer, navn",
  "INVOICE_ROWS": "Fakturadetaljer",
  "INVOICE_SEARCH": "Søk etter fakturanummer",
  "INVOICE_SUMMARY": "Sammendrag",
  "INVOICEHEADER__DUEDATE": "Forfallsdato",
  "INVOICEHEADER__INVOICEDATE": "Fakturadato",
  "INVOICES": "Fakturaer",
  "IS_IN_STOCK": "På lager",
  "ITEM_ASC": "Artikkelnummer, stigende",
  "ITEM_DESC": "Artikkelnummer, fallende",
  "ITEM_DESCRIPTION": "Beskrivning",
  "ITEM_GROUP": "Varegruppe",
  "ITEM_ID": "Art.nr",
  "ITEM_INFORMATION": "Artikkelinformasjon",
  "ITEM_NO": "Artikkelnummer",
  "ITEMS": "Artikler",
  "ITEMS_AVAILABLE": "Antall tilgjengelige",
  "ITEMS_FILTERED_FROM": "Filtrert fra",
  "ITEMS_IN_ORDER": "Produkter på ordren",
  "January": "January",
  "JEEVES_FT_FAKTRADSUMMA": "Fakt. Liniebel.",
  "JEEVES_FT_FAKTTB": "Dekningsgrad",
  "JEEVES_HRP_EDIT": "Noteringer",
  "JEEVES_HRP_EVENTNOTE": "Beskrivelse",
  "JEEVES_HRP_EVENTRPENDDAT": "Rapportert dato",
  "JEEVES_HRP_EVENTRRPKOD": "Rapportert aktivitet",
  "JEEVES_HRP_EVENTTIMANT2": "Timer",
  "JEEVES_HRP_PERSSIGN": "Ansvarlig",
  "JEEVES_HRP_PLANUTFORD": "Aktivitet avsluttet",
  "JOB": "Jobb",
  "July": "July",
  "June": "June",
  "KA_AD1": "Adresse1",
  "KA_AD2": "Adresse2",
  "KA_ORT": "Postnummer Sted",
  "KA_REF": "Referanse",
  "KA_SLJ": "Selger",
  "KEY": "KEY",
  "KP_ANTALKOP": "Antall kjøp",
  "KP_AVD": "Avdeling",
  "KP_BEFATTKOD": "Rolle",
  "KR_FAD": "Fakturadato",
  "KR_SUB": "Betalingsstatus",
  "KUS_BETKOD": "Betalingsvillkår",
  "KUS_KUNDBETALARENR": "Betalende kunde",
  "KUS_KUNDRABATT": "Kunderabatt",
  "KUS_LEVSATTKOD": "Leveringsmåte",
  "KUS_LEVVILKKOD": "Leveransevillkår",
  "KUS_SALJARE": "Selger",
  "KUS_VALKOD": "Valutakode",
  "LABEL": "Beskrivelse",
  "LANGUAGE_DE": "Deutsch",
  "LANGUAGE_DK": "Dansk",
  "LANGUAGE_EN": "Engelsk",
  "LANGUAGE_ET": "Estnisk",
  "LANGUAGE_FI": "Finsk",
  "LANGUAGE_NO": "Norsk",
  "LANGUAGE_SE": "Svensk",
  "LANGUAGES": "Språk",
  "LAST_EXECUTION_TIME": "Siste utførelsestid",
  "LAST_STATUS": "Siste status",
  "LAST_THREE_MONTHS": "Siste tre måneder",
  "LAST_TWELVE_MONTHS": "Siste tolv måneder",
  "LAST_UODATED": "Sist oppdatert",
  "LATEST_PURCHASE": "Forrige kjøp",
  "LENGTH": "Lengde",
  "LESS_INFO": "Mindre informasjon",
  "LINK_NOT_ENABLED": "Link - ikke aktivert",
  "LIST": "Liste",
  "LIST_PRICE": "Listepris",
  "LOAD_MORE": "Last inn flere",
  "LOAD_CART": "Last ned handlevogn",
  "LOADING": "Laster ned",
  "LOADING_CUSTOMERS": "Laster ned kunder",
  "LOADING_MESSAGE": "Laster ned",
  "LOADING_ORDERS": "Laster ned ordren",
  "LOADING_PRODUCTS": "Laster ned produkter",
  "LOG_IN_AGAIN": "Logg inn igjen",
  "LOG_IN_FOR_MORE": "Logg inn for å se priser og lagerbeholdning",
  "LOGGED_OUT": "Logget ut",
  "LOGGED_OUT_INFO": "Økten er utløpt. Det skyldes innaktivitet eller problem med tilkoblingen. Prøv å logge inn på nytt.",
  "LOGIN": "Logg inn",
  "LOGIN_FAILED": "Innlogging mislyktes",
  "LOGIN_IN_PROGRESS": "Innlogging pågår!",
  "LOGIN_INSTEAD": "Logg inn i stedet",
  "LOGOUT": "Logg ut",
  "LOWER_QUANTITY": "Reduser antall",
  "LR_FFD": "Forfallsdato",
  "MAIN_CATEGORY": "Hovedkategori",
  "MANAGE_CONCEPT": "MANAGE_CONCEPT",
  "MANAGE_CUSTOMERS": "Håndtere kunder",
  "MANAGE_FAVORITE_LISTS": "Håndtere favorittlister",
  "MANAGER_CUSTOMERS": "Håndtere kunder",
  "MAP_NEW_COVER_SHEET": "Kartlegg nytt omslag",
  "March": "March",
  "MARGIN": "Marginal",
  "MARGIN_%": "Marginal %",
  "MATERIAL": "Material",
  "MATERIAL_PERCENT": "Sammensetning",
  "MATRIX_FORMAT": "Matriser Format",
  "MAX_30_CHARACTERS": "Maks 30 tegn",
  "MAX_FUTURE": "Maksimal fremtid",
  "May": "May",
  "MESSAGE": "Beskjed",
  "MESSAGE_PLACEHOLDER": "Skriv en beskjed til oss",
  "METADATA_XH_EVENTRPBESKR": "Rapportert aktivitet",
  "MIN_PACKAGE": "Minimum forpakning",
  "MINIMUM_ORDER_INFORMATION": "<span class='highlight'>Minste ordrebeløp: </span>50 € H.T",
  "MISSING_DATA": "Mangler data",
  "MISSING_QUANTITIES": "Tilgjengelig antall mangler",
  "MODE": "Modus",
  "MODEL": "Modell",
  "MODEL_DISCOUNT": "Modellrabatt",
  "MODEL_PRICE": "Modellpris",
  "MODELS": "Modeller",
  "MONTH": "Måned",
  "MONTHLY_COST": "Månedsavgift",
  "MORE": "til",
  "MORE_FUNCTIONS": "Flere funksjoner",
  "MORE_INFO": "Mer informasjon",
  "MORE_INFORMATION": "Ytterligere informasjon",
  "MOST_PURCHASED": "Mest kjøpte produkter",
  "MY_ACCOUNT": "Min konto",
  "MY_CUSTOMERS": "Mine kunder",
  "MY_MENU": "Min meny",
  "MY_PAGES": "Mine sider",
  "NAME": "Navn",
  "NAME_ASC": "Navn A-Z",
  "NAME_DESC": "Navn Z-A",
  "NEW": "Ny",
  "NEW_PASSWORD": "Nytt passord",
  "NEW_PASSWORD_REPEAT": "Bekreft passordet",
  "NEW_PRICE": "Ny pris",
  "NEW_PRICE_OR_DISCOUNT": "Skriv inn ny pris, eller avslutt med % for rabatt",
  "NEXT_EXECUTION_TIME": "Neste utførelsestid",
  "NO": "NO",
  "NO_ACCESS": "Ingen adgang",
  "NO_AVAILABILITY": "Ikke tilgjengelig",
  "NO_AVAILABLE_DATE": "Ingen bestemt dato",
  "NO_CHOICE": "Hopp over",
  "NO_CHOICE_INFO": "Ingen valg er valgt",
  "NO_CUSTOM_PAGE_CONTACT_GUNG": "Ingen tilpasset side tilgjengelig. Kontakt Gung for tilpasning.",
  "NO_EXTRA_FREIGHT_ADDED": "Ingen frakt tilkommer på restede produkter",
  "NO_ITEMS_FOUND": "Ingen treff på ditt søk",
  "NO_PRICES": "Ingen priser",
  "NO_PRODUCT_SELECTED": "Ingen produkt er valgt",
  "NO_PRODUCTS_IN_CART": "Ingen produkter i handlevogn. Gå til",
  "NO_RESULTS_FOUND": "Ingen resultater",
  "NO_VALID_EMAIL": "Ingen gyldig E-post",
  "NONE_SELECTED": "Ingen er valgt",
  "NORMAL": "Normal",
  "NORMAL_STOCK_LEVEL": "Normal ordernivå",
  "NORWEIGAN_TEXT": "Norsk tekst",
  "NOT_DELIVERED": "Ikke levert",
  "NOT_GUNG_USER": "Ikke Gung-bruker",
  "NOT_IN_STOCK": "Ikke på lager",
  "NOT_IN_STOCK_REC": "Ikke på lager, innkommende",
  "NOT_ON_STOCK": "NOT_ON_STOCK",
  "NOT_PASSED": "Forfallsdato er ikke passert",
  "NOT_REQUIRED": "Ikke obligatorisk",
  "NOTE": "Anmerkning",
  "November": "November",
  "NUM_IN_STOCK": "stk i lager",
  "NUM_OF_ORDERS": "# Ordrer",
  "NUMBER_OF_DOCS": "{{documentList.length | number}} dokument",
  "October": "October",
  "OF": "av",
  "OFH_BETKOD": "Betalingsvillkår",
  "OFH_KUNDBESTNR": "Betalende kunde",
  "OFH_OFFERTDAT": "Tilbudsdato",
  "OFH_OFFNR": "Tilbudsnummer",
  "OFH_OFFSTATUS": "Tilbudstatus",
  "OFH_OFFSTATUS_1": "Tilbud",
  "OFH_OFFSTATUS_3": "Aktiv",
  "OFH_OFFSTATUS_4": "Aktivt anbud",
  "OFH_OFFSTATUS_6": "Registrert",
  "OFH_OFFSTATUS_9": "Avsluttet",
  "OFH_OFFSTATUS_A": "Opprettet",
  "OFH_OFFSTATUS_E": "Avslag",
  "OFH_OFFSTATUS_F": "Tilbudsspørsmål",
  "OFH_OFFSTATUS_G": "Avventer avtale",
  "OFH_OFFSTATUS_H": "Avtale klar",
  "OFH_OFFSTATUS_I": "Utlevert",
  "OFH_OFFSTATUS_J": "Nytt på gang",
  "OGA_BLT": "Bekreftet/ønsket leveringstid",
  "OGA_BVK": "Betalingsvilkår",
  "OGA_LSE": "Leveringsmåte",
  "OGA_LVK": "Leveringsvilkår",
  "OGA_OTY": "Ordretype",
  "OGA_PRL": "Prisliste",
  "OGA_RBK": "Generell rabattkode",
  "OGR_LVF_0": "Ikke levert",
  "OGR_LVF_4": "Delvis levert",
  "OH_BETKOD": "Betalingsvillkår",
  "OH_FTGKONTAKTNR": "Kundekontakt",
  "OH_FTGPOSTNR": "Postnr",
  "OH_GILTIGTOMDATUM": "Gyldig t.o.m dato",
  "OH_GODSMARKE1": "Merk forsendelsen",
  "OH_KUNDBESTNR": "Ditt referansenummer",
  "OH_KUNDBETALARENR": "OH_KUNDBETALARENR",
  "OH_KUNDREF2": "Navn på bestiller",
  "OH_LEVVILLKKOD": "Leveransevillkår",
  "OH_ORDBERLEVDAT": "Ber. levtid",
  "OH_ORDDATUM": "Ordredato",
  "OH_ORDERNR": "Ordrenummer",
  "OH_ORDLEVADR1": "Ordre leveringsadresse 1",
  "OH_ORDLEVADR2": "Ordre leveringsadresse 2",
  "OH_ORDLEVADR3": "Ordre leveringsadresse 3",
  "OH_ORDLEVADR4": "By",
  "OH_ORDLEVADRLANDSKOD": "Land",
  "OH_ORDSTAT": "Ordrestatus",
  "OH_ORDSTAT_13": "Under registering",
  "OH_ORDSTAT_20": "Leveransen er påbegyndt",
  "OH_ORDSTAT_40": "Leveransen er påbegyndt",
  "OH_ORDSTAT_50": "Frigivet til fakturering",
  "OH_ORDSTAT_6": "Webbestilling under registering",
  "OH_ORDSTAT_70": "Faktureret",
  "OH_ORDSTAT_90": "Annuleret",
  "OH_ORDTYP": "Ordretype",
  "OH_UPPDRAGSKLASS": "Uppdragsklass",
  "OH_VREF": "Vår referanse",
  "OK_CONTINUE": "OK, fortsett",
  "OPEN_PREORDERS": "åpne forhåndsordre",
  "OPEN_REPEATORDERS": "åpne repeatordre",
  "OPTION": "Valg",
  "OPTION_IMAGE": "Alternativbilde",
  "OPTION_LINK": "Alternativlink",
  "OPTION_REQUIRED": "Obligatorisk valg, du må velge minst ett tillegg",
  "OPTION_TEXT": "Alternativ tekst",
  "ORDER": "Ordre",
  "ORDER_ACCORDING_TO_LEAD": "Forventes på lager",
  "ORDER_ACCORDING_TO_LEADTIME": "Forventet på lager",
  "ORDER_ANALYTICS": "Statistikk",
  "ORDER_ANALYTICS_BOTTOM_10": "10 Dårligste",
  "ORDER_ANALYTICS_SALES": "Salg",
  "ORDER_ANALYTICS_TOP_10": "Topp 10",
  "ORDER_DATA": "Ordredata",
  "ORDER_DATE": "Ordredato",
  "ORDER_DATE_FILTER_PLACEHOLDER": "Ordredato",
  "ORDER_DETAILS": "Ordredetaljer",
  "ORDER_DISCOUNT": "Ordrerabatt",
  "ORDER_FINDER": "Ordresøk",
  "ORDER_FINDER_SEARCH": "Søk etter prod./ordrenr.",
  "ORDER_HISTORY": "Ordrehistorikk",
  "ORDER_HISTORY_FILTER": "Filter",
  "ORDER_HISTORY_FILTER_PLACEHOLDER": "Ordrenummer, kundenavn, referansenummer",
  "ORDER_INFO": "Ordreinformasjon",
  "ORDER_INFORMATION": "Ordreinformasjon",
  "ORDER_NO": "Ordrenummer",
  "ORDER_NUMBER": "Ordrenummer",
  "ORDER_PARAMS": "Ordreparameter",
  "ORDER_POINT": "Bestillingspunkt",
  "ORDER_POINT_TOOLTIP": "Hvis lagersaldoen er lavere enn Bestillingspunkt, vil det opprettes påfyllingsordre til «Påfyllingsnivå» med levering ved neste levering",
  "ORDER_PROCESSING_INFO": "Ordren er mottatt og vil bli bekreftet. Når det er gjort, finner du ordren i ordrehistorikken, som vanlig.",
  "ORDER_PRODUCT": "Bestillingsvare",
  "ORDER_ROWS": "Ordrelinjer",
  "ORDER_ROWS_TO_PICK": "Ordrelinjer som skal plukkes",
  "ORDER_SEARCH": "Søk etter ordrenummer",
  "ORDER_SEASONS": "Ordresesonger",
  "ORDER_SETTINGS": "Ordreinnstillinger",
  "ORDER_SLASH_ROW": "Ordre/Rad",
  "ORDER_STATUS": "Ordrestatus",
  "ORDER_STATUS_WARNING_CANCELLED": "Annullert",
  "ORDER_STATUS_WARNING_ENTERED": "Registrert",
  "ORDER_STATUS_WARNING_ENTRY_IN_PROGRESS": "Under registering",
  "ORDER_STATUS_WARNING_QUOTE": "Tilbud",
  "ORDER_SUM": "Ordreverdi",
  "ORDER_SUMMARY": "Ordresammendrag",
  "ORDER_TYPE": "Ordretype",
  "ORDER_TYPES": "Ordretyper",
  "ORDER_VALUE": "Ordreverdi",
  "ORDER_WILL_DELIVER_IN_FULL": "OBS! Ordren leveres i sin helhet når alle varer er i lager.",
  "ORDERED": "Bestilt til lager",
  "ORDERROWS": "Ordrelinje",
  "ORDERS": "Ordre",
  "ORDERS_TO_PICK": "Ordre som skal plukkes",
  "ORG_NO": "Organisasjonsnummer",
  "ORGID": "Organisasjonsnummer",
  "ORIGINAL_PRICE": "Ordinær pris",
  "OTHER": "Øvrig",
  "OTHER_INFORMATION": "Annen informasjon",
  "OUR_REFERENCE": "Vår referanse",
  "OUT_OF_STOCK": "Ikke på lager",
  "OUTER_PACKAGE": "Ytterforpakning",
  "OUTLET": "Outlet",
  "OUTPUT_FILE_NAME": "Utdatafilnavn",
  "OUTPUT_FORMAT": "Utgående format",
  "OUTSTANDING": "Utestående",
  "OVER_THREE_MONTHS": "Over tre måneder",
  "OVERVIEW": "Oversikt",
  "PACK_SIZE": "Pakkestørrelse",
  "PACKAGE_SIZE": "Forpakningsstørrelse",
  "PACKAGING": "Emballasje",
  "PART_DELIVERED": "Dellevert",
  "PARTIAL": "Delvis",
  "PARTIALLY_DELIVERED": "Delvis levert",
  "PASSED": "Forfallsdato er passert",
  "PASSED_DUE": "Forfallsdato er passert",
  "PASSWORD": "Passord",
  "PASSWORD_HAS_BEEN_UPDATED": "Passord er oppdatert. Nå kan du logge inn",
  "PASSWORD_TOO_SHORT": "Passord er for kort, minst 10 tegn",
  "PAST_DUE": "Forfallsdato er passert",
  "PAYER": "Kundenummer",
  "PAYMENT_CUSTOMER": "Kundenummer",
  "PAYMENT_TERM": "Betalingsvilkår",
  "PAYMENT_TERMS": "Betalingsvillkår",
  "PCS": "stk",
  "PDF_CATALOG": "PDF Katalog",
  "PDF_CUSTOMER_SPECIFIC": "PDF - kundespesifikk",
  "PENDING_ORDER_HEADER": "Takk for din bestilling!",
  "PENDING_ORDER_INFO": "Din ordre bearbeides for øyeblikket og du kan se den i din ordrehistorikk innen få minutter.",
  "PER_PIECE": "per stk.",
  "PERIOD": "Periode",
  "PERSONAL_INFORMATION": "Personlig information",
  "PHONE": "Telefon",
  "PHONE1": "Telefon 1",
  "PHONE2": "Telefon 2",
  "PHONE3": "Telefon 3",
  "PICKED_QUANTITY": "Plukket antall",
  "PICKLIST": "Plukkliste",
  "PICKLISTS": "Plukkliste",
  "PIECES": "stk.",
  "PLACE_ORDER": "Opprett ordre",
  "PLEASE_LOGIN": "Hvis du allerede har en konto, vennligst logg inn",
  "PO_NO": "Bestillingsnummer",
  "POPULAR": "Populær",
  "POSITION_IN_EXPORT": "Posisjon i eksport",
  "POST_OFFICE": "Boks",
  "PREORDER": "Forhåndsordre",
  "PREVIOUS_ORDERS": "Tidligere bestillinger",
  "PREVIOUS_PAGE": "Forrige side",
  "PRICE": "Pris stk",
  "PRICE_ADJUSTMENT": "Prisjustering",
  "PRICE_ASC": "Pris Lav-Høy",
  "PRICE_DESC": "Pris Høy-Lav",
  "PRICE_LIST": "Prisliste",
  "PRICE_MODEL": "Prismodell",
  "PRICE_PER_ITEM": "Pris pr. stk",
  "PRICELIST": "Prisliste",
  "PRINT": "Skriv ut",
  "PRINTED": "Skrevet ut",
  "PRINTED_NOT_COMPLETED": "Skrevet ut, ikke ferdig",
  "PRIORITY": "Prioritet",
  "PROCARGS_LEVSATTKOD": "Leveringsmåte",
  "PROCARGS_LEVVILLKKOD": "Leveransevillkår",
  "PROCARGS_SALJARE": "Selger",
  "PRODUCT": "Produkt",
  "PRODUCT_ADDED_TO_CART": "Produktet er lagt til i handlekurven.",
  "PRODUCT_ALTERNATIVES": "Se alternativ",
  "PRODUCT_CATALOG": "Produkter",
  "PRODUCT_CATEGORY": "Produktkategori",
  "PRODUCT_DATELASTREADFROMERP": "ERP -> PIM",
  "PRODUCT_DATELASTWRITETOERP": "PIM -> ERP",
  "PRODUCT_DESCRIPTION": "Produktbeskrivelse",
  "PRODUCT_DETAILS": "Produktdetaljer",
  "PRODUCT_ERP_PRODUCTID": "ERP produkt-ID",
  "PRODUCT_EXPORT_CATEGORY_MAPPING": "Kartlegging av produkteksportkategori",
  "PRODUCT_FAST_SEARCH": "Produkter raskt søk",
  "PRODUCT_FILTER_PLACEHOLDER": "Søk i over 10000 produkter. Søk på artikkelnummer, artikkelnavn, leverandørnavn eller leverandørens artikkelnummer",
  "PRODUCT_GROUP": "Produktgruppe",
  "PRODUCT_INFO": "Produktinformasjon",
  "PRODUCT_INFO_SECONDARY": "Ytterligere informasjon",
  "PRODUCT_INFO_TECH": "Teknisk informasjon",
  "PRODUCT_LIST_FILTER_TOTAL": "Vis {{render}} av {{filter}} filtrert fra {{total}} resultater",
  "PRODUCT_LIST_TOTAL": "Vis {{render}} av {{total}} resultater",
  "PRODUCT_NAME": "Navn",
  "PRODUCT_NAME_OR_ID": "SKU-navn eller ID",
  "PRODUCT_RELATED_PRODUCTS": "Relaterte produkter",
  "PRODUCT_SEARCH_PLACEHOLDER": "Søk",
  "PRODUCT_SETTINGS": "Produktinnstillinger",
  "PRODUCT_VIDEO": "Video",
  "PRODUCTS": "Produkter",
  "PRODUCTS_MISSING_QUANTITIES": "Produkter under mangler tilstrekelig antall",
  "PRODUCTS_NOT_ALLOWED_FOR_FLOW": "Følgende artikler er ikke tillatt for ordretype:",
  "PROPERTY": "Egenskaper",
  "PUBLISHED_GROUP": "Målgruppe",
  "PURCHASE_ORDER": "Innkjøpsordre",
  "PURCHASE_ORDER_DETAILS": "Innkjøpsordredetaljer",
  "PURCHASE_STATUS_1": "Innkjøpsordre er sendt",
  "PURCHASE_STATUS_2": "Innkjøpsordre er mottatt",
  "PURCHASE_STATUS_5": "Innkjøpsordre er godkjent",
  "PURCHASE_STATUS_6": "Klar til levering",
  "PURCHASE_STATUS_7": "Sendt",
  "PUSH_DELIVERY_DATE": "a",
  "QUALITY_100_1000": "Kvalitet (100-1000)",
  "QUANTITY": "Antall",
  "QUANTITY_AND_PRICE": "Antall og pris/stk.",
  "QUANTITY_AVAILABLE": "Tilgjengelig",
  "QUANTITY_IN_STOCK": "Antall på lager",
  "QUANTITY_REMAINING": "Antall gjenstående",
  "QUARTER": "Kvartal",
  "QUICK_ORDER": "Hurtigordre",
  "QUICKORDER": "Hurtigordre",
  "QUOTE": "Tilbud",
  "QUOTE_DATE": "Tilbudsdato",
  "QUOTE_DETAILS": "Tilbudsdetaljer",
  "QUOTE_HISTORY": "Tilbudshistorikk",
  "QUOTE_ID": "Tilbudsnummer",
  "QUOTE_INFO": "Tilbud info",
  "QUOTE_STATUS": "Tilbudsstatus",
  "QUOTE_SUM": "Tilbudssum",
  "QUOTES": "Tilbud",
  "READ_MORE": "Les mer",
  "REDUCE_AMOUNT_IN_ORDER": "Minstre antall på ordren",
  "REFERENCE": "Referanse",
  "REFERENCE_NO": "Referansenummer",
  "REGION": "Region",
  "REGISTERED": "Registrert",
  "RELATED_PRODUCTS": "Relaterte produkter",
  "REMAINING": "Resterende",
  "REMEMBER_TO_BUY": "Husk å kjøpe",
  "REMEMBER_TO_SAVE_CART": "Husk å spare din handlevogn om du vil beholde den innen du laster ned en ny",
  "REMOVE": "Ta bort",
  "REMOVE_AND_CONTINUE_CHECKOUT": "Fjern, og fortsett utsjekk",
  "REMOVE_BEFORE_CONTINUING": "Alle artikler i listen må fjernes før utsjekk",
  "REMOVE_FROM_ORDER": "Ta bort fra ordre",
  "REMOVE_MODEL_DISCOUNT": "Fjern modellrabatt",
  "REMOVE_MODEL_PRICE": "Fjern prisjustering på modellen",
  "REMOVE_PRODUCT": "Fjern produkt",
  "RENT": "Hyra",
  "REPEATORDER": "Repeatordre",
  "REPLACE_CURRENT_ORDER": "Bytt ut gjeldende ordre",
  "REQUEST_ACCOUNT": "Ny bruker",
  "REQUEST_ACCOUNT_INFO": "Dette er for de som allerede er kunde hos oss, men ønsker å være i stand til å plassere ordre fra nettbutikken vår.",
  "REQUEST_INVALID_FORM": "Alle felt må være fylt inn",
  "REQUEST_LOGIN": "Søk om konto/Logg inn",
  "REQUESTED_DELIVERY_DATE": "Ønsket sendningsdato",
  "REQUESTED_DELIVERYDATE": "Ønsket sendningsdato",
  "REQUESTED_QUANTITY": "Antall",
  "REQUESTING": "Kontoforespørsel",
  "REQUIRED": "Obligatorisk",
  "RESET": "Nullstille",
  "RESET_PASSWORD": "Tilbakestill passord",
  "RESET_TOKEN": "Tilbakestillingskode",
  "RESET_TOKEN_FROM_EMAIL": "Tilbakestill fra e-post",
  "RESPONSIBLE": "Ansvarlig",
  "RESTOCK": "Påfyllingsnivå",
  "RESTOCK_TOOLTIP": "Hvis saldo er lavere enn Bestillingspunkt, opprettes påfyllingsordre opptil Påfyllingsnivå",
  "RESULT": "RESULT",
  "RETAIL_PRICES_ONLY": "Kun Retailpriser",
  "RETURN": "Retur",
  "RETURN_ORDER": "Returordre",
  "REVERT_ALL_AND_FINISH": "Tilbakestill alle linjer, og avslutt",
  "REVERT_PICKLIST": "Reverser plukkliste",
  "ROLES": "Ruller",
  "ROW": "Linje",
  "ROW_INDEX": "Linjenr",
  "ROWS": "Rader",
  "SALES": "Selger",
  "SALES_ACTUAL_YR": "Salg inneværende år",
  "SALES_CALCULATION": "Salgskalkyle",
  "SALES_CONDITIONS": "Salgsvillkår",
  "SALES_DASHBOARD": "Salg Dashbord",
  "SALES_PREV_YR": "Salg forrige år",
  "SALES_TERMS": "Salgsvillkår",
  "SALESPERSON": "Selger",
  "SAVE": "Lagre",
  "SAVE_BACK": "SAVE_BACK",
  "SAVE_CART": "Lagre handlevogn",
  "SAVE_CART_MODAL_INFO_TEXT": "Lagre handlekurven for å kunne få tilgang til det på et senere tidspunkt",
  "SAVE_CHANGES": "Lagre endringer",
  "SAVED_CART": "Lagret handlekurv",
  "SAVED_CARTS": "Lagrede handlevogner",
  "SCHEDULERS": "Planleggere",
  "SE_DESCRIPTION": "Svensk beskrivelse",
  "SEARCH": "Søk",
  "SEARCH_CONTACTS_PLACEHOLDER": "Søk etter kontaktpersoner",
  "SEARCH_ORDER": "Søk etter ordre",
  "SEARCH_ORDER_ROW": "Søk etter ordrelinje",
  "SEARCH_PRODUCT": "Søk produkt",
  "SEARCH_PRODUCT_FIRST": "Vennligst søk på et produkt først",
  "SEARCH_PRODUCT_NAME_OR_ID": "Søk med artikkelens navn og/eller id",
  "SEARCH_PRODUCTS": "Søk produkter",
  "SEARCH_PRODUCTS_PLACEHOLDER": "Søk i over 10000 produkter. Søk på artikkelnummer, artikkelnavn, leverandørnavn eller leverandørens artikkelnummer",
  "SEARCH_SUPPLIER": "Søk etter leverandør",
  "SEASON": "Sesong",
  "SEASON_ORDER": "Sesong (ordre)",
  "SEASON_PRODUCT": "Sesong (produkt)",
  "SECONDARYDIMENSION": "STØRRELSE",
  "SECONDS": "sekunder",
  "SELECT_ALL": "Marker alle",
  "SELECT_CONTACT": "Velg kontakt",
  "SELECT_CUSTOMER": "Velg kunde",
  "SELECT_CUSTOMER_MODAL_TITLE": "Kundeinformasjon",
  "SELECT_DELIVERY_ADDRESS": "Velg leveringsadresse",
  "SELECT_FILE": "Velg fil",
  "SELECT_FILES": "Velg fil (er)",
  "SELECT_FLOW": "Velg ordretype",
  "SELECT_OPTION": "Velg",
  "SELECT_OPTIONS": "Velg tilvalg",
  "SELECT_ORDER_STATUS": "Velg ordrestatus",
  "SELECT_SALESPERSON": "Velg selger",
  "SELECT_WORKFLOW": "Velg ordretype",
  "SELECTED": "Valgt",
  "SELECTED_CUSTOMER": "Valgt kunde",
  "SELECTED_CUSTOMER_CONTINUE": "Vil du fortsette eller endre kunde til",
  "SELECTED_CUSTOMER_OPTION": "Den valgte kunden er",
  "SELECTED_DELIVERY_METHOD_FREIGHT_FREE": "Valgt leveringsmåte er fraktfritt",
  "SELECTED_PRODUCT": "Valgt produkt",
  "SELECTEDBRAND": "Velg merke",
  "SELECTEDMODEL": "Velg modell",
  "SEND": "Send",
  "SEND_LINK_TO_RESET": "Send tilbakestillingslink",
  "September": "September",
  "SET_DEFAULT": "Sett som obligatorisk",
  "SHIPPING_INFORMATION": "Leveringsinformasjon",
  "SHORT_DESCRIPTION": "Kort beskrivelse",
  "SHOW": "Vise",
  "SHOW_ACCOUNT_FORM": "Allerede kunde – Opprett konto her",
  "SHOW_ADDRESS": "Vise leveringsadresse",
  "SHOW_CATEGORIES": "Vis kategorier",
  "SHOW_CUSTOMER": "Vis kunde",
  "SHOW_DETAILS": "Vis detaljer",
  "SHOW_INVOICE": "Se faktura",
  "SHOW_MORE": "Vis mer",
  "SHOW_ORDER": "Se ordre",
  "SHOW_PRICE": "Vis pris",
  "SHOW_QUOTE": "Vis tilbud",
  "SHOW_SIZES": "Vis størrelser",
  "SHOW_STOCK": "Vis lagerstatus",
  "SHOWING": "Viser",
  "SHOWING_X_RESULTS": "Viser {{resultCount}} resultater.",
  "SIGN_OUT": "Logg ut",
  "SIMPLE": "Enkle innstillinger",
  "SIZE": "Mål",
  "SORT": "Sortering",
  "SORT_BY": "Sortering",
  "SORTING": "Sortering",
  "SPECIFICATION": "Spesifikasjon",
  "START_DATE": "Startdato",
  "START_PICKING": "Plukkestart",
  "START_TIME": "Starttid",
  "STATE": "State",
  "STATISTICS": "Statestikk",
  "STATUS": "Status",
  "STEP": "Trinn",
  "STJERNA_EXISTING_COLORS": "Finnes i følgende farger",
  "STJERNA_EXISTING_DIMENSIONS": "Finnes i følgende størrelser",
  "STOCK": "Lagersaldo",
  "STOCK_ID": "Lagernummer",
  "STOCKORDER": "Lagerordre",
  "STOCKS": "Lager",
  "STYLE": "Variant",
  "SUB": "Delsum",
  "SUB_CATEGORY_1": "Mellomkategori",
  "SUB_CATEGORY_2": "Underkategori",
  "SUBMIT": "Send",
  "SUBMIT_ORDER": "Send ordre",
  "SUBMIT_QUOTE": "Lagre tilbud",
  "SUBMITTING": "Legg til",
  "SUBSCRIBE_TO_NEWS": "Abboner på nyhetsbrev",
  "SUBTOTAL": "Delsum",
  "SUGGESTED_PASSWORD": "Foreslått passord",
  "SUMMARY": "Summering",
  "SUMMER": "Sommer",
  "SUPER_GROUP": "Super Group",
  "SUPPLIER": "Leverandør",
  "SUPPLIER_ADDRESS": "Leverandøradresse",
  "SYSTEM": "System",
  "TAG": "Tagg",
  "TAG_PLACEHOLDER": "Tagg",
  "TECHNICAL_DATA_IMPORT": "Importer teknisk data",
  "TECHNICAL_ERROR": "Teknisk feil",
  "TECHNICAL_INFORMATION": "Teknisk informasjon",
  "TEMPLATES": "Maler",
  "TERMS": "Villkår",
  "TEXT_NOT_ENABLED": "Tekst - ikke aktivert",
  "TEXTS": "Tekster",
  "THANK_YOU_FOR_YOUR_ORDER": "Takk for bestillingen!",
  "THE_COMPANY": "Firma",
  "THIS_CART_WILL_BE_DELETED": "Handlevognen vil bli slettet",
  "THIS_MONTH": "Denne måneden",
  "THIS_WEEK": "Denne uken",
  "THREE_MONTHS_OR_MORE": "> Tre måneder",
  "TILLBAKA": "Tilbake",
  "TIME": "Tid",
  "TIP": "TIP",
  "TITLE": "Tittel",
  "TO_BE_ORDERED": "Bestillingsvare",
  "TODAY": "I dag",
  "TOKEN_SENT": "Koden sendes til din e-post. Har du ikke fått koden innen 2 minutter, så se om den har havnet i Søppelpost.",
  "TOKEN_SENT_ALERT": "Bli på denne siden",
  "TOOLS": "Verktøy",
  "TOTAL": "Totalt",
  "TOTAL_EXCL_VAT": "Beløp eksl.mva",
  "TOTAL_INC_VAT": "Beløp",
  "TOTAL_INCL_EXTRAS": "Totalt, inkl. ekstrakostnader",
  "TOTAL_PRICE": "Totalt",
  "TOTAL_QUANTITY": "Totalt antall",
  "TOTAL_UNITS": "Totalt antall",
  "TOTAL_VALUE": "Total verdi",
  "TOTALS_CURRENCYCODE": "Valuta",
  "TOTALS_ORDERVALUEAFTERDISCOUNTS": "Verdi etter rabatt",
  "TOTALS_TOTALDISCOUNT": "Total rabatt",
  "TOTALS_TOTALEXCLVAT": "Totalt ekskl. mva",
  "TOTALS_TOTALFREIGHT": "Frakt",
  "TOTALS_TOTALHEADERDISCOUNT": "Ordrerabatt",
  "TOTALS_TOTALHEADERDISCOUNTPERCENT": "Rabatt %",
  "TOTALS_TOTALINCLVAT": "Totalt inkl. mva",
  "TOTALS_TOTALINCLVATINCLFREIGHT": "Totalt inkl. skatt og tillegg",
  "TOTALS_TOTALITEMS": "Totalt antall",
  "TOTALS_TOTALROWDISCOUNT": "Linjerabatt",
  "TOTALS_TOTALROWVALUE": "Total linjeverdi",
  "TOTALS_TOTALVAT": "Mva-beløp",
  "TOTALS_TOTALVATINCLFREIGHT": "Totalt inkl. tillegg",
  "TRIGGER_NOW": "Trigger nå",
  "TRIGGERS": "Triggers",
  "TRUE": "Ja",
  "UNABLE_TO_COMPLETE_IMPORT": "Importen kunne ikke utføres",
  "UNDETERMINED_DELIVERY": "Ukjent leveringstid",
  "UNIT": "Enhet",
  "UNIT_PRICE": "Enhetspris",
  "UNSAVED": "ufrelste",
  "UPDATE": "Oppdater",
  "UPDATE_EVERY": "Oppdatert hver",
  "UPDATE_PURCHASE_STATUS_1": "Skriv ut innkjøpsordre",
  "UPDATE_PURCHASE_STATUS_2": "Godkjenn innkjøpsordre",
  "UPDATE_PURCHASE_STATUS_5": "Merk som «klar til levering»",
  "UPDATE_PURCHASE_STATUS_6": "Merk som «sendt»",
  "UPLOAD": "Last ned",
  "UPLOAD_EXCEL_ORDERSHEET": "Last opp Excel bestillingsark",
  "UPLOAD_FORMAT": "Last opp format",
  "USE": "Benytt",
  "USER": "Bruker",
  "USERNAME": "Brukernavn",
  "USERNAME_YOUR_EMAIL": "Brukernavn (din e-postadresse)",
  "USERS": "Brukere",
  "WAITING": "Waiting",
  "VALIDATE_FORM_BEFORE_SUBMIT": "Fyll inn alle obligatoriske felt (*) først",
  "VALIDATING_USERNAME": "Validerer brukernavn",
  "VALUE": "Verdi",
  "VALUE_IN_STOCK": "lagerverdi",
  "VARIANT": "Variant",
  "WARNING_PRODUCTS_IN_CART": "Du har produkter i din handlevogn, om du fortsetter kommer disse å erstattes",
  "VAT": "Moms",
  "VAT_NO": "Organisasjonsnummer",
  "WEB_DISCOUNT": "Webbrabatt",
  "WEBORDER": "Webbestilling under registering",
  "WEBORDER_UNDER_REGISTRATION": "Under registering",
  "WEEK": "Uke",
  "WELCOME": "Velkommen",
  "WELCOME_START": "Velkommen",
  "WHOLESALE_PRICES_ONLY": "Kun Wholesalepriser",
  "WINTER": "Vinter",
  "VISIBLE_IN_MENU": "Synlig i menyen",
  "XR_LAND": "Land",
  "YES": "Ja",
  "YOUR_CART_HAS_BEEN_SAVED": "Handlevognen har blitt lagret med id:",
  "YOUR_EMAIL": "din@e-post.no",
  "YOUR_ORDER": "Din ordre",
  "YOUR_PO_NUMBER": "Ditt bestillingsnummer",
  "YOUR_PRICE": "Kun pris",
  "YOUR_REFERENCE": "Din referanse",
  "YOUR_REFERENCE_NO": "Kun referansenummer",
  "Z_OTHERS": "Øvrig",
  "ZIP": "Postnr",
  "ZIP_CITY": "Postnr / By",
  "GRAVITY_SCOOP_BINS": "Løsvektbokser",
  "DISPLAY_ACCESSORIES": " Displaytilbehør",
  "SHELF_MANAGEMENT_AUTOMATIC": "Hylleorganisering - automatisk fronting",
  "LIGHTING_POWER_INFRASTRUCTURE": "Lys og strøm - infrastruktur",
  "PRICE_COMMUNICATION": "Priskommunikasjon",
  "FRESH_PRODUCE_SOLUTIONS": "Løsninger frukt og grønt",
  "PROTECTION_SAFETY_EQUIPMENT": "Helse- og sikkerhetsutstyr",
  "SHELF_MANAGEMENT_MANUAL": "Hylleorganisering - manuelt",
  "VIEW_ALL_PRODUCTS": "Vis alle produktene",
  "MUSKETEERS_SELECTION": "Musketører utvalg",
  "PRESENTOIRS_PANIERS": "PRESENTOIRS_PANIERS",
  "STORE_COMMUNICATION_BRANDING": "Butikkommunikasjon og hyllemarkører",
  "FAQ": "FAQ",
  "FAQ_ACCESS_PORTAL_QUERY": "Hvordan får jeg tilgang til/aktiverer jeg min konto?",
  "FAQ_ACCESS_PORTAL_RESPONSE": "En e-post har blitt sent til din mailadresse med  brukernavn og passord. Har du ikke mottatt en slik mail eller er en ny butikk, vennligst kontakt vår kundeserviceavdeling. Informasjon finner du under <a href='/contact'>Kontakt oss</a>.",
  "FAQ_ACCOUNT_INFO_QUERY": "Hvordan finner jeg informasjon om min konto?",
  "FAQ_ACCOUNT_INFO_RESPONSE": "All informasjon om din konto ligger under",
  "FAQ_MES_DONNEES": "Mine sider.",
  "FAQ_CATALOUGE_QUERY": "Er det mulig å se alle deres produkter i en katalog?",
  "FAQ_CATALOUGE_RESPONSE": "Følg denne <a target='_BLANK' href='https://www.hl-display.com/no/vart-tilbud/katalog/'>linken</a> til vår webside for å bla i vår digitale katalog, hvor alle våre produkter er avbildet.",
  "FAQ_FORGOT_PASSWORD_QUERY": "Hva gjør jeg hvis jeg har glemt passordet?",
  "FAQ_FORGOT_PASSWORD_RESPONSE": "Trykk 'Glemt passord' eller kontakt vår kundeserviceavdeling. Informasjon finner du under <a href='/contact'>Kontakt oss</a>.",
  "FAQ_INVOICE_QUERY": "Er det mulig å printe ut min faktura?",
  "FAQ_INVOICE_RESPONSE": "Vennligst kontakt vår kundeserviceavdeling. Informasjon finner du under <a href='/contact'>Kontakt oss</a>.",
  "FAQ_ORDER_HISTORY_QUERY": "Hvordan finner jeg informasjon om mine tidligere bestillinger?",
  "FAQ_ORDER_HISTORY_RESPONSE": "All informasjon om din nåværende og tidligere bestillinger er lagret under",
  "FAQ_ORDER_MODIFICATION_QUERY": "Hvordan endrer eller kansellerer jeg min bestilling?",
  "FAQ_ORDER_MODIFICATION_RESPONSE": "Vennligst kontakt vår kundeserviceavdeling. Informasjon finner du under <a href='/contact'>Kontakt oss</a>.",
  "FAQ_ORDER_PLACEMENT_QUERY": "Hvordan legger jeg en bestilling?",
  "FAQ_ORDER_PLACEMENT_RESPONSE": "Gå til produktutvalget og legg produkter i din handlekurv. Følg instruksjonene skritt for skritt for å bekrefte din bestilling. Er du usikker på noe, vennligst kontakt vår kundeserviceavdeling eller salgsavdeling, og vi vil hjelpe deg videre. Informasjon finner du under <a href='/contact'>Kontakt oss</a>.",
  "FAQ_ORDER_TRACKING_QUERY": "Hvordan sporer jeg min bestilling?",
  "FAQ_ORDER_TRACKING_RESPONSE": "All informasjon om din nåværende og tidligere bestillinger er lagret under",
  "FAQ_PAYMENT_POLICY_QUERY": "Hva er deres leveringsvilkår?",
  "FAQ_PAYMENT_POLICY_RESPONSE": "Fraktfritt over:<br>Fraktkostnad:<br>Minimum ordreverdi:<br>Administrasjonsgebyr:",
  "FAQ_PAYMENT_POLICY_RESPONSE_COOP": "Frakt tilkommer.",
  "FAQ_PAYMENT_POLICY_RESPONSE_NORGE_GROUP_SPAR": "Administrasjonsgebyr: Kr 200 tilkommer ved bestillinger under kr 2500",
  "FAQ_PAYMENT_POLICY_RESPONSE_REITA_GROUP": "Administrasjonsgebyr: Kr 300 tilkommer ved bestillinger under kr 2500",
  "FAQ_PAYMENT_SAMPLE_QUERY": "Hvordan bestiller jeg en prøve?",
  "FAQ_PAYMENT_SAMPLE_RESPONSE": "Vennligst kontakt vår kundeserviceavdeling. Informasjon finner du under <a href='/contact'>Kontakt oss</a>.",
  "FAQ_PAYMENT_TERMS_QUERY": "Hva er deres betalingsbetingelser?",
  "FAQ_PAYMENT_TERMS_RESPONSE": "Våre betalingsbetingelser er 45 dager. Fullstendig informasjon om våre betingelser finnes <a href='/contact'>her</a>.",
  "FAQ_RETURNS_QUERY": "Hvordan kan jeg returnere min bestilling?",
  "FAQ_RETURNS_RESPONSE": "Vennligst kontakt vår kundeserviceavdeling. Informasjon finner du under <a href='/contact'>Kontakt oss</a>.",
  "FAQ_TECH_FITTING_QUERY": "Hvilke produkter er passende for min butikk?",
  "FAQ_TECH_FITTING_RESPONSE": "Vennligst kontakt salgsansvarlig i ditt distrikt eller vår kundeserviceavdeling. De vil hjelpe deg med å finne de beste løsningene til ditt behov. Informasjon finner du under <a href='/contact'>Kontakt oss</a>.",
  "FAQ_TECH_MEASUREMENT_QUERY": "Hvordan tar jeg riktige mål?",
  "FAQ_TECH_MEASUREMENT_RESPONSE": "Vennligst kontakt salgsansvarlig i ditt distrikt eller vår kundeserviceavdeling. De vil hjelpe deg med å finne de beste løsningene til ditt behov. Informasjon finner du under <a href='/contact'>Kontakt oss</a>.",
  "FAQ_TIME_EST_QUERY": "Hva er estimert leveringstid?",
  "FAQ_TIME_EST_RESPONSE": "Estimert leveringstid avhenger av hvilke artikler du har bestilt, men vi leverer normalt innen 5-10 arbeidsdager.",
  "FAQ_PRINT_ORDER_CONFIRMATION_QUERY": "Hvordan får jeg bestillingsbekreftelsen?",
  "FAQ_PRINT_ORDER_CONFIRMATION_RESPONSE": "En ordrebekreftelse vil bli sendt til deg via e-post så snart den er validert.",
  "MOUSQUETAIRES_SELECTION": "Musketører utvalg",
  "MOUSQUETAIRES_FILTER_SELECTED": "Utvalg",
  "CAPACITY": "Format",
  "CHANGE_DATE_ON_ALL_ROWS": "Velg dato for alle radene",
  "AVAILABLE_SHORT": "Til.",
  "TOP_PURCHASED_TITLE_PHARMA": "Mest kjøpte produkter",
  "CHECKOUT_CONDITIONS_LINK": "Vilkår og betingelser",
  "CAMPAIGN_CODE": "Velkomstkode",
  "FREIGHT_INFORMATION_COOP_CUSTOMER": "<span class='highlight'>Frakt: </span> vil bli lagt til senere",
  "ADMIN_FEE_INFORMATION_NORGE_GROUP_SPAR_PORTAL": "<span class='highlight'>Admin: </span>Kr 200 tilkommer ved bestillinger under kr 2500",
  "ADMIN_FEE_INFORMATION_REITA_GROUP_PORTAL": "<span class='highlight'>Admin: </span>Kr 300 tilkommer ved bestillinger under kr 2500",
  "FREIGHT_INFORMATION_NO_CUSTOMER": "<span class='highlight'>Fraktkostnad: </span>Kr {{value}} hvis bestillingsverdien er under kr {{min}}.",
  "ADMIN_FEE_INFORMATION_NO_CUSTOMER": "<span class='highlight'>Admin: </span>Kr {{value}} tilkommer ved bestillinger under kr {{min}}.",
  "CART_ITEM_QTY_ABOVE_AVAILABLE_QTY": "<span class='quantity-above-than-available'>*</span> Det ønskede antall for en eller flere artikler er ikke tilgjengelig. Det angitte leveringstidspunktet tar hensyn til påfylling av den aktuelle vare.",
  "IN_STOCK_BELOW_REQUESTED_QTY": "Lavt antall på lager",
  "CUSTOMER_PORTAL_OH_ORDLEVADR1": "Navn",
  "CUSTOMER_PORTAL_OH_ORDLEVADR2": "C/O",
  "CUSTOMER_PORTAL_OH_ORDLEVADR3": "Gateadresse",
  "CUSTOMER_PORTAL_OH_ORDLEVADR4": "Poststed",
  "CUSTOMER_PORTAL_CITY": "Poststed",
  "CUSTOMER_PORTAL_ADDRESS2": "Gateadresse",
  "CUSTOMER_PORTAL_ADDRESS1": "C/O",
  "OLD_PRODUCT_IMAGE": "Ikke tilgjengelig",
  "CARRIER_CODE": "Transportkode",
  "WARNING_NO_USE_EXPLORER": "For å få den beste brukeropplevelsen bruk Chrome, Firefox, Safari eller Edge. Vennligst unngå Microsoft Explorer som din nettelser",
  "SEND_PASSWORD_RESET": "Tilbakestille passord",
  "SET_PASSWORD": "Endre passord",
  "RESET_PASSWORD_REQUEST_SENT": "Endring av passord er sendt",
  "PASSWORD_CONFIRM": "Bekreft passord",
  "EMAIL_INVOICE": "E-post faktura",
  "EMAIL_QUOTE": "E-post tilbud",
  "EMAIL_QUOTE_TEMPORARY": "E-posttilbud midlertidig *",
  "MESSAGE_EMAIL_QUOTE_TEMP": "Informasjon som legges til i dette feltet vil være e-posten tilbudet sendes til. Ved å la dette stå tomt vil tilbudet bli sendt til \"E-posttilbud\".",
  "SEND_QUOTE": "SEND_QUOTE",
  "OUTSIDE_ASSORTMENT": "Utenfor sortiment",
  "BLOCKED": "Blokkert",
  "OFFERS": "Offers",
  "ADD_MEETING": "Add meeting",
  "MEETING_DATE": "Meeting date",
  "TRACKING_EMAIL": "Sporer e-post",
  "TRACKING_EMAIL_TEMP": "Sporing av e-posttemp",
  "TRACKING_LINK_TEMP_EMAIL": "Bruk hvis kunden ønsker at sporingslenken skal sendes til en midlertidig e-post",
  "GOODS_LABEL": "Varemerke",
  "CREATE_QUOTE": "Lage sitat",
  "SAMPLE_ORDER": "Send sample order",
  "SAMPLE": "Sample",
  "OFFER": "Offer",
  "PURCHASED": "Purchased",
  "DURATION": "Varighet",
  "HOURS": "Timer",
  "PORTE_ETIQUETTE": "Etiketthållare",
  "IMPULSE_PROMOTION": "Impulse & Promotion",
  "CONTACT_NAME": "Navn",
  "EMAIL_QUOTE_ORDER_CONFIRMATION": "E-posttilbud / ordrebekreftelse",
  "EMAIL_QUOTE_ORDER_CONFIRMATION_TEMPORARY": "E-posttilbud / ordrebekreftelse - midlertidig *",
  "EMAIL_QUOTE_TEMPORARY_REQUIRED": "E-posttilbud / ordrebekreftelse - midlertidig felt påkrevd",
  "CREDIT_BLOCKED_MODAL_HTML": "Kunden <b>{{customerName}}</b> har sperret kreditt, så det vil ikke være mulig å legge inn noen bestillinger.",
  "CREDIT_SOFT_BLOCKED_MODAL_HTML": "Kunden <b>{{customerName}}</b> vil kun kunne sende inn tilbud fordi handlekurvens gjeldende verdi er {{total}} {{currency}} og gjenværende kreditt er {{credit}} {{currency}}.",
  "CREDIT_SOFT_BLOCKED": "Kun tilbud tilgjengelig",
  "EMAIL_QUOTE_TEMPORARY_INVALID": "Ugyldig e-post oppgitt!",
  "ORDER_TITLE": "Ordre",
  "CREDIT_BLOCKED_MODAL_HTML_1210_FI": "Bestillingen kan dessverre ikke lagres, vennligst kontakt kundeservice: info.fi@hl-display.com / +358 9 562 9180",
  "CREDIT_BLOCKED_MODAL_HTML_1210_SE": "Bestillingen kan dessverre ikke lagres, vennligst kontakt kundeservice: info.se@hl-display.com / +46 8 683 7363",
  "CREDIT_BLOCKED_MODAL_HTML_1210_NO": "Bestillingen kan dessverre ikke lagres, vennligst kontakt kundeservice: info.no@hl-display.com / +47 66 983 600",
  "CREDIT_BLOCKED_MODAL_HTML_1810_DE": "Bestillingen kan dessverre ikke lagres, vennligst kontakt kundeservice: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_2400_CH": "Bestillingen kan dessverre ikke lagres, vennligst kontakt kundeservice: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_1600_FR_OR_NL": "Helaas hebben wij uw bestelling niet kunnen valideren. Kunt u contact opnemen met onze klantenservice op 02 47 48 85 00? Bij voorbaat bedankt.",
  "CREDIT_BLOCKED_MODAL_HTML_1600_BE": "Helaas hebben wij uw bestelling niet kunnen valideren. Kunt u contact opnemen met onze klantenservice op 02 47 48 85 00? Bij voorbaat bedankt.",
  "CREDIT_BLOCKED_MODAL_HTML_CUSTOMER_SERVICE": "Bestillingen kan dessverre ikke lagres, vennligst kontakt kundeservice: {customerServiceEmail} / {customerServicePhoneNumber}",
  "EMAIL_ORDER_CONFIRMATION_TEMPORARY": "Bestillingsbekreftelse på e-post",
  "MESSAGE_EMAIL_ORDER_CONFIRMATION_TEMP": "Informasjonen som legges til i dette feltet vil være e-posten ordrebekreftelsen sendes til. Hvis denne står tom, vil ordrebekreftelsen bli sendt til standard e-post.",
  "INTERNAL_CREDIT_BLOCKED_MODAL_HTML": "Bestillingen kan dessverre ikke lagres, vennligst kontakt kundeservice",
  "BASECOST_S_U": "Basecost S / U",
  "PRODUCT_LIST": "Product list",
  "SEE_YOUR_AGREED_ASSORTMENT_AND_PRICES": "See your agreed assortment and prices",
  "FIND_YOUR_PREVIOUS_ORDERS_TWO_YEAR_BACK": "Find your previous orders two year back",
  "GO_TO_YOUR_FAVOURITE_PRODUCTS": "Go to your favourite products",
  "FIND_OUR_CONTECT_INFORMATION_BY_CLICKING_HERE": "Find our contect information by clicking here",
  "HELP_US_IMPROVE": "Help us improve",
  "IF_YOU_WANT_TO_SEND_US_FEEDBACK_OR_HAVE_IDEAS_FOR_IMPROVEMENTS": "If you want to send us feedback or have ideas for improvements on the portal, please use this form.",
  "NEW_FREIGHT_INFORMATION": "<span class='highlight'>Fraktkostnad:</span> {{value}} {{currency}} hvis ordreverdien er under {{min}} {{currency}}",
  "NEW_MINIMUM_ORDER_INFORMATION": "<span class='highlight'>Minste bestillingsverdi:</span> {{value}} {{currency}}",
  "NEW_ADMIN_FEE_INFORMATION": "<span class='highlight'>Administrative kostnader:</span> {{value}} {{currency}} for enhver bestilling mindre enn {{min}} {{currency}}",
  "NEW_CUSTOM_FREIGHT_INFORMATION": "<span class='highlight'>Fraktkostnad:</span> {{value}} {{currency}} hvis ordreverdien er under {{min}} {{currency}}"
}
