import { Component as t, createElement as e } from "../../../external/preact/dist/preact.js";
import s from "./PaymentMethod/PaymentMethodList.js";
import o from "./status/index.js";
import n from "../../../core/Services/order-status.js";
import { sanitizeOrder as a } from "../../internal/UIElement/utils.js";
import { ANALYTICS_RENDERED_STR as r } from "../../../core/Analytics/constants.js";
import i from "../../../core/Errors/AdyenCheckoutError.js";
function d(t, e, s) {
  return e in t ? Object.defineProperty(t, e, {
    value: s,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[e] = s, t;
}
class h extends t {
  componentDidMount() {
    this.prepareDropinData();
  }
  get analyticConfigData() {
    return {
      openFirstStoredPaymentMethod: this.props.openFirstStoredPaymentMethod,
      showStoredPaymentMethods: this.props.showStoredPaymentMethods
    };
  }
  componentDidUpdate(t, e) {
    e.status.type !== this.state.status.type && this.state.activePaymentMethod && this.state.activePaymentMethod.setStatus(this.state.status.type), "ready" === this.state.status.type && "ready" !== e.status.type && this.props.onReady && this.props.onReady();
  }
  closeActivePaymentMethod() {
    this.setState({
      activePaymentMethod: null
    });
  }
  render(t, {
    elements: n,
    instantPaymentElements: a,
    storedPaymentElements: r,
    status: i,
    activePaymentMethod: d,
    cachedPaymentMethods: h
  }) {
    const p = "loading" === i.type,
      m = "redirect" === i.type,
      c = n?.length || a?.length || r?.length;
    switch (i.type) {
      case "success":
        return e(o.Success, {
          message: 0 === t?.amount?.value ? "resultMessages.preauthorized" : i.props?.message
        });
      case "error":
        return e(o.Error, {
          message: i.props?.message
        });
      case "custom":
        return i.props?.component?.render();
      default:
        return e("div", {
          className: `adyen-checkout__dropin adyen-checkout__dropin--${i.type}`
        }, m && i.props.component && i.props.component.render(), p && i.props && i.props.component && i.props.component.render(), !!c && e(s, {
          isLoading: p || m,
          isDisablingPaymentMethod: this.state.isDisabling,
          paymentMethods: n,
          instantPaymentMethods: a,
          storedPaymentMethods: r,
          activePaymentMethod: d,
          cachedPaymentMethods: h,
          order: this.props.order,
          orderStatus: this.state.orderStatus,
          onOrderCancel: this.onOrderCancel,
          onSelect: this.handleOnSelectPaymentMethod,
          openPaymentMethod: this.props.openPaymentMethod,
          openFirstPaymentMethod: this.props.openFirstPaymentMethod,
          openFirstStoredPaymentMethod: this.props.openFirstStoredPaymentMethod,
          onDisableStoredPaymentMethod: this.handleDisableStoredPaymentMethod,
          showRemovePaymentMethodButton: this.props.showRemovePaymentMethodButton,
          showRadioButton: this.props.showRadioButton
        }));
    }
  }
  constructor(...t) {
    super(...t), d(this, "state", {
      elements: [],
      instantPaymentElements: [],
      storedPaymentElements: [],
      orderStatus: null,
      isDisabling: !1,
      status: {
        type: "loading",
        props: void 0
      },
      activePaymentMethod: null,
      cachedPaymentMethods: {}
    }), d(this, "prepareDropinData", () => {
      const {
          order: t,
          clientKey: e,
          loadingContext: s
        } = this.props,
        [o, a, i] = this.props.onCreateElements(),
        d = t ? n({
          clientKey: e,
          loadingContext: s
        }, t) : null;
      Promise.all([o, a, i, d]).then(([t, e, s, o]) => {
        this.setState({
          instantPaymentElements: s,
          elements: e,
          storedPaymentElements: t,
          orderStatus: o
        }), this.setStatus("ready"), this.props.modules?.analytics.sendAnalytics("dropin", {
          type: r,
          configData: this.analyticConfigData
        });
      }), this.onOrderCancel = this.getOnOrderCancel();
    }), d(this, "setStatus", (t, e = {}) => {
      this.setState({
        status: {
          type: t,
          props: e
        }
      });
    }), d(this, "setActivePaymentMethod", t => {
      t !== this.state.activePaymentMethod && (this.setState(e => ({
        activePaymentMethod: t,
        cachedPaymentMethods: {
          ...e.cachedPaymentMethods,
          [t._id]: !0
        }
      })), this.state.cachedPaymentMethods[t._id] && t.activate());
    }), d(this, "handleOnSelectPaymentMethod", t => {
      const {
        activePaymentMethod: e
      } = this.state;
      this.setActivePaymentMethod(t), (e && e._id !== t._id || !e) && (this.props.onSelect?.(t), t.submitAnalytics({
        type: r
      }));
    }), d(this, "handleDisableStoredPaymentMethod", t => {
      this.setState({
        isDisabling: !0
      }), new Promise((e, s) => this.props.onDisableStoredPaymentMethod(t.props.storedPaymentMethodId, e, s)).then(() => {
        this.setState(e => ({
          storedPaymentElements: e.storedPaymentElements.filter(e => e._id !== t._id)
        })), this.setState({
          isDisabling: !1
        });
      }).catch(() => {
        this.setState({
          isDisabling: !1
        });
      });
    }), d(this, "getOnOrderCancel", () => this.props.onOrderCancel ? t => {
      const e = a(t.order);
      new Promise((t, s) => {
        this.props.onOrderCancel({
          order: e
        }, {
          resolve: t,
          reject: s
        });
      }).then(({
        amount: t
      }) => this.props.elementRef.handleAdvanceFlowPaymentMethodsUpdate(null, t)).catch(t => {
        throw new i("NETWORK_ERROR", t);
      });
    } : this.props.session ? t => this.props.session.cancelOrder(t).then(() => this.props.core.update({
      order: null
    })).catch(t => {
      console.error(t), this.setStatus(t?.message || "error");
    }) : null), d(this, "onOrderCancel", void 0);
  }
}
export { h as DropinComponent, h as default };
