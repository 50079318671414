function R(R, e, E) {
  return e in R ? Object.defineProperty(R, e, {
    value: E,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : R[e] = E, R;
}
const e = "NETWORK_ERROR",
  E = "CANCEL",
  r = "IMPLEMENTATION_ERROR",
  O = "API_ERROR",
  s = "ERROR",
  t = "SCRIPT_ERROR",
  a = "SDK_ERROR";
class n extends Error {
  constructor(e, E, r) {
    super(E), R(this, "cause", void 0), this.name = n.errorTypes[e], this.cause = r?.cause;
  }
}
R(n, "errorTypes", {
  NETWORK_ERROR: e,
  CANCEL: E,
  IMPLEMENTATION_ERROR: r,
  API_ERROR: O,
  ERROR: s,
  SCRIPT_ERROR: t,
  SDK_ERROR: a
});
export { O as API_ERROR, E as CANCEL, s as ERROR, r as IMPLEMENTATION_ERROR, e as NETWORK_ERROR, t as SCRIPT_ERROR, a as SDK_ERROR, n as default };
