import { ADDRESS_SPECIFICATIONS as e } from "./constants.js";
class t {
  countryHasDataset(e) {
    return !!this.specifications?.[e]?.hasDataset;
  }
  countryHasOptionalField(e, t) {
    return !!this.specifications?.[e]?.optionalFields?.includes(t);
  }
  getAddressSchemaForCountry(e) {
    return this.specifications?.[e]?.schema || this.specifications.default.schema;
  }
  getAddressLabelsForCountry(e) {
    return this.specifications?.[e]?.labels || this.specifications.default.labels;
  }
  getOptionalFieldsForCountry(e) {
    return this.specifications?.[e]?.optionalFields || this.specifications.default?.optionalFields || [];
  }
  getKeyForField(e, t) {
    return this.specifications?.[t]?.labels?.[e] || this.specifications?.default?.labels?.[e] || e;
  }
  getPlaceholderKeyForField(e, t) {
    return this.specifications?.[t]?.placeholders?.[e] || this.specifications?.default?.placeholders?.[e];
  }
  getAddressSchemaForCountryFlat(e) {
    return this.getAddressSchemaForCountry(e).flat(2).filter(e => "string" == typeof e);
  }
  constructor(t) {
    var i, s, a;
    a = void 0, (s = "specifications") in (i = this) ? Object.defineProperty(i, s, {
      value: a,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : i[s] = a, this.specifications = {
      ...e,
      ...t
    };
  }
}
export { t as default };
