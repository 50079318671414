import { TxVariants as a } from "./tx-variants.js";
const o = {
  [a.address]: "Address",
  [a.bankTransfer_IBAN]: "BankTransfer",
  [a.donation]: "Donation",
  [a.personal_details]: "PersonalDetails",
  [a.bcmc]: "Bancontact",
  [a.card]: "Card",
  [a.scheme]: "Card",
  [a.storedCard]: "Card",
  [a.customCard]: "CustomCard",
  [a.ach]: "Ach",
  [a.directdebit_GB]: "BacsDirectDebit",
  [a.sepadirectdebit]: "SepaDirectDebit",
  [a.affirm]: "Affirm",
  [a.afterpay]: "AfterPay",
  [a.afterpay_default]: "AfterPay",
  [a.afterpay_b2b]: "AfterPayB2B",
  [a.atome]: "Atome",
  [a.facilypay_3x]: "FacilyPay3x",
  [a.facilypay_4x]: "FacilyPay4x",
  [a.facilypay_6x]: "FacilyPay6x",
  [a.facilypay_10x]: "FacilyPay10x",
  [a.facilypay_12x]: "FacilyPay12x",
  [a.ratepay]: "RatePay",
  [a.ratepay_directdebit]: "RatePayDirectDebit",
  [a.riverty]: "Riverty",
  [a.amazonpay]: "AmazonPay",
  [a.applepay]: "ApplePay",
  [a.cashapp]: "CashAppPay",
  [a.clicktopay]: "ClickToPay",
  [a.googlepay]: "GooglePay",
  [a.paypal]: "PayPal",
  [a.paywithgoogle]: "GooglePay",
  [a.boletobancario]: "Boleto",
  [a.boletobancario_itau]: "Boleto",
  [a.boletobancario_santander]: "Boleto",
  [a.doku]: "Doku",
  [a.doku_alfamart]: "Doku",
  [a.doku_permata_lite_atm]: "Doku",
  [a.doku_indomaret]: "Doku",
  [a.doku_atm_mandiri_va]: "Doku",
  [a.doku_sinarmas_va]: "Doku",
  [a.doku_mandiri_va]: "Doku",
  [a.doku_cimb_va]: "Doku",
  [a.doku_danamon_va]: "Doku",
  [a.doku_bri_va]: "Doku",
  [a.doku_bni_va]: "Doku",
  [a.doku_bca_va]: "Doku",
  [a.doku_wallet]: "Doku",
  [a.oxxo]: "Oxxo",
  [a.primeiropay_boleto]: "Boleto",
  [a.billdesk_online]: "BillDeskOnline",
  [a.billdesk_wallet]: "BillDeskWallet",
  [a.dotpay]: "Dotpay",
  [a.eps]: "Eps",
  [a.molpay_ebanking_fpx_MY]: "MolPayEBankingMY",
  [a.molpay_ebanking_TH]: "MolPayEBankingTH",
  [a.molpay_ebanking_VN]: "MolPayEBankingVN",
  [a.onlineBanking_CZ]: "OnlineBankingCZ",
  [a.onlinebanking_IN]: "OnlineBankingIN",
  [a.onlineBanking_PL]: "OnlineBankingPL",
  [a.onlineBanking_SK]: "OnlineBankingSK",
  [a.paybybank]: "PayByBank",
  [a.payu_IN_cashcard]: "PayuCashcard",
  [a.payu_IN_nb]: "PayuNetBanking",
  [a.wallet_IN]: "WalletINElement",
  [a.dragonpay_ebanking]: "Dragonpay",
  [a.dragonpay_otc_banking]: "Dragonpay",
  [a.dragonpay_otc_non_banking]: "Dragonpay",
  [a.dragonpay_otc_philippines]: "Dragonpay",
  [a.econtext_atm]: "Econtext",
  [a.econtext_online]: "Econtext",
  [a.econtext_seven_eleven]: "Econtext",
  [a.econtext_stores]: "Econtext",
  [a.giropay]: "Giropay",
  [a.multibanco]: "Multibanco",
  [a.redirect]: "Redirect",
  [a.twint]: "Twint",
  [a.vipps]: "Vipps",
  [a.trustly]: "Trustly",
  [a.paybybank_AIS_DD]: "PayByBankUS",
  [a.klarna]: "Klarna",
  [a.klarna_account]: "Klarna",
  [a.klarna_paynow]: "Klarna",
  [a.klarna_b2b]: "Klarna",
  [a.bcmc_mobile]: "BcmcMobile",
  [a.bcmc_mobile_QR]: "BcmcMobile",
  [a.pix]: "Pix",
  [a.swish]: "Swish",
  [a.wechatpay]: "WeChat",
  [a.wechatpayQR]: "WeChat",
  [a.promptpay]: "PromptPay",
  [a.paynow]: "PayNow",
  [a.duitnow]: "DuitNow",
  [a.blik]: "Blik",
  [a.mbway]: "MBWay",
  [a.ancv]: "ANCV",
  [a.upi]: "UPI",
  [a.upi_qr]: "UPI",
  [a.upi_collect]: "UPI",
  [a.upi_intent]: "UPI",
  [a.giftcard]: "Giftcard",
  [a.mealVoucher_FR_natixis]: "MealVoucherFR",
  [a.mealVoucher_FR_sodexo]: "MealVoucherFR",
  [a.mealVoucher_FR_groupeup]: "MealVoucherFR"
};
function e(a) {
  return o[a];
}
export { e as default };
