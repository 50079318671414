import { createElement as e, Fragment as t } from "../../../external/preact/dist/preact.js";
import { useRef as s, useMemo as o, useState as r, useCallback as a, useEffect as n } from "../../../external/preact/hooks/dist/hooks.js";
import i from "../FormFields/Fieldset/Fieldset.js";
import l from "./components/ReadOnlyAddress.js";
import { getAddressValidationRules as d } from "./validate.js";
import { addressFormatters as c, countrySpecificFormatters as u } from "./validate.formats.js";
import m from "./components/FieldContainer.js";
import p from "../../../utils/useForm/useForm.js";
import f from "./Specifications.js";
import { FALLBACK_VALUE as y, ADDRESS_SCHEMA as F } from "./constants.js";
import { getMaxLengthByFieldAndCountry as h } from "../../../utils/validator-utils.js";
import { useCoreContext as C } from "../../../core/Context/CoreProvider.js";
import g from "./components/AddressSearch.js";
function A(A) {
  const {
      i18n: v
    } = C(),
    {
      label: b = "",
      requiredFields: j,
      visibility: x,
      iOSFocusedField: S = null,
      showContextualElement: k
    } = A,
    M = s({});
  Object.keys(M.current).length || A.setComponentRef?.(M.current);
  const O = o(() => new f(A.specifications), [A.specifications]),
    D = O.getAddressSchemaForCountryFlat(A.countryCode).filter(e => j.includes(e)),
    [w, B] = r(!1),
    [E, N] = r(!1),
    [P, L] = r(""),
    R = !!A.onAddressLookup,
    [V, q] = r(!1),
    H = !A.onAddressLookup || w || E,
    {
      data: T,
      errors: _,
      valid: I,
      isValid: $,
      handleChangeFor: z,
      triggerValidation: G,
      setData: J,
      mergeData: K
    } = p({
      schema: D,
      defaultData: A.data,
      rules: {
        ...d(O),
        ...A.validationRules
      },
      formatters: c
    }),
    Q = a(e => {
      const t = F.reduce((t, s) => {
        const o = e[s];
        return null != o && (t[s] = String(o)), t;
      }, {});
      K(t), q(!0), G(), B(!0);
    }, [B, G, J]),
    U = a(() => {
      N(!0);
    }, []);
  M.current.showValidation = () => {
    G(), L(!R || H || $ ? "" : v.get("address.errors.incomplete"));
  };
  const W = D.filter(e => !S || e === S);
  if (n(() => {
    if (V) return void q(!1);
    const e = O.countryHasDataset(T.country) ? "" : y,
      t = {
        ...T,
        stateOrProvince: e
      };
    j.forEach(e => {
      z(e, "input")(t[e] ?? "");
    }), t.postalCode && z("postalCode", "blur")(T.postalCode);
  }, [T.country]), n(() => {
    const e = j.includes("stateOrProvince"),
      t = T.country && O.countryHasDataset(T.country),
      s = e && t,
      o = T.stateOrProvince || (s ? "" : y);
    z("stateOrProvince", "input")(o);
  }, []), n(() => {
    const e = O.getOptionalFieldsForCountry(T.country),
      t = F.reduce((t, s) => {
        const o = e.includes(s),
          r = j.includes(s),
          a = T[s],
          n = A.data[s],
          i = o && !a || !r ? r || a || !n ? y : n : a;
        return i?.length && (t[s] = i), t;
      }, {});
    A.onChange({
      data: t,
      valid: I,
      errors: _,
      isValid: $
    });
  }, [T, I, _, $]), "hidden" === x) return null;
  if ("readOnly" === x) return e(l, {
    data: T,
    label: b
  });
  const X = (t, {
      classNameModifiers: s = []
    }) => j.includes(t) ? e(m, {
      key: t,
      allowedCountries: A.allowedCountries,
      classNameModifiers: [...s, t],
      data: T,
      errors: _,
      valid: I,
      fieldName: t,
      onInput: z(t, "input"),
      onBlur: z(t, "blur"),
      onDropdownChange: z(t, "blur"),
      specifications: O,
      maxLength: h(u, t, T.country),
      trimOnBlur: !0,
      disabled: !W.includes(t),
      onFieldFocusAnalytics: A.onFieldFocusAnalytics,
      onFieldBlurAnalytics: A.onFieldBlurAnalytics
    }) : null,
    Y = O.getAddressSchemaForCountry(T.country);
  return e(t, null, e(i, {
    classNameModifiers: [b || "address"],
    label: b
  }, R && e(g, {
    onAddressLookup: A.onAddressLookup,
    onAddressSelected: A.onAddressSelected,
    onSelect: Q,
    onManualAddress: U,
    externalErrorMessage: P,
    hideManualButton: H,
    showContextualElement: k,
    contextualText: v.get("address.search.contextualText"),
    addressSearchDebounceMs: A.addressSearchDebounceMs
  }), H && e(t, null, Y.map(t => t instanceof Array ? e("div", {
    className: "adyen-checkout__field-group"
  }, t.map(([e, t]) => X(e, {
    classNameModifiers: [`col-${t}`]
  }))) : X(t, {})))));
}
A.defaultProps = {
  countryCode: null,
  validationRules: null,
  data: {},
  onChange: () => {},
  visibility: "editable",
  requiredFields: F,
  specifications: {},
  onFieldFocusAnalytics: () => {},
  onFieldBlurAnalytics: () => {}
};
export { A as default };
