import { Language as t } from "../language/Language.js";
import o from "./RiskModule/RiskModule.js";
import s from "./ProcessResponse/PaymentMethods/PaymentMethods.js";
import { getComponentForAction as e } from "./ProcessResponse/PaymentAction/PaymentAction.js";
import i from "./Analytics/Analytics.js";
import { processGlobalOptions as n, assertConfigurationPropertiesAreValid as a } from "./utils.js";
import r from "./CheckoutSession/CheckoutSession.js";
import { hasOwnProperty as h } from "../utils/hasOwnProperty.js";
import { Resources as l } from "./Context/Resources.js";
import { SRPanel as c } from "./Errors/SRPanel.js";
import p from "./core.registry.js";
import { sanitizeResponse as m, verifyPaymentDidNotFail as d, cleanupFinalResult as u } from "../components/internal/UIElement/utils.js";
import y, { IMPLEMENTATION_ERROR as g } from "./Errors/AdyenCheckoutError.js";
import { ANALYTICS_ACTION_STR as f } from "./Analytics/constants.js";
import { THREEDS2_FULL as C } from "../components/ThreeDS2/constants.js";
import { DEFAULT_LOCALE as w } from "../language/constants.js";
import v from "./Services/get-translations.js";
import { defaultProps as b } from "./core.defaultProps.js";
import { formatLocale as j, formatCustomTranslations as E } from "../language/utils.js";
import { resolveEnvironments as A } from "./Environment/Environment.js";
function P(t, o, s) {
  return o in t ? Object.defineProperty(t, o, {
    value: s,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[o] = s, t;
}
class M {
  static setBundleType(t) {
    M.metadata.bundleType = t;
  }
  static register(...t) {
    p.add(...t);
  }
  register(...t) {
    p.add(...t);
  }
  getComponent(t) {
    return p.getComponent(t);
  }
  async initialize() {
    return await this.initializeCore(), this.validateCoreConfiguration(), await this.createCoreModules(), this;
  }
  async initializeCore() {
    return this.session ? this.session.setupSession(this.options).then(t => {
      const {
        amount: o,
        shopperLocale: s,
        countryCode: e,
        paymentMethods: i,
        ...n
      } = t;
      return this.setOptions({
        ...n,
        amount: this.options.order ? this.options.order.remainingAmount : o,
        locale: this.options.locale || s,
        countryCode: this.options.countryCode || e
      }), this.createPaymentMethodsList(i), this;
    }).catch(t => (this.options.onError && this.options.onError(t), Promise.reject(t))) : (this.createPaymentMethodsList(), Promise.resolve(this));
  }
  async fetchLocaleTranslations() {
    try {
      return await v(this.cdnTranslationsUrl, M.metadata.version, this.options.locale);
    } catch (t) {
      t instanceof y ? this.options.onError?.(t) : this.options.onError?.(new y("ERROR", "Failed to fetch translation", {
        cause: t
      }));
    }
  }
  validateCoreConfiguration() {
    if (this.options.paymentMethodsConfiguration && console.warn('WARNING:  "paymentMethodsConfiguration" is supported only by Drop-in.'), !this.options.countryCode) throw new y(g, "You must specify a countryCode when initializing checkout.");
    this.options.locale || this.setOptions({
      locale: w
    }), this.options.locale = j(this.options.locale), this.options.translations = E(this.options.translations);
  }
  submitDetails(t) {
    let o = null;
    this.options.onAdditionalDetails && (o = new Promise((o, s) => {
      this.options.onAdditionalDetails({
        data: t
      }, void 0, {
        resolve: o,
        reject: s
      });
    })), this.session && (o = this.session.submitDetails(t).catch(t => (this.options.onError?.(t), Promise.reject(t)))), o ? o.then(m).then(d).then(t => {
      u(t), this.options.onPaymentCompleted?.(t);
    }).catch(t => {
      u(t), this.options.onPaymentFailed?.(t);
    }) : this.options.onError?.(new y("IMPLEMENTATION_ERROR", 'It can not submit the details. The callback "onAdditionalDetails" or the Session is not setup correctly.'));
  }
  createFromAction(t, o = {}) {
    if (!t || !t.type) {
      if (h(t, "action") && h(t, "resultCode")) throw new Error('createFromAction::Invalid Action - the passed action object itself has an "action" property and a "resultCode": have you passed in the whole response object by mistake?');
      throw new Error('createFromAction::Invalid Action - the passed action object does not have a "type" property');
    }
    if (t.type) {
      const s = t.type === C ? `${t.type}${t.subtype}` : t.paymentMethodType;
      this.modules.analytics.sendAnalytics(s, {
        type: f,
        subtype: t.type,
        message: `${s} action was handled by the SDK`
      });
      const i = {
        ...this.getCorePropsForComponent(),
        ...o
      };
      return e(this, p, t, i);
    }
    return this.handleCreateError();
  }
  getCorePropsForComponent() {
    return {
      ...n(this.options),
      core: this,
      i18n: this.modules.i18n,
      modules: this.modules,
      session: this.session,
      loadingContext: this.loadingContext,
      cdnContext: this.cdnImagesUrl,
      createFromAction: this.createFromAction
    };
  }
  storeElementReference(t) {
    t && this.components.push(t);
  }
  handleCreateError(t) {
    const o = t ? `${t?.name ?? "The passed payment method"} is not a valid Checkout Component. What was passed as a txVariant was: ${JSON.stringify(t)}. Check if this payment method is configured in the Backoffice or if the txVariant is a valid one` : "No Payment Method component was passed";
    throw new Error(o);
  }
  createPaymentMethodsList(t) {
    this.paymentMethodsResponse = new s(this.options.paymentMethodsResponse || t, this.options);
  }
  async createCoreModules() {
    if (this.modules) return;
    const s = await this.fetchLocaleTranslations();
    this.modules = Object.freeze({
      risk: new o(this, {
        ...this.options,
        loadingContext: this.loadingContext
      }),
      analytics: i({
        loadingContext: this.loadingContext,
        analyticsContext: this.analyticsContext,
        clientKey: this.options.clientKey,
        locale: this.options.locale,
        analytics: this.options.analytics,
        amount: this.options.amount,
        bundleType: M.metadata.bundleType
      }),
      resources: new l(this.cdnImagesUrl),
      i18n: new t({
        locale: this.options.locale,
        translations: s,
        customTranslations: this.options.translations
      }),
      srPanel: new c(this, {
        ...this.options.srConfig
      })
    });
  }
  constructor(t) {
    P(this, "session", void 0), P(this, "paymentMethodsResponse", void 0), P(this, "modules", void 0), P(this, "options", void 0), P(this, "analyticsContext", void 0), P(this, "loadingContext", void 0), P(this, "cdnImagesUrl", void 0), P(this, "cdnTranslationsUrl", void 0), P(this, "components", []), P(this, "update", (t = {}) => (this.setOptions(t), this.initialize().then(() => (this.components.forEach(o => {
      const s = {
        ...t,
        ...(this.session && {
          session: this.session
        })
      };
      o.update(s);
    }), this)))), P(this, "remove", t => (this.components = this.components.filter(o => o._id !== t._id), t.unmount(), this)), P(this, "setOptions", t => {
      this.options = {
        ...this.options,
        ...t,
        locale: t?.locale || this.options?.locale
      };
    }), a(t), this.createFromAction = this.createFromAction.bind(this), this.setOptions({
      ...b,
      ...t
    });
    const {
      apiUrl: o,
      analyticsUrl: s,
      cdnImagesUrl: e,
      cdnTranslationsUrl: i
    } = A(this.options.environment, this.options._environmentUrls);
    this.loadingContext = o, this.analyticsContext = s, this.cdnImagesUrl = e, this.cdnTranslationsUrl = i, this.session = this.options.session && new r(this.options.session, this.options.clientKey, this.loadingContext);
    const n = this.options.clientKey?.substring(0, 4);
    if (("test" === n || "live" === n) && !this.loadingContext.includes(n)) throw new y("IMPLEMENTATION_ERROR", `Error: you are using a ${n} clientKey against the ${this.options._environmentUrls?.api || this.options.environment} environment`);
    "pub." === n && console.debug(`The value you are passing as your "clientKey" looks like an originKey (${this.options.clientKey?.substring(0, 12)}..). Although this is supported it is not the recommended way to integrate. To generate a clientKey, see the documentation (https://docs.adyen.com/development-resources/client-side-authentication/migrate-from-origin-key-to-client-key/) for more details.`), this.options.exposeLibraryMetadata && (window.AdyenWebMetadata = M.metadata);
  }
}
P(M, "metadata", {
  version: "6.6.0",
  bundleType: "esm"
}), P(M, "registry", p);
export { M as default };
