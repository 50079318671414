<div class="gung-table-component">
  @if (config) {
    <div class="table-head-options">
      @if (config.title) {
        <div class="title">
          <h2>{{ config.title | translate }}</h2>
        </div>
      }
      @if (config.enableSearch) {
        <div class="table-search">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              [value]="search"
              placeholder="{{ 'SEARCH' | translate }}"
              (input)="searchChange($event.target.value)"
            />
          </div>
        </div>
      }
    </div>
    <div class="gung-table-wrapper table-responsive gung-responsive">
      <table
        class="gung-table table table-striped"
        [ngClass]="{ 'custom-component': useCustomComponent, 'table-fixed-layout': layoutFixed }"
      >
        <thead>
          @if (selectItems) {
            <th class="select-td"></th>
          }
          @for (head of config.headers; track head; let index = $index) {
            <th [ngClass]="head?.extraClass">
              <div (click)="head.sortable ? gungTableSort(index) : null" class="d-flex">
                <span>{{ head.name | translate }}</span>
                @if (head.sortable) {
                  <span class="ml-2">
                    @if (indexSorted === index && head.sortActivated === 'ASC') {
                      <i class="fa-solid fa-arrow-up"></i>
                    } @else if (indexSorted === index && head.sortActivated === 'DESC') {
                      <i class="fa-solid fa-arrow-down"></i>
                    } @else {
                      <i class="fa-solid fa-arrows-up-down"></i>
                    }
                  </span>
                }
                @if (head.enableFilter) {
                  <span class="ml-2 filter">
                    <span class="filter-icon" (click)="openFilterOptions(index); $event.stopPropagation()">
                      <gung-table-active-filter-number
                        [index]="index"
                        [activeFilters]="activeFilters"
                      ></gung-table-active-filter-number>
                      <i class="fa-regular fa-filter"></i>
                    </span>
                    @if (openFilter && openFilter.headerIndex === index && openFilter.showFilterBox) {
                      <div class="filter-box" (click)="$event.stopPropagation()">
                        <div class="search-filter">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'SEARCH' | translate }}"
                              (input)="searchFilter($event.target.value)"
                            />
                          </div>
                        </div>
                        @if (openFilter.headerIndex >= 0) {
                          <gung-table-clear-filter-button
                            [index]="openFilter.headerIndex"
                            [activeFilters]="activeFilters"
                            (clearFilter)="clearFilter($event)"
                          />
                        }

                        @for (value of openFilter.values; track value) {
                          <div
                            class="filter-value d-flex justify-content-between align-items-center"
                            (click)="
                              selectFilter(openFilter, value.value);
                              value.active = !value.active;
                              $event.stopPropagation()
                            "
                          >
                            <div>
                              <i
                                class="far"
                                [ngClass]="{
                                  'fa-square': !openFilter.activeValues.includes(value.value),
                                  'fa-check-square': openFilter.activeValues.includes(value.value)
                                }"
                              ></i>
                              <span>
                                {{ value.value | translate }}
                              </span>
                            </div>
                            <div class="pl-2">{{ value.qty }}</div>
                          </div>
                        }
                      </div>
                    }
                  </span>
                }
              </div>
            </th>
          }
        </thead>

        @if (!useCustomComponent) {
          <tbody>
            @for (item of dataTable; track index; let index = $index) {
              <tr>
                @for (itemCol of item; track indexCol; let indexCol = $index) {
                  @if (selectItems && indexCol === 0) {
                    <td>
                      <div class="select-item" (click)="selectItem(itemCol)">
                        <i
                          class="far"
                          [ngClass]="{
                            'fa-square': !itemCol.selected,
                            'fa-check-square': itemCol.selected
                          }"
                        ></i>
                      </div>
                    </td>
                  } @else {
                    <td
                      [attr.data-label]="config.headers?.[indexCol]?.name | translate"
                      [ngClass]="config.headers[indexCol]?.extraClass"
                    >
                      {{ itemCol }}
                    </td>
                  }
                }
              </tr>
            }
          </tbody>
        } @else {
          <tbody>
            <ng-template #gungTableCustom></ng-template>
          </tbody>
        }
      </table>
    </div>
    @if (paginanitonNumber) {
      <nav>
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage === 1" (click)="goToFirstPage()">
            <a class="page-link"><i class="fa-solid fa-backward-fast"></i></a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === 1" (click)="goToPreviousPage()">
            <a class="page-link"><i class="fa-solid fa-backward-step"></i></a>
          </li>
          @for (page of paginationRange; track index; let index = $index) {
            @if (page !== -1) {
              <li class="page-item">
                <a class="page-link" (click)="goToPage(page)" [class.active]="page === currentPage">{{ page }}</a>
              </li>
            } @else {
              <li class="page-item"><a class="page-link no-link">...</a></li>
            }
          }
          <li class="page-item" [class.disabled]="currentPage === totalPages" (click)="goToNextPage()">
            <a class="page-link"><i class="fa-solid fa-forward-step"></i></a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages" (click)="goToLastPage()">
            <a class="page-link"><i class="fa-solid fa-forward-fast"></i></a>
          </li>
        </ul>
      </nav>
    } @else {
      @if (dataTable?.length && filterItems?.length && pageSize < filterItems.length) {
        <gung-button
          classes="btn-primary btn-sm w-100"
          label="{{
            'GUNG_ITEM_LIST_LOAD_MORE'
              | translate: { limit: dataTable.length, total: filterItems.length, batchSize: limitItems }
          }}"
          [onClick]="loadMoreitems.bind(this)"
        />
      }
    }
  }
</div>
