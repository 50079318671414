import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OptionsListMulti } from 'gung-common';
import { catchError, forkJoin, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { defaultIfEmpty, first, tap } from 'rxjs';
import {
  DigitalAsset,
  DigitalAssetFile,
  DigitalAssetsService
} from '../../../services/digital-assets/digital-assets.service';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-digital-assets-management-item',
  templateUrl: './digital-assets-management-item.component.html',
  styleUrls: ['./digital-assets-management-item.component.css']
})
export class DigitalAssetsManagementItemComponent implements OnInit, OnDestroy {
  isLoading = false;

  @Input()
  digitalAsset: DigitalAsset;

  parentId: string;

  public form: FormGroup = null;

  public filesDocuments: File[] = [];
  public filesImages: File[] = [];

  public userGroups: OptionsListMulti[];

  unsubscribe: Subject<void> = new Subject();

  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    public digitalAssetsService: DigitalAssetsService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected gungFlowService: GungFlowService
  ) { }

  ngOnInit() {
    this.gungFlowService
      .getSystemGung()
      .pipe(first())
      .subscribe(systemSettings => {
        const settingsGroups = systemSettings.extra?.userGroups || [];
        this.userGroups = settingsGroups.map(g => ({ id: g, name: undefined, selected: false }));
        if (this.digitalAsset && this.digitalAsset.extra?.groups) {
          const groups = this.digitalAsset.extra.groups.split(',');
          for (const group of groups) {
            this.userGroups.find(g => g.id === group).selected = true;
          }
        }
      });
    if (this.route.snapshot.url.length > 1) {
      this.parentId = this.route.snapshot.url[this.route.snapshot.url.length - 2].path;
    }
    const id =
      this.route.snapshot.paramMap.get('id') || this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
    if (!id || id === 'new') {
      const newDigitalAsset: DigitalAsset = {
        images: [],
        documents: []
      } as any;
      this.digitalAsset = newDigitalAsset;
      this.initForm();
    } else {
      this.digitalAssetsService
        .getDigitalAssets()
        .pipe(first())
        .subscribe(digitalAssets => {
          const digitalAsset = digitalAssets.find(d => d.id === id);
          this.digitalAsset = digitalAsset;
          this.initForm();
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control((this.digitalAsset && this.digitalAsset.id) || '', []),
      name: this.formBuilder.control((this.digitalAsset && this.digitalAsset.name) || '', [Validators.required]),
      season: this.formBuilder.control(
        (this.digitalAsset && this.digitalAsset.extra && this.digitalAsset.extra.season) || '',
        []
      ),
      brand: this.formBuilder.control(
        (this.digitalAsset && this.digitalAsset.extra && this.digitalAsset.extra.brand) || '',
        []
      ),
      roles: this.formBuilder.control(
        (this.digitalAsset && this.digitalAsset.extra && this.digitalAsset.extra.roles) || '',
        []
      ),
      groups: this.formBuilder.control(
        (this.digitalAsset && this.digitalAsset.extra && this.digitalAsset.extra.groups) || '',
        []
      ),
      tags: this.formBuilder.control(
        (this.digitalAsset && this.digitalAsset.extra && this.digitalAsset.extra.tags) || '',
        []
      ),
      sequence: this.formBuilder.control(this.digitalAsset?.extra?.sequence || '', []),
      links: this.formBuilder.array([])
    });

    if (this.digitalAssetsService.useLinks) {
      const itemsFormArray = this.form.get('links') as FormArray;

      if (Array.isArray(this.digitalAsset?.extra?.links)) {
        this.digitalAsset.extra.links.forEach(link => itemsFormArray.push(this.formBuilder.control(link)))
      }

      itemsFormArray.push(this.formBuilder.control(''));
    }

    this.form.get('links').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (value[value.length - 1] !== '') {
        (<FormArray>this.form.get('links')).push(this.formBuilder.control(''));
      }
    });
  }

  onDragOver(event /* : DragEvent */) {
    // console.log(`Drag Over`, event);
    event.target.style.opacity = 0.5;
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event /* : DragEvent */) {
    // console.log('Drag Leave', event);
    event.target.style.opacity = 1;
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event, type: string) {
    // console.log('Drop', event);
    event.preventDefault();
    event.stopPropagation();
    event.target.style.opacity = 1;
    const files: File[] = event.target.files || event.dataTransfer.files;
    if (files.length > 0) {
      if (type === 'images') {
        this.filesImages.push(...files);
        this.previewImage(files);
      } else {
        this.filesDocuments.push(...files);
        this.previewDocument(files);
      }
      event.target.value = '';
    }
  }

  previewImage(files: File[]) {
    if (!files || files.length === 0) {
      return;
    }
    for (const file of files) {
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        console.log('Only images are supported.');
        return;
      }

      const reader = new FileReader();
      const imagePath = file;
      reader.readAsDataURL(file);
      reader.onload = _event => {
        const fileDA: DigitalAssetFile = {
          category: 'Product Image',
          description: null,
          i18n: null,
          index: null,
          pimDimensionIds: null,
          s3ETag: null,
          s3Uri: reader.result as string,
          s3lastModifiedDate: file.lastModified,
          id: null,
          name: file.name,
          extra: { isPreview: true }
        };
        this.digitalAsset.images.push(fileDA);
      };
    }
  }

  uploadImages(files: File[], digitalAssetId?: string): Observable<DigitalAsset>[] {
    const aFiles = Array.of(...files);
    const request = aFiles.map(file => {
      const formData = new FormData();
      formData.append('file', file);
      return this.digitalAssetsService
        .postDigitalAssetImages(digitalAssetId || this.digitalAsset.id, formData)
        .pipe(first());
    });
    return request;
  }

  previewDocument(files: File[]) {
    if (!files || files.length === 0) {
      return;
    }
    for (const file of files) {
      const fileDA: DigitalAssetFile = {
        category: 'Product Document',
        description: null,
        i18n: null,
        index: null,
        pimDimensionIds: null,
        s3ETag: null,
        s3Uri: null,
        s3lastModifiedDate: file.lastModified,
        id: null,
        name: file.name,
        extra: { isPreview: true }
      };
      this.digitalAsset.documents.push(fileDA);
    }
  }

  uploadDocuments(files: File[], digitalAssetId?: string): Observable<DigitalAsset>[] {
    const aFiles = Array.of(...files);
    const request = aFiles.map(file => {
      const formData = new FormData();
      formData.append('file', file);
      return this.digitalAssetsService
        .postDigitalAssetDocuments(digitalAssetId || this.digitalAsset.id, formData)
        .pipe(
          first(),
          catchError(error => {
            console.error('error', error);
            this.gungModalService.openConfirmYesNoModal('FAILED_UPLOADS', this.translationService.instant('ERROR_FILE_TO_LARGE', { file: file.name }), undefined, 'OK', null);
            return of(null);
          })
        );
    });
    return request;
  }

  addLink() {
    if (!this.digitalAsset?.extra?.links) {
      this.digitalAsset = {
        ...this.digitalAsset,
        extra: {
          ...this.digitalAsset.extra,
          links: []
        }
      };
    }
    this.digitalAsset.extra.links.push('');
  }

  removeLink(index: number) {
    this.digitalAsset.extra.links.splice(index, 1);
  }

  removeElement(arr: any[], index) {
    const fileDA: DigitalAssetFile = arr[index];
    if (!fileDA.s3ETag && fileDA.category === 'Product Image') {
      const idx = this.filesImages.findIndex(
        f => f.name === fileDA.name && f.lastModified === fileDA.s3lastModifiedDate
      );
      this.filesImages.splice(idx, 1);
    }
    if (!fileDA.s3ETag && fileDA.category === 'Product Document') {
      const idx = this.filesImages.findIndex(
        f => f.name === fileDA.name && f.lastModified === fileDA.s3lastModifiedDate
      );
      this.filesDocuments.splice(idx, 1);
    }
    arr.splice(index, 1);
  }

  moveElement(arr: any[], oldIndex: number, newIndex: number) {
    arr.splice(newIndex, 0, ...arr.splice(oldIndex, 1));
  }

  onSave(digitalAsset: DigitalAsset) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const formRaw = this.form.getRawValue();
    const create = !digitalAsset || !digitalAsset.id;
    if (create) {
      const newDigitalAsset: DigitalAsset = {
        name: formRaw.name,
        extra: {
          brand: formRaw.brand,
          season: formRaw.season,
          roles: formRaw.roles,
          groups: formRaw.groups,
          tags: formRaw.tags,
          sequence: formRaw.sequence,
          links: formRaw.links.map(item => item.trim()).filter(item => item !== "")
        }
      } as any;

      if (!this.digitalAssetsService.useLinks) {
        delete newDigitalAsset.extra.links;
      }

      if (this.parentId) {
        newDigitalAsset.extra.parentId = this.parentId;
      }
      if (formRaw.brand.trim() === '') {
        delete newDigitalAsset.extra.brand;
      }
      if (formRaw.season.trim() === '') {
        delete newDigitalAsset.extra.season;
      }
      if (formRaw.roles.trim() === '') {
        delete newDigitalAsset.extra.roles;
      }
      if (formRaw.groups.trim() === '') {
        delete newDigitalAsset.extra.groups;
      }
      if (formRaw.tags.trim() === '') {
        delete newDigitalAsset.extra.tags;
      }
      if (formRaw.sequence === '') {
        delete newDigitalAsset.extra.sequence;
      }

      this.digitalAssetsService.postDigitalAsset(newDigitalAsset).subscribe(result => {
        this.digitalAsset = {
          ...result,
          id: null,
          images: this.digitalAsset.images,
          documents: this.digitalAsset.documents
        };
        this.onUpdate(this.digitalAsset, result.id);
      });
    } else {
      this.onUpdate(digitalAsset);
    }
  }

  onUpdate(digitalAsset: DigitalAsset, id?: string) {
    const formRaw = this.form.getRawValue();
    const newDigitalAsset: DigitalAsset = {
      ...digitalAsset,
      id: id || this.digitalAsset.id,
      name: formRaw.name,
      dateLastUpdate: new Date().getTime(),
      extra: {
        ...digitalAsset.extra,
        brand: formRaw.brand,
        season: formRaw.season,
        roles: formRaw.roles,
        groups: formRaw.groups,
        tags: formRaw.tags,
        sequence: formRaw.sequence,
        links: formRaw.links.map(item => item.trim()).filter(item => item !== "")
      }
    };
    newDigitalAsset.images = newDigitalAsset.images.filter(f => !f.extra.isPreview);
    newDigitalAsset.documents = newDigitalAsset.documents.filter(f => !f.extra.isPreview);

    if (!this.digitalAssetsService.useLinks) {
      delete newDigitalAsset.extra.links;
    }

    if (formRaw.brand.trim() === '') {
      delete newDigitalAsset.extra.brand;
    }
    if (formRaw.season.trim() === '') {
      delete newDigitalAsset.extra.season;
    }
    if (formRaw.roles.trim() === '') {
      delete newDigitalAsset.extra.roles;
    }
    if (formRaw.groups.trim() === '') {
      delete newDigitalAsset.extra.groups;
    }
    if (formRaw.tags.trim() === '') {
      delete newDigitalAsset.extra.tags;
    }
    if (formRaw.sequence === '') {
      delete newDigitalAsset.extra.sequence;
    }

    // File Upload
    const request = [];
    if (this.filesImages.length > 0) {
      request.push(...this.uploadImages(this.filesImages, id || this.digitalAsset.id));
    }
    if (this.filesDocuments.length > 0) {
      request.push(...this.uploadDocuments(this.filesDocuments, id || this.digitalAsset.id));
    }

    this.digitalAssetsService
      .putDigitalAsset(newDigitalAsset)
      .pipe(
        first(),
        switchMap(updatedDigitalAsset => forkJoin(request).pipe(defaultIfEmpty(null))),
        switchMap(_ => this.digitalAssetsService.getDigitalAsset(id || this.digitalAsset.id))
      )
      .subscribe(_ => {
        this.isLoading = false;
        this.router.navigate(['../'], { relativeTo: this.route });
      });
  }

  onDelete(digitalAsset: DigitalAsset) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE') + '?', { size: 'sm' })
      .then(result => {
        if (result) {
          this.isLoading = true;
          this.digitalAssetsService.deleteDigitalAsset(digitalAsset.id).subscribe(data => {
            this.isLoading = false;
            this.router.navigate(['../'], { relativeTo: this.route });
          });
        }
      });
  }

  setSelectedValues(event: string[]): void {
    this.form.get('groups').setValue(event.join(','));
  }
}
