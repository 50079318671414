import { Component as e, createElement as s } from "../../../../external/preact/dist/preact.js";
import t from "../../../../external/classnames/index.js";
import r from "../../../internal/IFrame/Iframe.js";
import a from "../../../internal/Spinner/Spinner.js";
import n from "../Form/ThreeDS2Form.js";
import i from "../../../../utils/get-process-message-handler.js";
import { encodeBase64URL as o } from "../utils.js";
import l from "../../../../utils/promiseTimeout.js";
import { CHALLENGE_TIMEOUT_REJECT_OBJECT as m, CHALLENGE_TIMEOUT as c, THREEDS2_NUM as h } from "../../constants.js";
function p(e, s, t) {
  return s in e ? Object.defineProperty(e, s, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[s] = t, e;
}
const d = "threeDSIframe";
class g extends e {
  get3DS2ChallengePromise() {
    return new Promise((e, s) => {
      this.processMessageHandler = i(this.props.postMessageDomain, e, s, "challengeResult"), window.addEventListener("message", this.processMessageHandler);
    });
  }
  componentDidMount() {
    this.challengePromise = l(c, this.get3DS2ChallengePromise(), m), this.challengePromise.promise.then(e => {
      window.removeEventListener("message", this.processMessageHandler), this.props.onCompleteChallenge(e);
    }).catch(e => {
      window.removeEventListener("message", this.processMessageHandler), this.props.onErrorChallenge(e);
    });
  }
  componentWillUnmount() {
    this.challengePromise && this.challengePromise.cancel(), window.removeEventListener("message", this.processMessageHandler);
  }
  render({
    acsURL: e,
    cReqData: i,
    iframeSizeArr: o,
    onFormSubmit: l
  }, {
    base64URLencodedData: m,
    status: c
  }) {
    const [h, p] = o;
    return s("div", {
      className: t(["adyen-checkout__threeds2__challenge", `adyen-checkout__threeds2__challenge--${i.challengeWindowSize}`])
    }, "iframeLoaded" !== c && s(a, null), s(r, {
      name: d,
      width: h,
      height: p,
      callback: this.iframeCallback
    }), s(n, {
      name: "cReqForm",
      action: e,
      target: d,
      inputName: "creq",
      inputValue: m,
      onFormSubmit: l
    }));
  }
  constructor(e) {
    super(e), p(this, "processMessageHandler", void 0), p(this, "challengePromise", void 0), p(this, "iframeCallback", () => {
      this.setState({
        status: "iframeLoaded"
      }), "init" === this.state.status && this.props.onActionHandled?.({
        componentType: "3DS2Challenge",
        actionDescription: `${h} challenge iframe loaded`
      });
    });
    const s = JSON.stringify(this.props.cReqData),
      t = o(s);
    this.state = {
      base64URLencodedData: t,
      status: "init"
    };
  }
}
export { g as default };
