import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class ProductEtiquetteFilterService extends SimpleConfigBaseFilter<Product> {
  getName(): string {
    return 'ETIQUETTE_TYPE';
  }
  getOptionIds(item: Product): string[] {
    if (item.extra.hlPimItem.ProductESLManufacturer && item.extra.hlPimItem.ProductESLManufacturer.length > 0) {
      return [item.extra.hlPimItem.ProductESLManufacturer[0].Value];
    } else {
      return [];
    }
    //return item.extra.i18n[this.translateService.currentLang].ProductESLManufacturer_Value || [];
  }
  getOptionName(key: string): string {
    return key;
  }
}
