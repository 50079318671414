{
  "OPEN_ORDERS_QUOTE": "Commandes",
  "GO_TO_ALL_PRODUCTS": "Voir tous les produits",
  "CUSTOMER_SERVICE_EMAIL": "Client",
  "CUSTOMER_CONTACT_EMAIL": "Interlocuteur commercial",
  "CAMPAIGN_CODE": "Code de Bienvenue",
  "FAQ": "FAQ",
  "FAQ_ACCESS_PORTAL_QUERY": "Comment accéder ou activer mon compte?",
  "FAQ_ACCESS_PORTAL_RESPONSE": "Un e-mail a été envoyé à l'adresse e-mail de votre magasin avec votre identifiant et mot de passe. Si vous n'avez pas reçu d'e-mail ou de notification via Les Mousquetaires, contactez le service client dans la rubrique <a href='/contact'>Nous contacter</a>.",
  "FAQ_ACCESS_PORTAL_RESPONSE_PHARMA": "Un e-mail vous a été envoyé avec votre identifiant et mot de passe. Si vous n'avez pas reçu d'email ou de notification, merci de contacter votre service client dans la rubrique <a href='/contact'>Nous contacter</a>.",
  "FAQ_ACCOUNT_INFO_QUERY": "Où trouver les informations liées à mon compte?",
  "FAQ_ACCOUNT_INFO_RESPONSE": "Toutes les informations vous concernant se trouvent dans la rubrique",
  "FAQ_MES_DONNEES": "Mes données.",
  "FAQ_CATALOUGE_QUERY": "Comment accéder au catalogue HL Display?",
  "FAQ_CATALOUGE_RESPONSE": "Suivez <a target='_BLANK' href='https://www.hl-display.com/fr/notre-offre/catalogue/'>ce lien</a> vers notre site internet pour accéder au catalogue en ligne avec tous nos produits.",
  "FAQ_FORGOT_PASSWORD_QUERY": "J'ai oublié mon mot de passe, que faire?",
  "FAQ_FORGOT_PASSWORD_RESPONSE": "Cliquez sur <a href='/contact'>mot de passe oublié</a>.",
  "FAQ_INVOICE_QUERY": "Est-il possible d'imprimer ma facture?",
  "FAQ_INVOICE_RESPONSE": "La facture correspondant à votre commande vous sera transmise par notre service client.",
  "FAQ_ORDER_HISTORY_QUERY": "Comment consulter mon historique de commandes?",
  "FAQ_ORDER_HISTORY_RESPONSE": "Votre historique de commandes est  disponible dans la rubrique",
  "FAQ_ORDER_MODIFICATION_QUERY": "Comment modifier ou annuler une commande?",
  "FAQ_ORDER_MODIFICATION_RESPONSE": "Contactez notre service client dans la rubrique <a href='/contact'>Nous contacter</a>.",
  "FAQ_ORDER_PLACEMENT_QUERY": "Comment enregistrer ma commande?",
  "FAQ_ORDER_PLACEMENT_RESPONSE": "N'hésitez pas à contacter votre contact commercial ou le service client, ils vous guideront pas à pas. Vous trouverez leurs coordonnées dans la rubrique <a href='/contact'>Nous contacter</a>.",
  "FAQ_ORDER_TRACKING_QUERY": "Comment suivre ma commande?",
  "FAQ_ORDER_TRACKING_RESPONSE": "Toutes les informations sur vos commandes en cours sont disponibles dans la rubrique",
  "FAQ_PAYMENT_POLICY_QUERY": "Quelles sont vos conditions générales de vente?",
  "FAQ_PAYMENT_POLICY_RESPONSE": "Montant minimum de commande : 50€ HT<br>Franco de port : 300€/HT<br>Participation Frais de transport : 10€<br>Frais administratifs : Offerts",
  "FAQ_PAYMENT_POLICY_RESPONSE_PHARMA": "Montant minimum de commande : 100€ HT<br>Franco de port : 300€/HT<br>Frais de transport : 25€<br>Frais administratifs : 17€ H.T. pour toute commande inférieure à 300€ H.T.",
  "FAQ_PAYMENT_POLICY_RESPONSE_PHARMA_VALU_OLD": "Montant minimum de commande : {{minimumOrder}}€ HT<br>Franco de port : {{minimumFreight}}€/HT<br>Frais de transport : {{freightCost}}€<br>Frais administratifs : {{adminCost}}€ H.T. pour toute commande inférieure à 300€ H.T.",
  "FAQ_PAYMENT_POLICY_RESPONSE_PHARMA_VALUE": "Les conditions générales de vente applicables sont identiques à celle mentionnées dans notre accord mutuel.",
  "FAQ_PAYMENT_POLICY_RESPONSE_COOP": "Les frais de transport seront appliqués",
  "FAQ_PAYMENT_POLICY_RESPONSE_NORGE_GROUP_SPAR": "Frais administratifs : 200 NOK seront ajoutés pour les commandes inférieures à 2500 NOK",
  "FAQ_PAYMENT_POLICY_RESPONSE_REITA_GROUP": "Frais administratifs : 300 NOK seront ajoutés pour les commandes inférieures à 2500 NOK",
  "FAQ_PAYMENT_SAMPLE_QUERY": "Comment demander un échantillon?",
  "FAQ_PAYMENT_SAMPLE_RESPONSE": "Contactez notre service client dans la rubrique <a href='/contact'>Nous contacter</a>.",
  "FAQ_PAYMENT_TERMS_QUERY": "Quelles sont les modalités de règlement?",
  "FAQ_PAYMENT_TERMS_RESPONSE_": "Votre paiement doit être effectué sous 45 jours fin de mois.",
  "FAQ_PAYMENT_TERMS_RESPONSE": "Votre paiement doit être effectué par carte bancaire.",
  "FAQ_PAYMENT_ONLINE_QUERY_PHARMA": "Mon paiement en ligne est-il sécurisé?",
  "FAQ_PAYMENT_ONLINE_RESPONSE_PHARMA": "HL Display a mis en place une solution de paiement dans les plus hauts standards de sécurité de transaction. Toutes les données que vous communiquez lors de votre paiement sont protégées et cryptées par le protocole SSL (Secure Sockets Layer) via la plateforme de notre partenaire. Nous n'avons en aucun cas accès à vos données bancaires. Ces dernières sont transférées de façon codées et sécurisées directement à notre banque.",
  "FAQ_RETURNS_QUERY": "Comment renvoyer ma commande?",
  "FAQ_RETURNS_RESPONSE": "Contactez notre service client dans la rubrique <a href='/contact'>Nous contacter</a>.",
  "FAQ_TECH_FITTING_QUERY": "Quels sont les produits les plus adaptés pour mon magasin?",
  "FAQ_TECH_FITTING_QUERY_PHARMA": "Quels sont les produits les plus adaptés à mon besoin?",
  "FAQ_TECH_FITTING_RESPONSE": "Contactez votre contact commercial ou le service client, ils vous guideront pas à pas pour trouver la meilleure solution possible. Vous trouverez leurs coordonnées dans la rubrique <a href='/contact'>Nous contacter</a>.",
  "FAQ_TECH_FITTING_RESPONSE_PHARMA": "Contactez votre interlocuteur commercial, il vous guidera pas à pas pour trouver la meilleure solution possible. Vous trouverez leurs coordonnées dans la rubrique <a href='/contact'>Nous contacter</a>.",
  "FAQ_TECH_MEASUREMENT_QUERY": "Comment puis-je prendre les mesures?",
  "FAQ_TECH_MEASUREMENT_RESPONSE": "Contactez votre représentant commercial ou le service client, ils vous guideront pas à pas pour trouver la meilleure solution possible. Vous trouverez leurs coordonnées dans la rubrique Nous contacter.",
  "FAQ_TIME_EST_QUERY": "Quels sont les délais de livraison estimés?",
  "FAQ_TIME_EST_RESPONSE": "Les délais de livraison varient en fonction du produit concerné. Notre objectif est de vous livrer dans un délai de 5 à 10 jours pour les produits disponibles sur stock.",
  "FAQ_PRINT_ORDER_CONFIRMATION_QUERY": "Comment obtenir ma confirmation de commande?",
  "FAQ_PRINT_ORDER_CONFIRMATION_RESPONSE": "Une confirmation de commande vous sera transmise par e-mail dès validation de celle-ci.",
  "HEADER_TEXT": "Bienvenue sur votre <b>portail client</b> par<br/> HL Display - The better shopping experience",
  "MOUSQUETAIRES_SELECTION": "Sélection Mousquetaires",
  "MOUSQUETAIRES_FILTER_SELECTED": "Sélection",
  "COUNTRY": "Pays",
  "ORDER_STATUS": "Statut de la commande",
  "10%_DISCOUNT_WILL_BE_APPLIED": "10% sera ajouté après la confirmation de commande",
  "SUBMITTING_ORDER": "",
  "DELIVERY_TRACKING": "Suivi de livraison",
  "DELIVERY_TRACKING_LINK": "Lien",
  "CLICK": "Cliquer",
  "HERE": "Ici",
  "COPY_TO_CART": "Copier dans le panier",
  "SEARCH_STORE_NAME_NUMBER": "Recherchez le nom ou le numéro de votre magasin",
  "PACK_SIZE": "Cond.",
  "MEETING": "Réunion",
  "MEETING_TYPE": "Type de réunion",
  "SALES_CYCLE": "Cycle de vente",
  "MEETING_SYSTEM": "Système",
  "MEETINGS": "Réunions",
  "UPDATE_MEETING": "Changement",
  "SYNC_OUTLOOK": "Sync Outlook",
  "SHOP_BY_PRODUCT_LIST": "Produits",
  "SPECIFICATION": "Spécification",
  "TOP_PURCHASED_TITLE": "Meilleures ventes chez Les Mousquetaires",
  "TOP_PURCHASED_TITLE_PHARMA": "Meilleures ventes",
  "ORDER": "Commande",
  "COLOUR": "Couleur",
  "MORE_INFO": "Plus d'informations",
  "TRACKING_EMAIL": "Email suivi livraison",
  "TRACKING_EMAIL_TEMP": "Email suivi livraison temporaire",
  "TRACKING_LINK_TEMP_EMAIL": "À utiliser si le client souhaite que le lien de suivi soit envoyé à un e-mail temporaire",
  "INVOICE_EMAIL": "E-mail de facturation",
  "CUSTOMER_TRACKING_EMAIL_UPDATE_SUCCESS": "Suivi des e-mails mis à jour avec succès",
  "CUSTOMER_TRACKING_EMAIL_UPDATE_ERROR": "Erreur lors de la tentative de mise à jour de l'e-mail de suivi",
  "GOODS_LABEL": "Étiquette de marchandises",
  "GRAVITY_SCOOP_BINS": "Concept Vrac",
  "DISPLAY_ACCESSORIES": "Display Accessories",
  "SHELF_MANAGEMENT_AUTOMATIC": "Facing automatique",
  "LIGHTING_POWER_INFRASTRUCTURE": "Mise en lumière",
  "PRICE_COMMUNICATION": "Porte-étiquettes",
  "FRESH_PRODUCE_SOLUTIONS": "Présentation Fruits & Légumes",
  "PROTECTION_SAFETY_EQUIPMENT": "Protection et sécurité",
  "SHELF_MANAGEMENT_MANUAL": "Séparateurs & Accessoires",
  "VIEW_ALL_PRODUCTS": "Voir tous les produits",
  "MUSKETEERS_SELECTION": "Sélection Mousquetaires",
  "PRESENTOIRS_PANIERS": "Présentoirs & Paniers",
  "STORE_COMMUNICATION_BRANDING": "Communication & signalétique",
  "CAPACITY": "Dimensions",
  "CONFIRM_ORDER_PAYMENT_BY_CARD": "Paiement par Carte Bancaire",
  "CONFIRM_ORDER_PHARMA_PORTAL": "Commander",
  "VAT_INFORMATION_PHARMA_PORTAL": "<span class='highlight'>TVA: </span> 20% sera ajouté à l'étape de paiement",
  "FREIGHT_INFORMATION_PHARMA_PORTAL": "<span class='highlight'>Frais de transport: </span> 25 € H.T. pour toute commande inférieure à 300 € H.T.",
  "FREIGHT_INFORMATION_PHARMA_PORTAL_VALUE": "<span class='highlight'>Frais de transport: </span> {{value}} € H.T. pour toute commande inférieure à {{min}} € H.T.",
  "MINIMUM_ORDER_INFORMATION_PHARMA_PORTAL": "<span class='highlight'>Minimum de commande: </span>100 € H.T",
  "MINIMUM_ORDER_INFORMATION_PHARMA_PORTAL_VALUE": "<span class='highlight'>Minimum de commande: </span>{{value}} € H.T",
  "MINIMUM_ORDER_INFORMATION_CURRYS_PORTAL_VALUE": "<span class='highlight'>Minimum de commande: </span>£ {{value}} ",
  "ADMIN_FEE_INFORMATION_PHARMA_PORTAL": "<span class='highlight'>Frais administratifs: </span> 17 € H.T. pour toute commande inférieure à 300 € H.T.",
  "ADMIN_FEE_INFORMATION_PHARMA_PORTAL_VALUE": "<span class='highlight'>Frais administratifs: </span> {{value}} € H.T. pour toute commande inférieure à {{min}} € H.T.",
  "FREIGHT_INFORMATION_COOP_CUSTOMER": "<span class='highlight'>Frais de transport: </span> ajouté plus tard",
  "ADMIN_FEE_INFORMATION_NORGE_GROUP_SPAR_PORTAL": "<span class='highlight'>Admin: </span>200 NOK seront ajoutés pour les commandes inférieures à 2500 NOK",
  "ADMIN_FEE_INFORMATION_REITA_GROUP_PORTAL": "<span class='highlight'>Admin: </span>300 NOK seront ajoutés pour les commandes inférieures à 2500 NOK",
  "CART_ITEM_QTY_ABOVE_AVAILABLE_QTY": "<span class='quantity-above-than-available'>*</span> La quantité souhaitée d'un ou plusieurs article n'est pas disponible. La date de livraison indiquée tient compte du délai de réapprovisionnement de l'article concerné.",
  "IN_STOCK_BELOW_REQUESTED_QTY": "Reste en stock",
  "CUSTOMER_PORTAL_OH_ORDLEVADR1": "Nom",
  "CUSTOMER_PORTAL_OH_ORDLEVADR2": "C/O",
  "CUSTOMER_PORTAL_OH_ORDLEVADR3": "Adresse",
  "CUSTOMER_PORTAL_OH_ORDLEVADR4": "Ville",
  "CUSTOMER_PORTAL_CITY": "Ville",
  "CUSTOMER_PORTAL_ADDRESS2": "Adresse",
  "CUSTOMER_PORTAL_ADDRESS1": "C/O",
  "CARRIER_CODE": "Code de transporteur",
  "WARNING_NO_USE_EXPLORER": "Pour une expérience utilisateur optimale, utilisez les navigateurs Chrome, Safari, Firefox ou Edge. Microsoft explorer est déconseillé.",
  "SET_PASSWORD": "Changer le mot de passe",
  "RESET_PASSWORD_REQUEST_SENT": "Demande de changement envoyée",
  "PASSWORD_CONFIRM": "Confirmer le mot de passe",
  "EMAIL_INVOICE": "Email envoi facture",
  "EMAIL_QUOTE": "Email envoi devis",
  "EMAIL_QUOTE_TEMPORARY": "Email envoi devis temporaire *",
  "EMAIL_TEXT": "Texte de l'e-mail",
  "MESSAGE_EMAIL_QUOTE_TEMP": "L'adresse email saisie dans ce champ sera celle utilisée pour l'envoi du devis. Si ce champ est vide l'adresse utilisée sera celle saisie dans « Email envoi devis »",
  "SEND_QUOTE": "Envoyer devis",
  "OUTSIDE_ASSORTMENT": "Assortiment extérieur",
  "BLOCKED": "Bloqué",
  "OFFERS": "Offres",
  "ACTIVITIES": "Vos activités",
  "ADD_MEETING": "Ajouter une réunion",
  "MEETING_DATE": "Date de la réunion",
  "FH_KUNDBETALARENR": "Code Payeur",
  "FH_FTGRN": "Code Livré",
  "FH_FTGNAMN": "Client Livré",
  "PAY_CLIENT_NAME": "Client Payeur",
  "OUR_REFERENCE": "Notre Référence",
  "LEFT_TO_PAY": "Il Reste a Payer",
  "TOTAL_VALUE": "Valeur Totale",
  "SAMPLE_ORDER": "Send sample order",
  "SAMPLE": "Echantillons",
  "OFFER": "Offre",
  "PURCHASED": "Purchased",
  "DURATION": "Durée",
  "HOURS": "Heures",
  "PORTE_ETIQUETTE": "Porte Etiquette",
  "IMPULSE_PROMOTION": "Impulse & Promotion",
  "CONTACT_NAME": "Nom",
  "HL_CONTACT_NAME": "HL Display Belgique S.A",
  "C_PHONE": "Tél",
  "POST_ADRESS": "Adresse postale",
  "NETHERLANDS": "Pays-Bas",
  "BELGIUM": "Belgique",
  "EMAIL_QUOTE_ORDER_CONFIRMATION": "Email devis / confirmation de commande",
  "EMAIL_QUOTE_ORDER_CONFIRMATION_TEMPORARY": "Email devis / confirmation de commande - temporaire *",
  "EMAIL_QUOTE_TEMPORARY_REQUIRED": "Email devis / confirmation de commande - champ temporaire obligatoire",
  "PRODUCT_SHELF_TYPE": "Modèle d'étagères",
  "SHELF_AUTOMATION": "Automatisation des rayons",
  "CREDIT_BLOCKED_MODAL_HTML": "Le client <b>{{customerName}}</b> a son crédit bloqué, il ne sera donc pas possible de passer des commandes.",
  "CREDIT_SOFT_BLOCKED_MODAL_HTML": "Le client <b>{{customerName}}</b> ne pourra soumettre des offres que parce que la valeur actuelle du panier est de {{total}} {{currency}} et que son crédit restant est de {{credit}} {{currency}}.",
  "CREDIT_SOFT_BLOCKED": "Seules les offres sont disponibles",
  "READ_ONLY_PRICE": "Prix en lecture seule",
  "CREDIT_BLOCKED_MODAL_HTML_1210": "Malheureusement, la commande ne peut pas être enregistrée, veuillez contacter le service client : info.no@hl-display.com / +47 66 983 600.",
  "CREDIT_BLOCKED_MODAL_HTML_1600": "Malheureusement, nous n'avons pas pu valider votre commande. Pourriez-vous contacter notre service client au 02 47 48 85 00 ? Merci par avance.",
  "EMAIL_QUOTE_TEMPORARY_INVALID": "E-mail fourni invalide!",
  "NO_FREIGHT_NO_VAT": "Hors TVA et transport",
  "ORDER_TITLE": "Commande",
  "PIMITEM_WIDTH_MM": "Largeur millimètre",
  "WIDTH_MM": "Largeur (mm)",
  "CREDIT_BLOCKED_MODAL_HTML_1210_FI": "Malheureusement, la commande ne peut pas être enregistrée, veuillez contacter le service client: info.fi@hl-display.com / +358 9 562 9180",
  "CREDIT_BLOCKED_MODAL_HTML_1210_SE": "Malheureusement, la commande ne peut pas être enregistrée, veuillez contacter le service client: info.se@hl-display.com / +46 8 683 7363",
  "CREDIT_BLOCKED_MODAL_HTML_1210_NO": "Malheureusement, la commande ne peut pas être enregistrée, veuillez contacter le service client: info.no@hl-display.com / +47 66 983 600",
  "CREDIT_BLOCKED_MODAL_HTML_1810_DE": "Malheureusement, la commande ne peut pas être enregistrée, veuillez contacter le service client: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_2400_CH": "Malheureusement, la commande ne peut pas être enregistrée, veuillez contacter le service client: xxx@hl-display.com / xxx",
  "CREDIT_BLOCKED_MODAL_HTML_1600_FR_OR_NL": "Malheureusement, nous n'avons pas pu valider votre commande. Pourriez-vous contacter notre service client au 02 47 48 85 00 ? Merci d'avance.",
  "CREDIT_BLOCKED_MODAL_HTML_1600_BE": "Malheureusement, nous n'avons pas pu valider votre commande. Pourriez-vous contacter notre service client au 02 47 48 85 00 ? Merci d'avance.",
  "CREDIT_BLOCKED_MODAL_HTML_CUSTOMER_SERVICE": "Malheureusement, la commande ne peut pas être enregistrée, veuillez contacter le service client: {customerServiceEmail} / {customerServicePhoneNumber}",
  "EMAIL_ORDER_CONFIRMATION_TEMPORARY": "Température de l'e-mail de confirmation de commande",
  "MESSAGE_EMAIL_ORDER_CONFIRMATION_TEMP": "Les informations ajoutées dans ce champ seront l'e-mail auquel la confirmation de commande est envoyée. En laissant ce champ vide, la confirmation de commande sera envoyée à l'e-mail par défaut.",
  "INTERNAL_CREDIT_BLOCKED_MODAL_HTML": "Malheureusement, la commande ne peut pas être enregistrée, veuillez contacter le service client",
  "Portes Etiquettes": "Porte-étiquettes",
  "POD_FILTER": "Point de livraison",
  "NO_POD_FOUND": "Aucun point de livraison",
  "BASECOST_S_U": "Basecost S / U",
  "PRODUCT_LIST": "Product list",
  "SEE_YOUR_AGREED_ASSORTMENT_AND_PRICES": "See your agreed assortment and prices",
  "ORDER_HISTORY": "Order history",
  "FIND_YOUR_PREVIOUS_ORDERS_TWO_YEAR_BACK": "Find your previous orders two year back",
  "FAVORITE_LIST": "Favorite list",
  "GO_TO_YOUR_FAVOURITE_PRODUCTS": "Go to your favourite products",
  "CONTACT_US": "Contact us",
  "FIND_OUR_CONTECT_INFORMATION_BY_CLICKING_HERE": "Find our contect information by clicking here",
  "HELP_US_IMPROVE": "Help us improve",
  "IF_YOU_WANT_TO_SEND_US_FEEDBACK_OR_HAVE_IDEAS_FOR_IMPROVEMENTS": "If you want to send us feedback or have ideas for improvements on the portal, please use this form.",
  "NEW_FREIGHT_INFORMATION": "<span class='highlight'>Coût du fret:</span> {{value}} {{currency}} si la valeur de la commande est inférieure à {{min}} {{currency}}",
  "NEW_MINIMUM_ORDER_INFORMATION": "<span class='highlight'>Valeur minimale de la commande:</span> {{value}} {{currency}}",
  "NEW_ADMIN_FEE_INFORMATION": "<span class='highlight'>Frais administratifs:</span> {{value}} {{currency}} pour toute commande inférieure à {{min}} {{currency}}",
  "NEW__CUSTOM_FREIGHT_INFORMATION": "<span class='highlight'>Coût du fret:</span> {{value}} {{currency}} si la valeur de la commande est inférieure à {{min}} {{currency}}"
}
