import { Component as e, createElement as t } from "../../../external/preact/dist/preact.js";
import a from "../../../external/classnames/index.js";
class p extends e {
  render({
    buttonColor: e,
    buttonType: p
  }) {
    return t("button", {
      type: "button",
      "aria-label": this.props.i18n.get("payButton"),
      lang: this.props.i18n.languageCode,
      className: a("adyen-checkout__applepay__button", `adyen-checkout__applepay__button--${e}`, `adyen-checkout__applepay__button--${p}`, "apple-pay", "apple-pay-button", `apple-pay-button-${e}`, `apple-pay-button--type-${p}`),
      onClick: this.props.onClick
    });
  }
}
var n, o, l;
l = {
  onClick: () => {},
  buttonColor: "black",
  buttonType: "plain"
}, (o = "defaultProps") in (n = p) ? Object.defineProperty(n, o, {
  value: l,
  enumerable: !0,
  configurable: !0,
  writable: !0
}) : n[o] = l;
export { p as default };
