import { createElement as o } from "../../../external/preact/dist/preact.js";
import { useEffect as t, useCallback as e } from "../../../external/preact/hooks/dist/hooks.js";
import { CtpState as n } from "./services/ClickToPayService.js";
import r from "./context/useClickToPayContext.js";
import s from "./components/CtPOneTimePassword/CtPOneTimePassword.js";
import a from "./components/CtPCards/CtPCards.js";
import i from "./components/CtPSection/CtPSection.js";
import p from "./components/CtPLoader/CtPLoader.js";
import m from "./components/CtPLogin/CtPLogin.js";
import c from "./services/sdks/SrciError.js";
const d = ({
  onDisplayCardComponent: d
}) => {
  const {
    ctpState: l,
    onReady: C,
    startIdentityValidation: P,
    logoutShopper: f
  } = r();
  t(() => {
    [n.OneTimePassword, n.Login, n.Ready].includes(l) && C();
  }, [l, C]), t(() => {
    l === n.ShopperIdentified && async function () {
      try {
        await P();
      } catch (o) {
        o instanceof c && console.warn(`CtP - Identity Validation error: ${o.toString()}`), await f();
      }
    }();
  }, [l]);
  const y = e(o => {
    "Enter" === o.key && (o.preventDefault(), o.stopPropagation());
  }, []);
  return l === n.NotAvailable ? null : o(i, {
    onEnterKeyPress: y
  }, [n.Loading, n.ShopperIdentified].includes(l) && o(p, null), l === n.OneTimePassword && o(s, {
    onDisplayCardComponent: d
  }), l === n.Ready && o(a, {
    onDisplayCardComponent: d
  }), l === n.Login && o(m, null));
};
export { d as default };
