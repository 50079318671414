import { createElement as t } from "../../external/preact/dist/preact.js";
import { UIElement as e } from "../internal/UIElement/UIElement.js";
import { CoreProvider as o } from "../../core/Context/CoreProvider.js";
import n from "../internal/PayButton/PayButton.js";
import { KlarnaContainer as i } from "./components/KlarnaContainer/KlarnaContainer.js";
import { TxVariants as s } from "../tx-variants.js";
function a(t, e, o) {
  return e in t ? Object.defineProperty(t, e, {
    value: o,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[e] = o, t;
}
class r extends e {
  get isValid() {
    return !0;
  }
  formatData() {
    return {
      paymentMethod: {
        type: this.type,
        ...(this.props.useKlarnaWidget ? {
          subtype: "sdk"
        } : {})
      }
    };
  }
  updateWithAction(t) {
    if (t.paymentMethodType !== this.type) throw new Error("Invalid Action");
    this.componentRef.setAction(t);
  }
  onLoaded() {
    this.setElementStatus("ready");
  }
  activate() {
    this.componentRef.reinitializeWidget();
  }
  onComplete(t) {
    this.handleAdditionalDetails(t);
  }
  render() {
    return t(o, {
      i18n: this.props.i18n,
      loadingContext: this.props.loadingContext,
      resources: this.resources
    }, t(i, {
      ...this.props,
      setComponentRef: this.setComponentRef,
      displayName: this.displayName,
      onComplete: this.onComplete,
      onError: this.props.onError,
      payButton: this.payButton,
      onLoaded: this.onLoaded,
      showPayButton: this.props.showPayButton,
      onActionHandled: this.onActionHandled,
      type: this.props.type
    }));
  }
  constructor(e, o) {
    super(e, o), a(this, "componentRef", void 0), a(this, "payButton", e => t(n, {
      amount: this.props.amount,
      onClick: this.submit,
      ...e
    })), this.onComplete = this.onComplete.bind(this), this.updateWithAction = this.updateWithAction.bind(this), this.submit = this.submit.bind(this), this.onLoaded = this.onLoaded.bind(this);
  }
}
a(r, "type", s.klarna), a(r, "txVariants", [s.klarna, s.klarna_account, s.klarna_paynow, s.klarna_b2b]), a(r, "defaultProps", {
  useKlarnaWidget: !1
});
export { r as default };
