<!-- INITIAL IMPLEMENTATION OF BOTTOM BUTTONS
   <div class="d-flex justify-content-between flex-row-reverse">
  <button class="btn btn-primary" type="button" (click)="nextClicked.emit()">
    <span translate>{{nextBtnText}}</span>
  </button>
  <button class="btn btn-light" type="button" (click)="prevClicked.emit()" *ngIf="!isFirstStep">
      <span translate>{{prevBtnText}}</span>
  </button>
</div> -->

<div class="row pt-1">
  <div class="col-4 text-truncate">
    <p class="font-weight-bold" translate>CART_SUBTOTAL</p>
  </div>
  <div class="col-4 text-center text-cart-articles-sku">
    {{ 'CART_ARTICLE_SKU_TOTAL' | translate : { article: cartTotalRows, sku: cartTotalItems } }}
  </div>
  <div class="col-4 font-weight-bold item-info text-primary text-right">
    <lib-price [price]="cartTotalValue" [currency]="cartCurrency"></lib-price>
  </div>
</div>

<div class="row align-items-end">
  <div class="col-12 col-lg-4 order-lg-2" *ngIf="enableDiscount && isLastStep">
    <lib-discount-input
      class="d-block"
      [ngModel]="discountCode ? campaignCode : discountCode" (ngModelChange)="discountCode ? campaignCode : discountCode = $event"
      (discountDescription)="discountDescription = $event"
      (discountCode)="setDiscountCode($event)"
    ></lib-discount-input>
    <div
      class="alert alert-success position-absolute discount-description"
      *ngIf="discountDescription && discountDescription.length > 0"
      style="z-index: 1"
    >
      {{ discountDescription | translate }}
    </div>
  </div>
  <div
    class="col-6 order-1"
    [ngClass]="{ 'col-lg-8': !enableDiscount || !isLastStep, 'col-lg-4': enableDiscount || isLastStep }"
  >
    <button
      type="button"
      class="btn btn-outline-primary btn-sm checkout-btn-previous"
      (click)="isFirstStep ? emptyCart() : prevClicked.emit()"
      translate
    >
      {{ isFirstStep ? 'EMPTY_CART' : prevBtnText }}
    </button>
  </div>
  <div class="col-6 col-lg-4 order-3">
    <div class="row align-items-end">
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-outline-primary btn-sm w-100 btn-save-cart" (click)="saveCart()" *ngIf="isFirstStep">
          {{ 'SAVE_CART' | translate }}
        </button>

        <button
          type="button"
          class="btn btn-outline-primary btn-sm w-100 btn-create-quotation mt-1"
          (click)="createQuotation()"
          *ngIf="isFirstStep && isSales && !createOffers && !checkoutConfig.hideCreateQuotation"
        >
          {{ 'CREATE_QUOTATION' | translate }}
        </button>
      </div>

      <div class="col-12 col-md-6">
        <button
          type="button"
          class="btn btn-outline-primary btn-sm w-100 mt-1"
          (click)="createOffer.emit()"
          *ngIf="isCreateOfferStep && createOffers && (isSales || isAdmin)"
        >
          {{ 'CREATE_OFFER' | translate }}
        </button>
        <button
          class="btn btn-primary w-100 btn-sm mt-1 checkout-btn-next"
          type="button"
          (click)="nextClicked.emit()"
          *ngIf="!isExternalPaymentStep"
          [disabled]="disableNextButton"
        >
          <span translate>{{ nextBtnText }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
