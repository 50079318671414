import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  PriceService,
  ProductCardComponent,
  CartService,
  CustomerProductPrice,
  MetadataService,
  Product,
  SelectedCustomerService,
  Availability,
  Customer,
  AuthService
} from 'gung-standard';
import { first } from 'rxjs';
import { langExists } from '../../services/hl-product.service';
import { environment } from '../../../environments/environment';
import {
  HlDisplayPortalFeaturesService,
  PODSettingsGroup,
  PriceSettingsGroup,
  ProductListInfoSettingsGroup
} from '../../services/hl-display-portal-features.service';
import { ListItemRendererComponent } from 'gung-list';
import {
  HlProductCardListGridViewComponent,
  ProductGrid
} from '../hl-product-card-list-grid-view/hl-product-card-list-grid-view.component';

@Component({
  selector: 'app-hl-product-card-list',
  templateUrl: './hl-product-card-list.component.html',
  styleUrl: './hl-product-card-list.component.scss'
})
export class HlProductCardListComponent extends HlProductCardListGridViewComponent implements OnInit {
  price: CustomerProductPrice;
  private customerId: string;
  isSales: boolean = false;
  public displayDiscountFromPriceList: boolean;
  displayExtraDescription: boolean;
  public displayStaffedPrices: boolean;

  currentLang = this.translateService.currentLang;

  availability: Availability;

  currentCustomer: Customer;

  ngOnInit() {
    super.ngOnInit();

    this.hldisplayPortalFeaturesService
      .getPortalFeatureGroup('priceSettingsGroup')
      .pipe(first())
      .subscribe(priceSettingsGroup => {
        this.displayDiscountFromPriceList = (priceSettingsGroup as PriceSettingsGroup).displayDiscountFromPriceList;
      });
    this.hldisplayPortalFeaturesService
      .getPortalFeatureGroup('productListInfoSettingsGroup')
      .pipe(first())
      .subscribe(productListInfoSettingsGroup => {
        this.displayExtraDescription = (
          productListInfoSettingsGroup as ProductListInfoSettingsGroup
        ).displayExtraDescription;
      });
    this.hldisplayPortalFeaturesService
      .getPortalFeatureGroup('priceSettingsGroup')
      .pipe(first())
      .subscribe(priceSettingsGroup => {
        this.displayStaffedPrices = (priceSettingsGroup as PriceSettingsGroup).displayStaffedPrices;
      });
  }

  getKuarDesc(product): string {
    return this.currentCustomer.extra?.kuarMapped?.[product.id]?.artnrkund || '';
  }

  getSecondaryDescription(product) {
    const artbeskr2 = product.extra.i18n[this.currentLang].artbeskr2;
    const isFound = !!artbeskr2 && !!this.displayExtraDescription;

    return isFound ? artbeskr2 : '';
  }

  mapItem(id: string, product: Product, price: CustomerProductPrice, availability: Availability): ProductGrid {
    this.productSize[id] = this.getProductSize(product);
    return {
      ...product,
      id,
      name: this.getName(product),
      price,
      availability
    };
  }

  getAssortmentType(extra: any): string {
    if (extra.assortmentType == null || extra.assortmentType === 'C') {
      return '';
    }

    return '(' + extra.assortmentType + ')';
  }
}
