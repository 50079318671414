import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  SimpleConfigBaseFilter,
  RenderFilter,
  ConfigService,
  SearchField,
  SubSimpleConfigBaseFilter,
  SubRenderFilter,
  RenderFilterSubValueList,
  RenderFilterValueList,
  IconConfigBaseFilter,
  RenderFilterIconValueList,
  ConceptGroupedConfigBaseFilter
} from '../types';
import { filter } from 'rxjs';
import { FilterListSearchComponent } from '../filter-list-search/filter-list-search.component';
import { gungGetPropertyOfObject, clearEmpties } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class FilterListService<T> {
  rangeFilters = [];

  enableSaveSearch = false

  constructor(protected route: ActivatedRoute) { }

  getInitializedFilters(
    items: T[],
    configService: ConfigService<T>,
    routeSnapshot?: ActivatedRouteSnapshot
  ): RenderFilter[] {
    const configFilters = configService.getFilters(routeSnapshot);
    const result = [];

    configFilters.forEach(configFilter => {
      const newRenderFilter: RenderFilter = {
        name: configFilter.getName(),
        type: configFilter.type,
        valueList: [],
        active: configFilter.isActiveFromStart(),
        hideItemCounter: configFilter.hideItemCounter(),
        isPostFilter: configFilter.isPostFilter(),
        excludedIds: [],
        sort: configFilter.sort?.bind(configFilter),
        sortField: configFilter.getSortField ? configFilter.getSortField() : undefined,
        customClass: configFilter.getCustomClass ? configFilter.getCustomClass() : undefined,
        disableSearch: configFilter.disableSearch,
        conceptGroupedPath: configFilter?.conceptGroupedPath,
        showTodayDate: configFilter?.showTodayDate
      };

      result.push(newRenderFilter);

      if (newRenderFilter.type === 'ConceptGroupedConfigBaseFilter') {
        newRenderFilter.conceptGroupedChoiceMap = {};
      }

      items.forEach(item => {
        if (this.filterIsSimpleConfigBaseFilter(configFilter)) {
          const configFilter2 = configFilter as SimpleConfigBaseFilter<T>;
          const itemOptionIds = configFilter2.getOptionIds(item);
          itemOptionIds.forEach(optionIdAny => {
            // Convert all option ids to strings
            const optionId = optionIdAny ? '' + optionIdAny : '';
            const optionIdWithoutCommas = optionId.replace(/,/g, '.');
            let value = newRenderFilter.valueList.find(optionValue => optionValue.valueId === optionIdWithoutCommas);

            if (!value) {
              value = {
                valueId: optionIdWithoutCommas,
                valueName: configFilter2.getOptionName(optionId, item),
                itemsIncluded: [configService.getItemId(item)],
                selected: false,
                itemCountTotal: 1,
                itemCountAfterFilter: 1,
                hide: false,
                sortIndex: configFilter2.getSortingIndex ? configFilter2.getSortingIndex(optionIdWithoutCommas) : null
              };

              if (!value.valueName) {
                value.valueName = value.valueId;
              }

              if (this.filterIsIconConfigBaseFilter(configFilter)) {
                const configFilter4 = configFilter as IconConfigBaseFilter<T>;
                const icon = configFilter4.getIcon && configFilter4.getIcon(optionId, item);
                value.valueName = configFilter4.getOptionName(optionId, item);
                if (icon) {
                  const value4 = value as RenderFilterIconValueList;
                  value4.icon = icon;
                }
              }

              newRenderFilter.valueList.push(value);
            } else {
              value.itemsIncluded.push(configService.getItemId(item));
              value.itemCountTotal += 1;
              value.itemCountAfterFilter += 1;
            }

            if (this.filterIsSubSimpleConfigBaseFilter(configFilter)) {
              // Get sub values of the current value
              const configFilter3 = (configFilter as SubSimpleConfigBaseFilter<T>).subFilter;
              const subItemOptionIds = configFilter3.getOptionIds(item);
              const newSubRenderFilter = newRenderFilter as SubRenderFilter;
              // const idx = result.indexOf(newRenderFilter);
              newSubRenderFilter.subName = configFilter3.getName();
              const subValue = value as RenderFilterSubValueList;
              if (!subValue.subValueList) {
                subValue.subValueList = [];
              }
              subItemOptionIds.forEach(optionIdAny => {
                // Convert all option ids to strings
                const subOptionId = optionIdAny ? '' + optionIdAny : '';
                const subOptionIdWithoutCommas = subOptionId.replace(',', '.');
                let sub: RenderFilterValueList = subValue.subValueList.find(
                  optionValue => optionValue.valueId === optionIdWithoutCommas + '_' + subOptionIdWithoutCommas
                );
                if (!sub) {
                  sub = {
                    valueId: optionIdWithoutCommas + '_' + subOptionIdWithoutCommas,
                    valueName: configFilter3.getOptionName(subOptionId, item),
                    itemsIncluded: [configService.getItemId(item)],
                    selected: false,
                    itemCountTotal: 1,
                    itemCountAfterFilter: 1,
                    hide: false,
                    sortIndex: configFilter3.getSortingIndex
                      ? configFilter3.getSortingIndex(subOptionIdWithoutCommas)
                      : null
                  };

                  if (!sub.valueName) {
                    sub.valueName = sub.valueId;
                  }

                  subValue.subValueList.push(sub);
                } else {
                  sub.itemsIncluded.push(configService.getItemId(item));
                  sub.itemCountTotal += 1;
                  sub.itemCountAfterFilter += 1;
                }
              });
            }
          });
        }
      });


      // Build choice map of the available options in each filter option
      if (newRenderFilter.type === 'ConceptGroupedConfigBaseFilter') {
        for (const value of newRenderFilter.valueList) {
          const valueId: string = value.valueId.toString();

          if (!newRenderFilter.conceptGroupedChoiceMap[valueId]) {
            newRenderFilter.conceptGroupedChoiceMap[valueId] = {};
          }

          for (const concept of items) {
            const conceptId: string = configService.getItemId(concept);

            for (const [productId, productExtra] of Object.entries((concept as any)?.extra?.productValueMap || {})) {
              if (!newRenderFilter.conceptGroupedChoiceMap[valueId][conceptId]) {
                newRenderFilter.conceptGroupedChoiceMap[valueId][conceptId] = {};
              }
              newRenderFilter.conceptGroupedChoiceMap[valueId][conceptId][productId] = this.matchFilterOption(productExtra, value, newRenderFilter.conceptGroupedPath);
            }
          }
        }
      }
    });

    const res = this.getFilteredRenderFilters(result);
    this.sortRenderFilterValues(res);
    return res;
  }

  protected getFilteredRenderFilters(renderFilters: RenderFilter[]): RenderFilter[] {
    return renderFilters.filter(renderFilter => {
      const valueList = renderFilter.valueList;
      const filteredValueList = valueList.filter(value => !!value.valueId);
      return !!filteredValueList && filteredValueList.length > 0;
    });
  }

  protected sortRenderFilterValues(renderFilters: RenderFilter[]): void {
    renderFilters.map(renderFilter => {
      const renderFilterInternal = renderFilter as RenderFilter;

      if (!!renderFilter.sort) {
        if (renderFilter.sortField) {
          renderFilterInternal.valueList = renderFilterInternal.valueList.sort((a, b) =>
            renderFilter.sort(a[renderFilter.sortField], b[renderFilter.sortField])
          );
        } else {
          renderFilterInternal.valueList = renderFilterInternal.valueList.sort((a, b) =>
            renderFilter.sort(a.valueName, b.valueName)
          );
        }
      } else if (renderFilter.valueList.findIndex(f => !!f.sortIndex) > -1) {
        renderFilterInternal.valueList = renderFilterInternal.valueList.sort(({ sortIndex: a }, { sortIndex: b }) => {
          if (a && b) {
            return Number(b) - Number(a);
          }
          if (a) {
            return -1;
          }
          if (b) {
            return 1;
          }
          return 0;
        });
      } else {
        renderFilterInternal.valueList = renderFilterInternal.valueList.sort((a, b) => {
          const aValueName = Array.isArray(a.valueName) ? a.valueName.join('') : a.valueName;
          const bValueName = Array.isArray(b.valueName) ? b.valueName.join('') : b.valueName;
          // Number
          if (
            aValueName.trim() !== '' &&
            bValueName.trim() !== '' &&
            !isNaN(Number(aValueName)) &&
            !isNaN(Number(bValueName))
          ) {
            return Number(aValueName) - Number(bValueName);
          }
          // Other Alphanumeric
          return aValueName.localeCompare(bValueName);
        });
      }

      return renderFilterInternal;
    });
  }

  getFilteredList(
    items: T[],
    searchTerms: Record<string, string>,
    configService: ConfigService<T>,
    filters: RenderFilter[]
  ): T[] {
    if (!items) {
      return [];
    }
    let filteredItems = items;
    const allIds = items.map(item => configService.getItemId(item));
    const searchFields = configService.getSearchFields?.() || [this.getDefaultSearchField(configService)];
    for (const key in searchTerms) {
      if (typeof key !== 'string') {
        continue;
      }
      const field = searchFields.find(sf => sf.getKey() === key);
      if (!field) {
        // old search field found in url most probably
        continue;
      }
      filteredItems = this.filterBySearchTerm(searchTerms[key], filteredItems, field, configService.searchDisabled);
    }

    const filteredItemIds = new Set(filteredItems.map(item => configService.getItemId(item)));
    const excludedBySearch = items
      .map(item => configService.getItemId(item))
      .filter(itemId => !filteredItemIds.has(itemId));

    filters.forEach(renderFilter => {
      renderFilter.excludedIds = [];
    });

    const filtersWithSelection = filters.filter(renderFilter => {
      const optionWithSelection = renderFilter.valueList.find(value => value.selected);
      if (renderFilter.type !== 'RangeConfigBaseFilter' && renderFilter.type !== 'dateRangeFilter') {
        return !!optionWithSelection;
      }
      const maxRange = Math.max(...renderFilter.valueList.map(v => Number(v.valueId)));
      const minRange = Math.min(...renderFilter.valueList.map(v => Number(v.valueId)));
      if (
        (!renderFilter.maxValue && !renderFilter.minValue) ||
        (maxRange === renderFilter.maxValue && minRange === renderFilter.minValue)
      ) {
        return !!optionWithSelection;
      }
      return renderFilter;
    });

    filtersWithSelection.forEach(renderFilter => {
      const includedIds = renderFilter.valueList
        .filter(value => value.selected)
        .map(value => {
          if (
            renderFilter.type === 'SubSimpleConfigBaseFilter' &&
            (value as RenderFilterSubValueList).subValueList.filter(subValue => subValue.selected)?.length > 0
          ) {
            // If sub filter selected show only the selected sub items
            return value.itemsIncluded.filter(id =>
              (value as RenderFilterSubValueList).subValueList
                .filter(subValue => subValue.selected)
                .map(subValue => subValue.itemsIncluded)
                .flat()
                .includes(id)
            );
          }
          return value.itemsIncluded;
        })
        .reduce((accumulatedIds, currentValueIds) => accumulatedIds.concat(currentValueIds), [])
        .reduce((accumulatedSet, currentIds) => accumulatedSet.add(currentIds), new Set());
      // items are filtered iteratively
      filteredItems = filteredItems.filter(item => {
        const itemId = configService.getItemId(item);
        if (!itemId) {
          throw new Error('Item ID could not be determined, please check the config service. ');
        }
        return includedIds.has(itemId);
      });

      renderFilter.excludedIds = allIds.filter(id => !includedIds.has(id));
    });




    // Concept Grouped Filters //
    if ((filteredItems as any[]).every(c => c?.productType === 'concept' && !!c?.extra?.productValueMap)) {
      // Filter concepts based on product values
      filteredItems = this.filterConceptGroupedResults(filteredItems, filters, configService);
      const filteredItemIds: Set<string> = new Set(filteredItems.map(item => configService.getItemId(item)));

      for (const renderFilter of filters) {
        renderFilter.excludedIds = Array.from(new Set(allIds.filter(id => !filteredItemIds.has(id))));
      }
    }

    let availableSelectedFilterChoices: { [conceptId: string]: { [productId: string]: boolean } } = {};
    let availableSelectedFilterChoicesArray: { [conceptId: string]: { [productId: string]: boolean } }[] = [];
    // If all selected filters are ConceptGroupedConfigBaseFilters
    if (filtersWithSelection.every(f => f.type === 'ConceptGroupedConfigBaseFilter')) {
      // Get choice map for all selected values and remove properties with value = false and clear empties
      for (const selectedFilter of filtersWithSelection) {
        const selectedValueIds: string[] = selectedFilter.valueList.filter(v => v.selected).map(v => v.valueId);
        const selectedConceptGroupedChoiceMap: { [s: string]: any } = selectedFilter.conceptGroupedChoiceMap;
        const test = {};

        for (const selectedValueId of selectedValueIds) {
          const conceptProduct: { [s: string]: any } = selectedConceptGroupedChoiceMap[selectedValueId];

          for (const conceptId in conceptProduct) {
            const product: { [s: string]: boolean } = conceptProduct[conceptId];

            if (!test[conceptId]) {
              test[conceptId] = {};
            }

            for (const productId in product) {
              const result: boolean = product[productId];

              if (result) {
                test[conceptId][productId] = result;
              }
            }
          }
        }

        availableSelectedFilterChoicesArray.push(clearEmpties(test));
      }

      // If availableSelectedFilterChoicesArray has items then intersect all choice maps and generate final choice map
      if (availableSelectedFilterChoicesArray.length > 0) {
        for (const key in availableSelectedFilterChoicesArray[0]) {
          if (availableSelectedFilterChoicesArray.every(obj => obj.hasOwnProperty(key))) {
            const products: { [s: string]: boolean } = availableSelectedFilterChoicesArray[0][key];
            if (products) {
              for (const productId in products) {
                if (availableSelectedFilterChoicesArray.every(obj => obj[key].hasOwnProperty(productId) && !!obj[key][productId])) {
                  if (!availableSelectedFilterChoices[key]) {
                    availableSelectedFilterChoices[key] = {};
                  }
                  availableSelectedFilterChoices[key][productId] = availableSelectedFilterChoicesArray[0][key][productId];
                }
              }
            }
          }
        }
      }
    }
    // Concept Grouped Filters //



    // Update postfilter info
    filters.forEach(renderFilter => {
      if (!renderFilter.isPostFilter) {
        return;
      }

      let excludedByOthers = excludedBySearch;

      filtersWithSelection.forEach(internalFilter => {
        if (internalFilter === renderFilter) {
          return;
        }

        excludedByOthers = excludedByOthers.concat(internalFilter.excludedIds);
      });

      const excludedByOthersSet = new Set(excludedByOthers);
      renderFilter.valueList.forEach(value => {
        const tmp = value.itemsIncluded.filter(includedItem => !excludedByOthersSet.has(includedItem));
        value.itemCountAfterFilter = tmp.length;
        value.hide =
          (configService.hideZeroOptions && configService.hideZeroOptions() && tmp.length === 0) ||
          (configService.hideZeroOptions === undefined && tmp.length === 0);
      });

      if (renderFilter.type === 'ConceptGroupedConfigBaseFilter' && !(renderFilter.valueList.filter(v => !!v.selected).length > 0)) {
        renderFilter.valueList.forEach(value => {
          const result: number = this.matchProductChoiceMatch(availableSelectedFilterChoices, renderFilter.conceptGroupedChoiceMap[value.valueId]);

          if (!isNaN(result)) {
            value.itemCountAfterFilter = result;
            value.hide = result === 0;
          }
        });
      }
    });

    return filteredItems;
  }

  filterBySearchTerm(searchTerm: string, items: T[], searchField: SearchField<T>, searchDisabled: boolean): T[] {
    if (!searchDisabled) {
      const queryTerms = searchField.mapSearchTerm?.(searchTerm) || this.defaultMapSearchTerm(searchTerm);
      return items.filter(item => {
        const itemTerms = searchField.getSearchTerms(item);
        if (searchField.filterBySearchTerms) {
          /**
           * If we set filterBySearchTerms we should not use defaultFilterByQueryTerms
           */
          return searchField.filterBySearchTerms?.(queryTerms, itemTerms);
        }
        return this.defaultFilterByQueryTerms(queryTerms, itemTerms);
      });
    }
    return items;
  }

  defaultMapSearchTerm(query: string): string[] {
    return query
      .split(' ')
      .map(e => e.trim().toUpperCase())
      .filter(e => e.length > 0);
  }

  defaultFilterByQueryTerms(queryTerms: string[], itemTerms: string[]): boolean {
    let hasHitAllTerms = true;
    queryTerms.forEach(queryTerm => {
      const locatedTerm = itemTerms.find(term => {
        if (term === null || term === undefined) {
          return false;
        }
        return term.toUpperCase().indexOf(queryTerm) >= 0;
      });

      hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
    });

    return hasHitAllTerms;
  }

  getDefaultSearchField(configService: ConfigService<T>): SearchField<T> {
    return {
      getComponent: () => {
        return FilterListSearchComponent;
      },
      getKey: () => {
        return 'DEFAULT';
      },
      getSearchTerms: (item: T) => configService.getSearchTerms(item),
      getPlaceHolder: () => configService.getSearchPlaceholderTranslate?.() || 'SEARCH',
      componentClass: () => 'col-12'
    };
  }

  protected filterIsSimpleConfigBaseFilter(configFilter: ConfigBaseFilter<T>): configFilter is SimpleConfigBaseFilter<T> {
    if (
      (configFilter as SimpleConfigBaseFilter<T>).type === 'SimpleConfigBaseFilter' ||
      (configFilter as SimpleConfigBaseFilter<T>).type === 'dateRangeFilter' ||
      (configFilter as SimpleConfigBaseFilter<T>).type === 'ColorConfigBaseFilter' ||
      (configFilter as SimpleConfigBaseFilter<T>).type === 'IconConfigBaseFilter'
    ) {
      return true;
    }
    // RangeConfigBaseFilter work like SimpleConfigBaseFilter
    if ((configFilter as SimpleConfigBaseFilter<T>).type === 'RangeConfigBaseFilter') {
      return true;
    }
    // SubSimpleConfigBaseFilter
    if ((configFilter as SubSimpleConfigBaseFilter<T>).type === 'SubSimpleConfigBaseFilter') {
      return true;
    }
    // ConceptGroupedConfigBaseFilter
    if ((configFilter as ConceptGroupedConfigBaseFilter<T>).type === 'ConceptGroupedConfigBaseFilter') {
      return true;
    }
    return false;
  }

  protected filterIsSubSimpleConfigBaseFilter(
    configFilter: ConfigBaseFilter<T>
  ): configFilter is SimpleConfigBaseFilter<T> {
    // SubSimpleConfigBaseFilter
    if ((configFilter as SubSimpleConfigBaseFilter<T>).type === 'SubSimpleConfigBaseFilter') {
      return true;
    }
    return false;
  }

  protected filterIsIconConfigBaseFilter(configFilter: ConfigBaseFilter<T>): configFilter is SimpleConfigBaseFilter<T> {
    // IconConfigBaseFilter
    if ((configFilter as IconConfigBaseFilter<T>).type === 'IconConfigBaseFilter') {
      return true;
    }
    return false;
  }

  protected filterConceptGroupedResults(items: T[], filters: RenderFilter[], configService: ConfigService<T>): T[] {
    const activeConceptGroupedFilters = filters.filter(
      f => f.type === 'ConceptGroupedConfigBaseFilter' && f.valueList.find(v => v.selected)
    );

    // Check if there are no concept grouped filters with selected values, if there are none return all items
    if (activeConceptGroupedFilters.length === 0) {
      return items;
    }

    let valuesToTest: { filterIndex: number; valueIds: string[]; valuePath: string }[] = [];

    // Get selected values from the concept grouped filters
    for (let index = 0; index < activeConceptGroupedFilters.length; index++) {
      const filter = activeConceptGroupedFilters[index];
      const activeValues = filter.valueList.filter(v => v.selected);
      valuesToTest = [
        ...valuesToTest,
        { filterIndex: index, valueIds: [...activeValues.map(v => v.valueId)], valuePath: filter?.conceptGroupedPath }
      ];
    }

    const itemsToKeepId: string[] = [];

    // Iterate through items
    for (const item of items) {
      // Iterate through a product map inside items
      for (const productValues of Object.values((item as any)?.extra?.productValueMap)) {
        const matches: boolean[] = [];

        // Iterate selected values in filters
        for (const valueTest of valuesToTest) {
          const value: any = gungGetPropertyOfObject(valueTest.valuePath, productValues);

          // Check if value inside product map is an array, or not
          if (Array.isArray(value) && value.length >= 1) {
            // If is array, iterate
            for (const v of value) {
              // Check if v is pim metadata or normal value and test for matches
              if (typeof v === 'object') {
                // This is done in a previous step, needs to be done here as well
                const descriptionWithoutCommas: string = (v.description as string).replace(/,/g, '.');
                if (valueTest.valueIds.includes(descriptionWithoutCommas)) {
                  matches.push(true);
                  break;
                }
              } else if (valueTest.valueIds.includes(v?.replace(/,/g, '.'))) {
                matches.push(true);
                break;
              }
            }
          } else {
            // If is value, convert to string and check if it matches
            if (valueTest.valueIds.includes(value?.toString()?.replace(/,/g, '.'))) {
              matches.push(true);
            } else {
              matches.push(false);
            }
          }
        }

        // Check if number of matches is equal to selected values and if every match is true
        if (matches.length === valuesToTest.length && matches.every(match => !!match)) {
          // Add to items to keep
          itemsToKeepId.push(configService.getItemId(item));
          break;
        }
      }
    }

    return items.filter(item => itemsToKeepId.includes(configService.getItemId(item)));
  }

  protected matchFilterOption(productValueMap: { [s: string]: any }, value: RenderFilterValueList, path: string): boolean {
    const valueToTest: string = value.valueId;
    const productValue: any = gungGetPropertyOfObject(path, productValueMap);

    // Check if value inside product map is an array, or not
    if (Array.isArray(productValue) && productValue.length >= 1) {
      // If is array, iterate
      for (const v of productValue) {
        if (typeof v === 'object' && v !== null && Object.keys(v).length === 0) {
          continue;
        }

        // Check if v is pim metadata or normal value and test for matches
        if (typeof v === 'object') {
          // This is done in a previous step, needs to be done here as well
          const descriptionWithoutCommas: string = (v.description as string).replace(/,/g, '.');
          if (valueToTest.includes(descriptionWithoutCommas)) {
            return true;
          }
        } else if (valueToTest === v?.toString()?.replace(/,/g, '.')) {
          return true;
        }
      }
    } else {
      // If is value, convert to string and check if it matches
      if (valueToTest === productValue?.toString()?.replace(/,/g, '.')) {
        return true
      } else {
        return false;
      }
    }

    return false;
  }

  protected matchProductChoiceMatch(availableSelectedFilterChoices: { [s: string]: any }, testFilterChoices: { [s: string]: any }): number {
    // If no selected filters then nothing to hide
    if (Object.keys(availableSelectedFilterChoices).length === 0) {
      return undefined;
    }

    let result: number = 0;

    // Iterate through available choices
    for (const conceptId in availableSelectedFilterChoices) {
      const products: { [s: string]: boolean } = availableSelectedFilterChoices[conceptId];
      const testProducts: { [s: string]: boolean } = testFilterChoices[conceptId];

      // Check if available choices and testChoices have the same concept available
      if (!!testProducts && !!products) {
        // Iterate through products
        for (const productId in products) {
          const value: boolean = products[productId];
          const valueTest: boolean = testProducts[productId];

          // Check if booth available and test have some product inside concept that matches
          if (!!value && !!valueTest) {
            result++;
            break;
          }
        }
      }
    }

    return result;
  }
}
