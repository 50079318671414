import { ANALYTICS_EVENT as e, ANALYTICS_ACTION_STR as t, ANALYTICS_SUBMIT_STR as a, ANALYTICS_VALIDATION_ERROR_STR as n, ANALYTICS_SELECTED_STR as s, ANALYTICS_DOWNLOAD_STR as o, ANALYTICS_INPUT_STR as r, ANALYTICS_DISPLAYED_STR as c, ANALYTICS_UNFOCUS_STR as i, ANALYTICS_FOCUS_STR as p, ANALYTICS_CONFIGURED_STR as y, ANALYTICS_RENDERED_STR as d, ANALYTICS_EXPRESS_PAGES_ARRAY as m } from "./constants.js";
import { THREEDS2_ERROR as l, THREEDS2_FULL as v } from "../../components/ThreeDS2/constants.js";
import g, { SDK_ERROR as b } from "../Errors/AdyenCheckoutError.js";
import { getCardConfigData as E } from "./utils.js";
const f = f => (u, h, k = {}) => {
  const {
    type: A,
    target: M
  } = h;
  if (!A) throw new g(b, "You are trying to create an analytics event without a type");
  switch (A) {
    case d:
      {
        const {
            isStoredPaymentMethod: t,
            brand: a,
            configData: n
          } = h,
          {
            isExpress: s,
            expressPage: o
          } = k,
          r = o && m.includes(o),
          {
            type: c
          } = k;
        let i = null;
        "scheme" !== c && "bcmc" !== c && "customcard" !== c || (i = E(k));
        const p = {
          component: u,
          type: A,
          ...("boolean" == typeof t && {
            isStoredPaymentMethod: t
          }),
          ...(a && {
            brand: a
          }),
          ...("boolean" == typeof s && {
            isExpress: s
          }),
          ...(!0 === s && r && {
            expressPage: o
          }),
          ...(i && {
            configData: i
          }),
          ...(n && {
            configData: n
          })
        };
        f.createAnalyticsEvent({
          event: e.info,
          data: p
        });
        break;
      }
    case y:
      {
        const {
            isStoredPaymentMethod: t,
            brand: a
          } = h,
          n = {
            component: u,
            type: A,
            isStoredPaymentMethod: t,
            brand: a
          };
        f.createAnalyticsEvent({
          event: e.info,
          data: n
        });
        break;
      }
    case p:
    case i:
    case c:
    case r:
    case o:
      f.createAnalyticsEvent({
        event: e.info,
        data: {
          component: u,
          type: A,
          target: M
        }
      });
      break;
    case s:
      {
        const {
          issuer: t
        } = h;
        f.createAnalyticsEvent({
          event: e.info,
          data: {
            component: u,
            type: A,
            target: M,
            issuer: t
          }
        });
        break;
      }
    case n:
      {
        const {
          validationErrorCode: t,
          validationErrorMessage: a
        } = h;
        f.createAnalyticsEvent({
          event: e.info,
          data: {
            component: u,
            type: A,
            target: M,
            validationErrorCode: t,
            validationErrorMessage: a
          }
        });
        break;
      }
    case a:
      f.createAnalyticsEvent({
        event: e.log,
        data: {
          component: u,
          type: A,
          message: "Shopper clicked pay"
        }
      });
      break;
    case t:
      {
        const {
          subtype: t,
          message: a
        } = h;
        f.createAnalyticsEvent({
          event: e.log,
          data: {
            component: u,
            type: A,
            subtype: t,
            message: a
          }
        });
        break;
      }
    case v:
      {
        const {
          message: t,
          metadata: a,
          subtype: n,
          result: s
        } = h;
        f.createAnalyticsEvent({
          event: e.log,
          data: {
            component: u,
            type: A,
            message: t,
            metadata: a,
            subtype: n,
            result: s
          }
        });
        break;
      }
    case l:
    case e.error:
      {
        const {
          message: t,
          code: a,
          errorType: n
        } = h;
        f.createAnalyticsEvent({
          event: e.error,
          data: {
            component: u,
            type: A,
            message: t,
            code: a,
            errorType: n
          }
        });
        break;
      }
    default:
      f.createAnalyticsEvent(h);
  }
};
export { f as analyticsPreProcessor };
