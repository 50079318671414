import { Component as e, createElement as t } from "../../../../external/preact/dist/preact.js";
import r from "./DoFingerprint3DS2.js";
import { isErrorObject as o, createOldFingerprintResolveData as s, createFingerprintResolveData as i, prepareFingerPrintData as n } from "../utils.js";
import { isValidHttpUrl as a } from "../../../../utils/isValidURL.js";
import { THREEDS2_FINGERPRINT_ERROR as p, MISSING_TOKEN_IN_ACTION_MSG as d, TIMEOUT as l, THREEDS2_ERROR as m, THREEDS2_FULL as h, THREEDS2_NUM as c, THREEDS2_FINGERPRINT as S } from "../../constants.js";
import { Analytics3DS2Errors as D, ANALYTICS_ERROR_TYPE as u, Analytics3DS2Events as g } from "../../../../core/Analytics/constants.js";
function f(e, t, r) {
  return t in e ? Object.defineProperty(e, t, {
    value: r,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = r, e;
}
class C extends e {
  componentDidMount() {
    if (!o(this.state.fingerPrintData)) {
      const e = "test" === this.props.environment && this.props._environmentUrls?.api?.includes("http://localhost:8080"),
        {
          threeDSMethodURL: t,
          threeDSMethodNotificationURL: r,
          postMessageDomain: o,
          threeDSServerTransID: s
        } = this.state.fingerPrintData;
      if (!a(t, e)) return this.setStatusComplete({
        threeDSCompInd: "U"
      }, {
        errorCode: D.TOKEN_IS_MISSING_THREEDSMETHODURL,
        message: `${p}: Decoded token is missing a valid threeDSMethodURL property`
      }), void console.debug("### PrepareFingerprint3DS2::exiting:: no valid threeDSMethodURL");
      const i = a(r, e),
        n = a(o, e),
        d = s?.length;
      if (!i || !n || !d) return this.setStatusComplete({
        threeDSCompInd: "N"
      }, {
        errorCode: D.TOKEN_IS_MISSING_OTHER_PROPS,
        message: `${p}: Decoded token is missing one or more of the following properties (threeDSMethodNotificationURL | postMessageDomain | threeDSServerTransID)`
      }), void console.debug("### PrepareFingerprint3DS2::exiting:: Decoded token is missing one or more of the following properties (threeDSMethodNotificationURL | postMessageDomain | threeDSServerTransID)");
      this.setState({
        status: "retrievingFingerPrint"
      });
    } else {
      const e = this.state.fingerPrintData.error,
        t = e.indexOf(d) > -1 ? D.ACTION_IS_MISSING_TOKEN : D.TOKEN_DECODE_OR_PARSING_FAILED;
      this.setStatusComplete({
        threeDSCompInd: "N"
      }, {
        errorCode: t,
        message: `${p}: ${e}`
      }), console.debug("### PrepareFingerprint3DS2:: token does not exist or could not be base64 decoded &/or JSON.parsed");
    }
  }
  setStatusComplete(e, t = null) {
    this.setState({
      status: "complete"
    }, () => {
      const r = (this.props.isMDFlow ? s : i)(this.props.dataKey, e, this.props.paymentData);
      let o;
      const n = t || e;
      if (n.errorCode) {
        const e = {
          code: n.errorCode === l ? D.THREEDS2_TIMEOUT : n.errorCode,
          errorType: n.errorCode === l ? u.network : u.apiError
        };
        o = {
          type: m,
          message: n.message,
          ...e
        }, this.props.onSubmitAnalytics(o);
      }
      let a;
      switch (e?.threeDSCompInd) {
        case "Y":
          a = "success";
          break;
        case "N":
          a = t ? t.errorCode === l ? l : "failedInternal" : "failed";
          break;
        case "U":
          a = "noThreeDSMethodURL";
      }
      o = {
        type: h,
        message: `${c} fingerprinting has completed`,
        subtype: g.FINGERPRINT_COMPLETED,
        result: a
      }, this.props.onSubmitAnalytics(o), this.props.onComplete(r);
    });
  }
  render({
    showSpinner: e
  }, {
    status: o,
    fingerPrintData: s
  }) {
    return "retrievingFingerPrint" === o ? t(r, {
      onCompleteFingerprint: e => {
        this.setStatusComplete(e.result);
      },
      onErrorFingerprint: e => {
        const t = {
          errorCode: e.errorCode,
          message: `${S}: ${e.errorCode}`
        };
        this.setStatusComplete(e.result, t);
      },
      showSpinner: e,
      ...s,
      onActionHandled: this.props.onActionHandled,
      onFormSubmit: this.onFormSubmit
    }) : null;
  }
  constructor(e) {
    super(e), f(this, "onFormSubmit", e => {
      this.props.onSubmitAnalytics({
        type: h,
        message: e,
        subtype: g.FINGERPRINT_DATA_SENT
      });
    });
    const {
      token: t,
      notificationURL: r
    } = this.props;
    if (t) {
      const e = n({
        token: t,
        notificationURL: r
      });
      this.state = {
        status: "init",
        fingerPrintData: e
      };
    } else this.state = {
      fingerPrintData: {
        success: !1,
        error: d
      }
    }, console.debug(`${p}: ${d}`);
  }
}
f(C, "type", "scheme"), f(C, "defaultProps", {
  onComplete: () => {},
  onError: () => {},
  paymentData: "",
  showSpinner: !0,
  isMDFlow: !1
});
export { C as default };
