import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  BuyBtnComponent,
  CartService,
  GungFlowService,
  GungModalService,
  ProductInputQuantityConfigService
} from 'gung-standard';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import {
  CheckoutSettingsGroup,
  HlDisplayPortalFeaturesService
} from '../../services/hl-display-portal-features.service';
import { first, forkJoin, map } from 'rxjs';

@Component({
  selector: 'app-hl-display-buy-btn',
  templateUrl: './hl-display-buy-btn.component.html',
  styleUrls: ['./hl-display-buy-btn.component.scss']
})
export class HlDisplayBuyBtnComponent extends BuyBtnComponent implements OnInit {
  isAnyStepAmount: boolean = false;

  constructor(
    protected cartService: CartService,
    protected gungFlowService: GungFlowService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected authService: AuthService,
    protected router: Router,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(
      cartService,
      gungFlowService,
      productInputQuantityConfigService,
      gungModalService,
      translationService,
      authService,
      router
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('orderCheckoutSettingsGroup')
      .pipe(
        first(),
        map(settingsGroup => settingsGroup as CheckoutSettingsGroup)
      )
      .pipe(first())
      .subscribe(orderCheckoutSettingsGroup => {
        this.isAnyStepAmount = orderCheckoutSettingsGroup.changeToAnyQtyAsUser;
      });
  }

  set(value: number) {
    // for customer portal when set the value
    // then Adjust qty up to closest Sales QTY (this means that the value is always multiple of the minOrderQty)
    // for example
    // minOrderQty is 20
    // value being set is 123
    // then it should be rounded up to 140

    if (this.isAnyStepAmount || this.isSales || this.isAdmin) {
      this.salesPortalSet(value);
    } else {
      if (value % this.minimumOrderQuantity === 0) {
        super.set(value);
      } else {
        const valueRoundedUp =
          Math.trunc(value / this.minimumOrderQuantity) * this.minimumOrderQuantity + this.minimumOrderQuantity;
        super.set(valueRoundedUp);
      }
    }
  }

  private salesPortalSet(value: number) {
    if (!this.minimumOrderQuantity) {
      this.minimumOrderQuantity = this.productInputQuantityConfigService.getMinimumOrderQuantity(
        this.id,
        this.product,
        this.price
      );
    }
    // for sales portal when adjust the value, ignore the stepAmount
    if (value >= this.minimumOrderQuantity) {
      this.cartService.setProductQuantity(this.id, value, this.targetStockId, this.productPartialId);
    } else {
      super.set(value);
    }
  }
}
