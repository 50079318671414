import t from "./SrciError.js";
import e from "../../../../../utils/Script.js";
function i(t, e, i) {
  return e in t ? Object.defineProperty(t, e, {
    value: i,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[e] = i, t;
}
class s {
  async loadSdkScript() {
    this.isSdkIsAvailableOnWindow() || (this.scriptElement = new e(this.sdkUrl), await this.scriptElement.load()), this.assignSdkReference();
  }
  removeSdkScript() {
    this.scriptElement.remove();
  }
  async checkout(e) {
    try {
      return await this.schemeSdk.checkout(e);
    } catch (e) {
      throw new t(e, "checkout", this.schemeName);
    }
  }
  async unbindAppInstance() {
    try {
      await this.schemeSdk.unbindAppInstance();
    } catch (e) {
      throw new t(e, "unbindAppInstance", this.schemeName);
    }
  }
  async isRecognized() {
    try {
      return await this.schemeSdk.isRecognized();
    } catch (e) {
      throw new t(e, "isRecognized", this.schemeName);
    }
  }
  async initiateIdentityValidation() {
    try {
      return await this.schemeSdk.initiateIdentityValidation();
    } catch (e) {
      throw new t(e, "initiateIdentityValidation", this.schemeName);
    }
  }
  async getSrcProfile(e) {
    try {
      return await this.schemeSdk.getSrcProfile({
        idTokens: e
      });
    } catch (e) {
      throw new t(e, "getSrcProfile", this.schemeName);
    }
  }
  constructor(t, e) {
    if (i(this, "schemeSdk", void 0), i(this, "customSdkConfiguration", void 0), i(this, "sdkUrl", void 0), i(this, "scriptElement", null), !t) throw Error("AbstractSrcInitiator: Invalid SDK URL");
    this.sdkUrl = t, this.customSdkConfiguration = e;
  }
}
export { s as default };
