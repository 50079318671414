import { useRef as r, useEffect as e } from "../../../external/preact/hooks/dist/hooks.js";
import { CountdownA11yReporter as t } from "./CountdownA11yReporter.js";
import o from "../../../core/Errors/useSRPanelContext.js";
import { useCoreContext as n } from "../../../core/Context/CoreProvider.js";
const s = s => {
  const {
      i18n: c
    } = n(),
    {
      srPanel: u
    } = o(),
    a = r(null);
  e(() => (a.current = new t({
    i18n: c,
    srPanel: u
  }), () => {
    a.current.tearDown();
  }), []), e(() => {
    try {
      if (!a.current) return;
      a.current.update(s);
    } catch (r) {
      throw a.current.tearDown(), a.current = null, r;
    }
  }, [s]);
};
export { s as useCountdownA11yReporter };
