import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import t from "../../../../../external/classnames/index.js";
import n from "./CtPLogoutLink.js";
import { CtPBrand as c } from "../CtPBrand/CtPBrand.js";
import a from "../../context/useClickToPayContext.js";
const o = ({
  children: o,
  onEnterKeyPress: s
}) => {
  const {
    isStandaloneComponent: r
  } = a();
  return e("div", {
    className: t("adyen-checkout-ctp__section", {
      "adyen-checkout-ctp__section--standalone": r
    }),
    onKeyPress: s
  }, e("div", {
    className: "adyen-checkout-ctp__section-brand"
  }, e(c, null), e(n, null)), o);
};
o.Title = ({
  endAdornment: t,
  children: n
}) => e("div", {
  className: "adyen-checkout-ctp__section-header"
}, e("h1", {
  className: "adyen-checkout-ctp__section-header-title"
}, n), t && e("span", {
  className: "adyen-checkout-ctp__section-header-adornment"
}, t)), o.Text = ({
  children: t
}) => e("p", {
  className: "adyen-checkout-ctp__section-text"
}, t);
export { o as default };
