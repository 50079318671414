import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GungDataExportConfirmModalComponent } from '../../components/gung-data-export-confirm-modal/gung-data-export-confirm-modal.component';
import { GungDataExportPricatConfirmModalComponent } from '../../components/gung-data-export-pricat-confirm-modal/gung-data-export-pricat-confirm-modal.component';
import { AvailabilityModalComponent } from '../../components/availability/availability-modal/availability-modal.component';
import { Availability } from '../../models/availability';
import { CreateAvailabilityConditionModalComponent } from '../../components/availability-conditions/create-availability-condition-modal/create-availability-condition-modal.component';
import { News } from '../../models/news';
import { NewsModalComponent } from '../../components/news/news-modal/news-modal.component';
import { NewsEditorModalComponent } from '../../components/news/news-editor-modal/news-editor-modal.component';
import {
  Customer,
  Product,
  SupplierPurchaseComment,
  SupplierPurchaseOrder,
  SupplierPurchaseOrderHistory,
  SupplierTableColumns
} from '../../models';
import { ActivityInternalCreateModalComponent } from '../../components/activity-internal/activity-internal-create-modal/activity-internal-create-modal.component';
import { DiscountCode } from '../discount-codes.service';
import { DiscountCodesModalComponent } from '../../components/discount-codes/discount-codes-modal/discount-codes-modal.component';
import { CreateUserModalComponent } from '../../components/users/create-user-modal/create-user-modal.component';
import { ActivityInternal } from '../activity-internal/activity-internal.service';
import { OrderStatistic } from '../../models/order-statistics';
import { TopProductsCreatePurchaseOrderModalComponent } from '../../components/top-products/top-products-create-purchase-order-modal/top-products-create-purchase-order-modal.component';
import { CustomerContact } from '../../models/customerContact';
import { CustomerContactAddEditModalComponent } from '../../components/customer-contact-add-edit-modal/customer-contact-add-edit-modal.component';
import { CommonModalService } from 'gung-common';
import { DeleteSavedCartModalComponent } from '../../components/delete-saved-card/delete-saved-card.component';
import { CreateCustomerModalComponent } from '../../components/create-customer-modal/create-customer-modal.component';
import { OrderServicesRow, OrderServicesTicket } from '../order-services/order-services.service';
import { ReturnWarrantyCreateViewModalComponent } from '../../components/return-warranty/return-warranty-create-view-modal/return-warranty-create-view-modal.component';
import { ConceptProductsModalComponent } from '../../components/concept-products-modal/concept-products-modal.component';
import { SavedCartProductModalComponent } from '../../components/saved-cart-product-modal/saved-cart-product-modal.component';
import { SavedCart } from '../../services/saved-cart-config/saved-cart-config.service';
import { PimTemplateItemEditorModalComponent } from '../../components/pim-templates/pim-template-item-editor-modal/pim-template-item-editor-modal.component';
import { PimTemplateColumn, PimTemplate } from '../pim-template.service';
import { PimTemplateCreateModalComponent } from '../../components/pim-templates/pim-template-create-modal/pim-template-create-modal.component';
import { SelectIntervalOrdersDatesModalComponent } from '../../components/select-interval-orders-dates-modal/select-interval-orders-dates-modal.component';
import { ActivityInternalViewModalComponent } from '../../components/activity-internal/activity-internal-view-modal/activity-internal-view-modal.component';
import { SupplierPoDetailsModalComponent } from '../../components/supplier/supplier-po-details-modal/supplier-po-details-modal.component';
import { SupplierPoHistoryModalComponent } from '../../components/supplier/supplier-po-history-modal/supplier-po-history-modal.component';
import { SupplierPoSplitModalComponent } from '../../components/supplier/supplier-po-split-modal/supplier-po-split-modal.component';
import { DigitalAssetsModalComponent } from '../../components/digital-assets/digital-assets-modal/digital-assets-modal.component';
import { DigitalAsset } from '../digital-assets/digital-assets.service';
import { DocumentsUploadModalComponent } from '../../components/documents-upload/documents-upload-modal/documents-upload-modal.component';
import { GungViewer3dModalComponent } from '../../components/gung-viewer3d-modal/gung-viewer3d-modal.component';
import { SelectProductImageExportSizeModalComponent } from '../../components/select-product-image-export-size-modal/select-product-image-export-size-modal.component';
import { SupplierCommentsModalComponent } from '../../components/supplier/supplier-comments-modal/supplier-comments-modal.component';
import { Observable } from 'rxjs';
import { CheckoutCartDiscountMatrixModalComponent } from '../../components/checkout/steps/checkout-cart-list/checkout-cart-discount-matrix-modal/checkout-cart-discount-matrix-modal.component';
import { CheckoutAddRowModalComponent } from '../../components/checkout/steps/checkout-cart-list/checkout-add-row-modal/checkout-add-row-modal/checkout-add-row-modal.component';
import { PimMetadataModalComponent } from '../../components/pim-metadata/pim-metadata-modal/pim-metadata-modal.component';
import { PimMetadata } from '../pim-metadata/pim-metadata.service';
import { MapModalComponent } from '../../components/map/map-modal/map-modal.component';
import { GungMap } from '../../components/map/gung-map/gung-map.component';
import { MapOptions } from 'leaflet';
import { Activity } from '../../models/activity';
import { ActivitiesEditorModalComponent } from '../../components/activity-hrp/activities-editor-modal/activities-editor-modal.component';
import { EanReplaceModalComponent } from '../../components/ean-replace-modal/ean-replace-modal.component';
import { ItemsChangesUpdateModalComponent } from '../../components/items-changes-update-modal/items-changes-update-modal.component';
import { CompanyTemplate } from '../../models/company-template';
import { CompanyTemplatesAddEditModalComponent } from '../../components/company-templates-add-edit-modal/company-templates-add-edit-modal.component';
import { EditProductModalComponent } from '../../components/edit-product-modal/edit-product-modal.component';
import { SelectIntervalDatesModalComponent } from '../../components/select-interval-dates-modal/select-interval-dates-modal.component';

@Injectable({
  providedIn: 'root'
})
export class GungModalService extends CommonModalService {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  constructor(protected ngbModal: NgbModal) {
    super(ngbModal);
  }

  openConfirmExportModal(email: string, template?: string): Promise<any> {
    const id = GungDataExportConfirmModalComponent.name;

    const ref = this.ngbModal.open(GungDataExportConfirmModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.email = email;

    this.modalRef.set(id, ref);

    return ref.result;
  }

  openConfirmExportPricatModal(email: string): Promise<any> {
    const id = GungDataExportPricatConfirmModalComponent.name;

    const ref = this.ngbModal.open(GungDataExportPricatConfirmModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.email = email;

    this.modalRef.set(id, ref);

    return ref.result;
  }

  openAvailabilityModal(availability: Availability, modalOption?: NgbModalOptions, product?: Product): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      centered: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(AvailabilityModalComponent, option);
    const componentInstance = ref.componentInstance as AvailabilityModalComponent;
    componentInstance.availability = availability;
    componentInstance.product = product;

    return ref.result;
  }

  openCreateAvailabilityConditionModal(modalOption?: NgbModalOptions): Promise<any> {
    const id = CreateAvailabilityConditionModalComponent.name;

    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(CreateAvailabilityConditionModalComponent, option);
    ref.componentInstance.delegate = this;

    this.modalRef.set(id, ref);

    return ref.result;
  }

  openEditorNewsModal(news: News, modalOption?: NgbModalOptions): Promise<any> {
    const id = NewsEditorModalComponent.name;

    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      size: 'lg',
      windowClass: 'modal-lg-custom',
      ...modalOption
    };

    const ref = this.ngbModal.open(NewsEditorModalComponent, option);
    ref.componentInstance.news = news;
    ref.componentInstance.delegate = this;
    this.modalRef.set(id, ref);

    return ref.result;
  }

  openShowNewsModal(news: News[], modalOption?: NgbModalOptions): Promise<any> {
    const id = NewsModalComponent.name;

    const option: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      centered: true,
      size: 'lg',
      windowClass: 'modal-lg-custom',
      ...modalOption
    };

    const ref = this.ngbModal.open(NewsModalComponent, option);
    ref.componentInstance.news = news;
    ref.componentInstance.delegate = this;
    this.modalRef.set(id, ref);

    return ref.result;
  }

  openCreateActivityModal(
    activity?: ActivityInternal,
    modalOption?: NgbModalOptions,
    customerId?: string,
    selectedCustomers?: Customer[],
    selectedDate?: Date
  ): Promise<any> {
    const id = ActivityInternalCreateModalComponent.name;

    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      size: 'lg',
      // windowClass: 'modal-lg-custom',
      ...modalOption
    };
    const ref = this.ngbModal.open(ActivityInternalCreateModalComponent, option);
    ref.componentInstance.activity = activity;
    ref.componentInstance.customerId = customerId;
    ref.componentInstance.selectedCustomers = selectedCustomers;
    ref.componentInstance.selectedDate = selectedDate;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  openViewActivityModal(activity: ActivityInternal, modalOption?: NgbModalOptions): Promise<any> {
    const id = ActivityInternalViewModalComponent.name;

    const option: NgbModalOptions = {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: true,
      ...modalOption
    };
    const ref = this.ngbModal.open(ActivityInternalViewModalComponent, option);
    ref.componentInstance.data = activity;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  openDiscountCodesModal(discountCode?: DiscountCode, modalOption?: NgbModalOptions): Promise<any> {
    const id = DiscountCodesModalComponent.name;

    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      size: 'lg',
      // windowClass: 'modal-lg-custom',
      ...modalOption
    };
    const ref = this.ngbModal.open(DiscountCodesModalComponent, option);
    ref.componentInstance.discountCode = discountCode;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  createUser(userFrom: any, copyUser: boolean, modalOption?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      size: 'lg',
      // windowClass: 'modal-lg-custom',
      ...modalOption
    };
    const modalRef = this.ngbModal.open(CreateUserModalComponent, option);
    if (!copyUser) {
      // Create user
      modalRef.componentInstance.createUserFrom = userFrom;
    } else {
      // Copy user
      modalRef.componentInstance.userToCopyFrom = userFrom;
    }
    return modalRef.result;
  }

  openPurchaseOrder(item: OrderStatistic, modalOption?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      size: 'xl',
      // windowClass: 'modal-lg-custom',
      ...modalOption
    };
    const modalRef = this.ngbModal.open(TopProductsCreatePurchaseOrderModalComponent, option);
    modalRef.componentInstance.item = item;
    return modalRef.result;
  }

  openAddContactModal(customer: Customer, modalOption?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      // windowClass: 'modal-lg-custom',
      ...modalOption
    };
    const modalRef = this.ngbModal.open(CustomerContactAddEditModalComponent, option);
    modalRef.componentInstance.customer = customer;
    return modalRef.result;
  }

  openEditContactModal(customerContact: CustomerContact, modalOption?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      // windowClass: 'modal-lg-custom',
      ...modalOption
    };
    const modalRef = this.ngbModal.open(CustomerContactAddEditModalComponent, option);
    modalRef.componentInstance.customerContact = customerContact;
    return modalRef.result;
  }

  openDeleteSavedCart(cart, modalOption?: NgbModalOptions) {
    const id = DeleteSavedCartModalComponent.name;
    const option: NgbModalOptions = {
      size: 'md',
      centered: true,
      keyboard: true,
      ...modalOption
    };
    const ref = this.ngbModal.open(DeleteSavedCartModalComponent, option);
    ref.componentInstance.delegate = this;
    ref.componentInstance.delete = cart;

    this.modalRef.set(id, ref);
    return ref;
  }

  openCreateCustomerModal() {
    const id = CreateCustomerModalComponent.name;
    const option: NgbModalOptions = {
      backdrop: 'static',
      size: 'lg',
      keyboard: true
    };
    const ref = this.ngbModal.open(CreateCustomerModalComponent, option);
    ref.componentInstance.delegate = this;

    this.modalRef.set(id, ref);
  }

  openViewCreateReturnWarrantyModal(orderServicesTicket?: OrderServicesTicket, modalOption?: NgbModalOptions) {
    const id = ReturnWarrantyCreateViewModalComponent.name;
    const option: NgbModalOptions = {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'modal-lg-custom',
      keyboard: true,
      ...modalOption
    };
    const ref = this.ngbModal.open(ReturnWarrantyCreateViewModalComponent, option);
    ref.componentInstance.delegate = this;
    ref.componentInstance.data = orderServicesTicket;
    ref.componentInstance.create = orderServicesTicket ? false : true;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openConceptProductsModal(conceptId: string, modalOptions?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: true,
      size: 'lg',
      keyboard: true,
      centered: true,
      ...modalOptions
    };
    const id = ConceptProductsModalComponent.name;

    const ref = this.ngbModal.open(ConceptProductsModalComponent, option);
    ref.componentInstance.conceptId = conceptId;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openSavedCartProductModal(savedCart: SavedCart, modalOptions?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: true,
      size: 'lg',
      keyboard: true,
      centered: true,
      ...modalOptions
    };
    const id = SavedCartProductModalComponent.name;

    const ref = this.ngbModal.open(SavedCartProductModalComponent, option);
    ref.componentInstance.savedCart = savedCart;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openPimTemplateEditorModal(columns: PimTemplateColumn[], i: number, newColumn?: PimTemplateColumn): Promise<any> {
    const id = PimTemplateItemEditorModalComponent.name;
    const ref = this.ngbModal.open(PimTemplateItemEditorModalComponent, { keyboard: true, ariaLabelledBy: 'modal-basic-title' });
    const item = { ...columns[i] };
    ref.componentInstance.item = JSON.stringify(newColumn || item);

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openPimTemplateCreateModal(template?: PimTemplate): Promise<any> {
    const id = PimTemplateCreateModalComponent.name;
    const ref = this.ngbModal.open(PimTemplateCreateModalComponent, { keyboard: true, ariaLabelledBy: 'modal-basic-title' });
    ref.componentInstance.template = JSON.stringify(template);

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openSelectIntervalOrdersDates(modalOptions?: NgbModalOptions): Promise<any> {
    const id = SelectIntervalOrdersDatesModalComponent.name;

    const ref = this.ngbModal.open(SelectIntervalOrdersDatesModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    });
    ref.componentInstance.delegate = this;

    this.modalRef.set(id, ref);

    return ref.result;
  }

  openSupplierPurchaseOrderSplitModal(supplierPurchaseOrder: SupplierPurchaseOrder) {
    const id = SupplierPoSplitModalComponent.name;

    const ref = this.ngbModal.open(SupplierPoSplitModalComponent, {
      keyboard: true,
      size: 'lg'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.supplierPurchaseOrder = supplierPurchaseOrder;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  openSupplierPurchaseOrderHistoryModal(supplierPurchaseOrderHistory: SupplierPurchaseOrderHistory[], isMap: boolean = false) {
    const id = SupplierPoHistoryModalComponent.name;

    const ref = this.ngbModal.open(SupplierPoHistoryModalComponent, {
      keyboard: true,
      size: 'lg'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.data = supplierPurchaseOrderHistory;
    ref.componentInstance.isMap = isMap
    this.modalRef.set(id, ref);
    return ref.result;
  }

  openSupplierPoDetailsModal(
    supplierPurchaseOrder: SupplierPurchaseOrder,
    supplierTableColumns: SupplierTableColumns[]
  ) {
    const id = SupplierPoDetailsModalComponent.name;

    const ref = this.ngbModal.open(SupplierPoDetailsModalComponent, {
      keyboard: true,
      size: 'lg'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.data = supplierTableColumns;
    ref.componentInstance.item = supplierPurchaseOrder;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  setValuesToShipStepModal(supplierPurchaseOrder: SupplierPurchaseOrder): Promise<any> {
    return Promise.resolve();
  }


  openDigitalAssetsModal(digitalAsset: DigitalAsset, modalOptions?: NgbModalOptions) {
    const id = DigitalAssetsModalComponent.name;
    const option: NgbModalOptions = {
      backdrop: true,
      size: 'xl',
      keyboard: true,
      centered: true,
      scrollable: true,
      ...modalOptions
    };
    const ref = this.ngbModal.open(DigitalAssetsModalComponent, option);
    ref.componentInstance.delegate = this;
    ref.componentInstance.selectedDigitalAsset = digitalAsset;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openDocumentsUploadModalComponentModal(
    uploadMethod: (file: File[], fileDescription: string, inMenu: boolean, userGroups: any) => Promise<any>,
    multiple?: boolean,
    hideDescription?: boolean,
    hideInMenu?: boolean,
    modalOptions?: NgbModalOptions,
    accept?: string
  ) {
    const id = DocumentsUploadModalComponent.name;
    const option: NgbModalOptions = {
      backdrop: false,
      size: 'lg',
      keyboard: true,
      centered: true,
      scrollable: true,
      ...modalOptions
    };
    const ref = this.ngbModal.open(DocumentsUploadModalComponent, option);
    ref.componentInstance.delegate = this;
    ref.componentInstance.uploadMethod = uploadMethod;
    ref.componentInstance.multiple = multiple;
    ref.componentInstance.hideDescription = hideDescription;
    ref.componentInstance.hideInMenu = hideInMenu;
    ref.componentInstance.accept = accept;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openExpanded3DModelViewerModal(modelFile: string[], modalOption?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      size: 'lg',
      windowClass: 'modal-lg-viewer-custom',
      backdrop: 'static',
      keyboard: true,
      centered: true
    };

    const ref = this.ngbModal.open(GungViewer3dModalComponent, option);
    const componentInstance = ref.componentInstance;
    componentInstance.models = modelFile;
    return ref.result;
  }

  openExportImagesSizeModal(
    itemList: Product[],
    sizeOptions: { id: number; name: string; startUrl: string; imageOptions: string; transformations: string }[],
    modalOptions?: NgbModalOptions
  ): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      centered: true,
      scrollable: true,
      ...modalOptions
    };

    const id = SelectProductImageExportSizeModalComponent.name;
    const ref = this.ngbModal.open(SelectProductImageExportSizeModalComponent, option);
    ref.componentInstance.items = itemList;
    ref.componentInstance.sizeOptions = sizeOptions;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openCommentsModal(
    purchaseOrderIds: string[],
    comments: SupplierPurchaseComment[],
    postComment: (comment: SupplierPurchaseComment) => Observable<any>,
    modalOptions?: NgbModalOptions
  ): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      centered: true,
      // scrollable: true,
      ...modalOptions
    };

    const id = SupplierCommentsModalComponent.name;
    const ref = this.ngbModal.open(SupplierCommentsModalComponent, option);
    ref.componentInstance.comments = comments;
    ref.componentInstance.postComment = postComment;
    ref.componentInstance.purchaseOrderIds = purchaseOrderIds;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openCartCheckoutDiscountModal(productId: string): Promise<any> {
    const id = CheckoutCartDiscountMatrixModalComponent.name;

    const ref = this.ngbModal.open(CheckoutCartDiscountMatrixModalComponent, {
      keyboard: true,
      size: 'lg'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.productId = productId;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  addRowCheckoutModal(): Promise<any> {
    const id = CheckoutAddRowModalComponent.name;

    const ref = this.ngbModal.open(CheckoutAddRowModalComponent, {
      keyboard: true,
      size: 'lg'
    });
    ref.componentInstance.delegate = this;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  openPimMetadataModalComponent(edit: boolean, metadata?: PimMetadata, modalOptions?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      keyboard: true,
      ...modalOptions
    };

    const id = PimMetadataModalComponent.name;
    const ref = this.ngbModal.open(PimMetadataModalComponent, option);
    ref.componentInstance.metadata = metadata;
    ref.componentInstance.edit = edit;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openMapModal(
    data: GungMap[],
    modalOption?: NgbModalOptions,
    modalTitle?: string,
    mapOptions?: MapOptions
  ): Promise<any> {
    console.log(mapOptions);
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      ...modalOption
    };
    const id = MapModalComponent.name;
    const modalRef = this.ngbModal.open(MapModalComponent, option);
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.modalTitle = modalTitle;
    modalRef.componentInstance.mapOptions = mapOptions;

    this.modalRef.set(id, modalRef);
    return modalRef.result;
  }

  openAddActivityModal(customer: Customer, modalOptions?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      keyboard: true,
      ...modalOptions
    };

    const id = ActivitiesEditorModalComponent.name;
    const ref = this.ngbModal.open(ActivitiesEditorModalComponent, option);
    ref.componentInstance.customer = customer;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openEditActivityModal(customer: Customer, activity: Activity, modalOptions?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      keyboard: true,
      ...modalOptions
    };

    const id = ActivitiesEditorModalComponent.name;
    const ref = this.ngbModal.open(ActivitiesEditorModalComponent, option);
    ref.componentInstance.customer = customer;
    ref.componentInstance.activity = activity;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openEanReplaceModal(selectedIdList: string[]) {
    const id = EanReplaceModalComponent.name;

    const ref = this.ngbModal.open(EanReplaceModalComponent, {
      keyboard: true,
      size: 'lg'
    });

    ref.componentInstance.delegate = this;
    ref.componentInstance.selectedItemIdList = selectedIdList;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  openItemsChangesUpdateModal(selectedIdList: string[]) {
    const ref = this.ngbModal.open(ItemsChangesUpdateModalComponent, {
      keyboard: true,
      size: 'lg'
    });
    const id = ItemsChangesUpdateModalComponent.name;

    ref.componentInstance.delegate = this;
    ref.componentInstance.selectedItemIdList = selectedIdList;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  openAddEditCompanyTemplate(template?: CompanyTemplate): Promise<any> {
    const id = CompanyTemplatesAddEditModalComponent.name;

    const ref = this.ngbModal.open(CompanyTemplatesAddEditModalComponent, {
      backdrop: 'static',
      keyboard: true,
      size: 'lg'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.template = template;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  openEditProductModalComponent(productId: string, product: Product, modalOption?: NgbModalOptions) {
    const id = EditProductModalComponent.name;
    const option: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(EditProductModalComponent, option);
    ref.componentInstance.delegate = this;
    ref.componentInstance.productId = productId;
    ref.componentInstance.product = product;

    this.modalRef.set(id, ref);
    return ref.result;
  }

  openSelectIntervalDates(modalSaveText?: string, modalOptions?: NgbModalOptions): Promise<any> {
    const id = SelectIntervalDatesModalComponent.name;

    const ref = this.ngbModal.open(SelectIntervalDatesModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    });
    ref.componentInstance.delegate = this;
    if (modalSaveText) {
      ref.componentInstance.modalSaveText = modalSaveText;
    }

    this.modalRef.set(id, ref);

    return ref.result;
  }
}
