import { Injectable } from '@angular/core';
import { Customer, Product } from '../../models';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutSingleComponent, SelectionAction, SimpleConfigBaseFilter, ListLayoutMultipleComponent } from 'gung-list';
import { ProductNameFilter } from '../product-list-config/product-list-config.service';
import { CustomerService } from '../customers/customer.service';
import { forkJoin, Observable, of, toArray } from 'rxjs';
import { CustomerListCardComponent } from '../../components/customer-list-card/customer-list-card.component';
import { UsersService } from '../users/users.service';
import { first, map, mergeMap, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CustomerSelectionActionConfigService } from '../customer-selection-action-config/customer-selection-action-config.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerListTableComponent } from '../../components/customer-list-table/customer-list-table.component';

export class CustomerSalespersonFilter extends SimpleConfigBaseFilter<Customer> {
  constructor() {
    super();
  }
  getName(): string {
    return 'SALESPERSON';
  }

  getOptionIds(item: Customer): string[] {
    if (!!item.extra?.gme?.salesperson) {
      return [item.extra.gme.salesperson];
    }
    return [];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class CustomerCountryFilter extends SimpleConfigBaseFilter<Customer> {
  constructor() {
    super();
  }
  getName(): string {
    return 'COUNTRY';
  }

  getOptionIds(item: Customer): string[] {
    if (!!item.extra?.gme?.address?.countryCode) {
      return [item.extra.gme.address.countryCode];
    }
    return [];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerListConfigService implements ConfigService<Customer> {
  constructor(
    public customerService: CustomerService,
    protected usersService: UsersService,
    protected authService: AuthService,
    protected customerSelectionActionConfigService: CustomerSelectionActionConfigService,
    protected translateService: TranslateService
  ) {}

  getItems(): Observable<Customer[]> {
    return this.customerService.getCustomers().pipe(first());
  }

  getFilters(): ConfigBaseFilter<Customer>[] {
    return [new CustomerCountryFilter(), new CustomerSalespersonFilter()];
  }

  getSortOptions(): ListSortOption<Customer>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Customer>[] {
    return [
      {
        getIconClass: () => '',
        /* getListItemComponent: () => CustomerListTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent, */
        getListItemComponent: () => CustomerListCardComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Customer list'
      }
    ];
  }

  getSearchTerms(item: Customer): string[] {
    return [item.id, item.name];
  }

  getItemId(item: Customer): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }

  getSelectionActions(): Observable<SelectionAction<Product>[]> {
    return forkJoin([this.customerSelectionActionConfigService.getSelectionActions().pipe(first())]).pipe(
      mergeMap(([staticActions]) => {
        const res = [];
        res.push(...staticActions);
        return res;
      }),
      toArray()
    );
  }

  getSelectionActionsButtonTitle(): string {
    return this.translateService.instant('ACTIONS');
  }
}
