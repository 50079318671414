import { createContext as e, createElement as r, toChildArray as o } from "../../external/preact/dist/preact.js";
import { useEffect as t, useContext as s } from "../../external/preact/hooks/dist/hooks.js";
const i = e(void 0),
  n = ({
    i18n: e,
    loadingContext: s,
    resources: n,
    children: a
  }) => (t(() => {
    e && s && n || console.warn(`CoreProvider - WARNING core provider is missing:${e ? "" : "i18n"} ${s ? "" : "loadingContext"} ${n ? "" : "resources"}`);
  }, [e, s, n]), r(i.Provider, {
    value: {
      i18n: e,
      loadingContext: s,
      resources: n
    }
  }, o(a))),
  a = () => {
    const e = s(i);
    if (void 0 === e) throw new Error('"useCoreContext" must be used within a CoreProvider');
    return e;
  };
export { n as CoreProvider, a as useCoreContext };
