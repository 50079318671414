import { Inject, Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  PimTemplateProperties
} from 'gung-list';
import { BehaviorSubject, Observable, Subject, filter, map, switchMap, tap } from 'rxjs';
import { ConceptGridCardV2Component } from '../../components/concept-grid-card-v2/concept-grid-card-v2.component';
import { ProductGridCardV2Component } from '../../components/product-grid-card-v2/product-grid-card-v2.component';
import { ProductGridViewV2Component } from '../../components/product-grid-view-v2/product-grid-view-v2.component';
import { GridViewProductCard } from '../../components/product-grid-view/product-grid-view.component';
import { ProductListViewV2Component } from '../../components/product-list-view-v2/product-list-view-v2.component';
import { Product, ProductExtended } from '../../models';
import { Assortment, AssortmentService } from '../assortment.service';
import { AuthService } from '../auth/auth.service';
import { CurrentAssortmentService } from '../base-view-config/base-view-config.service';
import { CloudPimFiltersService } from '../cloud-pim-filters/cloud-pim-filters.service';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { MetadataService } from '../metadata/metadata.service';
import { ProductSortService } from '../product-sort/product-sort.service';
import { ProductService } from '../products/product.service';
import { gungComparatorHelper, GungImageType, GungImageUrlService } from 'gung-common';
import { NavigationConfig } from '../../models/navigation-config';
import { ActivatedRouteSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ProductListV2ConfigService implements ConfigService<Product> {
  assortmentSubject: Subject<Assortment> = new BehaviorSubject(null);
  assortmentBannerEnabled = true;
  fetchProductsExpanded = false;
  topFilter = false;
  searchDisabled = false;

  dynamicColumns: PimTemplateProperties[];

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected gungFlowService: GungFlowService,
    protected metadataService: MetadataService,
    protected assortmentService: AssortmentService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService,
    protected gungImageUrlService: GungImageUrlService,
    @Inject('environment')
    protected environment: NavigationConfig,
  ) {
    if (this.environment?.standardErp) {
      this.topFilter = true;
    }
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return this.cloudPimFiltersService.getFilters(this.currentAssortmentService.currentAssortment);
  }

  getSortOptions(): ListSortOption<Product>[] {
    if (this.environment.standardErp) {
      return [
        {
          getLabel: () => 'NAME_A_Z',
          getComparator: () => (item1: Product, item2: Product) => gungComparatorHelper(item1?.name, item2?.name, 1)
        },
        {
          getLabel: () => 'NAME_Z_A',
          getComparator: () => (item1: Product, item2: Product) => gungComparatorHelper(item1?.name, item2?.name, -1)
        },

      ];
    }
    return [
      {
        getLabel: () => 'POPULAR',
        getComparator: () => (item1: Product, item2: Product) => gungComparatorHelper(item1?.extra?.pim?.sequence, item2?.extra?.pim?.sequence, -1)
      },
      {
        getLabel: () => 'NAME_ASC',
        getComparator: () => (item1: Product, item2: Product) => gungComparatorHelper(item1?.name, item2?.name, 1)
      },
      {
        getLabel: () => 'NAME_DESC',
        getComparator: () => (item1: Product, item2: Product) => gungComparatorHelper(item1?.name, item2?.name, -1)
      },
      {
        getLabel: () => 'ITEM_NO_ASC',
        getComparator: () => (item1: Product, item2: Product) => gungComparatorHelper(item1?.id, item2?.id, 1)
      },
      {
        getLabel: () => 'ITEM_NO_DESC',
        getComparator: () => (item1: Product, item2: Product) => gungComparatorHelper(item1?.id, item2?.id, -1)
      }
    ];
  }

  getBatchSizes(): number[] {
    if (this.environment?.standardErp) {
      return [24];
    }
    return [24, 48];
  }

  getLayouts(): ListLayout<Product>[] {
    const currentAssortment = this.currentAssortmentService.currentAssortment;
    if (currentAssortment && currentAssortment.extra.pim && currentAssortment.extra.pim.showAsList) {
      return [
        {
          getIconClass: () => 'fa fa-list',
          getListItemComponent: () => ProductListViewV2Component,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductGrid'
        },
        {
          getIconClass: () => 'fa fa-th',
          getListItemComponent: () => ProductGridViewV2Component,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductGrid'
        }
      ];
    } else {
      return [
        {
          getIconClass: () => 'fa fa-th',
          getListItemComponent: () => ProductGridViewV2Component,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductGrid'
        },
        {
          getIconClass: () => 'fa fa-list',
          getListItemComponent: () => ProductListViewV2Component,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductGrid'
        }
      ];
    }
  }

  getProductGridCardComponent(item: GridViewProductCard): any {
    if (item.product.productType === 'concept') {
      return ConceptGridCardV2Component;
    } else {
      return ProductGridCardV2Component;
    }
  }

  getItems(): Observable<Product[]> {
    return this.assortmentSubject.pipe(
      switchMap((assortment: Assortment) => {
        if (assortment == null || !assortment.id) {
          return this.authService.getCurrentUser().pipe(
            filter(user => !!user),
            switchMap(user => this.assortmentService.getPimAssortmentData(user.assortment)),
            tap(userAssortment => (this.currentAssortmentService.currentAssortment = userAssortment)),
            switchMap(assortmentData => this.fetchProducts(assortmentData))
          );
        }
        return this.fetchProducts(assortment);
      })
    );
  }

  protected fetchProducts(assortment: Assortment): Observable<Product[]> {
    let productObservable: Observable<Product[]>;
    if (this.fetchProductsExpanded === true) {
      productObservable = this.productService.getProductsByAssortmentExpanded(assortment.id).pipe(
        map(products => {
          return this.productSortService.sortProducts(products);
        })
      );
    } else {
      productObservable = this.productService.getProductsByAssortment(assortment.id).pipe(
        map(products => {
          return this.productSortService.sortProducts(products);
        })
      );
    }
    return productObservable.pipe(
      map(products => {
        // Dyncamic column values
        this.dynamicColumns = this.getDynamicColumnsV2(assortment);
        if (this.dynamicColumns && this.dynamicColumns.length > 0) {
          products.map((product: ProductExtended) => {
            const dynamicCollumnsValues: string[] = [];
            for (const column of this.dynamicColumns) {
              if (column.isDisplay) {
                const paths: string[] = column.path.split('.');
                const value = (product.extra[paths[0]] && product.extra[paths[0]][paths[1]]) || '';
                if (value && value.description) {
                  dynamicCollumnsValues.push(value.description);
                } else if (Array.isArray(value)) {
                  const valueArray = value.map(v => v.description).join(', ');
                  dynamicCollumnsValues.push(valueArray);
                } else if (column.type === 'metadata') {
                  if (column.metaReference) {
                    dynamicCollumnsValues.push(
                      this.metadataService.getMetadataValue(
                        column.metaReference.table,
                        column.metaReference.field,
                        value
                      )
                    );
                  } else if (column.metadata && column.metadata.split('.').length === 2) {
                    const splitmeta = column.metadata.split('.');
                    const metaTable = splitmeta[0];
                    const metaField = splitmeta[1];
                    dynamicCollumnsValues.push(this.metadataService.getMetadataValue(metaTable, metaField, value));
                  } else {
                    dynamicCollumnsValues.push(
                      this.metadataService.getMetadataValue(column.metadata, 'description', value)
                    );
                  }
                } else {
                  dynamicCollumnsValues.push(value || '');
                }
              }
            }
            product.dynamicCollumnsValues = dynamicCollumnsValues;
          });
        }
        return products;
      })
    );
  }

  getDynamicColumnsV2(assortment: Assortment): PimTemplateProperties[] {
    const headerDynamicTranslationKeys: PimTemplateProperties[] = [];
    // Dynamic column
    if (assortment?.extra?.skuProperties || assortment?.extra?.itemProperties) {
      for (const skuProperty of (assortment.extra.skuProperties ||
        assortment.extra.itemProperties) as PimTemplateProperties[]) {
        if (skuProperty.isDisplay || skuProperty.isDisplayGrid) {
          headerDynamicTranslationKeys.push(skuProperty);
        }
      }
    }
    return headerDynamicTranslationKeys;
  }

  getDynamicColumns?(route?: ActivatedRouteSnapshot): PimTemplateProperties[] {
    return this.dynamicColumns;
  }

  getItemId(item: Product) {
    return item.id;
  }

  getSearchTerms(item: Product) {
    return [item.id, item.name];
  }

  getCurrentFlow(): Observable<any> {
    return this.gungFlowService.getSelectedFlow();
  }

  getAssortmentBannerStyle(assortment: Assortment) {
    if (assortment?.extra?.images?.length > 0) {
      return {
        // eslint-disable-next-line quote-props
        height: '200px',
        // eslint-disable-next-line quote-props
        width: '100%',
        'background-size': 'cover',
        'background-position': 'center',
        'margin-bottom': '10px',
        'background-image': 'url("' + this.gungImageUrlService.getUrl(assortment?.extra?.images?.[0]?.s3Uri, { etag: assortment?.extra?.images?.[0]?.s3ETag, type: GungImageType.high }) + '")'
      };
    }
  }

  showAssortmentBannerText(): boolean {
    return true;
  }

  getAddQueryParameterLimitToUrl(): boolean {
    return true;
  }
}
