import { useMemo as e, useReducer as a, useCallback as t, useEffect as r } from "../../external/preact/hooks/dist/hooks.js";
import s from "../Validator/Validator.js";
import { getReducer as l, init as o } from "./reducer.js";
function m(m) {
  const {
      rules: d = {},
      formatters: i = {},
      defaultData: c = {},
      fieldProblems: u = {},
      schema: f = []
    } = m,
    n = e(() => new s(d), [d]),
    p = ({
      key: e,
      value: a,
      mode: t
    }, r) => {
      const s = i?.[e],
        l = function (e) {
          return e && "formatterFn" in e;
        }(s) ? s.formatterFn : s,
        o = l && "function" == typeof l ? l(a ?? "", r) : a;
      return [o, n.validate({
        key: e,
        value: o,
        mode: t
      }, r)];
    },
    [y, h] = a(l(p), {
      defaultData: c,
      schema: f ?? [],
      processField: p,
      fieldProblems: u
    }, o),
    v = e(() => y.schema.reduce((e, a) => e && y.valid[a], !0), [y.schema, y.valid]),
    g = t((e = null) => {
      h({
        type: "validateForm",
        selectedSchema: e
      });
    }, []),
    k = t((e, a) => h({
      type: "setErrors",
      key: e,
      value: a
    }), []),
    F = t((e, a) => h({
      type: "setValid",
      key: e,
      value: a
    }), []),
    b = t((e, a) => h({
      type: "setData",
      key: e,
      value: a
    }), []),
    D = t(e => h({
      type: "mergeData",
      data: e
    }), []),
    P = t(e => h({
      type: "setSchema",
      schema: e,
      defaultData: c
    }), [y.schema]),
    V = t(e => h({
      type: "mergeForm",
      formValue: e
    }), []),
    S = t(e => h({
      type: "setFieldProblems",
      fieldProblems: e
    }), [y.schema]);
  return r(() => {
    S(u ?? {});
  }, [JSON.stringify(u)]), {
    handleChangeFor: (e, a) => t => {
      const r = ((e, a) => a.target ? "checkbox" === a.target.type ? !y.data[e] : a.target.value : a)(e, t);
      h({
        type: "updateField",
        key: e,
        value: r,
        mode: a
      });
    },
    triggerValidation: g,
    setSchema: P,
    setData: b,
    mergeData: D,
    setValid: F,
    setErrors: k,
    isValid: v,
    mergeForm: V,
    setFieldProblems: S,
    schema: y.schema,
    valid: y.valid,
    errors: y.errors,
    data: y.data,
    fieldProblems: y.fieldProblems
  };
}
export { m as default };
