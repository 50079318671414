import { createElement as r } from "../../../../../external/preact/dist/preact.js";
import { getCardImageUrl as e, getFullBrandName as o } from "../utils.js";
import a from "../../../../../core/Context/useImage.js";
import { useState as t } from "../../../../../external/preact/hooks/dist/hooks.js";
import n from "../../../../../external/classnames/index.js";
function c({
  brand: c,
  brandsConfiguration: s = {}
}) {
  const d = a(),
    i = "card" === c ? "nocard" : c,
    m = s[c]?.icon ?? e(i, d),
    [u, p] = t(!1),
    _ = n({
      "adyen-checkout-card-input__icon": !0,
      "adyen-checkout__card__cardNumber__brandIcon": !0,
      "adyen-checkout-card-input__icon--hidden": !u
    });
  return r("img", {
    className: _,
    onLoad: () => {
      p(!0);
    },
    onError: () => {
      p(!1);
    },
    alt: o(c),
    src: m
  });
}
export { c as default };
