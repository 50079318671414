import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HlDisplayOrderService } from './hl-display-order.service';
import { SelectedCustomerService, MetadataService, Order } from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { JeevesOrderListConfigService } from 'gung-standard-jeeves';
import { Observable, forkJoin, of, switchMap, tap } from 'rxjs';
import { CustomerService } from 'gung-standard';
import { mergeMap, first, map, takeLast } from 'rxjs';
import { ListLayout, ConfigBaseFilter } from 'gung-list';
import { HlDisplayJeevesOrderCardListComponent } from '../components/hl-display-open-orders-quotes/hl-display-jeeves-order-card-list/hl-display-jeeves-order-card-list.component';
import { environment } from '../../environments/environment';
import { OrderNameFilter } from './hl-display-open-orders-list-config.service';
import { HlDisplayOrderPODFilter } from './hl-display-jeeves-order-list-config.service';
import { HlDisplayPortalFeaturesService, OrderSearchSettingsGroup } from './hl-display-portal-features.service';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayQuotesListConfigService extends JeevesOrderListConfigService {
  filterOrdersOnPOD = false;
  filterOrdersOnName = false;

  topFilter: boolean = false;

  constructor(
    protected translationService: TranslateService,
    protected orderService: HlDisplayOrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    protected dateUtilService: DateUtilService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService, dateUtilService);
  }

  getItems(): Observable<Order[]> {
    return forkJoin({
      settings: this.hlDisplayPortalFeaturesService.getPortalFeatureGroup('orderSearchSettingsGroup').pipe(
        first(),
        switchMap(settingsGroup => {
          let settings = settingsGroup as OrderSearchSettingsGroup;
          this.filterOrdersOnPOD = settings.filterOrdersOnPOD;
          return of(settings);
        })
      ),
      orders: this.orderService.getQuotes().pipe(first())
    }).pipe(
      mergeMap(({ settings, orders }) => forkJoin([of(orders), this.customerService.getCustomers().pipe(first())])),
      map(([orders, customers]) => {
        const result = orders.map(order => {
          return {
            ...order,
            extra: {
              ...order.extra
            }
          };
        });

        result.map(resOrder => {
          const cust = customers.find(customer => customer.id === resOrder.deliveryCustomerId);
          resOrder.extra.customerName = cust ? cust.name : '';
          resOrder.extra.deliveryCustomer = cust;
          return resOrder;
        });

        const sorted = result.sort((a, b) => {
          if (a.extra.oh.orddatum > b.extra.oh.orddatum) {
            return 1;
          }
          if (a.extra.oh.orddatum < b.extra.oh.orddatum) {
            return -1;
          }
          return 0;
        });

        return sorted.reverse();
      }),
      map(orders => {
        if (this.filterOrdersOnPOD) {
          for (const order of orders) {
            const lpMap: { name: string; addr1: string; addr2: string; postnr: string; land: string }[] = [];
            const lps: any[] = order.extra?.deliveryCustomer?.extra?.lp || [];

            for (const lp of lps) {
              lpMap.push({
                name: lp._fr?.ftgnamn || '',
                addr1: lp._fr?.ftgpostadr1 || '',
                addr2: lp._fr?.ftgpostadr2 || '',
                postnr: lp._fr?.ftgpostnr || '',
                land: lp._fr?.landskod || ''
              });
            }

            lpMap.push({
              name: order.extra?.deliveryCustomer?.extra?.fr?.ftgnamn || '',
              addr1: order.extra?.deliveryCustomer?.extra?.fr?.ftgnamn || '',
              addr2: order.extra?.deliveryCustomer?.extra?.fr?.ftgpostadr2 || '',
              postnr: order.extra?.deliveryCustomer?.extra?.fr?.ftgpostnr || '',
              land: order.extra?.deliveryCustomer?.extra?.fr?.landskod || ''
            });

            order.extra._gungLpMap = lpMap;
          }
        }

        return orders;
      })
    );
  }

  getSearchTerms(item: Order): string[] {
    const res = [...super.getSearchTerms(item)];

    if (item.extra.pod) {
      res.push(item.extra.pod.ftgnamn);
    } else if (item.extra.customerName) {
      res.push(item.extra.customerName);
    }
    return res;
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        ...super.getLayouts()[0],
        getListItemComponent: () => HlDisplayJeevesOrderCardListComponent
      }
    ];
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    const filters = super.getFilters();

    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('orderSearchSettingsGroup')
      .pipe(
        first(),
        map(settingsGroup => settingsGroup as OrderSearchSettingsGroup)
      )
      .subscribe(orderSearchSettings => {
        if (orderSearchSettings.filterOrdersOnName) {
          filters.push(new OrderNameFilter());
        }
        if (orderSearchSettings.filterOrdersOnPOD) {
          filters.push(new HlDisplayOrderPODFilter(this.translationService));
        }
      });

    return filters;
  }
}
