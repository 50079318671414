import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import {
  Product,
  CustomerProductPrice,
  PriceService,
  AvailabilityService,
  AuthService,
  Availability,
  MetadataService,
  SelectedCustomerService,
  Customer
} from 'gung-standard';
import { Subject, Subscription, forkJoin, of, fromEventPattern, map } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { langExists } from '../../services/hl-product.service';
import { TranslateService } from '@ngx-translate/core';
import {
  HlDisplayPortalFeaturesService,
  PODSettingsGroup,
  PriceSettingsGroup
} from '../../services/hl-display-portal-features.service';
import { HlDisplayMetadataHelperService } from '../../services/hl-display-metadata-helper.service';

export interface ProductGrid extends Product {
  price: CustomerProductPrice;
  availability?: Availability;
}

@Component({
  selector: 'app-hl-product-card-list-grid-view',
  templateUrl: './hl-product-card-list-grid-view.component.html',
  styleUrl: './hl-product-card-list-grid-view.component.css'
})
export class HlProductCardListGridViewComponent
  extends ListItemRendererComponent<Product[]>
  implements OnInit, OnChanges, OnDestroy {
  showImage = true;
  isSales: boolean = false;
  public displayDiscountFromPriceList: boolean;
  public displayStaffedPrices: boolean;

  private unsubscribe: Subject<boolean> = new Subject<boolean>();
  private subscriptions: Subscription[] = [];
  public mappedData: ProductGrid[] = [];
  private keyedMapData: { [id: string]: ProductGrid } = {};

  productSize = [];
  stockId: string;
  currentCustomer: Customer;
  hasArticlesOutsidePriceLists = environment.getArticlesOutsidePriceLists || false;
  currentLang = this.translateService.currentLang;

  baseCustId: string;

  constructor(
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected metadataService: MetadataService,
    protected selectedCustomerService: SelectedCustomerService,
    protected translateService: TranslateService,
    protected hldisplayPortalFeaturesService: HlDisplayPortalFeaturesService,
    protected hlDisplayMetadataHelperService: HlDisplayMetadataHelperService
  ) {
    super();
  }

  public ngOnInit() {
    forkJoin({
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      podSettings: this.hldisplayPortalFeaturesService.getPortalFeatureGroup('podSettingsGroup').pipe(
        first(),
        map(podSettingsGroup => podSettingsGroup as PODSettingsGroup)
      )
    })
      .pipe(first())
      .subscribe(({ customer, podSettings }) => {
        this.currentCustomer = customer;

        if (podSettings.podenabled) {
          this.baseCustId = this.currentCustomer?.id.includes('@')
            ? this.currentCustomer.id.slice(0, this.currentCustomer.id.indexOf('@'))
            : this.currentCustomer?.id;
        } else {
          this.baseCustId = this.currentCustomer?.id;
        }
      });

    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.isSales = roles.indexOf('SALES') > -1;
      });

    this.subscribeToProducts();

    if (this.isSales) {
      const mediaQuery = matchMedia('screen and (max-width: 576px), screen and (max-height: 576px)');
      this.showImage = !mediaQuery.matches;

      const mediaObserver = fromEventPattern<MediaQueryListEvent>(
        mediaQuery.addListener.bind(mediaQuery),
        mediaQuery.removeListener.bind(mediaQuery)
      );

      mediaObserver
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(mediaQueryResult => (this.showImage = !mediaQueryResult.matches));
    }

    this.hldisplayPortalFeaturesService
      .getPortalFeatureGroup('priceSettingsGroup')
      .pipe(first())
      .subscribe(priceSettingsGroup => {
        this.displayDiscountFromPriceList = (priceSettingsGroup as PriceSettingsGroup).displayDiscountFromPriceList;
      });
    this.hldisplayPortalFeaturesService
      .getPortalFeatureGroup('priceSettingsGroup')
      .pipe(first())
      .subscribe(priceSettingsGroup => {
        this.displayStaffedPrices = (priceSettingsGroup as PriceSettingsGroup).displayStaffedPrices;
      });
  }

  ngOnChanges() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscribeToProducts();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());

    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  protected subscribeToProducts() {
    const newIds = this.data.map(p => p.id).filter(id => !Object.keys(this.keyedMapData).includes(id));
    if (newIds.length === 0) {
      this.readMappedDataFromCache();
      return;
    }
    const subscription = this.authService
      .getCurrentUser()
      .pipe(
        first(),
        switchMap(user =>
          forkJoin([
            of(this.data),
            this.priceService.getCurrentCustomerPrices(newIds).pipe(first()),
            this.availabilityService.getAvailabilities(newIds, user.managedMultistockIds[0]).pipe(first()),
            /* forkJoin([
              ...newIds.map(id => this.availabilityService.getAvailability(id, user.managedMultistockIds[0]))
            ]).pipe(first()), */
            of(user)
          ])
        )
      )
      .subscribe(data => {
        const productData = data[0];
        const prices = data[1];
        const avs = data[2];
        const user = data[3];
        if (!this.stockId && user?.managedMultistockIds?.length > 0) {
          this.stockId = user.managedMultistockIds[0];
        }
        this.keyedMapData = {
          ...this.keyedMapData,
          ...newIds.reduce((acc, curr) => {
            const product = productData.filter(p => p.id === curr)[0];
            if (!product) {
              throw new Error('No product found');
            }
            const price = prices.filter(p => p.productId === curr)[0];
            if (!price) {
              throw new Error('No price found');
            }
            const av = avs.filter(p => p.productId === curr)[0];
            if (!av) {
              throw new Error('No availability found');
            }

            const item: ProductGrid = this.mapItem(curr, product, price, av);
            return {
              ...acc,
              [curr]: item
            };
          }, {})
        };
        this.readMappedDataFromCache();
      });
    this.subscriptions.push(subscription);
  }

  private readMappedDataFromCache() {
    const ids = this.data.map(d => d.id);
    this.mappedData = ids.map(id => this.keyedMapData[id]);
  }

  mapItem(id: string, product: Product, price: CustomerProductPrice, availability: Availability): ProductGrid {
    this.productSize[id] = this.getProductSize(product);
    return {
      ...product,
      id,
      name: this.getName(product),
      price
      // availability,
    };
  }

  getName(product: Product): string {
    return product.name;
  }

  getProductSize(product: Product): string {
    let productSize: string;
    if (product.extra.hlPimItem.ItemSize && product.extra.hlPimItem.ItemSize !== '-') {
      productSize = product.extra.hlPimItem.ItemSize;
    } else if (
      product.extra.hlPimProduct.ProductExternalCategory === 'PriceCommunication' &&
      product.extra.hlPimItem.ItemLabelHeightmm
    ) {
      productSize = '';
      if (product.extra.hlPimItem.ItemLabelHeightmm && product.extra.hlPimItem.ItemLabelHeightmm !== '-') {
        productSize += `H:${product.extra.hlPimItem.ItemLabelHeightmm} mm`;
      }
      if (product.extra.hlPimItem.ItemWidthmm && product.extra.hlPimItem.ItemWidthmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `W:${product.extra.hlPimItem.ItemWidthmm}mm`;
      }
    } else {
      productSize = '';
      if (product.extra.hlPimItem.ItemWidthmm && product.extra.hlPimItem.ItemWidthmm !== '-') {
        productSize += `W:${product.extra.hlPimItem.ItemWidthmm}mm`;
      }
      if (product.extra.hlPimItem.ItemDepthmm && product.extra.hlPimItem.ItemDepthmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `D:${product.extra.hlPimItem.ItemDepthmm}mm`;
      }
      if (product.extra.hlPimItem.ItemHeightmm && product.extra.hlPimItem.ItemHeightmm !== '-') {
        if (productSize) {
          productSize += ` x `;
        }
        productSize += `H:${product.extra.hlPimItem.ItemHeightmm}mm`;
      }
    }

    return productSize;
  }

  getKuarDesc(product): string {
    return this.currentCustomer.extra?.kuarMapped?.[product.id]?.artnrkund || '';
  }

  public getAssortmentType(extra: any): string {
    if (extra.assortmentType == null || extra.assortmentType === 'C') {
      return '';
    }

    return '(' + extra.assortmentType + ')';
  }
}
