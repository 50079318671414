function i(i, o, t) {
  return o in i ? Object.defineProperty(i, o, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : i[o] = t, i;
}
class o {
  constructor() {
    i(this, "sfConfig", void 0), i(this, "loadingContext", void 0), i(this, "holderEl", void 0), i(this, "iframeRef", void 0), i(this, "loadToConfigTimeout", void 0), i(this, "_isValid", void 0), i(this, "_iframeContentWindow", void 0), i(this, "_numKey", void 0), i(this, "_isEncrypted", void 0), i(this, "_hasError", void 0), i(this, "_errorType", void 0), i(this, "_cvcPolicy", void 0), i(this, "_expiryDatePolicy", void 0), i(this, "_iframeOnLoadListener", void 0), i(this, "_postMessageListener", void 0), i(this, "onIframeLoadedCallback", void 0), i(this, "onConfigCallback", void 0), i(this, "onEncryptionCallback", void 0), i(this, "onValidationCallback", void 0), i(this, "onFocusCallback", void 0), i(this, "onBinValueCallback", void 0), i(this, "onTouchstartCallback", void 0), i(this, "onShiftTabCallback", void 0), i(this, "onAutoCompleteCallback", void 0), i(this, "onKeyPressedCallback", void 0), this.sfConfig = {};
  }
}
export { o as default };
