import { Component, OnInit, ComponentFactoryResolver, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {
  CheckoutWrapperComponent,
  CheckoutConfigService,
  CartService,
  OrderSubmitService,
  GungModalService,
  CartKeyService,
  SelectedCustomerService,
  CartTotalsService,
  TotalObject,
  Customer,
  CartListCheckoutConfigService
} from 'gung-standard';
import { combineLatest, first, forkJoin, map, mergeMap, of, Subject, switchMap, takeUntil } from 'rxjs';
import { JeevesCheckCreditService } from 'gung-standard-jeeves';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { HlDisplayCreditBlockedService } from '../../services/hl-display-credit-blocked.service';
import {
  CheckoutSettingsGroup,
  FreightSettingsGroup,
  HlDisplayPortalFeaturesService,
  PriceSettingsGroup
} from '../../services/hl-display-portal-features.service';
import { HlCheckoutButtonsPortalComponent } from '../checkout/hl-checkout-buttons-portal/hl-checkout-buttons-portal.component';
import { HlDisplayAdditionalCostsService } from '../../services/hl-display-additional-costs.service';
import { HlDisplayFreightService } from '../../services/hl-display-freight.service';

@Component({
  selector: 'app-hl-checkout-wrapper',
  templateUrl: './hl-checkout-wrapper.component.html',
  styleUrls: ['./hl-checkout-wrapper.component.css']
})
export class HlCheckoutWrapperComponent extends CheckoutWrapperComponent implements OnInit, OnDestroy {
  isCreditHardBlock: boolean = false;
  isCreditSoftBlock: boolean = false;
  minOrderValue: number;

  useTradePolicy: boolean = false;
  freightCost: number;
  freightCostMin: number;
  adminCharge: number;
  adminChargeMin: number;
  orderTotalValue: number;

  customFreightLevels: any;

  currencySymbol: string;

  currencyCode: string = '';
  customer: Customer;
  creditLeft: number = 0;
  totals: TotalObject = { totalQuantity: 0, totalValue: 0, totalRows: 0 };
  protected modalOpen: boolean = false;
  protected unsubscribeTotals: Subject<void> = new Subject();

  constructor(
    protected orderSubmitService: OrderSubmitService,
    protected checkoutConfig: CheckoutConfigService,
    componentFactoryResolver: ComponentFactoryResolver,
    protected cartService: CartService,
    changeDetectorRef: ChangeDetectorRef,
    gungModalService: GungModalService,
    cartKeyService: CartKeyService,
    protected selectCustomerService: SelectedCustomerService,
    protected cartTotalsService: CartTotalsService,
    protected jeevesCheckCreditService: JeevesCheckCreditService,
    protected translateService: TranslateService,
    protected creditBlockedService: HlDisplayCreditBlockedService,
    protected portalFeatureService: HlDisplayPortalFeaturesService,
    protected hlDisplayAdditionalCostsService: HlDisplayAdditionalCostsService,
    protected hlDisplayFreightService: HlDisplayFreightService,
    protected cartListCheckoutConfigService: CartListCheckoutConfigService
  ) {
    super(
      orderSubmitService,
      checkoutConfig,
      componentFactoryResolver,
      cartService,
      changeDetectorRef,
      gungModalService,
      cartKeyService,
      cartListCheckoutConfigService
    );
  }

  ngOnInit(): void {
    forkJoin({
      checkoutSettingsGroup: this.portalFeatureService.getPortalFeatureGroup('orderCheckoutSettingsGroup').pipe(
        first(),
        map(checkoutSettingsGroup => checkoutSettingsGroup as CheckoutSettingsGroup)
      ),
      priceSettingsGroup: this.portalFeatureService.getPortalFeatureGroup('priceSettingsGroup').pipe(
        first(),
        map(priceSettingsGroup => priceSettingsGroup as PriceSettingsGroup)
      ),
      freightSettingsGroup: this.portalFeatureService.getPortalFeatureGroup('freightSettingsGroup').pipe(
        first(),
        map(freightSettingsGroup => freightSettingsGroup as FreightSettingsGroup)
      ),
      cust: this.selectCustomerService.getSelectedCustomer().pipe(first())
    })
      .pipe(
        takeUntil(this.unsubscribeTotals),
        switchMap(({ checkoutSettingsGroup, priceSettingsGroup, freightSettingsGroup, cust }) => {
          return combineLatest({
            checkoutSettingsGroup: of(checkoutSettingsGroup).pipe(first()),
            priceSettingsGroup: of(priceSettingsGroup).pipe(first()),
            additionalCostTerms: checkoutSettingsGroup.useTradePolicy
              ? this.hlDisplayAdditionalCostsService.getAdditionalCostTerms(cust).pipe(first())
              : of(null),
            customFreight: freightSettingsGroup.useSparNLFreight
              ? this.hlDisplayFreightService.getCustomFreightLevels().pipe(first())
              : of(null),
            totals: this.cartTotalsService.getTotals().pipe(takeUntil(this.unsubscribeTotals))
          });
        })
      )
      .subscribe(({ checkoutSettingsGroup, priceSettingsGroup, additionalCostTerms, customFreight, totals }) => {
        this.minOrderValue = checkoutSettingsGroup.minimumOrderValue;

        if (totals.totalCurrency !== '') {
          this.currencyCode = totals.totalCurrency;
        } else {
          this.currencyCode = priceSettingsGroup.defaultCurrencyCode;
        }

        const currencySymbol = this.hlDisplayAdditionalCostsService.getCurrencySymbol(this.currencyCode);

        if (currencySymbol) {
          this.currencySymbol = currencySymbol;
        }

        this.orderTotalValue = totals.totalValue;

        if (additionalCostTerms != null) {
          const freightCostItem = this.hlDisplayAdditionalCostsService.getFreightCharge(
            this.orderTotalValue,
            additionalCostTerms
          );

          if (freightCostItem) {
            this.freightCost = freightCostItem.value;
            this.freightCostMin = freightCostItem.freightMin;
          }

          const adminFeeItem = this.hlDisplayAdditionalCostsService.getAdminFee(
            this.orderTotalValue,
            additionalCostTerms
          );

          if (adminFeeItem) {
            this.adminCharge = adminFeeItem.value;
            this.adminChargeMin = adminFeeItem.adminChargeMin;
          }

          const minOrderItem = this.hlDisplayAdditionalCostsService.getMinOrder(additionalCostTerms);

          if (minOrderItem) {
            this.minOrderValue = minOrderItem.value;
          }
        } else if (!!customFreight && customFreight.length > 0) {
          this.customFreightLevels = customFreight;
        }
      });
    this.portalFeatureService.getPortalFeatureGroup('orderCheckoutSettingsGroup').subscribe(settings => {});

    this.creditBlockedService
      .checkCreditBlockedSelectedCustomer()
      .pipe(first())
      .subscribe(result => {
        this.customer = result.customer;

        if (result.status === 'CREDIT_HARD_BLOCK') {
          this.isCreditHardBlock = true;
          this.isCreditSoftBlock = false;
        }

        if (result.status === 'CREDIT_SOFT_BLOCK' && result.credit) {
          this.creditLeft = Number(result.credit?.creditLeft) || 0;

          this.cartTotalsService
            .getTotals()
            .pipe(takeUntil(this.unsubscribeTotals))
            .subscribe(totalsObject => {
              this.totals = totalsObject;

              if (this.creditLeft < this.totals.totalValue && !this.modalOpen) {
                // Open Modal
                this.modalOpen = true;
                const options: NgbModalOptions = {
                  backdrop: 'static',
                  size: 'sm',
                  keyboard: true
                };

                const translation = this.creditBlockedService.getPortalTranslationCart(
                  result.customer,
                  this.creditLeft,
                  this.totals.totalValue
                );

                this.gungModalService.openBasicModal(translation.title, translation.body, options).then(
                  result => {
                    this.isCreditSoftBlock = true;
                  },
                  reason => {
                    this.isCreditSoftBlock = true;
                  }
                );
              }

              if (this.creditLeft >= this.totals.totalValue) {
                this.modalOpen = false;
                this.isCreditSoftBlock = false;
              }
            });
        }
      });
  }

  protected renderDynamicComponents(): void {
    super.renderDynamicComponents();
    let hlButtons = this.renderedButtons as HlCheckoutButtonsPortalComponent;
    hlButtons.additionalCosts = this.currentCheckout?.extra?.additionalCosts;
    hlButtons.ngOnChanges({
      additionalCosts: {
        currentValue: this.currentCheckout?.extra?.additionalCosts,
        previousValue: undefined,
        firstChange: false,
        isFirstChange: function (): boolean {
          throw new Error('Function not implemented.');
        }
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeTotals.next();
    this.unsubscribeTotals.complete();
  }
}
