<div class="card product-card">
  <div class="card-body">
    <div class="image-wrapper" (click)="goToDetail(data.product)">
      <img
        class="img-fluid"
        *ngIf="data.product.extra && data.product.extra.images && data.product.extra.images[0]"
        [src]="data.product.extra.images[0].s3Uri | gungImageUrl
          :'type:list'
          :'etag:'+data.product.extra.images[0].s3ETag
        "
        alt=""
      />
    </div>
    <div class="product-text">
      <div>{{ data.product.id }}</div>
      <div class="text-truncate" translate>{{ data.product.name }}</div>
      <div class="product-card-button">
        <button class="btn btn-block btn-sm btn-primary" (click)="goToDetail(data.product)" translate>
          SHOW_PRODUCTS
        </button>
      </div>
    </div>
  </div>
</div>
