import { Inject, NgModule } from '@angular/core';
import { GungNumberInputComponent } from './components/gung-number-input/gung-number-input.component';
import { GungNumberInputHorizontalComponent } from './components/gung-number-input-horizontal/gung-number-input-horizontal.component';
import { GungCheckboxInputHorizontalComponent } from './components/gung-checkbox-input-horizontal/gung-checkbox-input-horizontal.component';
import { GungTextInputComponent } from './components/gung-text-input/gung-text-input.component';
import { GungHtmlEditorComponent } from './components/gung-html-editor/gung-html-editor.component';
import { GungInputMultiSelectComponent } from './components/gung-input-multi-select/gung-input-multi-select.component';
import { GungInputMultiSelectHorizontalComponent } from './components/gung-input-multi-select-horizontal/gung-input-multi-select-horizontal.component';
import { GungSelectOptionInputComponent } from './components/gung-select-option-input/gung-select-option-input.component';
import { GungSelectOptionInputHorizontalComponent } from './components/gung-select-option-input-horizontal/gung-select-option-input-horizontal.component';
import { GungPasswordInputComponent } from './components/gung-password-input/gung-password-input.component';
import { GungTextAreaInputComponent } from './components/gung-text-area-input/gung-text-area-input.component';
import { GungTextAreaInputHorizontalComponent } from './components/gung-text-area-input-horizontal/gung-text-area-input-horizontal.component';
import { GungTextInputHorizontalComponent } from './components/gung-text-input-horizontal/gung-text-input-horizontal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateService, LangChangeEvent, TranslateModule } from '@ngx-translate/core';
import { ActionBtnComponent } from './components/action-btn/action-btn.component';
import { FormsMessageErrorComponent } from './components/forms-message-error/forms-message-error.component';
import { ModalConfirmYesNoComponent } from './components/modals/modal-confirm-yes-no/modal-confirm-yes-no.component';
import { BasicModalComponent } from './components/modals/basic-modal/basic-modal.component';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { SchedulersMonitorComponent } from './components/schedulers-monitor/schedulers-monitor.component';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { TemplateItemEditorComponent } from './components/document-template/template-item-editor/template-item-editor.component';
import { DocumentTemplateCreateModalComponent } from './components/document-template/document-template-create-modal/document-template-create-modal.component';
import { TemplateItemEditorModalComponent } from './components/document-template/template-item-editor-modal/template-item-editor-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatToolbarModule } from '@angular/material/toolbar';
import { InputSaveModalComponent } from './components/modals/input-save-modal/input-save-modal.component';
import {
  GungTabContentDirective,
  GungTabDirective,
  GungTabTitleDirective,
  GungTabsetComponent
} from './components/gung-tabs/gung-tabs.component';
import { DownloadMultipleFilesComponent } from './components/modals/download-multiple-files/download-multiple-files.component';
import { EmailExportConfirmModalComponent } from './components/modals/email-export-confirm-modal/email-export-confirm-modal.component';
import { CommonModule, DOCUMENT } from '@angular/common';
import { NoteModalComponent } from './components/modals/note-modal/note-modal.component';
import { GungErrorHandlerModalComponent } from './components/modals/gung-error-handler-modal/gung-error-handler-modal.component';
import { JsonViewModalComponent } from './components/modals/json-view-modal/json-view-modal.component';
import { GungDatePickerComponent } from './components/gung-date-picker/gung-date-picker.component';
import { GungInputFileComponent } from './components/gung-input-file/gung-input-file.component';
import { ListManagementModalComponent } from './components/modals/list-management-modal/list-management-modal.component';
import { GungTextInputTypeaheadComponent } from './components/gung-text-input-typeahead/gung-text-input-typeahead.component';
import { GungInputDropdownFilterComponent } from './components/gung-input-dropdown-filter/gung-input-dropdown-filter.component';
import { FilterListSearchComponent } from './components/filter-list-search/filter-list-search.component';
import { LanguageSelectionComponent } from './components/language-selection/language-selection.component';
import { LanguageSelectionDropdownComponent } from './components/language-selection-dropdown/language-selection-dropdown.component';
import { GungJsonEditorComponent } from './components/gung-json-editor/gung-json-editor.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { GungCalendarViewComponent } from './components/gung-calendar-view/gung-calendar-view.component';
import { RouterModule } from '@angular/router';
import { GungDatepickerModalComponent } from './components/modals/gung-datepicker-modal/gung-datepicker-modal.component';
import { InputModalComponent } from './components/modals/input-modal/input-modal.component';
import { GungNotificationComponent } from './components/gung-notification/gung-notification.component';
import { ListInputComponent } from './components/list-input/list-input.component';
import { GungCookieConsentComponent } from './components/gung-cookie-consent/gung-cookie-consent.component';
import { EncodeUriPipe } from './pipes/encode-uri/encode-uri.pipe';
import { GungPhoneNumberInputComponent } from './components/gung-phone-number-input/gung-phone-number-input.component';
import { GungDownloadUrlPipe, GungImageUrlPipe } from './pipes/gung-image-url/gung-image-url.pipe';
import { GungAccordionBody, GungAccordionComponent, GungAccordionDirective, GungAccordionHeader } from './components/gung-accordion/gung-accordion.component';
import { MatIconModule } from '@angular/material/icon';

import { GungRibbonBannerComponent } from './components/gung-ribbon-banner/gung-ribbon-banner.component';
import { GungTableComponent } from './components/gung-table/gung-table.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { GungButtonComponent } from './components/gung-button/gung-button.component';
import { GungTableActiveFilterNumberComponent } from './components/gung-table/gung-table-active-filter-number/gung-table-active-filter-number.component';
import { GungTableClearFilterButtonComponent } from './components/gung-table/gung-table-clear-filter-button/gung-table-clear-filter-button.component';
import { UploadModalComponent } from './components/upload-modal/upload-modal.component';



@NgModule({
  declarations: [
    GungNumberInputComponent,
    GungNumberInputHorizontalComponent,
    GungCheckboxInputHorizontalComponent,
    GungTextInputComponent,
    GungHtmlEditorComponent,
    GungInputMultiSelectComponent,
    GungInputMultiSelectHorizontalComponent,
    GungSelectOptionInputComponent,
    GungSelectOptionInputHorizontalComponent,
    GungPasswordInputComponent,
    GungTextAreaInputComponent,
    GungTextAreaInputHorizontalComponent,
    GungTextInputHorizontalComponent,
    ActionBtnComponent,
    FormsMessageErrorComponent,
    ModalConfirmYesNoComponent,
    BasicModalComponent,
    SafeHtmlPipe,
    SchedulersMonitorComponent,
    TemplateItemEditorComponent,
    DocumentTemplateCreateModalComponent,
    TemplateItemEditorModalComponent,
    InputSaveModalComponent,
    GungTabContentDirective,
    GungTabDirective,
    GungTabTitleDirective,
    GungTabsetComponent,
    DownloadMultipleFilesComponent,
    EmailExportConfirmModalComponent,
    NoteModalComponent,
    GungErrorHandlerModalComponent,
    JsonViewModalComponent,
    GungDatePickerComponent,
    GungInputFileComponent,
    ListManagementModalComponent,
    GungTextInputTypeaheadComponent,
    GungInputDropdownFilterComponent,
    FilterListSearchComponent,
    LanguageSelectionComponent,
    LanguageSelectionDropdownComponent,
    GungJsonEditorComponent,
    OrderByPipe,
    GungCalendarViewComponent,
    GungDatepickerModalComponent,
    InputModalComponent,
    GungNotificationComponent,
    ListInputComponent,
    GungCookieConsentComponent,
    EncodeUriPipe,
    GungPhoneNumberInputComponent,
    GungImageUrlPipe,
    GungDownloadUrlPipe,
    GungAccordionComponent,
    GungAccordionDirective,
    GungAccordionHeader,
    GungAccordionBody,
    GungRibbonBannerComponent,
    GungTableComponent,
    ProgressBarComponent,
    GungButtonComponent,
    GungTableActiveFilterNumberComponent,
    GungTableClearFilterButtonComponent,
    UploadModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    AngularEditorModule,
    FormsModule,
    TranslateModule,
    DragDropModule,
    MatCardModule,
    MatExpansionModule,
    MatToolbarModule,
    RouterModule,
    MatIconModule
    // MatListModule,
    // MatMenuModule,
    // MatSidenavModule,
    // MatTableModule,
  ],
  exports: [
    GungNumberInputComponent,
    GungNumberInputHorizontalComponent,
    GungCheckboxInputHorizontalComponent,
    GungTextInputComponent,
    GungHtmlEditorComponent,
    GungInputMultiSelectComponent,
    GungInputMultiSelectHorizontalComponent,
    GungSelectOptionInputComponent,
    GungSelectOptionInputHorizontalComponent,
    GungPasswordInputComponent,
    GungTextAreaInputComponent,
    GungTextAreaInputHorizontalComponent,
    GungTextInputHorizontalComponent,
    ActionBtnComponent,
    FormsMessageErrorComponent,
    ModalConfirmYesNoComponent,
    BasicModalComponent,
    SchedulersMonitorComponent,
    TemplateItemEditorComponent,
    DocumentTemplateCreateModalComponent,
    TemplateItemEditorModalComponent,
    GungTabContentDirective,
    GungTabDirective,
    GungTabTitleDirective,
    GungTabsetComponent,
    GungDatePickerComponent,
    GungInputFileComponent,
    GungTextInputTypeaheadComponent,
    GungInputDropdownFilterComponent,
    LanguageSelectionComponent,
    LanguageSelectionDropdownComponent,
    GungJsonEditorComponent,
    OrderByPipe,
    GungCalendarViewComponent,
    GungNotificationComponent,
    ListInputComponent,
    GungCookieConsentComponent,
    EncodeUriPipe,
    GungPhoneNumberInputComponent,
    GungImageUrlPipe,
    GungDownloadUrlPipe,
    GungAccordionComponent,
    GungAccordionDirective,
    GungAccordionHeader,
    GungAccordionBody,
    GungRibbonBannerComponent,
    GungTableComponent,
    ProgressBarComponent,
    GungButtonComponent
  ]
})
export class GungCommonModule {
  constructor(translate: TranslateService, @Inject(DOCUMENT) private document: Document) {
    const lang = localStorage.getItem('selectedLanguage');
    this.document.documentElement.lang = this.getLanguageCode(lang || 'se');
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.document.documentElement.lang = this.getLanguageCode(event.lang);
    });
  }

  private getLanguageCode(lang: string): string {
    return LANGUAGE_COUNTRYCODE_MAP[lang] || lang;
  }
}

// Used to map ISO country codes to ISO language codes when COUNTRY_CODE !== LANGUAGE_CODE
// In language config service we use ISO Country Code
// Example: se(Sweden ISO Country Code) !== sv(Swedish ISO Language Code)
const LANGUAGE_COUNTRYCODE_MAP = {
  se: 'sv'
};
