import { Component as e, createElement as s } from "../../../../external/preact/dist/preact.js";
import r from "../../../../components/internal/IFrame/Iframe.js";
import i from "../../../../utils/promiseTimeout.js";
import { DEVICE_FINGERPRINT as t, FAILED_DFP_RESOLVE_OBJECT_TIMEOUT as o, DF_TIMEOUT as n } from "../../constants.js";
import a from "../../../../utils/get-process-message-handler.js";
import { getOrigin as m } from "../../../../utils/getOrigin.js";
function p(e, s, r) {
  return s in e ? Object.defineProperty(e, s, {
    value: r,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[s] = r, e;
}
class c extends e {
  getDfpPromise() {
    return new Promise((e, s) => {
      this.processMessageHandler = a(this.postMessageDomain, e, s, t), window.addEventListener("message", this.processMessageHandler);
    });
  }
  componentDidMount() {
    this.deviceFingerPrintPromise = i(n, this.getDfpPromise(), o), this.deviceFingerPrintPromise.promise.then(e => {
      this.props.onCompleteFingerprint(e), window.removeEventListener("message", this.processMessageHandler);
    }).catch(e => {
      this.props.onErrorFingerprint(e), window.removeEventListener("message", this.processMessageHandler);
    });
  }
  render({
    dfpURL: e
  }) {
    return s("div", {
      className: "adyen-checkout-risk__device-fingerprint"
    }, s(r, {
      name: "dfIframe",
      src: e,
      allow: "geolocation; microphone; camera;",
      title: "devicefingerprinting iframe"
    }));
  }
  constructor(e) {
    super(e), p(this, "postMessageDomain", void 0), p(this, "processMessageHandler", void 0), p(this, "deviceFingerPrintPromise", void 0), this.postMessageDomain = m(this.props.loadingContext) || this.props.loadingContext;
  }
}
export { c as default };
