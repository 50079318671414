import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MetadataService, Product } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayMetadataHelperService {

  constructor(protected metadataService: MetadataService, protected translate: TranslateService) { }

  getProductColours(product: Product): string {
    const colours = product.extra.hlPimItem?.ItemColourName?.filter(colour => !!colour).map(colour => colour.Id);
    if (!colours || colours.length === 0) {
      return '';
    }

    const currentLang = this.translate.currentLang || 'en';

    let langColours = [];
    colours.forEach(colour => {
      const x = this.metadataService.getMetadataValue('ColourName', 'Values', colour)[currentLang];
      langColours.push(x);
    })

    return langColours.join(', ') || '';
  }
}
