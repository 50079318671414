import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class ProductHeightFilterService extends SimpleConfigBaseFilter<Product> {
  constructor() {
    super();
  }

  getName(): string {
    return 'HEIGHT_MM';
  }
  getOptionIds(item: Product): string[] {
    // IF field ItemLabelHeightmm has a value, then use itemLabelHeightmm,
    // IF field ItemLabelHeightmm is empty, then use ItemHeightmm
    return [item?.extra?.hlPimItem?.ItemLabelHeightmm || item?.extra?.hlPimItem?.ItemHeightmm || ''];
  }
  getOptionName(key: string): string {
    return key + ' mm';
  }
}
