import { hasOwnProperty as e } from "./hasOwnProperty.js";
const t = (t, a, r, n) => r => {
  const o = {};
  if ((r.origin || r.originalEvent?.origin) !== t) return "Message was not sent from the expected domain";
  if ("string" != typeof r.data) return "Event data was not of type string";
  if (!r.data.length) return "Invalid event data string";
  try {
    const t = JSON.parse(r.data);
    if (!e(t, "type") || t.type !== n) return "Event data was not of expected type";
    a(t);
  } catch (e) {
    return o.type = `${n}-JSON-parse-error`, o.comment = "failed to JSON parse event.data", o.extraInfo = `event.data = ${r.data}`, o.eventDataRaw = r.data, console.debug("get-process-message-handler::CATCH::Un-parseable JSON:: parseErrorObj=", o), !1;
  }
  return !0;
};
export { t as default };
