import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Template, Alias } from '../../models/document-template/document-template';
import { OptionsListMulti } from '../../components/gung-input-multi-select/gung-input-multi-select.component';

@Injectable({
  providedIn: 'root'
})
export class DocumentTemplatesService {
  protected baseUrlDefinitions = `json/list-export-definitions`;
  protected baseUrlAliases = `json/expression-aliases`;

  enableCreateTemplate = true;

  constructor(protected http: HttpClient) { }

  getTemplate(id: string): Observable<Template> {
    const headers = {
      maxAge: '-1'
    };
    const url = `${this.baseUrlDefinitions}` + '/' + id;
    return this.http.get<Template>(url, { headers });
  }

  getTemplates(): Observable<Template[]> {
    const headers = {
      maxAge: '-1'
    };
    const url = `${this.baseUrlDefinitions}`;
    return this.http.get<Template[]>(url, { headers });
  }

  getAlias(): Observable<Alias[]> {
    const headers = {
      maxAge: '-1'
    };
    const url = `${this.baseUrlAliases}`;
    return this.http.get<Alias[]>(url, { headers });
  }

  updateTemplate(template: Template): Observable<Template[]> {
    const url = `${this.baseUrlDefinitions}/${template.id}`;
    return this.http.post<any>(url, template);
  }
  createNewTemplate(template: Template): Observable<Template> {
    const url = `${this.baseUrlDefinitions}/create`;
    return this.http.post<any>(url, template);
  }

  updateAlias(alias: Alias): Observable<Template[]> {
    const url = `${this.baseUrlAliases}/${alias.id}`;
    return this.http.post<any>(url, alias);
  }

  checkIfIsActuator(): Observable<boolean> {
    return of(false);
  }

  getRolesList(): OptionsListMulti[] {
    const rolesList = [];
    rolesList.push({ id: 'USER', name: 'USER', selected: false });
    rolesList.push({ id: 'SALES', name: 'SALES', selected: false });
    rolesList.push({ id: 'ADMIN', name: 'ADMIN', selected: false });

    return rolesList;
  }

  syncFromPim(): Observable<string[]> {
    const headers = {
      maxAge: '-1'
    };
    const url = `json/sync/expression-alias`;
    return this.http.get<string[]>(url, { headers });

  }
}
