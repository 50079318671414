import { createElement as e } from "../../../../external/preact/dist/preact.js";
import r from "../../FormFields/Field/Field.js";
import o from "./StateField.js";
import t from "./CountryField.js";
import { useCoreContext as i } from "../../../../core/Context/CoreProvider.js";
import s from "../../FormFields/InputText.js";
function a(a) {
  const {
      i18n: n
    } = i(),
    {
      classNameModifiers: l = [],
      data: d,
      errors: c,
      valid: u,
      fieldName: m,
      onInput: f,
      onBlur: p,
      trimOnBlur: g,
      maxLength: F,
      disabled: C
    } = a,
    M = d[m],
    y = d.country,
    b = a.specifications.countryHasOptionalField(y, m),
    j = a.specifications.getKeyForField(m, y),
    w = b ? ` ${n.get("field.title.optional")}` : "",
    h = `${n.get(j)}${w}`,
    v = function (e, r, o, t) {
      if ("object" == typeof e[r]?.errorMessage) {
        const {
          translationKey: t,
          translationObject: i
        } = e[r].errorMessage;
        return o.get(t, i);
      }
      return o.get(e[r]?.errorMessage, {
        values: {
          label: t.toLowerCase()
        }
      }) || !!e[r];
    }(c, m, n, h);
  switch (m) {
    case "country":
      return e(t, {
        allowedCountries: a.allowedCountries,
        classNameModifiers: l,
        label: h,
        errorMessage: v,
        onDropdownChange: a.onDropdownChange,
        value: M,
        required: !b
      });
    case "stateOrProvince":
      return e(o, {
        classNameModifiers: l,
        label: h,
        errorMessage: v,
        onDropdownChange: a.onDropdownChange,
        selectedCountry: y,
        specifications: a.specifications,
        value: M,
        required: !b
      });
    default:
      return e(r, {
        label: h,
        classNameModifiers: l,
        errorMessage: v,
        isValid: u[m],
        name: m,
        i18n: n,
        onFocus: e => a.onFieldFocusAnalytics(m, e),
        onBlur: e => a.onFieldBlurAnalytics(m, e)
      }, e(s, {
        name: m,
        classNameModifiers: l,
        value: M,
        onInput: f,
        onBlur: p,
        maxlength: F,
        trimOnBlur: g,
        disabled: C,
        required: !b
      }));
  }
}
export { a as default };
