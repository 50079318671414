function e(e, r, i) {
  return r in e ? Object.defineProperty(e, r, {
    value: i,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = i, e;
}
class r extends Error {
  setCorrelationId(e) {
    this.correlationId = e;
  }
  toString() {
    return this.message;
  }
  constructor(r) {
    super(`ClickToPayService - Timeout during ${r.source}() of the scheme '${r.scheme}'`), e(this, "scheme", void 0), e(this, "source", void 0), e(this, "isTimeoutTriggeredBySchemeSdk", void 0), e(this, "correlationId", void 0), this.name = "TimeoutError", this.source = r.source, this.scheme = r.scheme, this.isTimeoutTriggeredBySchemeSdk = r.isTimeoutTriggeredBySchemeSdk;
  }
}
export { r as default };
