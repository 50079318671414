import t from "../core/Errors/AdyenCheckoutError.js";
function s(t, s, i) {
  return s in t ? Object.defineProperty(t, s, {
    value: i,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[s] = i, t;
}
class i {
  constructor(i, r = "body", e = {}, c = {}) {
    s(this, "src", void 0), s(this, "node", void 0), s(this, "attributes", void 0), s(this, "dataAttributes", void 0), s(this, "isScriptLoadCalled", !1), s(this, "script", void 0), s(this, "load", () => {
      if (!this.isScriptLoadCalled) return new Promise((s, i) => {
        const r = () => {
            this.script.setAttribute("data-script-loaded", "true"), s();
          },
          e = s => {
            this.remove(), i(new t("SCRIPT_ERROR", `Unable to load script ${this.src}. Message: ${s.message}`, {
              cause: s.error
            }));
          };
        this.isScriptLoadCalled = !0;
        const c = document.querySelector(this.node);
        if (this.script = c.querySelector(`script[src="${this.src}"]`), this.script && this.script.getAttribute("data-script-loaded")) s();else {
          if (this.script) return this.script.addEventListener("load", r), void this.script.addEventListener("error", e);
          this.script = document.createElement("script"), Object.assign(this.script, this.attributes), Object.assign(this.script.dataset, this.dataAttributes), this.script.src = this.src, this.script.async = !0, this.script.addEventListener("load", r), this.script.addEventListener("error", e), c.appendChild(this.script);
        }
      });
    }), s(this, "remove", () => this.script.parentNode && this.script.parentNode.removeChild(this.script)), this.src = i, this.node = r, this.attributes = e, this.dataAttributes = c;
  }
}
export { i as default };
