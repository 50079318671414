import { ValidationRuleResult as r } from "./ValidationRuleResult.js";
function t(r, t, e) {
  return t in r ? Object.defineProperty(r, t, {
    value: e,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : r[t] = e, r;
}
class e {
  get isValid() {
    return this.validationResults.reduce((r, t) => r && t.isValid, !0);
  }
  hasError(r = !1) {
    return Boolean(this.getError(r));
  }
  getError(r = !1) {
    return this.validationResults.find(t => t.hasError(r));
  }
  getAllErrors() {
    return this.validationResults.filter(r => r.hasError());
  }
  constructor(r) {
    t(this, "validationResults", void 0), this.validationResults = r;
  }
}
class s {
  setRules(r) {
    this.rules = {
      ...this.rules,
      ...r
    };
  }
  getRulesFor(r) {
    let t = this.rules[r] ?? this.rules.default;
    return Array.isArray(t) || (t = [t]), t;
  }
  validate({
    key: t,
    value: s,
    mode: l = "blur"
  }, i) {
    const u = this.getRulesFor(t).map(t => new r(t, s, l, i));
    return new e(u);
  }
  constructor(r) {
    t(this, "rules", {
      default: {
        validate: () => !0,
        modes: ["blur", "input"]
      }
    }), this.setRules(r);
  }
}
export { s as default };
