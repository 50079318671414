import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { GungTableActiveFilter, GungTableConfig, GungTableCustomBodyComponent } from '../../models/common-table';
import { gungComparatorHelper, gungGetPropertyOfObject } from '../../utils/gung-utils';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'gung-table',
  templateUrl: './gung-table.component.html',
  styleUrl: './gung-table.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class GungTableComponent<T> implements OnInit, OnChanges, AfterViewInit {
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (this.openFilter) {
      const clickedInsideMenu = (event.target as HTMLElement).closest('.filter-box');
      if (!clickedInsideMenu) {
        this.openFilter.showFilterBox = false;
      }
    }
  }

  @Input() config: GungTableConfig<T>;

  @Input() selectedItemsInput: string[];

  @Input() showOnlySelected = false;

  @Output() selectedItemsOutput = new EventEmitter<string[]>();

  @Output() filterDataOutput = new EventEmitter<T[]>();

  dynamicComponentRef: ComponentRef<GungTableCustomBodyComponent<T>>;

  @ViewChild('gungTableCustom', { read: ViewContainerRef, static: false }) viewChild;

  useCustomComponent = false;

  indexSorted: number;
  filterItems: T[];

  dataTable: any;
  tempData: any;
  activeFilters: GungTableActiveFilter[] = [];
  openFilter: GungTableActiveFilter;
  search: string = '';
  pageSize: number = 30;
  currentPage = 1;
  totalPages = 1;
  paginationRange: number[] = [];
  paginanitonNumber = true;
  limitItems = 30;
  useQueryParams = false;
  loadQueryParams = false;
  layoutFixed = false;
  currentQuery;
  tableId;
  selectItems = false;
  selectedItems: string[] = [];



  constructor(
    protected changeDetector: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngAfterViewInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (window.scrollY) {
          sessionStorage.setItem(this.tableId, window.scrollY.toString());
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.config && this.config) {
      this.filterItems = structuredClone(this.config.data);
      this.pageSize = this.config.itemsPerPage || 30;
      this.useQueryParams = this.config.useQueryParams || false;
      this.layoutFixed = this.config.layoutFixed === false ? false : true;
      this.selectItems = this.config.selectItems;
      this.tableId = 'table' + (this.config.tableId ? '_' + this.config.tableId : '');


      this.limitItems = this.config.itemsPerPage || 30;
      this.paginanitonNumber = this.config.useNumberPaginantion === false ? false : true;
      this.getQueryParams();
      if (this.config.customBodyComponent) {
        this.useCustomComponent = true;
        this.changeDetector.detectChanges();
        this.renderLayout(this.config.customBodyComponent);
      }

      const defaultHeaderSortIndex: number = this.config.headers.findIndex(
        h => h.sortActivated === 'ASC' || h.sortActivated === 'DESC'
      );
      if (defaultHeaderSortIndex > -1) {
        this.gungTableSort(defaultHeaderSortIndex, true);
      } else {
        this.mountData();
      }
    }
    if (changes.selectedItemsInput && this.filterItems) {
      this.selectedItems = this.selectedItemsInput;
      this.mountData();
      if (this.config.customBodyComponent) {
        this.dynamicComponentRef.instance.selectedItems = this.selectedItems;
      }
    }
    if (changes.showOnlySelected && this.filterItems) {
      this.mountData();
    }
  }

  ngOnInit() { }

  mountData() {
    this.dataTable = [];
    for (const item of this.filterItems) {
      const dataItem = [];
      if (this.selectItems) {
        const value = gungGetPropertyOfObject(this.config.pathToSelect, item);

        let selectValue = { id: value, selected: this.selectedItemsInput.includes(value) };
        dataItem.push(selectValue);
      }
      for (const header of this.config.headers) {
        const value = gungGetPropertyOfObject(header.path, item);
        dataItem.push(value);
      }
      this.dataTable.push(dataItem);
    }
    if (this.showOnlySelected) {
      this.mountSelectedData();
    }
    this.tempData = structuredClone(this.dataTable);

    this.filterDataOutput.emit(this.filterItems);


    if (this.paginanitonNumber) {
      this.updatePagination();
    } else {
      this.loadMoreLimit();
    }
    this.scrollToPosition();
  }

  mountSelectedData() {

    this.dataTable = this.dataTable.filter(d => d[0].selected);
  }

  scrollToPosition() {
    const savedScrollPosition = sessionStorage.getItem(this.tableId);
    if (savedScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
        sessionStorage.removeItem(this.tableId);
      }, 500);
    }
  }

  loadMoreLimit() {
    this.totalPages = Math.ceil(this.filterItems.length / this.pageSize);
    this.currentPage = 1;
    this.updateTableData();
  }

  loadMoreitems(): void {
    this.pageSize += this.limitItems;
    this.setQueryParams('limit');
    this.updateTableData();
  }

  setQueryParams(type: 'limit' | 'filter' | 'search' | 'sort' | 'page') {
    if (this.useQueryParams) {
      if (type === 'filter') {
        let filtersParams = [];
        for (const filter of this.activeFilters) {
          if (filter.activeValues.length > 0) {
            const filterparam = { index: filter.headerIndex, values: filter.activeValues };
            filtersParams.push(filterparam);
          }
        }
        if (filtersParams.length > 0) {
          this.currentQuery = {
            ...this.currentQuery,
            filter: filtersParams
          };
        } else {
          delete this.currentQuery.filter
        }
      } else if (type === 'limit') {
        if (this.pageSize > this.limitItems) {
          this.currentQuery = {
            ...this.currentQuery,
            limit: this.pageSize
          };
        }
      } else if (type === 'sort') {
        if (this.indexSorted !== null && this.indexSorted > -1) {
          const sort = { sort: this.config.headers[this.indexSorted].sortActivated, col: this.indexSorted };
          this.currentQuery = {
            ...this.currentQuery,
            sort: sort
          };
        } else {
          delete this.currentQuery.sort;
        }
      } else if (type === 'page') {
        if (this.currentPage !== 1) {
          this.currentQuery = {
            ...this.currentQuery,
            page: this.currentPage
          };
        } else {
          delete this.currentQuery?.page;
        }
      } else {
        if (this.search) {
          this.currentQuery = {
            ...this.currentQuery,
            search: this.search
          };
        } else {
          delete this.currentQuery.search;
        }
      }
      const tableQuery = JSON.stringify(this.currentQuery);
      let queryParams = {};
      queryParams[this.tableId] = tableQuery;

      const urlTree = this.router.createUrlTree([], {
        relativeTo: null,
        queryParams: queryParams,
        preserveFragment: false
      });

      this.location.replaceState(urlTree.toString());
    }
  }

  getQueryParams() {
    if (this.useQueryParams && !this.loadQueryParams) {
      const queryParams = this.route.snapshot.queryParams;
      if (queryParams && queryParams[this.tableId]) {
        const dataParams = JSON.parse(queryParams[this.tableId]);
        this.currentQuery = dataParams;
        if (dataParams.limit) {
          this.pageSize = Number(dataParams.limit);
        }
        if (dataParams.search) {
          this.search = dataParams.search;
        }
        if (dataParams.page) {
          this.currentPage = dataParams.page;
        }
        const filters = dataParams.filter;
        if (filters && filters.length > 0) {
          for (const filter of filters) {
            let activeFilter: GungTableActiveFilter = {
              activeValues: filter.values,
              headerIndex: filter.index,
              showFilterBox: false,
              values: []
            };
            const valueCountMap: { [key: string]: number } = {};
            for (const item of this.filterItems) {
              const header = this.config.headers[filter.index];
              const value = gungGetPropertyOfObject(header.path, item);
              if (value) {
                if (valueCountMap[value]) {
                  valueCountMap[value]++;
                } else {
                  valueCountMap[value] = 1;
                }
              }
            }
            activeFilter.values = Object.keys(valueCountMap).map(value => ({
              value,
              qty: valueCountMap[value],
              active: filter.values.includes(value)
            }));
            this.activeFilters = [...this.activeFilters, activeFilter];
          }
        }

        if (dataParams.sort) {
          this.config.headers[Number(dataParams.sort.col)].sortActivated = dataParams.sort.sort;
          this.gungTableSort(dataParams.sort.col, true);
        }

        this.filterData();
      }
      this.loadQueryParams = true;
    }
  }

  updatePagination() {
    this.totalPages = Math.ceil(this.filterItems.length / this.pageSize);
    this.calculatePaginationRange();
  }

  updateTableData() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.dataTable = this.tempData.slice(startIndex, endIndex);
    if (this.useCustomComponent) {
      this.dynamicComponentRef.instance.dataTable = [...this.dataTable];
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updateTableData();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateTableData();
    }
  }

  gungTableSort(index: number, defaultSort: boolean = false) {
    const header = this.config.headers[index];
    let sort;

    if (defaultSort) {
      if (header.sortActivated === 'ASC') {
        sort = 1;
      } else if (header.sortActivated === 'DESC') {
        sort = -1;
      }
    } else {
      if (header.sortActivated === 'ASC') {
        header.sortActivated = 'DESC';
        sort = -1;
      } else if (header.sortActivated === 'DESC') {
        header.sortActivated = 'NONE';
      } else {
        header.sortActivated = 'ASC';
        sort = 1;
      }
    }

    for (let i = 0; i < this.config.headers.length; i++) {
      if (i !== index) {
        this.config.headers[i].sortActivated = 'NONE';
      }
    }

    if (header.sortActivated !== 'NONE') {
      this.indexSorted = index;
      this.filterItems.sort((a, b) => {
        const aValue = gungGetPropertyOfObject(header.path, a);
        const bValue = gungGetPropertyOfObject(header.path, b);
        return gungComparatorHelper(aValue, bValue, sort);
      });
    } else {
      this.filterData();
      this.indexSorted = null;
    }
    this.setQueryParams('sort');

    this.mountData();
  }

  searchChange(search) {
    this.search = search;
    this.setQueryParams('search');
    this.filterData();
  }

  openFilterOptions(index) {
    const header = this.config.headers[index];
    let activeFilter = this.activeFilters.find(f => f.headerIndex === index);
    
    if (!activeFilter) {
      activeFilter = { activeValues: [], headerIndex: index, values: [], showFilterBox: true };
      this.activeFilters = [...this.activeFilters, activeFilter];
      const valueCountMap: { [key: string]: number } = {};
      for (const item of this.filterItems) {
        const value = gungGetPropertyOfObject(header.path, item);
        if (value) {
          if (valueCountMap[value]) {
            valueCountMap[value]++;
          } else {
            valueCountMap[value] = 1;
          }
        }
      }
      activeFilter.values = Object.keys(valueCountMap).map(value => ({
        value,
        qty: valueCountMap[value],
        active: false
      }));
    } else {
      const valueCountMap: { [key: string]: number } = {};
      for (const item of this.filterItems) {
        const value = gungGetPropertyOfObject(header.path, item);
        if (value) {
          if (valueCountMap[value]) {
            valueCountMap[value]++;
          } else {
            valueCountMap[value] = 1;
          }
        }
      }

      let otherFilters = this.activeFilters.filter(f => f.headerIndex !== index);
      for (const otherFilter of otherFilters) {
        const valueCountOthersMap: { [key: string]: number } = {};
          for (const item of this.filterItems) {
            const value = gungGetPropertyOfObject(header.path, item);
            if (value) {
              if (valueCountOthersMap[value]) {
                valueCountOthersMap[value]++;
              } else {
                valueCountOthersMap[value] = 1;
              }
            }
          }
          otherFilter.values = otherFilter.values.filter((existingValue) => {
            if (valueCountOthersMap[existingValue.value] !== undefined) {
              existingValue.qty = valueCountOthersMap[existingValue.value];
              return true; 
            }
            if(existingValue.active){
              return true;
            }
            return false;
          });
      } 

      

      activeFilter.values = activeFilter.values.filter((existingValue) => {
        if (valueCountMap[existingValue.value] !== undefined) {
          existingValue.qty = valueCountMap[existingValue.value];
          return true; 
        }
        return false;
      });

      Object.keys(valueCountMap).forEach((value) => {
        if (!activeFilter.values.find((existingValue) => existingValue.value === value)) {
          activeFilter.values.push({
            value,
            qty: valueCountMap[value],
            active: false
          });
        }
      });
      activeFilter.showFilterBox = true;
    }
    this.openFilter = structuredClone(activeFilter);
    
    this.sortOpenFilter();
  }

  sortOpenFilter() {
    this.openFilter.values.sort((a, b) => {
      if (a.active && !b.active) {
        return -1;
      }
      if (!a.active && b.active) {
        return 1
      };
      return a.value.localeCompare(b.value)
    });
  }

  selectFilter(filter: GungTableActiveFilter, value: string) {

    let changeFilter = this.activeFilters.find(f => f.headerIndex === filter.headerIndex);
    const currentVal = changeFilter.values.find(v => v.value === value);
    if (!changeFilter.activeValues.includes(value)) {
      changeFilter.activeValues.push(value);
      currentVal.active = true;
    } else {
      changeFilter.activeValues = filter.activeValues.filter(v => v !== value);
      currentVal.active = false;
    }
    if (!filter.activeValues.includes(value)) {
      filter.activeValues.push(value);
    } else {
      filter.activeValues = filter.activeValues.filter(v => v !== value);
    }

    this.activeFilters = [...this.activeFilters];
    this.filterData();
    this.setQueryParams('filter');

    const valueCountMap: { [key: string]: number } = {};
    for (const item of this.filterItems) {
      const header = this.config.headers[filter.headerIndex];
      const value = gungGetPropertyOfObject(header.path, item);
      if (value) {
        if (valueCountMap[value]) {
          valueCountMap[value]++;
        } else {
          valueCountMap[value] = 1;
        }
      }
    }
    let updateCurrentFilter = this.activeFilters.find(f => f.headerIndex === filter.headerIndex);
    Object.keys(valueCountMap).forEach((value) => {
      if (!updateCurrentFilter.values.find((existingValue) => existingValue.value === value)) {
        updateCurrentFilter.values.push({
          value,
          qty: valueCountMap[value],
          active: false
        });
      }
    });
    this.activeFilters = [...this.activeFilters];
  }

  filterData() {
    this.currentPage = 1;
    this.filterItems = structuredClone(this.config.data);
    if (this.search) {
      this.filterItems = this.filterItems.filter(item => {
        return this.config.headers.some(header => {
          const value = gungGetPropertyOfObject(header.path, item);

          if (header.searchable && value && value.toString().toLowerCase().includes(this.search.toLowerCase())) {
            return true;
          }
          return false;
        });
      });
    }
    if (this.activeFilters && this.activeFilters.length > 0) {
      for (const filter of this.activeFilters) {
        if (filter.activeValues.length > 0) {
          const head = this.config.headers[filter.headerIndex];
          this.filterItems = this.filterItems.filter(item => {
            const value = gungGetPropertyOfObject(head.path, item);
            return filter.activeValues.includes(value);
          });
        }
      }
    }

    this.mountData();
  }

  calculatePaginationRange() {
    const range = [];
    const totalNumbersToShow = 1; // Page number to show before and after current page

    if (this.totalPages <= 1) {
      this.paginationRange = [1];
    } else {
      range.push(1);

      const startPage = Math.max(2, this.currentPage - totalNumbersToShow);
      const endPage = Math.min(this.totalPages - 1, this.currentPage + totalNumbersToShow);

      if (startPage > 2) {
        range.push(-1);
      }
      for (let i = startPage; i <= endPage; i++) {
        range.push(i);
      }
      if (endPage < this.totalPages - 1) {
        range.push(-1);
      }
      range.push(this.totalPages);

      this.paginationRange = range;
    }
    this.setQueryParams('page');
    this.updateTableData();
  }

  goToPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.calculatePaginationRange();
    }
  }

  goToFirstPage() {
    this.currentPage = 1;
    this.calculatePaginationRange();
  }

  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.calculatePaginationRange();
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.calculatePaginationRange();
    }
  }

  goToLastPage() {
    this.currentPage = this.totalPages;
    this.calculatePaginationRange();
  }

  renderLayout(layoutComponent: Type<GungTableCustomBodyComponent<T>>): void {
    this.viewChild?.clear();
    this.dynamicComponentRef = this.viewChild.createComponent(layoutComponent);
    this.dynamicComponentRef.instance.data = this.config.data;
    this.dynamicComponentRef.instance.selectedItems = this.selectedItems;
    this.dynamicComponentRef.instance.selectItemOutput.subscribe(selected => {
      this.selectedItems = selected;
      this.selectedItemsInput = selected;
      this.selectedItemsOutput.emit(this.selectedItems);
      this.mountData();
    })
  }

  searchFilter(value) {
    this.openFilter = structuredClone(this.activeFilters.find(a => a.headerIndex === this.openFilter.headerIndex));
    this.openFilter.values = this.openFilter.values.filter(v => {
      if (v.value && v.value.toString().toLowerCase().includes(value.toLowerCase())) {
        return true;
      }
      return false;
    });
  }

  selectItem(itemCol) {
    itemCol.selected = !itemCol.selected;
    if (itemCol.selected) {
      this.selectedItems.push(itemCol.id)
    } else {
      const index = this.selectedItems.indexOf(itemCol.id);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    }
    this.selectedItemsOutput.emit(this.selectedItems);
  }


  clearFilter(value: number) {
    let changeFilter = this.activeFilters.find(f => f.headerIndex === value);
    if (changeFilter) {
      changeFilter.activeValues = [];
      changeFilter.values.forEach(v => v.active = false);
      this.filterData();
      const valueCountMap: { [key: string]: number } = {};
      const header = this.config.headers[value];
      for (const item of this.filterItems) {
        const value = gungGetPropertyOfObject(header.path, item);
        if (value) {
          if (valueCountMap[value]) {
            valueCountMap[value]++;
          } else {
            valueCountMap[value] = 1;
          }
        }
      }
      changeFilter.values = Object.keys(valueCountMap).map(value => ({
        value,
        qty: valueCountMap[value],
        active: false
      }));
      changeFilter.showFilterBox = true; 
      this.openFilter = structuredClone(changeFilter);
    }
    this.setQueryParams('filter');
    this.activeFilters = [...this.activeFilters];
  }
}
