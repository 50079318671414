const e = "encrypted",
  r = "encryptedCardNumber",
  c = "encryptedExpiryDate",
  t = "encryptedExpiryMonth",
  a = "encryptedExpiryYear",
  d = "encryptedSecurityCode",
  i = "encryptedPassword",
  n = "encryptedBankAccountNumber",
  o = "encryptedBankLocationId",
  s = "encryptedSecurityCode3digits",
  y = "encryptedSecurityCode4digits",
  p = "giftcard",
  u = "5.3.1",
  m = ["amex", "mc", "visa"],
  C = ["ach", p],
  b = [r, c, t, a, d, i],
  x = [n, o],
  h = b.concat(x),
  D = ["bcmc"],
  g = "required",
  k = "optional",
  B = "hidden",
  l = g,
  v = k,
  A = B,
  E = g,
  M = k,
  N = B,
  S = "data-cse",
  f = "data-info",
  j = "data-uid",
  I = ["accel", "pulse", "star", "nyce"],
  w = {
    visa: "VISA",
    mc: "MasterCard",
    amex: "American Express",
    discover: "Discover",
    cup: "China Union Pay",
    jcb: "JCB",
    diners: "Diners Club",
    maestro: "Maestro",
    bcmc: "Bancontact card",
    bijcard: "de Bijenkorf Card"
  },
  L = {
    [r]: "cardNumber",
    [c]: "expiryDate",
    [d]: "securityCode",
    [t]: "expiryMonth",
    [a]: "expiryYear",
    [i]: "password",
    [n]: "bankAccountNumber",
    [o]: "bankLocationId",
    [s]: "securityCodeThreeDigits",
    [y]: "securityCodeFourDigits"
  },
  P = 6e3;
export { h as ALL_SECURED_FIELDS, I as BRAND_ICON_UI_EXCLUSION_LIST, w as BRAND_READABLE_NAME_MAP, b as CREDIT_CARD_SF_FIELDS, A as CVC_POLICY_HIDDEN, v as CVC_POLICY_OPTIONAL, l as CVC_POLICY_REQUIRED, S as DATA_ENCRYPTED_FIELD_ATTR, f as DATA_INFO, j as DATA_UID, N as DATE_POLICY_HIDDEN, M as DATE_POLICY_OPTIONAL, E as DATE_POLICY_REQUIRED, D as DEDICATED_CARD_COMPONENTS, m as DEFAULT_CARD_GROUP_TYPES, e as ENCRYPTED, n as ENCRYPTED_BANK_ACCNT_NUMBER_FIELD, o as ENCRYPTED_BANK_LOCATION_FIELD, r as ENCRYPTED_CARD_NUMBER, c as ENCRYPTED_EXPIRY_DATE, t as ENCRYPTED_EXPIRY_MONTH, a as ENCRYPTED_EXPIRY_YEAR, i as ENCRYPTED_PWD_FIELD, d as ENCRYPTED_SECURITY_CODE, s as ENCRYPTED_SECURITY_CODE_3_DIGITS, y as ENCRYPTED_SECURITY_CODE_4_DIGITS, p as GIFT_CARD, B as HIDDEN, C as NON_CREDIT_CARD_TYPE_SECURED_FIELDS, k as OPTIONAL, x as OTHER_SF_FIELDS, g as REQUIRED, P as SF_CONFIG_TIMEOUT, L as SF_FIELDS_MAP, u as SF_VERSION };
