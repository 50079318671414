import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { first } from 'rxjs';
import { TemplateItemEditorModalComponent } from '../template-item-editor-modal/template-item-editor-modal.component';
import { Template, Alias, Row } from '../../../models/document-template/document-template';
import { DocumentTemplatesService } from '../../../services/document-templates/document-templates.service';
import { DocumentTemplateCreateModalComponent } from '../document-template-create-modal/document-template-create-modal.component';
import { OptionsListMulti } from '../../gung-input-multi-select/gung-input-multi-select.component';
import { GungTabChangeEvent } from '../../gung-tabs/gung-tabs.component';
import { CommonModalService } from '../../../services/common-modal/common-modal.service';
import { RoutingUtilService } from '../../../services/routing-util/routing-util.service';
import { gungAddRemoveSpinner } from '../../../utils/gung-utils';
import { GungBackendFeatureService } from '../../../services/gung-backend-feature/gung-backend-feature.service';

@Component({
  selector: 'lib-template-item-editor',
  templateUrl: './template-item-editor.component.html',
  styleUrls: ['./template-item-editor.component.css']
})
export class TemplateItemEditorComponent implements OnInit {
  templatesList: Template[];
  selectedTemplate: Template;
  templateId: string;
  editedTemplatesId: string[] = [];

  showAlias = false;
  aliasList: Alias[];
  editedAliasId: string[] = [];
  rolesList: OptionsListMulti[];
  rows: Row[] = [];
  rowsHeaders: Row[] = [];
  filteredRows: Row[] = [];
  filteredRowsHeaders: Row[] = [];
  filteredAlias: Alias[] = [];
  searchString: string;

  isActuator = false;
  enableCreateTemplate = this.documentTemplatesService.enableCreateTemplate;

  alertMessage: Alert;
  loader = false;

  currentTab: string;
  featurePimEnabled: boolean;

  constructor(
    protected router: Router,
    // protected authService: AuthService,
    protected modalService: NgbModal,
    protected documentTemplatesService: DocumentTemplatesService,
    protected commonModalService: CommonModalService,
    protected routingUtilService: RoutingUtilService,
    protected backendFeatureService: GungBackendFeatureService
  ) { }

  ngOnInit() {
    window.addEventListener('beforeunload', e => {
      if (!this.forceExit && this.editedTemplatesId.length > 0) {
        const confirmationMessage = 'o/';
        e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        return confirmationMessage; // Gecko, WebKit, Chrome <34
      }
    });
    this.rolesList = this.documentTemplatesService.getRolesList();

    this.documentTemplatesService.checkIfIsActuator().subscribe(isActuator => (this.isActuator = isActuator));

    this.documentTemplatesService.getTemplates().pipe(first()).subscribe(templates => {
      if (!templates || templates.length === 0) {
        return;
      }
      this.templatesList = structuredClone(templates);
      const template = this.templatesList[0];
      this.templateId = template.id;
      this.setTemplate(this.templateId);
    });
    this.documentTemplatesService.getAlias().subscribe(alias => {
      if (!alias) {
        return;
      }
      this.aliasList = alias;
      this.filteredAlias = this.aliasList;
    });

    this.backendFeatureService.isActivated('gung-cloud-pim').pipe(first()).subscribe(isActivated => this.featurePimEnabled = isActivated);
  }

  public setSelectedValues(roles): void {
    if (this.selectedTemplate.availableToRoles === roles) {
      return;
    }
    this.selectedTemplate.availableToRoles = roles;
    this.unsaved(this.selectedTemplate.id);
  }

  updateRolesList(roleIds: string[]) {
    roleIds = roleIds || [];
    this.rolesList.forEach(role => {
      if (roleIds.includes(role.id)) {
        role.selected = true;
      } else {
        role.selected = false;
      }
    });
    this.rolesList = JSON.parse(JSON.stringify(this.rolesList));
  }

  createNewXlsxModal() {
    const modalRef = this.modalService.open(DocumentTemplateCreateModalComponent, { size: 'lg' });
    modalRef.componentInstance.templatesList = this.templatesList;
    modalRef.result.then(
      () => { },
      reason => { }
    );
  }

  setTemplate(templateId) {
    this.showAlias = false;
    this.templateId = templateId;

    const template = this.templatesList.find(t => t.id === templateId);
    this.selectedTemplate = template;
    this.updateRolesList(template.availableToRoles);
    this.rows = template.lineDefinition.columns;
    this.rowsHeaders = template.headerDefinition.columns;

    this.filteredRows = this.rows;
    this.filteredRowsHeaders = this.rowsHeaders;
  }

  setSearch(search: string): void {
    this.searchString = search;
    if (!search || search.trim() === '') {
      this.filteredRows = this.rows;
      this.filteredRowsHeaders = this.rowsHeaders;
      this.filteredAlias = this.aliasList;
      return;
    }

    if (this.showAlias) {
      this.filteredAlias = this.filterBySearchTerm(search, this.aliasList);
    } else {
      this.filteredRows = this.filterBySearchTerm(search, this.rows);
      this.filteredRowsHeaders = this.filterBySearchTerm(search, this.filteredRowsHeaders);
    }
  }

  // COPY from filter-list.service
  private filterBySearchTerm(searchTerm: string, items: any[]): any[] {
    return items.filter(item => {
      let hasHitAllTerms = true;
      const queryTerms = searchTerm.split(' ');
      const terms = this.showAlias ? [item.id] : [item.title];
      queryTerms.forEach(queryTerm => {
        const locatedTerm = terms.find(term => {
          if (term === null || term === undefined) {
            return false;
          }
          return term.toUpperCase().indexOf(queryTerm.toUpperCase()) >= 0;
        });

        hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
      });

      return hasHitAllTerms;
    });
  }

  clickClone(i: number) {
    const item = this.rows[i];
    const newItem = { ...item, title: item.title + ' (copy)' };
    this.clickEdit(i, newItem, true);
  }

  clickRemove(i: number) {
    this.rows.splice(i, 1);
    this.unsaved(this.templateId);
  }

  clickEdit(i: number, newRow?: Row, cloneItem?: boolean) {
    if (this.rows.length !== this.filteredRows.length) {
      i = this.rows.findIndex(r => r === this.filteredRows[i]);
    }
    const modalRef = this.modalService.open(TemplateItemEditorModalComponent, { ariaLabelledBy: 'modal-basic-title' });
    const item = { ...this.rows[i] };
    modalRef.componentInstance.item = JSON.stringify(newRow || item);
    modalRef.componentInstance.isActuator = this.isActuator;
    modalRef.componentInstance.cloneItem = cloneItem;
    modalRef.result.then(
      (result: string) => {
        if (result.localeCompare('Cance click') !== 0) {
          const row = JSON.parse(result) as Row;
          this.unsaved(this.templateId);
          if (newRow) {
            this.rows.splice(i + 1, 0, row);
          } else {
            this.rows[i] = row;
          }
          if (this.rows.length !== this.filteredRows.length) {
            this.setSearch(this.searchString);
          }
        }
      },
      reason => {
        // console.log(`Dismissed ${reason}`); // ModalDismissReasons.BACKDROP_CLICK
      }
    );
  }

  addRow() {
    if (this.showAlias) {
      const newItem: Alias = {
        id: '',
        expression: '',
        extra: {},
        name: null
      };
      this.aliasList.push(newItem);
      this.unsaved(newItem.id);
    } else {
      if (this.currentTab === 'header') {
        const newItem: Row = {
          active: false,
          ignoreErrors: false,
          title: '',
          targetPath: '',
          expression: '',
          targetType: 'String',
          alias: null
        };
        this.clickEditHeaders(this.rowsHeaders.length - 1, newItem);
      } else {
        const newItem: Row = {
          active: false,
          ignoreErrors: false,
          title: '',
          targetPath: '',
          expression: '',
          targetType: 'String',
          alias: null
        };
        this.clickEdit(this.rows.length - 1, newItem);
      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    this.unsaved(this.templateId);
  }

  unsaved(id: string, saved?: boolean) {
    if (this.showAlias) {
      if (saved) {
        // Remove after save
        this.editedAliasId = this.editedAliasId.filter(t => t !== id);
        return;
      }
      if (this.editedAliasId.includes(id)) {
        return;
      }
      this.editedAliasId.push(id);
    } else {
      if (saved) {
        // Remove after save
        this.editedTemplatesId = this.editedTemplatesId.filter(t => t !== id);
        return;
      }
      if (this.editedTemplatesId.includes(id)) {
        return;
      }
      this.editedTemplatesId.push(id);
    }
  }

  onSave() {
    if (this.showAlias) {
      this.onSaveAlias();
    } else {
      this.onSaveTemplate();
    }
  }

  onSaveTemplate() {
    const template: Template = this.selectedTemplate;
    this.loader = true;
    const dataRows: Row[] = this.rows.map(r => {
      return {
        active: r.active,
        ignoreErrors: r.ignoreErrors,
        title: r.title,
        targetPath: r.targetPath,
        expression: r.expression,
        alias: r.alias,
        targetType: r.targetType
      };
    });

    const dataRowsHeaders: Row[] = this.rowsHeaders.map(r => {
      return {
        active: r.active,
        ignoreErrors: r.ignoreErrors,
        title: r.title,
        targetPath: r.targetPath,
        expression: r.expression,
        alias: r.alias,
        targetType: r.targetType
      };
    });

    const dataTemplate: Template = {
      ...template,
      headerDefinition: {
        ...template.headerDefinition,
        columns: dataRowsHeaders
      },
      lineDefinition: {
        ...template.lineDefinition,
        columns: dataRows
      }
    };

    this.documentTemplatesService.updateTemplate(dataTemplate).subscribe(
      () => {
        this.alertMessage = {
          type: 'success',
          message: 'CHANGE_SAVED_SUCCESS'
        };
        const templateSaved = this.templatesList.find(t => t.id === this.templateId);
        templateSaved.lineDefinition.columns = JSON.parse(JSON.stringify(this.rows));
        templateSaved.headerDefinition.columns = JSON.parse(JSON.stringify(this.rowsHeaders));
        templateSaved.name = template.name;
        this.unsaved(this.templateId, true);
        this.loader = false;
      },
      error => {
        this.alertMessage = {
          type: 'danger',
          message: 'CHANGE_SAVED_FAIL'
        };
        this.loader = false;
      }
    );
  }

  onSaveAlias() {
    const observables = [];
    this.loader = true;
    this.editedAliasId = this.editedAliasId.filter(a => this.aliasList.map(alias => alias.id).includes(a));

    this.editedAliasId.forEach(aliasId => {
      const alias: Alias = this.aliasList.find(a => a.id === aliasId);
      observables.push(this.documentTemplatesService.updateAlias(alias));
    });

    forkJoin(observables)
      .pipe(first())
      .subscribe(
        data => {
          this.alertMessage = {
            type: 'success',
            message: 'CHANGE_SAVED_SUCCESS'
          };
          data.forEach((a: Alias) => {
            this.unsaved(a.id, true);
          });
          this.loader = false;
        },
        err => {
          this.alertMessage = {
            type: 'danger',
            message: 'CHANGE_SAVED_FAIL'
          };
          this.loader = false;
        }
      );
  }

  clickCloneHeaders(i: number) {
    const item = this.rowsHeaders[i];
    const newItem = { ...item, title: item.title + ' (copy)' };
    this.clickEditHeaders(i, newItem);
  }

  clickRemoveHeaders(i: number) {
    this.rowsHeaders.splice(i, 1);
    this.unsaved(this.templateId);
  }

  clickEditHeaders(i: number, newRow?: Row) {
    if (this.rowsHeaders.length !== this.filteredRowsHeaders.length) {
      i = this.rowsHeaders.findIndex(r => r === this.filteredRowsHeaders[i]);
    }
    const modalRef = this.modalService.open(TemplateItemEditorModalComponent, { ariaLabelledBy: 'modal-basic-title' });
    const item = { ...this.rowsHeaders[i] };
    modalRef.componentInstance.item = JSON.stringify(newRow || item);
    modalRef.componentInstance.isActuator = this.isActuator;
    modalRef.result.then(
      (result: string) => {
        if (result.localeCompare('Cance click') !== 0) {
          const row = JSON.parse(result) as Row;
          this.unsaved(this.templateId);
          if (newRow) {
            this.rowsHeaders.splice(i + 1, 0, row);
          } else {
            this.rowsHeaders[i] = row;
          }
          if (this.rowsHeaders.length !== this.filteredRowsHeaders.length) {
            this.setSearch(this.searchString);
          }
        }
      },
      reason => {
        // console.log(`Dismissed ${reason}`); // ModalDismissReasons.BACKDROP_CLICK
      }
    );
  }

  dropHeaders(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rowsHeaders, event.previousIndex, event.currentIndex);
    this.unsaved(this.templateId);
  }

  handleTabChange(event: GungTabChangeEvent) {
    this.currentTab = event.nextId;
  }

  trackByFn(index: number, item: any) {
    return item.name;
  }

  savedUrl: string;
  forceExit = false;
  hasUnsavedChanges(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    if (this.forceExit) {
      return false;
    }
    if (this.editedTemplatesId.length > 0) {
      this.savedUrl = nextState.url;
      this.exit();
    }
    return this.editedTemplatesId.length > 0;
  }
  exit(): void {
    this.commonModalService
      .openConfirmYesNoModal(
        undefined,
        'You have data to save. Are you sure you want to leave?',
        { size: 'sm' }
      )
      .then(
        result => {
          this.forceExit = result;
          if (result) {
            window.onpopstate = () => { };
            const pathWithParams = this.routingUtilService.parsePathWithQueryParams(this.savedUrl);
            this.router.navigate([pathWithParams.path], { queryParams: pathWithParams.params });
          }
        },
        reason => { }
      );
  }

  syncPim({ target }) {
    gungAddRemoveSpinner(target);
    this.commonModalService.openConfirmYesNoModal('SYNC', 'SYNC_FROM_PIM_CONFIRMATION').then((result => {
      if (result) {
        this.documentTemplatesService.syncFromPim().subscribe(data => {
          this.searchString = '';
          this.aliasList.push(...data.filter(d => this.aliasList.findIndex(a => a.id === d) === -1).map(d => ({
            id: d,
            expression: '',
            extra: {},
            name: d
          }) as Alias));
          this.filteredAlias = this.aliasList;
          gungAddRemoveSpinner(target);
        })
      } else {
        gungAddRemoveSpinner(target);
      }
    }
    ),
      (
        (err) => {
          gungAddRemoveSpinner(target);
        }
      )
    );
  }
}
interface Alert {
  type: string;
  message: string;
}
