import { createElement as e } from "../../../external/preact/dist/preact.js";
import { getTimeDifference as t } from "./utils.js";
import { useRef as s, useState as n, useEffect as o } from "../../../external/preact/hooks/dist/hooks.js";
import { useCountdownA11yReporter as a } from "./useCountdownA11yReporter.js";
function r({
  minutesFromNow: r,
  onTick: c = () => {},
  onCompleted: m = () => {}
}) {
  const u = s((e => {
      const t = 6e4 * e,
        s = new Date().getTime();
      return {
        startTime: new Date(s),
        endTime: new Date(s + t)
      };
    })(r)),
    {
      startTime: i,
      endTime: d
    } = u.current,
    [p, l] = n({
      minutes: "-",
      seconds: "-"
    });
  return a(p), o(() => {
    const e = setInterval(() => {
      const {
        minutes: e,
        seconds: s,
        percentage: n,
        completed: o
      } = t(i, d);
      if (o) m();else {
        const t = {
          minutes: e,
          seconds: s,
          percentage: n
        };
        l(t), c(t);
      }
    }, 1e3);
    return () => {
      clearInterval(e);
    };
  }, []), e("span", {
    className: "adyen-checkout__countdown",
    role: "timer"
  }, e("span", {
    className: "countdown__minutes"
  }, p.minutes), e("span", {
    className: "countdown__separator"
  }, ":"), e("span", {
    className: "countdown__seconds"
  }, p.seconds));
}
export { r as default };
