import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrl: './upload-modal.component.scss'
})
export class UploadModalComponent {
  public loading: boolean;
  protected files: File[];
  @Input()
  public accept: string = '*';
  @Input()
  public multiple: boolean = false;
  @Input()
  public enableDragDrop: boolean = false;
  @Input()
  public uploading: boolean = false;
  @Input()
  uploadMethod: (file: File[]) => Promise<any>;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  onFileSelect(files: File[]) {
    this.files = files;
    // if (files.length > 0) {
    //   const fileName = files.length === 1 ? files[0].name : files.length + '';
    // }
  }

  onUpload() {
    if (!this.files || this.files.length === 0) {
      return;
    }
    this.loading = true;
    const files: File[] = this.files;
    this.uploadMethod(files).then(
      (data) => {
        this.activeModal.close(data);
        this.loading = false;
      },
      error => {
        console.log('error', error);
        this.loading = false;
      }
    );
  }
}
