import s from "../Services/sessions/make-payment.js";
import e from "../Services/sessions/submit-details.js";
import t from "../Services/sessions/setup-session.js";
import i from "../Services/sessions/check-balance.js";
import o from "../../utils/Storage.js";
import n from "../Services/sessions/create-order.js";
import { sanitizeSession as a } from "./utils.js";
import r from "../Services/sessions/cancel-order.js";
import h from "../../utils/browserInfo.js";
function c(s, e, t) {
  return e in s ? Object.defineProperty(s, e, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : s[e] = t, s;
}
class u {
  get shopperLocale() {
    return this.session.shopperLocale;
  }
  get id() {
    return this.session.id;
  }
  get data() {
    return this.session.sessionData;
  }
  updateSessionData(s) {
    this.session.sessionData = s, this.storeSession();
  }
  setupSession(s) {
    const e = {
      ...s,
      browserInfo: h()
    };
    return t(this, e).then(s => (s.configuration && (this.configuration = {
      ...s.configuration
    }), s.sessionData && this.updateSessionData(s.sessionData), s));
  }
  submitPayment(e) {
    return s(e, this).then(s => (s.sessionData && this.updateSessionData(s.sessionData), s));
  }
  submitDetails(s) {
    return e(s, this).then(s => (s.sessionData && this.updateSessionData(s.sessionData), s));
  }
  checkBalance(s) {
    return i(s, this).then(s => (s.sessionData && this.updateSessionData(s.sessionData), s));
  }
  createOrder() {
    return n(this).then(s => (s.sessionData && this.updateSessionData(s.sessionData), s));
  }
  cancelOrder(s) {
    return r(s.order, this).then(s => (s.sessionData && this.updateSessionData(s.sessionData), s));
  }
  getStoredSession() {
    const s = this.storage.get();
    return this.id === s?.id ? s : this.session;
  }
  storeSession() {
    this.storage.set({
      id: this.session.id,
      sessionData: this.session.sessionData
    });
  }
  removeStoredSession() {
    this.storage.remove();
  }
  constructor(s, e, t) {
    c(this, "session", void 0), c(this, "storage", void 0), c(this, "clientKey", void 0), c(this, "loadingContext", void 0), c(this, "configuration", void 0);
    const i = a(s);
    if (!e) throw new Error("No clientKey available");
    if (!t) throw new Error("No loadingContext available");
    this.storage = new o("session", "localStorage"), this.clientKey = e, this.loadingContext = t, this.session = i, this.session.sessionData ? this.storeSession() : this.session = this.getStoredSession();
  }
}
export { u as default };
