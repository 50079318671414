import { Component as e, createElement as t } from "../../../../external/preact/dist/preact.js";
import r from "../../../../utils/detectInIframeInSameOrigin.js";
function o(e, t, r) {
  return t in e ? Object.defineProperty(e, t, {
    value: r,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = r, e;
}
class s extends e {
  componentDidMount() {
    new Promise((e, t) => {
      this.props.beforeRedirect(e, t, {
        url: this.props.url,
        method: this.props.method,
        ...(this.props.data ? {
          data: this.props.data
        } : {})
      });
    }).then(() => {
      this.props.onActionHandled?.({
        componentType: this.props.paymentMethodType,
        actionDescription: "performing-redirect"
      }), this.postForm ? this.postForm.submit() : this.props.redirectFromTopWhenInIframe && r() ? window.top.location.assign?.(this.props.url) : window.location.assign(this.props.url);
    }).catch(() => {
      this.props.onRedirectError();
    });
  }
  render({
    url: e,
    method: o,
    data: s = {}
  }) {
    return "POST" === o ? t("form", {
      method: "post",
      "data-testid": "redirect-shopper-form",
      action: e,
      style: {
        display: "none"
      },
      ref: e => {
        this.postForm = e;
      },
      ...(this.props.redirectFromTopWhenInIframe && r() && {
        target: "_top"
      })
    }, Object.keys(s).map(e => t("input", {
      type: "hidden",
      name: e,
      key: e,
      value: s[e]
    }))) : null;
  }
  constructor(...e) {
    super(...e), o(this, "postForm", void 0);
  }
}
o(s, "defaultProps", {
  beforeRedirect: e => e(),
  onRedirectError: () => {},
  method: "GET"
});
export { s as default };
