function e(e, t, n) {
  return t in e ? Object.defineProperty(e, t, {
    value: n,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = n, e;
}
class t {
  begin() {
    return this.session.begin();
  }
  onvalidatemerchant(e, t) {
    return new Promise((n, o) => t(n, o, e.validationURL)).then(e => {
      this.session.completeMerchantValidation(e);
    }).catch(e => {
      console.error(e), this.session.abort(), this.options.onError(e);
    });
  }
  onpaymentauthorized(e, t) {
    return new Promise((n, o) => t(n, o, e)).then(e => {
      this.session.completePayment(e);
    }).catch(e => {
      this.session.completePayment(e);
    });
  }
  onpaymentmethodselected(e, t) {
    return new Promise((n, o) => t(n, o, e)).then(e => {
      this.session.completePaymentMethodSelection(e);
    }).catch(e => {
      this.session.completePaymentMethodSelection(e);
    });
  }
  onshippingcontactselected(e, t) {
    return new Promise((n, o) => t(n, o, e)).then(e => {
      this.session.completeShippingContactSelection(e);
    }).catch(e => {
      this.session.completeShippingContactSelection(e);
    });
  }
  onshippingmethodselected(e, t) {
    return new Promise((n, o) => t(n, o, e)).then(e => {
      this.session.completeShippingMethodSelection(e);
    }).catch(e => {
      this.session.completeShippingMethodSelection(e);
    });
  }
  oncancel(e, t) {
    t(e);
  }
  constructor(t, n) {
    e(this, "session", void 0), e(this, "options", void 0), this.options = n, this.session = new ApplePaySession(n.version, t), this.session.onvalidatemerchant = e => {
      this.onvalidatemerchant(e, n.onValidateMerchant);
    }, this.session.onpaymentauthorized = e => {
      this.onpaymentauthorized(e, n.onPaymentAuthorized);
    }, this.session.oncancel = e => {
      this.oncancel(e, n.onCancel);
    }, "function" == typeof n.onPaymentMethodSelected && (this.session.onpaymentmethodselected = e => {
      this.onpaymentmethodselected(e, n.onPaymentMethodSelected);
    }), "function" == typeof n.onShippingContactSelected && (this.session.onshippingcontactselected = e => {
      this.onshippingcontactselected(e, n.onShippingContactSelected);
    }), "function" == typeof n.onShippingMethodSelected && (this.session.onshippingmethodselected = e => {
      this.onshippingmethodselected(e, n.onShippingMethodSelected);
    });
  }
}
export { t as default };
