import { getMastercardSettings as t, MC_SDK_PROD as e, MC_SDK_TEST as i } from "./config.js";
import n from "./AbstractSrcInitiator.js";
import a from "./SrciError.js";
const o = {
  email: "EMAIL_ADDRESS",
  telephoneNumber: "MOBILE_PHONE_NUMBER"
};
class s extends n {
  isSdkIsAvailableOnWindow() {
    return !!window.SRCSDK_MASTERCARD;
  }
  assignSdkReference() {
    this.schemeSdk = window.SRCSDK_MASTERCARD;
  }
  async init(e, i) {
    try {
      const n = {
        ...e,
        ...t(this.customSdkConfiguration),
        srciTransactionId: i
      };
      await this.schemeSdk.init(n);
    } catch (t) {
      throw new a(t, "init", this.schemeName);
    }
  }
  async identityLookup({
    identityValue: t,
    type: e
  }) {
    try {
      const i = {
        identityValue: t,
        identityType: o[e]
      };
      return await this.schemeSdk.identityLookup({
        consumerIdentity: i
      });
    } catch (t) {
      throw new a(t, "identityLookup", this.schemeName);
    }
  }
  async completeIdentityValidation(t) {
    try {
      return await this.schemeSdk.completeIdentityValidation({
        validationData: t
      });
    } catch (t) {
      throw new a(t, "completeIdentityValidation", this.schemeName);
    }
  }
  constructor(t, n) {
    var a, o, s;
    super(t.toLowerCase().includes("live") ? e : i, n), s = "mc", (o = "schemeName") in (a = this) ? Object.defineProperty(a, o, {
      value: s,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : a[o] = s;
  }
}
export { s as default };
