import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import { useRef as r, useState as o, useCallback as s, useEffect as t } from "../../../../../external/preact/hooks/dist/hooks.js";
import { loginValidationRules as a } from "./validate.js";
import { useCoreContext as n } from "../../../../../core/Context/CoreProvider.js";
import i from "../../../../../utils/useForm/useForm.js";
import p from "../../../FormFields/Field/Field.js";
import l from "../../../FormFields/InputEmail.js";
const d = d => {
  const {
      i18n: u
    } = n(),
    {
      handleChangeFor: m,
      data: c,
      triggerValidation: g,
      valid: h,
      errors: f,
      isValid: L
    } = i({
      schema: ["shopperLogin"],
      rules: a
    }),
    F = r({
      validateInput: null
    }),
    [j, v] = o(!1),
    b = s(() => {
      v(!0), g();
    }, [g]);
  t(() => {
    c.shopperLogin && v(!0);
  }, [c.shopperLogin]), t(() => {
    F.current.validateInput = b, d.onSetInputHandlers(F.current);
  }, [b, d.onSetInputHandlers]);
  const E = s(e => {
    "Enter" === e.key && d.onPressEnter();
  }, [d.onPressEnter]);
  return t(() => {
    d.onChange({
      data: c,
      valid: h,
      errors: f,
      isValid: L
    });
  }, [c, h, f]), e(p, {
    name: "shopperLogin",
    label: u.get("ctp.login.inputLabel"),
    errorMessage: j ? d.errorMessage || !!f.shopperLogin : null,
    classNameModifiers: ["shopperLogin"]
  }, e(l, {
    name: "shopperLogin",
    autocorrect: "off",
    spellcheck: !1,
    value: c.shopperLogin,
    disabled: d.disabled,
    onInput: m("shopperLogin", "input"),
    onBlur: m("shopperLogin", "blur"),
    onKeyPress: E
  }));
};
export { d as default };
