import { createElement as e } from "../../../../external/preact/dist/preact.js";
import { useState as r, useLayoutEffect as t } from "../../../../external/preact/hooks/dist/hooks.js";
import o from "../../FormFields/Field/Field.js";
import { useCoreContext as s } from "../../../../core/Context/CoreProvider.js";
import a from "../../../../core/Services/get-dataset.js";
import i from "../../FormFields/Select/Select.js";
function n(n) {
  const {
      classNameModifiers: l,
      label: c,
      onDropdownChange: d,
      readOnly: m,
      selectedCountry: f,
      specifications: u,
      value: p,
      required: h
    } = n,
    {
      i18n: g,
      loadingContext: j
    } = s(),
    [v, y] = r([]),
    [C, F] = r(!1);
  return t(() => {
    if (!f || !u.countryHasDataset(f)) return y([]), void F(!0);
    a(`states/${f}`, j, g.locale).then(e => {
      const r = e && e.length ? e : [];
      y(r), F(!0);
    }).catch(() => {
      y([]), F(!0);
    });
  }, [f]), C && v.length ? e(o, {
    label: c,
    classNameModifiers: l,
    errorMessage: n.errorMessage,
    isValid: !!p,
    showValidIcon: !1,
    name: "stateOrProvince",
    i18n: g,
    readOnly: m && !!p
  }, e(i, {
    name: "stateOrProvince",
    onChange: d,
    selectedValue: p,
    items: v,
    required: h,
    readonly: m && !!p
  })) : null;
}
export { n as default };
