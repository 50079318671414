import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutSingleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { mergeMap, Observable, of } from 'rxjs';
import { OrderCardListComponent } from '../../components/order-card-list/order-card-list.component';
import { Order } from '../../models/order';
import { OrderService } from '../orders/order.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { DateUtilService } from 'gung-common';

export class OrderIdFilter extends SimpleConfigBaseFilter<Order> {
  getOptionIds(item: Order): string[] {
    return [item.id];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return 'ORDER_NUMBER';
  }
}

export class OrderGmeStatusFilter extends SimpleConfigBaseFilter<Order> {
  getOptionIds(item: Order): string[] {
    return [item?.extra.gme?.status];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return 'ORDER_STATUS';
  }
}

export class OrderGmeDateFilter extends SimpleConfigBaseFilter<Order> {
  type = 'dateRangeFilter';
  constructor(
    protected dateUtilService: DateUtilService
  ) {
    super();
  }

  getOptionIds(item: Order): string[] {
    if (item.extra.gme?.orderDate?.trim()) {
      return [this.dateUtilService.getFormattedDateString(new Date(item.extra.gme.orderDate), 'yyMMdd')];
    }
    return [];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return 'ORDER_DATE';
  }
}

export class OrderGmeDeliveryDateFilter extends SimpleConfigBaseFilter<Order> {
  type = 'dateRangeFilter';
  constructor(
    protected dateUtilService: DateUtilService
  ) {
    super();
  }

  getOptionIds(item: Order): string[] {
    if (item.extra.gme?.deliveryDate?.trim()) {
      return [this.dateUtilService.getFormattedDateString(new Date(item.extra.gme.deliveryDate), 'yyMMdd')];
    }
    return [];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return 'DELIVERY_DATE';
  }
}

@Injectable({
  providedIn: 'root'
})
export class OrderListConfigService implements ConfigService<Order> {
  customerId: string;
  protected maxSize = 1000;
  protected includeRows = false;
  topFilter = true;
  /*
  This variable and setter is called in the invoice-list-component to set
  the customerId to the id in the route params. It did not work to get the routeparams
  from this service.
  */
  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  constructor(protected orderService: OrderService, protected selectedCustomerService: SelectedCustomerService, protected dateUtilService: DateUtilService) { }

  getItems(): Observable<Order[]> {
    if (this.customerId == null) {
      return this.selectedCustomerService.getSelectedCustomer().pipe(
        mergeMap(customer => {
          if (customer != null) {
            return this.orderService.getOrders(customer.id, false, this.maxSize, this.includeRows);
          } else {
            return of([]);
          }
        })
      );
    } else {
      return this.orderService.getOrders(this.customerId, false, this.maxSize, this.includeRows);
    }
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    return [
      new OrderGmeStatusFilter(),
      new OrderGmeDateFilter(this.dateUtilService),
      new OrderGmeDeliveryDateFilter(this.dateUtilService)
    ];
  }

  getSortOptions(): ListSortOption<Order>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OrderCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getSearchTerms(item: Order): string[] {
    return [item.id];
  }

  getItemId(item: Order): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
