import { createElement as e } from "../../../../external/preact/dist/preact.js";
import { useState as r, useLayoutEffect as o } from "../../../../external/preact/hooks/dist/hooks.js";
import t from "../../FormFields/Field/Field.js";
import { useCoreContext as a } from "../../../../core/Context/CoreProvider.js";
import s from "../../../../core/Services/get-dataset.js";
import n from "../../FormFields/Select/Select.js";
function l(l) {
  const {
      allowedCountries: i = [],
      classNameModifiers: c = [],
      errorMessage: m,
      onDropdownChange: d,
      value: u,
      required: p
    } = l,
    {
      i18n: f,
      loadingContext: g
    } = a(),
    [h, y] = r([]),
    [j, C] = r(!1),
    [F, x] = r(l.readOnly);
  return o(() => {
    s("countries", g, f.locale).then(e => {
      const r = ((e, r) => {
        const o = e => ({
          ...e,
          name: e.name,
          selectedOptionName: e.name
        });
        return r.length ? e.filter(e => r.includes(e.id)).map(o) : e.map(o);
      })(e, i);
      y(r || []), x(1 === r.length || F), C(!0);
    }).catch(e => {
      console.error(e), y([]), C(!0);
    });
  }, []), j ? e(t, {
    name: "country",
    label: f.get("country"),
    errorMessage: m,
    classNameModifiers: c,
    isValid: !!u,
    showValidIcon: !1,
    i18n: f,
    readOnly: F && !!u
  }, e(n, {
    onChange: d,
    name: "country",
    selectedValue: u,
    items: h,
    readonly: F && !!u,
    required: p
  })) : null;
}
export { l as default };
