import { createElement as e } from "../../../../../../external/preact/dist/preact.js";
import { useState as t, useRef as r, useCallback as o, useEffect as s } from "../../../../../../external/preact/hooks/dist/hooks.js";
import { otpValidationRules as n } from "./validate.js";
import a from "./CtPResendOtpLink.js";
import l from "../../../context/useClickToPayContext.js";
import { useCoreContext as i } from "../../../../../../core/Context/CoreProvider.js";
import d from "../../../../../../utils/useForm/useForm.js";
import u from "../../../../FormFields/Field/Field.js";
import p from "../../../../FormFields/InputText.js";
const c = c => {
  const {
      i18n: m
    } = i(),
    {
      configuration: {
        disableOtpAutoFocus: f
      }
    } = l(),
    [C, g] = t(null),
    {
      handleChangeFor: F,
      data: b,
      triggerValidation: j,
      valid: h,
      errors: k,
      isValid: v,
      setData: x
    } = d({
      schema: ["otp"],
      rules: n
    }),
    E = r({
      validateInput: null
    }),
    P = r(null),
    [R, I] = t(!1),
    O = o(() => {
      I(!0), j();
    }, [j]);
  s(() => {
    b.otp && I(!0);
  }, [b.otp]), s(() => {
    !f && P.current && P.current.focus();
  }, [P.current, f]), s(() => {
    E.current.validateInput = O, c.onSetInputHandlers(E.current);
  }, [O, c.onSetInputHandlers]);
  const V = o(() => {
      x("otp", ""), g(null), f || P.current.focus(), c.onResendCode();
    }, [c.onResendCode, P.current, f]),
    y = o(e => {
      const t = m.get(`ctp.errors.${e}`);
      t && g(t);
    }, [m]),
    M = o(e => {
      "Enter" === e.key && c.onPressEnter();
    }, [c.onPressEnter]);
  return s(() => {
    c.onChange({
      data: b,
      valid: h,
      errors: k,
      isValid: v
    });
  }, [b, h, k]), e(u, {
    name: "oneTimePassword",
    label: m.get("ctp.otp.fieldLabel"),
    labelEndAdornment: !c.hideResendOtpButton && e(a, {
      disabled: c.isValidatingOtp,
      onError: y,
      onResendCode: V
    }),
    errorMessage: R ? C || c.errorMessage || !!k.otp : null,
    classNameModifiers: ["otp"]
  }, e(p, {
    name: "otp",
    autocorrect: "off",
    spellcheck: !1,
    value: b.otp,
    disabled: c.disabled,
    onInput: F("otp", "input"),
    onBlur: F("otp", "blur"),
    onKeyPress: M,
    setRef: e => {
      P.current = e;
    }
  }));
};
export { c as default };
