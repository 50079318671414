import { ERROR_ACTION_FOCUS_FIELD as e, ERROR_ACTION_BLUR_SCENARIO as r, SF_ErrorCodes as s, ErrorCodePrefixes as t } from "./constants.js";
import { ENCRYPTED_BANK_LOCATION_FIELD as o, ENCRYPTED_BANK_ACCNT_NUMBER_FIELD as n, ENCRYPTED_PWD_FIELD as a, ENCRYPTED_SECURITY_CODE as i, ENCRYPTED_EXPIRY_YEAR as c, ENCRYPTED_EXPIRY_MONTH as l, ENCRYPTED_EXPIRY_DATE as u, ENCRYPTED_CARD_NUMBER as f } from "../../components/internal/SecuredFields/lib/constants.js";
const d = (e, r) => Object.values(s).reduce((s, t) => (t.includes(r) && (s[t] = e.get(t)), s), {}),
  p = (e, r, s) => {
    const t = {
        ...e
      },
      o = g(s);
    return t.error = d(r, o), t;
  },
  b = (e, r) => {
    let s = e;
    for (const [t, o] of Object.entries(r)) if (o === e) {
      s = t;
      break;
    }
    return s?.toLowerCase().replace(/[_.\s]/g, "-");
  },
  g = e => {
    let r;
    switch (e) {
      case f:
        r = t.CC_NUM;
        break;
      case u:
        r = t.CC_DAT;
        break;
      case l:
        r = t.CC_MTH;
        break;
      case c:
        r = t.CC_YR;
        break;
      case i:
        r = t.CC_CVC;
        break;
      case a:
        r = t.KCP_PWD;
        break;
      case n:
        r = t.ACH_NUM;
        break;
      case o:
        r = t.ACH_LOC;
    }
    return r;
  },
  y = ({
    errors: e,
    i18n: r,
    layout: s,
    countrySpecificLabels: t,
    fieldTypeMappingFn: o
  }) => Object.entries(e).reduce((n, [a, i]) => {
    if (i) {
      const i = e[a],
        c = "errorI18n" in i && "rootNode" in i,
        l = "object" == typeof i.errorMessage;
      let u, f;
      if (u = c ? i.error : l ? i.errorMessage.translationKey : i.errorMessage, c && "errorI18n" in i) f = i.errorI18n + "";else {
        const e = o ? o(a, r, t) : "";
        if (l) {
          const s = i.errorMessage.translationKey,
            t = i.errorMessage.translationObject.values.format;
          f = `${r.get(s, {
            values: {
              label: e,
              format: t
            }
          })}`;
        } else f = r.get(i.errorMessage, {
          values: {
            label: e
          }
        }) + "";
      }
      n.push({
        field: a,
        errorMessage: f,
        errorCode: u
      }), s && n.sort((e, r) => s.indexOf(e.field) - s.indexOf(r.field));
    }
    return n;
  }, []),
  C = ({
    i18n: s,
    fieldTypeMappingFn: t,
    SRPanelRef: o
  }, {
    errors: n,
    isValidating: a,
    layout: i,
    countrySpecificLabels: c
  }) => {
    const l = y({
      errors: n,
      i18n: s,
      fieldTypeMappingFn: t,
      countrySpecificLabels: c,
      layout: i
    });
    if (l.length) {
      if (a) {
        const r = l.map(e => e.errorMessage);
        o.setMessages(r);
        const s = l.map(e => e.field);
        return {
          currentErrorsSortedByLayout: l,
          action: e,
          fieldToFocus: s[0]
        };
      }
      return o?.setMessages(null), {
        currentErrorsSortedByLayout: l,
        action: r
      };
    }
    return o?.setMessages(null), {
      currentErrorsSortedByLayout: l,
      action: "none"
    };
  },
  M = (e, r) => {
    if (!e) return null;
    return Object.entries(e).reduce((e, [s, t]) => {
      if (t) {
        e[`${r}${s}`] = t;
      }
      return e;
    }, {});
  };
export { p as addErrorTranslationsToObject, M as enhanceErrorObjectKeys, b as getErrorMessageFromCode, d as getTranslatedErrors, C as setSRMessagesFromErrors, y as sortErrorsByLayout };
