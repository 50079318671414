import { Component } from '@angular/core';
import { CustomPageEditorModalComponent } from '../custom-page-editor-modal/custom-page-editor-modal.component';

@Component({
  selector: 'lib-custom-page-text-input-editor-modal',
  templateUrl: './custom-page-text-input-editor-modal.component.html',
  styleUrls: ['./custom-page-text-input-editor-modal.component.css']
})
export class CustomPageTextInputEditorModalComponent extends CustomPageEditorModalComponent {
  mapData() { }
}
