<div class="row contrast-container product-detail" *ngIf="concept != null; else loading">
  <div class="col-xs-12 col-sm-6">
    <lib-image-carousel *ngIf="concept" [openExternal]="false" [zoom]="false" [images]="concept.extra.images" imageSize="500x500" [highRes]="true">
    </lib-image-carousel>
  </div>
  <div class="col-xs-12 col-sm-6">
    <h1>{{ concept.name }} - {{ concept.id }}</h1>
    <p [innerHTML]="(concept.extra?.i18n?.[currentLang]?.description || concept.extra?.pim?.description || concept.description || '') | safeHtml"></p>
    <!--TODO add dynamic properties for the concept here-->
  </div>
</div>

<ng-template #cloudPimFields>
  <lib-cloud-pim-product-fields [product]="concept"></lib-cloud-pim-product-fields>
</ng-template>
<ng-template #nan></ng-template>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
