import e from "../Errors/AdyenCheckoutError.js";
function r(e, r, t) {
  return r in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
class t {
  getImage(e = {}) {
    return this.getImageUrl({
      ...e,
      resourceContext: this.resourceContext
    });
  }
  constructor(t) {
    if (r(this, "resourceContext", void 0), r(this, "returnImage", ({
      name: e,
      resourceContext: r,
      imageFolder: t = "",
      parentFolder: o = "",
      extension: n,
      size: s = "",
      subFolder: i = ""
    }) => `${r}images/${t}${i}${o}${e}${s}.${n}`), r(this, "getImageUrl", ({
      resourceContext: e,
      extension: r = "svg",
      ...t
    }) => o => {
      const n = {
        extension: r,
        resourceContext: e,
        imageFolder: "logos/",
        parentFolder: "",
        name: o,
        ...t
      };
      return this.returnImage(n);
    }), !t) throw new e("IMPLEMENTATION_ERROR", 'Resources module: "environmentsUrls.cdn" is not a valid URL');
    this.resourceContext = t;
  }
}
export { t as Resources };
