import t from "../Services/analytics/collect-id.js";
import e from "./EventsQueue.js";
import { ANALYTICS_EVENT as n, ANALYTICS_INFO_TIMER_INTERVAL as a, ANALYTIC_LEVEL as l, ANALYTICS_PATH as o } from "./constants.js";
import { debounce as s } from "../../utils/debounce.js";
import { processAnalyticsData as c, createAnalyticsObject as i } from "./utils.js";
import { analyticsPreProcessor as r } from "./analyticsPreProcessor.js";
let u = null,
  y = null;
const d = ({
  locale: d,
  clientKey: m,
  analytics: p,
  amount: f,
  analyticsContext: b,
  bundleType: h
}) => {
  const v = {
      enabled: !0,
      checkoutAttemptId: null,
      analyticsData: {},
      ...p
    },
    j = t({
      analyticsContext: b,
      clientKey: m,
      locale: d,
      amount: f,
      analyticsPath: o,
      bundleType: h
    }),
    g = e({
      analyticsContext: b,
      clientKey: m,
      analyticsPath: o
    }),
    A = () => u ? g.run(u) : Promise.resolve(null),
    E = {
      setUp: async t => {
        const {
            payload: e,
            enabled: n
          } = v,
          a = n ? l.all : l.initial,
          o = c(v.analyticsData);
        if (!u) try {
          u = await j({
            ...t,
            ...(e && {
              ...e
            }),
            ...(Object.keys(o).length && {
              ...o
            }),
            level: a
          });
        } catch (t) {
          console.warn("Fetching checkoutAttemptId failed." + (t ? ` Error=${t}` : ""));
        }
      },
      getCheckoutAttemptId: () => u,
      getEventsQueue: () => g,
      createAnalyticsEvent: ({
        event: t,
        data: e
      }) => {
        if (!v.enabled) return;
        const l = i({
          event: t,
          ...e
        });
        return ((t, e) => {
          const l = t === n.info ? t : `${t}s`;
          g.add(`${l}`, e), t === n.info && (clearTimeout(y), y = setTimeout(() => {
            A();
          }, a)), t !== n.log && t !== n.error || (clearTimeout(y), s(A)());
        })(t, l), l;
      },
      getEnabled: () => v.enabled,
      sendAnalytics: null
    };
  return E.sendAnalytics = !0 === v.enabled ? r(E) : () => {}, E;
};
export { d as default };
