import e from "./VisaSdk.js";
import t from "./MastercardSdk.js";
import o from "../../../../../core/Errors/AdyenCheckoutError.js";
import { isRejected as s, isFulfilled as r } from "../../../../../utils/promise-util.js";
function i(e, t, o) {
  return t in e ? Object.defineProperty(e, t, {
    value: o,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = o, e;
}
const a = {
  visa: e,
  mc: t,
  default: null
};
class n {
  async load(e) {
    if (!this.schemes || 0 === this.schemes.length) throw new o("ERROR", "ClickToPay -> SrcSdkLoader: There are no schemes set to be loaded");
    return new Promise((t, i) => {
      const n = this.schemes.map(t => ((e, t, o) => {
          const s = a[e] || a.default;
          return s ? new s(t, o) : null;
        })(t, e, this.customSdkConfiguration)),
        c = n.map(e => e.loadSdkScript());
      Promise.allSettled(c).then(e => {
        e.every(s) && i(new o("ERROR", `ClickToPay -> SrcSdkLoader # Unable to load network schemes: ${this.schemes.toString()}`));
        const a = n.filter((t, o) => r(e[o]));
        t(a);
      });
    });
  }
  constructor(e, {
    dpaLocale: t = "en_US",
    dpaPresentationName: o = ""
  }) {
    i(this, "schemes", void 0), i(this, "customSdkConfiguration", void 0), this.schemes = e, this.customSdkConfiguration = {
      dpaLocale: t,
      dpaPresentationName: o
    };
  }
}
export { n as default };
