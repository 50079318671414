import { interpolateElement as t } from "../../../language/utils.js";
function s(t, s, e) {
  return s in t ? Object.defineProperty(t, s, {
    value: e,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[s] = e, t;
}
class e {
  update(t) {
    const {
      minutes: s,
      seconds: e
    } = t;
    if ("-" === s || "-" === e) return;
    const i = "string" == typeof s ? parseInt(s, 10) : s,
      r = "string" == typeof e ? parseInt(e, 10) : e;
    this.timeLeft = {
      minutes: i,
      seconds: r
    }, i > 5 && this.timeout !== this.LONG_TIMEOUT && (this.timeout = this.LONG_TIMEOUT, this.setInterval(this.timeout)), i >= 1 && i <= 5 && this.timeout !== this.MID_TIMEOUT && (this.timeout = this.MID_TIMEOUT, this.setInterval(this.timeout)), i < 1 && r > 0 && this.timeout !== this.SHORT_TIMEOUT && (this.timeout = this.SHORT_TIMEOUT, this.setInterval(this.timeout));
  }
  tearDown() {
    this.clearInterval(), this.srPanel.setAriaProps({
      "aria-relevant": this.srPanel.constructor.defaultProps.ariaAttributes["aria-relevant"]
    }), this.srPanel.setMessages(null);
  }
  setInterval(t) {
    this.clearInterval();
    const s = () => {
      this.srPanel.setMessages(null), this.srPanel.setMessages(this.getSrMessages(this.timeLeft));
    };
    s(), this.srInterval = setInterval(s, t);
  }
  getSrMessages({
    minutes: s,
    seconds: e
  }) {
    const i = this.i18n.get(this.TRANSLATION_KEY),
      r = [s, e].map(t => 0 !== t ? s => `${t} ${s}` : () => "");
    return [t(i, r).join("")];
  }
  clearInterval() {
    this.srInterval && clearInterval(this.srInterval);
  }
  constructor(t) {
    s(this, "TRANSLATION_KEY", "sr.wechatpay.timetopay"), s(this, "LONG_TIMEOUT", 18e4), s(this, "MID_TIMEOUT", 6e4), s(this, "SHORT_TIMEOUT", 3e4), s(this, "srPanel", void 0), s(this, "i18n", void 0), s(this, "srInterval", void 0), s(this, "timeout", void 0), s(this, "timeLeft", void 0);
    const {
      srPanel: e,
      i18n: i
    } = t;
    this.srPanel = e, this.i18n = i, this.srPanel.setAriaProps({
      "aria-relevant": "additions text"
    });
  }
}
export { e as CountdownA11yReporter };
