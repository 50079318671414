import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class ProductDepthFilterService extends SimpleConfigBaseFilter<Product> {
  getName(): string {
    return 'DEPTH';
  }
  getOptionIds(item: Product): string[] {
    const depth = item.extra.hlPimItem.ItemShelfDepth || item.extra.hlPimItem.ItemDepthmm || '';
    return [depth];
  }
  getOptionName(key: string): string {
    return key + ' mm';
  }
}
