function t(t, e, r) {
  return e in t ? Object.defineProperty(t, e, {
    value: r,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[e] = r, t;
}
class e {
  get length() {
    return Object.keys(this.storage).length;
  }
  key(t) {
    return Object.keys(this.storage)[t];
  }
  getItem(t) {
    return this.storage[t] || null;
  }
  setItem(t, e) {
    return this.storage[t] = e;
  }
  removeItem(t) {
    delete this.storage[t];
  }
  clear() {
    this.storage = {};
  }
  constructor() {
    t(this, "storage", void 0), this.storage = {};
  }
}
class r {
  get() {
    try {
      return JSON.parse(this.storage.getItem(this.key));
    } catch (t) {
      return null;
    }
  }
  set(t) {
    this.storage.setItem(this.key, JSON.stringify(t));
  }
  remove() {
    this.storage.removeItem(this.key);
  }
  clear() {
    this.storage.clear();
  }
  keyByIndex(t) {
    return this.storage.key(t);
  }
  get length() {
    return this.storage.length;
  }
  constructor(r, s) {
    t(this, "prefix", "adyen-checkout__"), t(this, "key", void 0), t(this, "storage", void 0);
    try {
      if (this.storage = s ? window[s] : window.localStorage, !this.storage) throw new Error("storage does not exist");
    } catch (t) {
      this.storage = new e();
    }
    this.key = this.prefix + r;
  }
}
export { r as default };
