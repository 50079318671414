import { createElement as t } from "../../external/preact/dist/preact.js";
import { UIElement as e } from "../internal/UIElement/UIElement.js";
import n from "./components/PaypalComponent.js";
import i from "./defaultProps.js";
import { CoreProvider as r } from "../../core/Context/CoreProvider.js";
import s from "../../core/Errors/AdyenCheckoutError.js";
import { ERRORS as a } from "./constants.js";
import { TxVariants as o } from "../tx-variants.js";
import { formatPaypalOrderContactToAdyenFormat as h } from "./utils/format-paypal-order-contact-to-adyen-format.js";
function p(t, e, n) {
  return e in t ? Object.defineProperty(t, e, {
    value: n,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[e] = n, t;
}
class d extends e {
  formatProps(t) {
    const e = t.configuration?.merchantId,
      n = t.configuration?.intent,
      i = 0 === t.amount?.value ? "tokenize" : t.intent || n,
      r = "tokenize" === i || t.vault,
      s = "continue" === t.userAction;
    return {
      ...t,
      commit: !s && t.commit,
      vault: r,
      configuration: {
        intent: i,
        merchantId: e
      }
    };
  }
  submitAnalytics(t) {
    super.submitAnalytics({
      ...t
    }, this.props);
  }
  updatePaymentData(t) {
    t || console.warn("PayPal - Updating payment data with an invalid value"), this.paymentData = t;
  }
  formatData() {
    const {
      isExpress: t,
      userAction: e
    } = this.props;
    return {
      paymentMethod: {
        type: d.type,
        userAction: e,
        subtype: t ? "express" : d.subtype
      }
    };
  }
  get isValid() {
    return !0;
  }
  handleResolve(t) {
    if (!this.resolve) return this.handleError(new s("ERROR", a.WRONG_INSTANCE));
    this.resolve(t);
  }
  handleReject(t) {
    if (!this.reject) return this.handleError(new s("ERROR", a.WRONG_INSTANCE));
    this.reject(new Error(t));
  }
  handleSubmit() {
    return super.submit(), new Promise((t, e) => {
      this.resolve = t, this.reject = e;
    });
  }
  handleOnShippingAddressChange(t, e) {
    return this.props.onShippingAddressChange(t, e, this);
  }
  handleOnShippingOptionsChange(t, e) {
    return this.props.onShippingOptionsChange(t, e, this);
  }
  render() {
    if (!this.props.showPayButton) return null;
    const {
      onShippingAddressChange: e,
      onShippingOptionsChange: i,
      ...a
    } = this.props;
    return t(r, {
      i18n: this.props.i18n,
      loadingContext: this.props.loadingContext,
      resources: this.resources
    }, t(n, {
      ref: t => {
        this.componentRef = t;
      },
      ...a,
      ...(e && {
        onShippingAddressChange: this.handleOnShippingAddressChange
      }),
      ...(i && {
        onShippingOptionsChange: this.handleOnShippingOptionsChange
      }),
      onCancel: () => this.handleError(new s("CANCEL")),
      onChange: this.setState,
      onApprove: this.handleOnApprove,
      onError: t => {
        this.handleError(new s("ERROR", t.toString(), {
          cause: t
        }));
      },
      onScriptLoadFailure: t => this.handleError(t),
      onSubmit: this.handleSubmit
    }));
  }
  constructor(t, e) {
    super(t, e), p(this, "paymentData", null), p(this, "resolve", null), p(this, "reject", null), p(this, "submit", () => {
      this.handleError(new s("IMPLEMENTATION_ERROR", a.SUBMIT_NOT_SUPPORTED));
    }), p(this, "handleAction", t => this.updateWithAction(t)), p(this, "updateWithAction", t => {
      if (t.paymentMethodType !== this.type) throw new Error("Invalid Action");
      return t.paymentData && (this.paymentData = t.paymentData), t.sdkData && t.sdkData.token ? (this.onActionHandled({
        componentType: this.type,
        actionDescription: "sdk-loaded",
        originalAction: t
      }), this.handleResolve(t.sdkData.token)) : this.handleReject(a.NO_TOKEN_PROVIDED), null;
    }), p(this, "handleOnApprove", (t, e) => {
      const {
          onAuthorized: n
        } = this.props,
        i = {
          data: {
            details: t,
            paymentData: this.paymentData
          }
        };
      if (n) return e.order.get().then(t => {
        const e = h(t?.payer),
          i = h(t?.purchase_units?.[0].shipping, !0);
        return this.setState({
          authorizedEvent: t,
          ...(e && {
            billingAddress: e
          }),
          ...(i && {
            deliveryAddress: i
          })
        }), new Promise((r, s) => n({
          authorizedEvent: t,
          ...(e && {
            billingAddress: e
          }),
          ...(i && {
            deliveryAddress: i
          })
        }, {
          resolve: r,
          reject: s
        }));
      }).then(() => this.handleAdditionalDetails(i)).catch(t => this.handleError(new s("ERROR", "Something went wrong while parsing PayPal Order", {
        cause: t
      })));
      this.handleAdditionalDetails(i);
    }), this.handleSubmit = this.handleSubmit.bind(this), this.handleOnShippingAddressChange = this.handleOnShippingAddressChange.bind(this), this.handleOnShippingOptionsChange = this.handleOnShippingOptionsChange.bind(this);
  }
}
p(d, "type", o.paypal), p(d, "subtype", "sdk"), p(d, "defaultProps", i);
export { d as default };
