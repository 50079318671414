function i(i, e, t) {
  return e in i ? Object.defineProperty(i, e, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : i[e] = t, i;
}
class e {
  constructor(e) {
    i(this, "callbacks", void 0), i(this, "config", void 0), i(this, "props", void 0), i(this, "state", void 0), i(this, "validateForm", void 0), i(this, "handleBrandFromBinLookup", void 0), i(this, "callbacksHandler", void 0), i(this, "configHandler", void 0), i(this, "createCardSecuredFields", void 0), i(this, "createNonCardSecuredFields", void 0), i(this, "createSecuredFields", void 0), i(this, "destroySecuredFields", void 0), i(this, "handleIOSTouchEvents", void 0), i(this, "destroyTouchendListener", void 0), i(this, "destroyTouchstartListener", void 0), i(this, "handleBinValue", void 0), i(this, "handleEncryption", void 0), i(this, "handleFocus", void 0), i(this, "handleIframeConfigFeedback", void 0), i(this, "handleValidation", void 0), i(this, "handleSFShiftTab", void 0), i(this, "handleShiftTab", void 0), i(this, "isConfigured", void 0), i(this, "postMessageToAllIframes", void 0), i(this, "processAutoComplete", void 0), i(this, "processBrand", void 0), i(this, "sendBrandToCardSF", void 0), i(this, "sendExpiryDatePolicyToSF", void 0), i(this, "setFocusOnFrame", void 0), i(this, "setupSecuredField", void 0), i(this, "touchendListener", void 0), i(this, "touchstartListener", void 0), i(this, "hasGenuineTouchEvents", void 0), i(this, "encryptedAttrName", void 0), i(this, "hasRedundantCVCField", void 0), i(this, "isSingleBrandedCard", void 0), i(this, "securityCode", void 0), this.props = e, this.state = {}, this.config = {}, this.callbacks = {};
  }
}
export { e as default };
